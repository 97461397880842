import React from "react";
import NavBar from "./navbar";
import HeadBar from "./headBar";
import ButtonBar from "./buttonBar";
import Table from "./table";

class Patients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="container-fluid bo-top">
        <div className="row">
          <div className="col-2 bo-right">
            <NavBar history={this.props.history} />
          </div>
          <div className="col-10 bc-f5">
            <HeadBar />
            <ButtonBar />
            <Table />
          </div>
        </div>
      </div>
    );
  }
}
export default Patients;
