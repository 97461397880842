import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "rsuite";
import { faEye, faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, Redirect } from "react-router-dom";
import Pulse from "react-reveal/Pulse";
import { postData } from "../services/request";
import waveLogo from "../assets/images/fulllogo.png"
import translate from "../i18n/translation";
export default class RuralReg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      isBVNShow: false,
      isAgreedCheck: false,
      formEvent: null,
    };
  }
  signup = (e) => {
    const formEvent = e.target;
    e.preventDefault();
    var details = {
      firstname: e.target.firstname.value,
      lastname: e.target.lastname.value,
      email: e.target.email.value,
      country: e.target.country.value,
      dob: e.target.dob.value,
      user: "Patient",
      bvn: e.target.bvn && e.target.bvn.value ? e.target.bvn.value : "",
      regNumber: e.target.nin.value,
      nin: e.target.nin.value,
      phone: e.target.phone.value,
      password: e.target.password.value,
      confirm_password: e.target.confirm_password.value,
    };
    if (details.firstname.trim() === "" || details.firstname.trim() === null) {
      Alert.warning("Input your first name");
    } else if (
      details.lastname.trim() === "" ||
      details.lastname.trim() === null
      ) {
        Alert.warning("Input your last name");
      } else if (details.email.trim() === "" || details.email.trim() === null) {
        Alert.warning("Input your email");
      } else if (details.country === "Select Country") {
        Alert.warning("Select a country");
      } else if (details.nin.trim() === "" || details.nin.trim() === null) {
        Alert.warning("Provide a valid NIN number");
      } else if (
        details.password.trim() === null ||
        details.password.trim() === ""
        ) {
          Alert.warning("Input your password");
        } else if (
          details.confirm_password.trim() === null ||
          details.confirm_password.trim() === ""
          ) {
            Alert.warning("Confirm your password");
          } else if (details.password.trim() !== details.confirm_password.trim()) {
            Alert.error("Passwords do not match");
          } else {
            this.setState({ loader: true });
            // postData("register", details)
            if(this.state.isBVNShow) { 
              this.verifyUserByBVN(details);
            }else{
              postData("verify-nin", { nin: details.nin })
              .then((response) => {
                this.setState({ loader: false,formEvent:formEvent });
                if (response.info && response.info.length > 0) {
                  Alert.success(
                    "A verified NIN found, Validating User Information"
                    )
                    var data = response.info[0];
                    let isDataValid= true // make it false
                    if(data.firstname && details.firstname && data.lastname && details.lastname && data.firstname.toLowerCase().trim() ==  details.firstname.toLowerCase().trim() && data.lastname.toLowerCase().trim() ==  details.lastname.toLowerCase().trim() && new Date(data.Birth_date.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")) == new Date(details.dob)){
                      isDataValid = true;
                    }
  
                    if(!isDataValid){
                      Alert.error("NIN Not Matched, please enter your BVN to register", 4000);
                      // setTimeout(() => {window.location.href= "https://idverify.ng"}, 1000)
                      this.setState({isBVNShow:true,formEvent:formEvent})
                      return false;
                    }
  
                    const dataObj = {
                      firstname: data.firstname,
                      lastname: data.lastname,
                      email: data.NIN+'@telemedics.com',
                      user: "Patient",
                      reg_number: 0,
                      nin: "",
                      phone : details.phone,
                      country: details.country,
                      password: details.password,
                      confirm_password: details.confirm_password,
                      source: "nin"
                    }
                  this.registerUser(dataObj);
                } else {
                  Alert.error("No NIN found, please enter your BVN to register");
                  // setTimeout(() => {window.location.href= "https://idverify.ng"}, 1000)
                  this.setState({isBVNShow:true})
                }
              })
              .catch((error) => {
                this.setState({ loader: false });
                if (error.request.status === 422) {
                  Alert.error(error.response.data.errors.email);
                } else if(error.request.status === 404){
                  Alert.error("No NIN found, please enter your BVN to register");
                  this.setState({isBVNShow:true})
                }else{
                  Alert.error("Error creating account");
                }
              });
            }
          }
        };

        verifyUserByBVN = (details) => {
          postData("verify-bvn", { bvn: details.bvn })
          .then((response) => {
            this.setState({ loader: false });
            if (response && response.ResponseData !== null) {
              Alert.success(
                "A verified BVN found, Validating User Information"
                )
                var data = response.ResponseData;
                let isDataValid= false
                if(data.FirstName && details.firstname && data.LastName && details.lastname && data.FirstName.toLowerCase().trim() ==  details.firstname.toLowerCase().trim() && data.LastName.toLowerCase().trim() ==  details.lastname.toLowerCase().trim() && new Date(data.DateOfBirth) == new Date(details.dob)){
                  isDataValid = true;
                }

                if(!isDataValid){
                  Alert.error("BVN Not Matched, We can not proceed further", 4000);
                  // setTimeout(() => {window.location.href= "https://idverify.ng"}, 1000)
                  // this.setState({isBVNShow:true})
                  return false;
                }
                this.state.formEvent.firstname.value=data.FirstName
                this.state.formEvent.lastname.value=data.LastName
                this.state.formEvent.email.value=data.email
                this.state.formEvent.nin.value=data.nin
                this.state.formEvent.country.value=data.nationality
                this.state.formEvent.dob.value=data.DateOfBirth
                const dataObj = {
                  firstname: data.FirstName,
                  lastname: data.LastName,
                  email: data.email,
                  user: "Patient",
                  reg_number: 0,
                  phone : details.phone,
                  nin: "",
                  country: data.nationality,
                  password: details.password,
                  confirm_password: details.confirm_password,
                  source: "bvn"
                }
              this.registerUser(dataObj);
            } else {
              Alert.error("No BVN found, We Can Not Proceed with registration");
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({ loader: false });
            Alert.error("Error creating account");
          });
        }

        registerUser = (data) => {
          var details = data;
          postData("register", details)
          .then((response) => {
            this.setState({ loader: false });
            if (response.status === true) {
              Alert.success(response.message);
            } else {
              Alert.error(response.message);
            }
          })
          .catch((error) => {
            this.setState({ loader: false });
            if (error.request.status === 422) {
              Alert.error(error.response.data.errors.email);
            } else {
              Alert.error("Error creating account");
            }
          });

          this.registerWallet(details);
        };

        registerWallet(details) {
          postData("wallet-registration", {name: details.firstname + " " + details.lastname, email: details.email,  password: details.password, user_type: "telemedics"})
          .then((response) => {
            if (response.status === true) {
              // Alert.success();
              console.log('success in creation of wallet',response);
            } else {
              console.log('error in creation of wallet');
              // Alert.error(response.message);
            }
          })
          .catch((error) => {
            // this.setState({ loader: false });
            // if (error.request.status === 422) {
              // Alert.error(error.response.data.errors.email);
            // } else {
              // Alert.error("Error creating account");
            // }
            console.log('error in creation of wallet');
          });
        }

        render() {
          return (
            <>
              <Container fluid className="container-fluid">
                <div className="backdrop-signup2">
                  <Pulse>
                    <div className="">
                    {/* Normal Version */}
                      <Row className="d-none d-sm-block">
                        <Col md="5" className="bg-white pt-3" style={{"height": "100vh"}}>
                        {/* <span className="pull-right"><img src={waveLogo} alt="1" className="doc-images"/></span>
                        <Link to="/patientIndex">
                        <FontAwesomeIcon
                        size="2x"
                        icon={faArrowCircleLeft}
                        className="icons"
                        style={{"color":"#020197"}}
                        />
                        </Link> */}
                        <Row>
                          <Col md={2} className="pt-3">
                              <Link to="/patientIndex"><FontAwesomeIcon size="2x" icon={faArrowCircleLeft} className="icons" style={{"color":"#020197"}}/></Link>
                          </Col>
                          <Col md={10} className="pt-2">
                              <img src={waveLogo} alt="1" className="doc-images float-right" style={{"width": "72%"}}/>
                          </Col>
                        </Row>
                        <div style={{ width: "90%", margin: "auto" }}>
                          <br/>
                          <br/>
                        <form onSubmit={this.signup}>
                        <Row>
                          <Col>
                        <h3 className="text-center h2 font-weight-bold">
                          {translate('rural')} {translate('user')} {translate('registration')}
                        </h3>
                        <Link className="d-block text-center text-dark" to="/rurallogin">
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px"
                          }}
                        >
                          {translate('already have an account?')} {translate('login')}
                        </span>{" "}
                      </Link>
                      </Col>
                      </Row>
                        <br />
                        <Row>
                        <Col>
                        <label className="h5">First Name</label>
                        <input
                        type="text"
                        placeholder="First Name"
                        name="firstname"
                        id="firstname"
                        className="text-boxe"
                        />
                        </Col>
                        <Col>
                        <label className="h5">Last Name</label>
                        <input
                        type="text"
                        placeholder="Last Name"
                        className="text-boxe"
                        name="lastname"
                        id="lastname"
                        />
                        </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col>
                          <label className="h5">Date of Birth</label>
                          <input
                          type="date"
                          placeholder="Enter your date of birth"
                          className="text-boxe"
                          name="dob"
                          id="dob"
                          />
                          </Col>
                        </Row>
                        <br />
                        <Row>
                        <Col>
                        <label className="h5">Email Address</label>
                        <input
                        type="text"
                        placeholder="Email Name"
                        className="text-boxe"
                        name="email"
                        id="email"
                        />
                        </Col>
                        </Row>
                        <br/>
                        <Row>
                          <Col>
                          <label className="h5">Phone Number</label>
                            <input
                              type="text"
                              placeholder="Phone Number e.g (+1xxxxxxxxxx)"
                              className="text-boxe"
                              name="phone"
                              id="phone"
                            />
                          </Col>
                          <Col>
                          <label className="h5">Country</label>
                            <select
                            className="text-boxe"
                            onChange={this.check}
                            name="country"
                            id="country"
                            >
                            <option className="text-boxe">
                            Select Country
                            </option>
                            <option value='Nigeria'>Nigeria</option>
                            <option value='United Kingdom'>United Kingdom</option>
                            <option value='USA'>USA</option>
                            </select>
                            <br />
                          </Col>
                        </Row>
                        <br />
                        <Row>
                        <Col>
                        <label className="h5">NIN</label>
                        <input
                        type="number"
                        placeholder="NIN"
                        className="text-boxe"
                        name="nin"
                        id="nin"
                        />
                        </Col>
                        {this.state.isBVNShow && (
                          <>
                          <Col>
                          <label className="h5">BVN Number</label>
                          <input
                          type="number"
                          placeholder="BVN"
                          className="text-boxe"
                          name="bvn"
                          id="bvn"
                          />
                          </Col>
                          </>
                        )}
                        </Row> 
                        <br />
                        <Row className="no-gutters">
                        <Col className="position-relative mr-4">
                        <label className="h5">Password</label>
                        <input
                        type="password"
                        placeholder="Password"
                        className="text-boxe"
                        name="password"
                        id="password"
                        />
                        <FontAwesomeIcon
                        onClick={this.showPassword}
                        icon={faEye}
                        style={{
                          marginTop: "10px",
                          marginLeft: "5px",
                          cursor: "pointer",
                          position: "absolute",
                          zIndex: 9,
                          right: "9px"
                        }}
                        />
                        </Col>
                        <Col>
                        <label className="h5">Confirm Password</label>
                        <input
                        type="password"
                        placeholder="Confirm Password"
                        className="text-boxe"
                        name="confirm_password"
                        id="confirm_password"
                        />
                        <FontAwesomeIcon
                        onClick={this.showPassword}
                        icon={faEye}
                        style={{
                          marginTop: "10px",
                          marginLeft: "5px",
                          cursor: "pointer",
                          position: "absolute",
                          zIndex: 9,
                          right: "9px"
                        }}
                        />
                        </Col>
                        </Row>
                        <br />
                        <Row className="no-gutters row">
                        <Col xs={12}>
                                    <input type="checkbox" name="checkbox" value="check" id="agree" onChange={(e)=>{this.setState({isAgreedCheck: !this.state.isAgreedCheck}) }}/> 
                                    &nbsp;&nbsp;I have read and agree to the Terms and Conditions and Privacy Policy
                                  </Col>
                        </Row>
                        <br/>
                        {this.state.isAgreedCheck && (
                          <>
                            {this.state.loader === true ? (
                          <Loader
                          type="TailSpin"
                          width={60}
                          height={60}
                          color="blue"
                          />
                          ) : (
                            <button
                            style={{
                              float: "right",
                            }}
                            className="blue-btn pt-2 pb-2 pl-5 pr-5 rounded"
                            >
                            SIGN UP {">"}
                            </button>
                            )}
                          
                          </>
                        )}
                        {!this.state.isAgreedCheck && (
                    <>
                     <button
                            type="button"
                            style={{
                            float: "right",
                            }}
                            className="btn btn-dark pt-2 pb-2 pl-5 pr-5 rounded"
                            >
                            SIGNUP {">"}
                            </button>
                    </>
                )} 
                        
                            <br />
                            <br />
                            <br />
                            <Link to="/rurallogin">
                            <span
                            style={{
                              color: "white",
                              fontWeight: "bold",
                              fontSize: "16px",
                              float: "left",
                            }}
                            >
                            {translate('already have an account?')} {translate('login')}
                            </span>{" "}
                            </Link>
                            </form>
                            </div>
                            </Col>
                        <Col md="9"></Col>
                      </Row>

                      {/* Mobile Version */}
                      <Row className="d-block d-sm-none">
                          <Col className="align-self-center p-4">
                            <div className="area-class text-left p-3 bg-white">
                                <form onSubmit={this.signup}>
                                  <div style={{"maxHeight": "80vh","overflowY":"auto"}}>
                                  <Row>
                                  <Col>
                                <h3 className="text-center h2 font-weight-bold">
                                  {translate('rural')} {translate('user')} {translate('registration')}
                                </h3>
                                <Link className="d-block text-center text-dark" to="/rurallogin">
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "16px"
                                  }}
                                >
                                  {translate('already have an account?')} {translate('login')}
                                </span>{" "}
                              </Link>
                              </Col>
                              </Row>
                                <br />
                                <Row>
                                <Col>
                                <label className="h5">First Name</label>
                                <input
                                type="text"
                                placeholder="First Name"
                                name="firstname"
                                id="firstname"
                                className="text-boxe"
                                />
                                </Col>
                                <Col>
                                <label className="h5">Last Name</label>
                                <input
                                type="text"
                                placeholder="Last Name"
                                className="text-boxe"
                                name="lastname"
                                id="lastname"
                                />
                                </Col>
                                </Row>
                                <br />
                                <Row>
                                  <Col>
                                  <label className="h5">Date of Birth</label>
                                  <input
                                  type="date"
                                  placeholder="Enter your date of birth"
                                  className="text-boxe"
                                  name="dob"
                                  id="dob"
                                  />
                                  </Col>
                                </Row>
                                <br />
                                <Row>
                                <Col>
                                <label className="h5">Email Address</label>
                                <input
                                type="text"
                                placeholder="Email Name"
                                className="text-boxe"
                                name="email"
                                id="email"
                                />
                                </Col>
                                </Row>
                                <br/>
                                <Row>
                                  <Col>
                                  <label className="h5">Phone Number</label>
                                    <input
                                      type="text"
                                      placeholder="Phone Number e.g (+1xxxxxxxxxx)"
                                      className="text-boxe"
                                      name="phone"
                                      id="phone"
                                    />
                                  </Col>
                                  <Col>
                                  <label className="h5">Country</label>
                                    <select
                                    className="text-boxe"
                                    onChange={this.check}
                                    name="country"
                                    id="country"
                                    >
                                    <option className="text-boxe">
                                    Select Country
                                    </option>
                                    <option value='Nigeria'>Nigeria</option>
                                    <option value='United Kingdom'>United Kingdom</option>
                                    <option value='USA'>USA</option>
                                    </select>
                                    <br />
                                  </Col>
                                </Row>
                                <br />
                                <Row>
                                <Col>
                                <label className="h5">NIN</label>
                                <input
                                type="number"
                                placeholder="NIN"
                                className="text-boxe"
                                name="nin"
                                id="nin"
                                />
                                </Col>
                                {this.state.isBVNShow && (
                                  <>
                                  <Col>
                                  <label className="h5">BVN Number</label>
                                  <input
                                  type="number"
                                  placeholder="BVN"
                                  className="text-boxe"
                                  name="bvn"
                                  id="bvn"
                                  />
                                  </Col>
                                  </>
                                )}
                                </Row> 
                                <br />
                                <Row className="no-gutters">
                                <Col xs={12} className="position-relative mr-4">
                                <label className="h5">Password</label>
                                <input
                                type="password"
                                placeholder="Password"
                                className="text-boxe"
                                name="password"
                                id="password"
                                />
                                <FontAwesomeIcon
                                onClick={this.showPassword}
                                icon={faEye}
                                style={{
                                  marginTop: "10px",
                                  marginLeft: "5px",
                                  cursor: "pointer",
                                  position: "absolute",
                                  zIndex: 9,
                                  right: "9px"
                                }}
                                />
                                </Col>
                                <Col xs={12} className="mt-3">
                                <label className="h5">Confirm Password</label>
                                <input
                                type="password"
                                placeholder="Confirm Password"
                                className="text-boxe"
                                name="confirm_password"
                                id="confirm_password"
                                />
                                <FontAwesomeIcon
                                onClick={this.showPassword}
                                icon={faEye}
                                style={{
                                  marginTop: "10px",
                                  marginLeft: "5px",
                                  cursor: "pointer",
                                  position: "absolute",
                                  zIndex: 9,
                                  right: "9px"
                                }}
                                />
                                </Col>
                                </Row>
                                <br />
                                <Row className="no-gutters">
                                
                                <Col xs={1}>
                                
                                </Col>
                                </Row>
                                  </div>
                                
                                {this.state.loader === true ? (
                                  <Loader
                                  type="TailSpin"
                                  width={60}
                                  height={60}
                                  color="blue"
                                  />
                                  ) : (
                                    <button
                                    style={{
                                      float: "right",
                                    }}
                                    className="blue-btn pt-2 pb-2 pl-5 pr-5 rounded"
                                    >
                                    SIGN UP {">"}
                                    </button>
                                    )}
                                    <br />
                                    <br />
                                    <br />
                                    <Link to="/rurallogin">
                                    <span
                                    style={{
                                      color: "white",
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      float: "left",
                                    }}
                                    >
                                    {translate('already have an account?')} {translate('login')}
                                    </span>{" "}
                                    </Link>
                                    </form>
                                
                            </div>
                          </Col>
                      </Row>
                    </div>
                  </Pulse>
                </div>
              </Container>
            </>
                );
              }
            }
            