import React from "react"
import { Container,Col,Row } from 'react-bootstrap';
import doctor1 from "../assets/images/d1.png"
import doctor2 from "../assets/images/d2.png"
import doctor3 from "../assets/images/d3.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft,faEye } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import { Modal,Button } from 'rsuite';
import waveLogo from "../assets/images/fulllogo.png"
import Ups from './urbanpatientSignUp';
import UrbanLogin from './urbanLogin';
import RuralReg from './ruralSignup';
import RuraLogin from './ruraLogin';
import translate from '../i18n/translation'
export default class MedicalIndex extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      user:false,
      redirect:false,
      show:false,
      activeForm:"jd"
    }
  }
  close = () =>{
    this.setState({show:false})
  }
  showUrbanReg = () => {
    this.setState({activeForm:<Ups/>,show:true})
  }
  showUrbanLogin = () => {
    this.setState({activeForm:<UrbanLogin/>,show:true})
  }
  
  showRuralReg = () => {
    this.setState({activeForm:<RuralReg/>,show:true})
  }
  
  showRuralLogin = () => {
    this.setState({activeForm:<RuraLogin/>,show:true})
  }
  
  open = () =>{
    this.setState({show:true})
  }
  urbanLogin = () =>{
    this.setState({activeForm:<Ups/>})
  }
  render(){
    return(
      <Container className="container-fluid index" fluid>
      {/* modal */}
      
      <Modal show={this.state.show}  onHide={this.close}>
      <Modal.Header>
      <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {this.state.activeForm}
      </Modal.Body>
      
      </Modal>
      
      <div className="backdrop-doc">
      <div className="">
      <div className="">
      {/* Normal Version */}
      <Row className="d-none d-sm-block">
      <Col md={3} className="bg-white">
      
      <Row>
          <Col md={2} className="pt-2">
            <Link to="/"><FontAwesomeIcon size="2x" icon={faArrowCircleLeft} className="icons" style={{"color":"#020197"}}/></Link>
          </Col>
          <Col md={10} className="pt-2">
            <img src={waveLogo} alt="1" className="doc-images float-right"/>
          </Col>
        </Row>
      <div className="area-class text-left pl-4" style={{"height": "100vh"}}>
      
      <h3 className="text-center">{translate('medical Expert')}</h3>
      <br/>
      <Link to="/login"  className="btn blue-btn d-block p-3 rounded">
      {/* <button className="buttonsp" > */}
        {translate('login')}
      {/* </button> */}
      </Link>
      <br/><br/>
      <Link to="/doctorsignup" className="btn blue-btn d-block p-3 rounded">
      {/* <button className="buttonsp"> */}
        {translate('sign Up')}
      {/* </button> */}
      </Link>
      <br/><br/>
      </div>
      </Col>
      <Col md={8}>
      <div className="area-class">
      <Row className="d-none d-md-block">
      <Col lg={12}>
      
      </Col>
      
      </Row>
      </div>
      </Col>
      </Row>
      
       {/* Mobile Version */}
       <Row className="d-block d-sm-none">
        
        <Col className="align-self-center p-4">
        
          <div className="area-class text-left p-4  bg-white">
          <Row>
            <Col md={2} xs={12} sm={12}>
              <Link to="/"><FontAwesomeIcon size="2x" icon={faArrowCircleLeft} className="icons" style={{"color":"#020197"}}/></Link>
            </Col>
            {/* <Col md={10} xs={12} sm={12}  className="pt-2">
              <img src={waveLogo} alt="1" className="doc-images float-right"/>
            </Col> */}
          </Row>
          <div className="area-class text-left p-4">
      
      <h3 className="text-center">{translate('medical Expert')}</h3>
      <br/>
      <Link to="/login"  className="btn blue-btn d-block p-3 rounded">
      {/* <button className="buttonsp" > */}
        {translate('login')}
      {/* </button> */}
      </Link>
      <br/><br/>
      <Link to="/doctorsignup" className="btn blue-btn d-block p-3 rounded">
      {/* <button className="buttonsp"> */}
        {translate('sign Up')}
      {/* </button> */}
      </Link>
      <br/><br/>
      </div>
     
          </div>
        </Col>
       </Row> 
      </div>
      </div>
      </div>
      </Container>
      )
    }
  }
  