import React from "react";
import "rsuite/dist/styles/rsuite-default.css";
import Header2 from "../../componenets/headers/Header2";
import V3 from "../../assets/Vector3.png";
import { Link } from "react-router-dom";
import { Alert } from "rsuite";
import { postData, postDr, signUpMail } from "../../services/request";
import Loader from "react-loader-spinner";

export default class MadicalExpertSignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      user: false,
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      country: "",
      nin: "",
      hospital: "",
      password: "",
      confirm_password: "",
      role: "",
      specialaztion: "",
      reg_number: "",
      membership: "",
      membershipExpiry: "",
      annualPatient: "",
      message: "",
      records: "",
    };
  }
  componentDidMount = () => {};
  signup = async (e) => {
    const drData = await postDr("checkDr", {
      firstname: this.state.firstname,
      lastname: this.state.lastname,
    });

    if (drData.length === 0) {
      Alert.error("Please first register on telemed.wavepalm.com");
    } else {
      var details = {
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        email: this.state.email,
        country: this.state.country,
        user: "Medical Expert",
        reg_number: this.state.reg_number,
        password: this.state.password,
        confirm_password: this.state.confirm_password,
        hospitalType: this.state.hospital,
        role: this.state.role,
        speciality: this.state.specialaztion,
        registration: this.state.reg_number,
        phone: this.state.phone,
      };

      this.setState({ loader: true });
      postData("register", details)
        .then((response) => {
          this.setState({ loader: false });
          if (response.status === true) {
            const mailSignup = {
              fullName: this.state.firstname + " " + this.state.lastname,
              email: this.state.email,
            };
            signUpMail("signUpMail", mailSignup);
            Alert.success(response.message);
            this.props.history.push("/");
          } else {
            Alert.error(response.message);
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
          if (error.request.status === 422) {
            Alert.error(error.response.data.errors.email);
          } else if (error.request.status === 500) {
            Alert.error(
              "Connection to internet lost, please check connection and retry"
            );
          } else {
            Alert.error("Error creating account");
          }
        });
      this.registerWallet();
    }
  };
  registerWallet() {
    postData("wallet-registration", {
      name: this.state.firstname + " " + this.state.lastname,
      email: this.state.email,
      password: this.state.password,
      user_type: "telemedics",
      currency: "NGN",
    })
      .then((response) => {
        if (response.status === true) {
          // Alert.success();
          console.log("success in creation of wallet", response);
        } else {
          console.log("error in creation of wallet");
          // Alert.error(response.message);
        }
      })
      .catch((error) => {
        console.log("error in creation of wallet");
      });
  }
  showPassword = (id) => {
    var password = document.getElementById(id);
    if (password.type === "password") {
      password.type = "text";
    } else {
      password.type = "password";
    }
  };

  checkForm = () => {
    if (
      this.state.firstname.trim() === "" ||
      this.state.firstname.trim() === null
    ) {
      Alert.warning("Input your first name");
    } else if (
      this.state.lastname.trim() === "" ||
      this.state.lastname.trim() === null
    ) {
      Alert.warning("Input your last name");
    } else if (
      this.state.email.trim() === "" ||
      this.state.email.trim() === null
    ) {
      Alert.warning("Input your email");
    } else if (
      this.state.phone.trim() === "" ||
      this.state.phone.trim() === null
    ) {
      Alert.warning("Input your phone no");
    } else if (this.state.country === "") {
      Alert.warning("Select a country");
    } else if (this.state.nin === "") {
      Alert.warning("Enter your NIN");
    } else if (this.state.password === "") {
      Alert.warning("Your password is missing");
    } else if (this.state.role === "") {
      Alert.warning("Please select your role");
    } else if (this.state.role != "Student") {
      if (this.state.specialaztion === "") {
        Alert.warning("Please select your specialaztion");
      }
    } else {
      this.signup();
    }
  };

  render() {
    if (
      window.location.href.indexOf("https") == -1 &&
      window.location.href.indexOf("wavetelemedicine") > -1
    ) {
      window.location.href = "https://wavetelemedicine.com/";
    }
    return (
      <div className="container-fluid">
        <div className="row ">
          <div className="col-sm-6 head2">
            <Header2 />
            <div className="login-box">
              <div className="row">
                <Link to="/" style={{ textDecoration: "none" }}>
                  <div className="back-btn">
                    <img src={V3} alt="" style={{ paddingBottom: "4px" }}></img>
                    <span className="pl-3 fz20 fw4 cl1A">Back</span>
                  </div>
                </Link>
              </div>
              <div className="row">
                <div className=" mt-4">
                  <div className="fz32 fw5 cl1A">Create an account</div>
                  <div className="fz20 fw5">
                    <span>Sign up as a </span>
                    <span className="pl-2 cl00 fw5">Medical Expert</span>
                  </div>
                  <div className="mt-5 ">
                    <div className="pr">
                      <span className="fw4 fz20 pt-3 ">First Name</span>
                      <span className="pa" style={{ right: "1px" }}>
                        <input
                          type="text"
                          className="input-field"
                          placeholder="Enter your first name"
                          onChange={(e) => {
                            this.setState({
                              firstname: e.target.value,
                            });
                          }}
                          value={this.state.firstname}
                        ></input>
                      </span>
                    </div>
                    <div className="mt-4 pr">
                      <span className="fw4 fz20 pt-3 ">Last Name </span>
                      <span className="pa" style={{ right: "1px" }}>
                        <input
                          type="text"
                          className="input-field"
                          placeholder="Enter your last name"
                          onChange={(e) => {
                            this.setState({
                              lastname: e.target.value,
                            });
                          }}
                          value={this.state.lastname}
                        ></input>
                      </span>
                    </div>
                    <div className="mt-4 pr">
                      <span className="fw4 fz20 pt-3 ">Email </span>
                      <span className="pa" style={{ right: "1px" }}>
                        <input
                          type="email"
                          className="input-field"
                          placeholder="Enter your email"
                          onChange={(e) => {
                            this.setState({
                              email: e.target.value,
                            });
                          }}
                          value={this.state.email}
                        ></input>
                      </span>
                    </div>
                    {/* <div className="mt-4 pr">
                      <span className="fw4 fz20 pt-3 ">Date of Birth </span>
                      <span className="pa" style={{ right: "1px" }}>
                        <input
                          type="date"
                          className="input-field"
                          placeholder="mm/dd/yyyy"
                        ></input>
                      </span>
                    </div> */}
                    <div className="mt-4 pr">
                      <span className="fw4 fz20 pt-3 ">Phone Number </span>
                      <span className="pa" style={{ right: "1px" }}>
                        <input
                          type="number"
                          className="input-field"
                          placeholder="Enter your phone number"
                          onChange={(e) => {
                            this.setState({
                              phone: e.target.value,
                            });
                          }}
                          value={this.state.phone}
                        ></input>
                      </span>
                    </div>
                    <div className="mt-4 pr">
                      <span className="fw4 fz20 pt-3 "> Country </span>
                      <span className="pa" style={{ right: "1px" }}>
                        <select
                          className="input-field"
                          name="country"
                          id="country"
                          onChange={(e) => {
                            this.setState({
                              country: e.target.value,
                            });
                          }}
                          value={this.state.country}
                        >
                          <option className="input-field">
                            Select Country
                          </option>
                          <option value="Nigeria">Nigeria</option>
                          <option value="United Kingdom">United Kingdom</option>
                          <option value="USA">USA</option>
                          <option value="Kenya">Kenya</option>
                          <option value="Zambia">Zambia</option>
                          <option value="Ghana">Ghana</option>
                        </select>
                      </span>
                    </div>
                    <div className="mt-4 pr">
                      <span className="fw4 fz20 pt-3 ">NIN </span>
                      <span className="pa" style={{ right: "1px" }}>
                        <input
                          type="number"
                          className="input-field"
                          placeholder="Enter your NIN"
                          onChange={(e) => {
                            this.setState({
                              nin: e.target.value,
                            });
                          }}
                          value={this.state.nin}
                        ></input>
                      </span>
                    </div>
                    <div className="mt-4 pr">
                      <span className="fw4 fz20 pt-3 ">Hospital </span>
                      <span className="pa" style={{ right: "1px" }}>
                        <select
                          className="input-field"
                          onChange={(e) => {
                            this.setState({
                              hospital: e.target.value,
                            });
                          }}
                          value={this.state.hospital}
                        >
                          <option className="form-control">
                            Select Hospital
                          </option>
                          <option value="Governemt">Governemt</option>
                          <option value="Private">Private</option>
                          <option value="Private Consultant">
                            Private Consultant
                          </option>
                        </select>
                      </span>
                    </div>
                    {this.state.country === "Nigeria" && (
                      <>
                        <div className="mt-4 pr">
                          <span className="fw4 fz20 pt-3 ">Folio Number </span>
                          <span className="pa" style={{ right: "1px" }}>
                            <input
                              type="number"
                              className="input-field"
                              placeholder="Enter your NIN"
                              onChange={(e) => {
                                this.setState({
                                  membership: e.target.value,
                                });
                              }}
                              value={this.state.membership}
                            ></input>
                          </span>
                        </div>
                        <div className="mt-4 pr">
                          <span className="fw4 fz18 pt-3 ">
                            Membership Expiry{" "}
                          </span>
                          <span className="pa" style={{ right: "1px" }}>
                            <input
                              type="date"
                              className="input-field"
                              placeholder="Enter your NIN"
                              onChange={(e) => {
                                this.setState({
                                  membershipExpiry: e.target.value,
                                });
                              }}
                              value={this.state.membershipExpiry}
                            ></input>
                          </span>
                        </div>
                      </>
                    )}

                    <div className="mt-4 pr">
                      <span className="fw4 fz20 pt-3 ">Role</span>
                      <span className="pa" style={{ right: "1px" }}>
                        <select
                          className="input-field"
                          onChange={(e) => {
                            this.setState({ role: e.target.value });
                          }}
                          value={this.state.role}
                        >
                          <option className="form-control bc-f5">
                            Select Role
                          </option>
                          <option value="Doctor">Doctor</option>
                          <option value="Nurse">Nurse</option>
                          <option value="Student">Medical Student</option>
                        </select>
                      </span>
                    </div>

                    {this.state.role === "Doctor" && (
                      <div className="mt-4 pr">
                        <span className="fw4 fz20 pt-3 ">Specialization </span>
                        <span className="pa" style={{ right: "1px" }}>
                          <select
                            className="input-field"
                            onChange={(e) => {
                              this.setState({ specialaztion: e.target.value });
                            }}
                            value={this.state.specialaztion}
                          >
                            <option className="form-control bc-f5">
                              Specialisation
                            </option>
                            <option value="Physician">General Physician</option>
                            <option value="Pediatrician">Pediatrician</option>
                            <option value="Gynecologist">Gynecologist</option>
                            <option value="Surgeon"> Surgeon</option>
                            <option value="Psychiatrist"> Psychiatrist</option>
                            <option value="Cardiologist"> Cardiologist</option>
                            <option value="Dermatologist">Dermatologist</option>
                            <option value="Endocrinologist">
                              Endocrinologist
                            </option>
                            <option value="Gastroenterologist">
                              Gastroenterologist
                            </option>
                            <option value="Endocrinologist">
                              Nephrologist
                            </option>
                            <option value="Ophthalmologist">
                              Ophthalmologist
                            </option>
                            <option value="Otolaryngologist">
                              Otolaryngologist
                            </option>
                            <option value="Pulmonologist">Pulmonologist</option>
                            <option value="Radiologist"> Radiologist</option>
                            <option value="Anesthesiologist">
                              Anesthesiologist
                            </option>
                            <option value="Oncologist"> Oncologist</option>
                            <option value="Dentist"> Dentist</option>
                          </select>
                        </span>
                      </div>
                    )}
                    {this.state.role === "Nurse" && (
                      <div className="mt-4 pr">
                        <span className="fw4 fz20 pt-3 ">Specialization </span>
                        <span className="pa" style={{ right: "1px" }}>
                          <select
                            className="input-field"
                            onChange={(e) => {
                              this.setState({ specialaztion: e.target.value });
                            }}
                            value={this.state.specialaztion}
                          >
                            <option className="input-field">Specialty</option>
                            <option value="Registered Nurse">
                              Registered Nurse
                            </option>
                            <option value="Cardiac Nurse">Cardiac Nurse</option>
                            <option value="CRNA">
                              Certified Registered Nurse Anesthetist
                            </option>
                            <option value="CNS">
                              Clinical Nurse Specialist
                            </option>
                            <option value="Critical Care Nurse">
                              Critical Care Nurse
                            </option>
                            <option value="ER Nurse"> ER Nurse</option>
                            <option value="Family Nurse Practitioner">
                              Family Nurse Practitioner
                            </option>
                            <option value="Geriatric Nursing">
                              Geriatric Nursing
                            </option>
                            <option value="Perioperative Nurse">
                              Perioperative Nurse
                            </option>
                            <option value="Mental Health Nurse">
                              Mental Health Nurse
                            </option>
                            <option value="Nurse Educator">
                              Nurse Educator
                            </option>
                            <option value="Nurse Midwife">Nurse Midwife</option>
                            <option value="Nurse Practitioner">
                              Nurse Practitioner
                            </option>
                            <option value="Oncology Nurse">
                              Oncology Nurse
                            </option>
                            <option value="Orthopedic Nurse">
                              Orthopedic Nurse
                            </option>
                            <option value="Pediatric Nurse">
                              Pediatric Nurse
                            </option>
                          </select>
                        </span>
                      </div>
                    )}
                    <div className="mt-4 pr">
                      <span className="fw4 fz20 pt-3 ">Registration # </span>
                      <span className="pa" style={{ right: "1px" }}>
                        <input
                          type="text"
                          className="input-field"
                          placeholder="Enter your Registration Number"
                          onChange={(e) => {
                            this.setState({
                              reg_number: e.target.value,
                            });
                          }}
                          value={this.state.reg_number}
                        ></input>
                      </span>
                    </div>
                    <div className="mt-4 pr">
                      <span className="fw4 fz20 pt-3 ">Password </span>
                      <span>
                        <input
                          type="password"
                          className="input-field"
                          placeholder="Enter your password"
                          onChange={(e) => {
                            this.setState({
                              password: e.target.value,
                            });
                          }}
                          value={this.state.password}
                        ></input>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row  mt-4">
                <span style={{ paddingTop: "10px" }}>
                  <input type="checkbox" className="form-check-input"></input>
                </span>
                <span style={{ paddingTop: "10px" }}>
                  I accept WaveTelemedicine terms and conditions
                </span>
                <span>
                  {this.state.loader === true ? (
                    <Loader
                      type="TailSpin"
                      width={60}
                      height={60}
                      color="white"
                    />
                  ) : (
                    <button
                      type="button"
                      className="bt-patient"
                      style={{ marginLeft: "100px" }}
                      onClick={this.checkForm}
                    >
                      Sign up
                    </button>
                  )}
                </span>
              </div>
            </div>

            <div className="row mt-3 pb-2">
              <div style={{ marginLeft: "34%" }}>
                <span className="fw3 fz14">Already have an account? </span>
                <Link to="/dr-login">
                  <span className="fz14 fw5 cl00">Login</span>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-sm-6 img7 desktop"></div>
        </div>
      </div>

      //               <Row>
      //                 <Col className="mt-3">
      //                   <input
      //                     placeholder="Estimated annual Patient"
      //                     type="number"
      //                     className="form-control bc-f5"
      //                     onChange={(e) => {
      //                       this.setState({ annualPatient: e.target.value });
      //                     }}
      //                     value={this.state.annualPatient}
      //                   />
      //                 </Col>
      //               </Row>
      //               <Row>
      //                 <Col sm={12} lg={12} className=" mt-3">
      //                   <textarea
      //                     placeholder="Your Message"
      //                     type="text"
      //                     rows="3"
      //                     className="form-control bc-f5"
      //                     onChange={(e) => {
      //                       this.setState({ message: e.target.value });
      //                     }}
      //                     value={this.state.message}
      //                   />
      //                 </Col>
      //               </Row>
      //               <br />

      //               <Row className="justify-content-end mt-4">
      //                 <div className="col-12 col-sm-4 text-center">3/3</div>
      //                 <div className="col-10 col-sm-4">
      //                   {this.state.loader === true ? (
      //                     <Loader
      //                       type="TailSpin"
      //                       width={60}
      //                       height={60}
      //                       color="white"
      //                     />
      //                   ) : (
      //                     <div className="signup-btn cur" onClick={this.signup}>
      //                       Submit
      //                     </div>
      //                   )}
      //                 </div>
      //               </Row>

      //               <Row className="justify-content-center mt-2">
      //                 <div className="col-10 col-sm-8 mt-3 mb-4 text-center">
      //                   <b>
      //                     {translate("already have an account?")}
      //                     <Link to="/dr-login">{translate("login")}</Link>
      //                   </b>
      //                 </div>
      //               </Row>
      //             </div>
      //           </Row>
      //         </div>
      //       </Row>
      //     </div>
      //   )}
      // </>
    );
  }
}
