import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Pulse from "react-reveal/Pulse";
import { Link, Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import { postData } from "./../services/request";
import { Alert } from "rsuite";
import { faEye, faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import translate from "../i18n/translation";
import waveLogo from "../assets/images/fulllogo.png"

export default class MedicalSignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: false,
      redirect: false,
      isHMO: false,
      isDoctor: false,
      isNurse: false,
      isNigeria: false
    };
  }
  check = (e) => {
    if (e.target.value === "Medical Expert") {
      this.setState({ user: true });
    } else {
      this.setState({ user: false });
    }
    
    if(e.target.value === "Nigeria"){
      this.setState({isNigeria : true})
    }else{
      this.setState({isNigeria : false})
    }

  };
  signup = (e) => {
    e.preventDefault();
    var details = {
      firstname: e.target.firstname.value,
      lastname: e.target.lastname.value,
      email: e.target.email.value,
      country: e.target.country.value,
      user: "Medical Expert",
      reg_number: e.target.reg_number.value,
      password: e.target.password.value,
      confirm_password: e.target.confirm_password.value,
      hospitalType : e.target.hospital.value,
      role: e.target.role.value,
      speciality: e.target.specialaztion.value,
      registration : e.target.reg_number.value
    };
    if (details.firstname.trim() === "" || details.firstname.trim() === null) {
      Alert.warning("Input your first name");
    } else if (
      details.lastname.trim() === "" ||
      details.lastname.trim() === null
    ) {
      Alert.warning("Input your last name");
    } else if (details.email.trim() === "" || details.email.trim() === null) {
      Alert.warning("Input your email");
    } else if (details.country === "Select Country") {
      Alert.warning("Select a country");
    } else if (details.user === "Select user type") {
      Alert.warning("Select user type");
    } else if (
      details.reg_number.trim() === "" ||
      details.reg_number.trim() === null
    ) {
      Alert.warning("Input your registration number");
    } else if (
      details.password.trim() === null ||
      details.password.trim() === ""
    ) {
      Alert.warning("Input your password");
    } else if (
      details.confirm_password.trim() === null ||
      details.confirm_password.trim() === ""
    ) {
      Alert.warning("Confirm your password");
    } else if (details.password.trim() !== details.confirm_password.trim()) {
      Alert.error("Passwords do not match");
    } else {
      this.setState({ loader: true });
      postData("register", details)
        .then((response) => {
          this.setState({ loader: false });
          if (response.status === true) {
            Alert.success(response.message);
          } else {
            Alert.error(response.message);
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
          if (error.request.status === 422) {
            Alert.error(error.response.data.errors.email);
          } else if (error.request.status === 500) {
            Alert.error(
              "Connection to internet lost, please check connection and retry"
            );
          } else {
            Alert.error("Error creating account");
          }
        });

        this.registerWallet(details)
    }
  };
  registerWallet(details) {
    postData("wallet-registration", {name: details.firstname + " " + details.lastname, email: details.email,  password: details.password, user_type: "telemedics",currency : "NGN"})
    .then((response) => {
    if (response.status === true) {
        // Alert.success();
        console.log('success in creation of wallet',response);
    } else {
        console.log('error in creation of wallet');
        // Alert.error(response.message);
    }
    })
    .catch((error) => {
      console.log('error in creation of wallet');
    });
  }
  showPassword = (id) => {
    var password = document.getElementById(id);
    if (password.type === "password") {
      password.type = "text";
    } else {
      password.type = "password";
    }
  };
  _handleRole = (event) => {
  
    if(event.target.value == 'HMO'){
      this.setState({isHMO: true,isDoctor:false,isNurse:false})
    }
    if(event.target.value == 'Doctor'){
      this.setState({isHMO: false,isDoctor:true,isNurse:false})
    }
    if(event.target.value == 'Nurse'){
      this.setState({isHMO: false,isDoctor:false,isNurse:true})
    }
    if(event.target.value == 'Student'){
      this.setState({isHMO: false,isDoctor:false,isNurse:false})  
    }
  }
  render() {
    if (this.state.redirect === true) {
      return <Redirect to="/login" />;
    }
    return (
      <Container fluid className="container-fluid"  >
        <div className="backdrop-doc">
          <Pulse>
            <div className="">
              {/* Normal Version */}
              <Row  className="d-none d-sm-block">
                <Col md="5" className="bg-white pt-3 shadow-sm" style={{"height": "100vh"}}>
                <Row>
                        <Col md={2} className="pt-3">
                            <Link to="/medicalIndex"><FontAwesomeIcon size="2x" icon={faArrowCircleLeft} className="icons" style={{"color":"#020197"}}/></Link>
                        </Col>
                        <Col md={10} className="pt-2">
                            <img src={waveLogo} alt="1" className="doc-images float-right" style={{"width": "72%"}}/>
                        </Col>
                        </Row>
                {/* <span className="pull-right"><img src={waveLogo} alt="1" className="doc-images"/></span>
                <Link to="/medicalIndex">
                    <FontAwesomeIcon
                      size="2x"
                      icon={faArrowCircleLeft}
                      className="icons"
                      style={{"color":"#020197"}}
                    />
                  </Link> */}
                  
                  <div style={{ width: "100%", marginTop: "5vh" }}>
                  <h3 className="text-center">
                   Doctor {translate('registration')}
                  </h3>
                  <Link className="d-block text-center text-dark" to="/login">
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px"
                    }}
                  >
                    {translate('already have an account?')} {translate('login')}
                  </span>{" "}
                </Link>
                    <div
                    className="mt-4"
                      style={{
                        width: "90%",
                        margin: "auto",
                        textAlign: "center",
                      }}
                    >
                      <form className="pt-4" onSubmit={this.signup}>
                        <Row>
                          <Col>
                            <input
                              type="text"
                              placeholder="First Name"
                              name="firstname"
                              id="firstname"
                              className="text-boxe"
                            />
                          </Col>
                          <Col>
                            <input
                              type="text"
                              placeholder="Last Name"
                              className="text-boxe"
                              name="lastname"
                              id="lastname"
                            />
                          </Col>
                        </Row>
                        <br />
            
                        <Row>
                          <Col>
                            <input
                              type="text"
                              placeholder="Email Name"
                              className="text-boxe"
                              name="email"
                              id="email"
                            />
                          </Col>
                          <Col>
                            <input
                              type="text"
                              placeholder="Phone Number e.g (+1xxxxxxxxxx)"
                              className="text-boxe"
                              name="phone"
                              id="phone"
                            />
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col>
                            <input
                              type="text"
                              placeholder="NIN"
                              className="text-boxe"
                              name="nin"
                              id="nin"
                            />
                          </Col>
                          <Col>
                            <select
                              className="text-boxe"
                              onChange={this.check}
                              name="country"
                              id="country"
                            >
                                <option className="text-boxe">
                                Select Country
                                </option>
                                <option value='Nigeria'>Nigeria</option>
                                <option value='United Kingdom'>United Kingdom</option>
                                <option value='USA'>USA</option>
                            </select>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          
                          <Col xs={1}></Col>
                        </Row>
                        {this.state.isNigeria && (
                          <>
                          <Row>
                          <Col>
                            <input
                              type="text"
                              placeholder="BVN"
                              className="text-boxe"
                              name="bvn"
                              id="bvn"
                            />
                          </Col>
                          <br />
                
                        </Row>
                        <br />
                        <Row>
                          <Col>
                            <input
                              type="text"
                              placeholder="Membership Number"
                              className="text-boxe"
                              name="membership"
                              id="membership"
                            />
                          </Col>
                          <Col>
                            <input
                              type="date"
                              placeholder="Membership Expiry"
                              className="text-boxe"
                              name="membershipExpiry"
                              id="membershipExpiry"
                            />
                          </Col>
                         
                        </Row>
                        <br />
                          
                          </>
                        )}
                        <Row>
                          <Col>
                            <select
                              className="text-boxe"
                              name="hospital"
                              id="hospital"
                            >
                                <option className="text-boxe">
                                Select Hospital
                                </option>
                                <option value='Governemt'>Governemt</option>
                                <option value='Private'>Private</option>
                            </select>
                            <br />
                            <br />
                          </Col>
                        </Row>
                        <Row className="no-gutters">
                          <Col className="position-relative mr-4">
                            <input
                              type="password"
                              placeholder="Password"
                              className="text-boxe"
                              name="password"
                              id="password"
                            />
                            <FontAwesomeIcon
                              onClick={() => {
                                this.showPassword("password");
                              }}
                              icon={faEye}
                              style={{
                                marginTop: "10px",
                                marginLeft: "5px",
                                cursor: "pointer",
                                position: "absolute",
                                zIndex: 9,
                                right: "9px"
                              }}
                            />
                          </Col>
                          <Col>
                            <input
                              type="password"
                              placeholder="Confirm Password"
                              className="text-boxe"
                              name="confirm_password"
                              id="confirm_password"
                            />
                            <FontAwesomeIcon
                              onClick={() => {
                                this.showPassword("confirm_password");
                              }}
                              icon={faEye}
                              style={{
                                marginTop: "10px",
                                marginLeft: "5px",
                                cursor: "pointer",
                                position: "absolute",
                                zIndex: 9,
                                right: "9px"
                              }}
                            />
                          </Col>
                        </Row>
                        <Row className="no-gutters">
                          
                          <Col xs={1}>
                            
                          </Col>
                        </Row>
                        <br/>
                        <Row>
                          <Col>
                            <select
                              className="text-boxe"
                              onChange={this._handleRole}
                              name="role"
                              id="type"
                            >
                                <option className="text-boxe" >
                                Select Role
                                </option>
                                <option value='Doctor'>Doctor</option>
                                <option value='Nurse'>Nurse</option>
                                <option value='Student'>Medical Student</option>
                            </select>
                            <br />
                            <br />
                          </Col>
                        </Row>

                        <Row>
                          {this.state.isHMO && (
                            <Col xs={11}>
                            
                                <input
                                type="text"
                                name="hmo"
                                id="hmo"
                                placeholder="Enter HMOs"
                                className="text-boxe"
                              />
                              
                          
                              <br />
                              <br />
                            </Col>
                            )
                          }
                          {this.state.isDoctor && (
                            <Col>
                            <select
                              className="text-boxe"
                              onChange={this._handleRole}
                              name="specialaztion"
                              id="type"
                            >
                            <option className="text-boxe" >Specialty</option>
                                <option value='Physician'>Genral Physician</option>
                                <option value='Pediatrician'>Pediatrician</option>
                                <option value='Gynecologist'>Gynecologist</option>
                                <option value='Surgeon'> Surgeon</option>
                                <option value='Psychiatrist'> Psychiatrist</option>
                                <option value='Cardiologist'> Cardiologist</option>
                                <option value='Dermatologist'> Dermatologist</option>
                                <option value='Endocrinologist'> Endocrinologist</option>
                                <option value='Gastroenterologist'> Gastroenterologist</option>
                                <option value='Endocrinologist'> Nephrologist</option>
                                <option value='Ophthalmologist'> Ophthalmologist</option>
                                <option value='Otolaryngologist'> Otolaryngologist</option>
                                <option value='Pulmonologist'> Pulmonologist</option>
                                <option value='Radiologist'> Radiologist</option>
                                <option value='Anesthesiologist'> Anesthesiologist</option>
                                <option value='Oncologist'> Oncologist</option>
                          
                                </select>
                          <br />
                          <br />
                        </Col>
                          )}
                          {this.state.isNurse && (
                            <Col>
                            <select
                              className="text-boxe"
                              onChange={this._handleRole}
                              name="specialized"
                              id="type"
                            >
                            <option className="text-boxe" >
                                Specialty
                                </option>
                                <option value='Registered Nurse'>Registered Nurse</option>
                                <option value='Cardiac Nurse'>Cardiac Nurse</option>
                                <option value='CRNA'>Certified Registered Nurse Anesthetist</option>
                                <option value='CNS'> Clinical Nurse Specialist</option>
                                <option value='Critical Care Nurse'> Critical Care Nurse</option>
                                <option value='ER Nurse'> ER Nurse</option>
                                <option value='Family Nurse Practitioner'> Family Nurse Practitioner</option>
                                <option value='Geriatric Nursing'> Geriatric Nursing</option>
                                <option value='Perioperative Nurse'> Perioperative Nurse</option>
                                <option value='Mental Health Nurse'> Mental Health Nurse</option>
                                <option value='Nurse Educator'> Nurse Educator</option>
                                <option value='Nurse Midwife'> Nurse Midwife</option>
                                <option value='Nurse Practitioner'> Nurse Practitioner</option>
                                <option value='Oncology Nurse'> Oncology Nurse</option>
                                <option value='Orthopedic Nurse'> Orthopedic Nurse</option>
                                <option value='Pediatric Nurse'> Pediatric Nurse</option>
                          
                                </select>
                          <br />
                          <br />
                        </Col>
                          )}
                        </Row>
                        <Row>
                          <Col>
                            <input
                              type="text"
                              name="reg_number"
                              id="reg_number"
                              placeholder="Registration Number"
                              className="text-boxe"
                            />
                          </Col>
                        </Row>
                        <br />
                        <br />
                        <div className="err" id="error-div"></div>
                        <div className="succ" id="success-div"></div>
                        {this.state.loader === true ? (
                          <Loader
                            type="TailSpin"
                            width={60}
                            height={60}
                            color="white"
                          />
                        ) : (
                          <button
                            style={{
                              float: "right",
                            }}
                            className="blue-btn pt-2 pb-2 pl-5 pr-5 rounded bg-white"
                            >
                            {translate('sign Up')} {">"}
                            </button>
                        )}
                      </form>
                      <br />
                      <br />
                      <br />
                      <Link to="/login">
                        <span
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "16px",
                            float: "left",
                          }}
                        >
                          {translate('already have an account?')} {translate('login')}
                        </span>{" "}
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col md={9}>
                  {/* <div style={{border:"1px solid red",width:"300px"}}>
                                <br/><br/><br/>
                                <Link to="/login">
                                <button style={{marginLeft:"65%",width:"10%",border:"none",height:"35px",borderRadius:"18px",color:"white",border:"1px solid white",backgroundColor:"transparent"}}>Login</button>
                                </Link>
                                <button style={{marginLeft:"10px",width:"10%",border:"none",height:"35px",borderRadius:"18px",color:"white",border:"1px solid white",backgroundColor:"transparent"}}>Sign Up</button>
                                </div> */}
                </Col>
              </Row>
              {/* Mobile Version */}
              <Row className="d-block d-sm-none">
                <Col className="align-self-center p-4">
                  <div className="area-class text-left  bg-white">
                  <Row>
                  <Col md={2} className="pt-3">
                    <Link to="/medicalIndex"><FontAwesomeIcon size="2x" icon={faArrowCircleLeft} className="icons" style={{"color":"#020197"}}/></Link>
                  </Col>
                    {/* <Col md={10} xs={12} sm={12}  className="pt-2">
                      <img src={waveLogo} alt="1" className="doc-images float-right"/>
                    </Col> */}
                  </Row>
                  <div style={{ width: "100%"}}>
                  <h3 className="text-center">
                   Doctor {translate('registration')}
                  </h3>
                  <Link className="d-block text-center text-dark" to="/login">
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px"
                    }}
                  >
                    {translate('already have an account?')} {translate('login')}
                  </span>{" "}
                </Link>
                    <div
                    className=""
                      style={{
                        width: "90%",
                        margin: "auto",
                        textAlign: "center",
                      }}
                    >
                      <form className="pt-4" onSubmit={this.signup}>
                        <div style={{"maxHeight":"57vh","overflowY":"auto"}}>
                        <Row>
                          <Col>
                            <input
                              type="text"
                              placeholder="First Name"
                              name="firstname"
                              id="firstname"
                              className="text-boxe"
                            />
                          </Col>
                          <Col>
                            <input
                              type="text"
                              placeholder="Last Name"
                              className="text-boxe"
                              name="lastname"
                              id="lastname"
                            />
                          </Col>
                        </Row>
                        <br />

                        <Row>
                          <Col>
                            <input
                              type="text"
                              placeholder="Email Name"
                              className="text-boxe"
                              name="email"
                              id="email"
                            />
                          </Col>
                          <Col>
                            <input
                              type="text"
                              placeholder="Phone Number e.g (+1xxxxxxxxxx)"
                              className="text-boxe"
                              name="phone"
                              id="phone"
                            />
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col>
                            <input
                              type="text"
                              placeholder="NIN"
                              className="text-boxe"
                              name="nin"
                              id="nin"
                            />
                          </Col>
                          <Col>
                            <select
                              className="text-boxe"
                              onChange={this.check}
                              name="country"
                              id="country"
                            >
                                <option className="text-boxe">
                                Select Country
                                </option>
                                <option value='Nigeria'>Nigeria</option>
                                <option value='United Kingdom'>United Kingdom</option>
                                <option value='USA'>USA</option>
                            </select>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          
                          <Col xs={1}></Col>
                        </Row>
                        {this.state.isNigeria && (
                          <>
                          <Row>
                          <Col>
                            <input
                              type="text"
                              placeholder="BVN"
                              className="text-boxe"
                              name="bvn"
                              id="bvn"
                            />
                          </Col>
                          <br />
                
                        </Row>
                        <br />
                        <Row>
                          <Col>
                            <input
                              type="text"
                              placeholder="Membership Number"
                              className="text-boxe"
                              name="membership"
                              id="membership"
                            />
                          </Col>
                          <Col>
                            <input
                              type="date"
                              placeholder="Membership Expiry"
                              className="text-boxe"
                              name="membershipExpiry"
                              id="membershipExpiry"
                            />
                          </Col>
                         
                        </Row>
                        <br />
                          
                          </>
                        )}
                        <Row>
                          <Col>
                            <select
                              className="text-boxe"
                              name="hospital"
                              id="hospital"
                            >
                                <option className="text-boxe">
                                Select Hospital
                                </option>
                                <option value='Governemt'>Governemt</option>
                                <option value='Private'>Private</option>
                            </select>
                            <br />
                            <br />
                          </Col>
                        </Row>
                        <Row className="no-gutters">
                          <Col className="position-relative mr-4">
                            <input
                              type="password"
                              placeholder="Password"
                              className="text-boxe"
                              name="password"
                              id="password"
                            />
                            <FontAwesomeIcon
                              onClick={() => {
                                this.showPassword("password");
                              }}
                              icon={faEye}
                              style={{
                                marginTop: "10px",
                                marginLeft: "5px",
                                cursor: "pointer",
                                position: "absolute",
                                zIndex: 9,
                                right: "9px"
                              }}
                            />
                          </Col>
                          <Col>
                            <input
                              type="password"
                              placeholder="Confirm Password"
                              className="text-boxe"
                              name="confirm_password"
                              id="confirm_password"
                            />
                            <FontAwesomeIcon
                              onClick={() => {
                                this.showPassword("confirm_password");
                              }}
                              icon={faEye}
                              style={{
                                marginTop: "10px",
                                marginLeft: "5px",
                                cursor: "pointer",
                                position: "absolute",
                                zIndex: 9,
                                right: "9px"
                              }}
                            />
                          </Col>
                        </Row>
                        <Row className="no-gutters">
                          
                          <Col xs={1}>
                            
                          </Col>
                        </Row>
                        <br/>
                        <Row>
                          <Col>
                            <select
                              className="text-boxe"
                              onChange={this._handleRole}
                              name="role"
                              id="type"
                            >
                                <option className="text-boxe" >
                                Select Role
                                </option>
                                <option value='Doctor'>Doctor</option>
                                <option value='Nurse'>Nurse</option>
                                <option value='Student'>Medical Student</option>
                            </select>
                            <br />
                            <br />
                          </Col>
                        </Row>

                        <Row>
                          {this.state.isHMO && (
                            <Col xs={11}>
                            
                                <input
                                type="text"
                                name="hmo"
                                id="hmo"
                                placeholder="Enter HMOs"
                                className="text-boxe"
                              />
                              
                          
                              <br />
                              <br />
                            </Col>
                            )
                          }
                          {this.state.isDoctor && (
                            <Col>
                            <select
                              className="text-boxe"
                              onChange={this._handleRole}
                              name="specialaztion"
                              id="type"
                            >
                            <option className="text-boxe" >Specialty</option>
                                <option value='Physician'>Genral Physician</option>
                                <option value='Pediatrician'>Pediatrician</option>
                                <option value='Gynecologist'>Gynecologist</option>
                                <option value='Surgeon'> Surgeon</option>
                                <option value='Psychiatrist'> Psychiatrist</option>
                                <option value='Cardiologist'> Cardiologist</option>
                                <option value='Dermatologist'> Dermatologist</option>
                                <option value='Endocrinologist'> Endocrinologist</option>
                                <option value='Gastroenterologist'> Gastroenterologist</option>
                                <option value='Endocrinologist'> Nephrologist</option>
                                <option value='Ophthalmologist'> Ophthalmologist</option>
                                <option value='Otolaryngologist'> Otolaryngologist</option>
                                <option value='Pulmonologist'> Pulmonologist</option>
                                <option value='Radiologist'> Radiologist</option>
                                <option value='Anesthesiologist'> Anesthesiologist</option>
                                <option value='Oncologist'> Oncologist</option>
                          
                                </select>
                          <br />
                          <br />
                        </Col>
                          )}
                          {this.state.isNurse && (
                            <Col>
                            <select
                              className="text-boxe"
                              onChange={this._handleRole}
                              name="specialized"
                              id="type"
                            >
                            <option className="text-boxe" >
                                Specialty
                                </option>
                                <option value='Registered Nurse'>Registered Nurse</option>
                                <option value='Cardiac Nurse'>Cardiac Nurse</option>
                                <option value='CRNA'>Certified Registered Nurse Anesthetist</option>
                                <option value='CNS'> Clinical Nurse Specialist</option>
                                <option value='Critical Care Nurse'> Critical Care Nurse</option>
                                <option value='ER Nurse'> ER Nurse</option>
                                <option value='Family Nurse Practitioner'> Family Nurse Practitioner</option>
                                <option value='Geriatric Nursing'> Geriatric Nursing</option>
                                <option value='Perioperative Nurse'> Perioperative Nurse</option>
                                <option value='Mental Health Nurse'> Mental Health Nurse</option>
                                <option value='Nurse Educator'> Nurse Educator</option>
                                <option value='Nurse Midwife'> Nurse Midwife</option>
                                <option value='Nurse Practitioner'> Nurse Practitioner</option>
                                <option value='Oncology Nurse'> Oncology Nurse</option>
                                <option value='Orthopedic Nurse'> Orthopedic Nurse</option>
                                <option value='Pediatric Nurse'> Pediatric Nurse</option>
                          
                                </select>
                          <br />
                          <br />
                        </Col>
                          )}
                        </Row>
                        <Row>
                          <Col>
                            <input
                              type="text"
                              name="reg_number"
                              id="reg_number"
                              placeholder="Registration Number"
                              className="text-boxe"
                            />
                          </Col>
                        </Row>
                        <br />
                        <br />
                        </div>
                       
                        <div className="err" id="error-div"></div>
                        <div className="succ" id="success-div"></div>
                        {this.state.loader === true ? (
                          <Loader
                            type="TailSpin"
                            width={60}
                            height={60}
                            color="white"
                          />
                        ) : (
                          <button
                            style={{
                              float: "right",
                            }}
                            className="blue-btn pt-2 pb-2 pl-5 pr-5 rounded bg-white"
                            >
                            {translate('sign Up')} {">"}
                            </button>
                        )}
                      </form>
                      <br />
                      <br />
                      <br />
                      <Link to="/login">
                        <span
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "16px",
                            float: "left",
                          }}
                        >
                          {translate('already have an account?')} {translate('login')}
                        </span>{" "}
                      </Link>
                    </div>
                  </div>
                
                  </div>
                </Col>
              </Row>
            </div>
          </Pulse>
        </div>
      </Container>
    );
  }
}
