import React from "react";
import Header2 from "../../componenets/headers/Header2";
import "rsuite/dist/styles/rsuite-default.css";
import { Link } from "react-router-dom";
import { Alert } from "rsuite";
import { postData } from "../../services/request";
import V3 from "../../assets/Vector3.png";
import translate from "../../i18n/translation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

export default class UrbanSignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      loader: false,
      type: "own",
      redirect: false,
      isHmo: false,
      showHmoNumber: false,
      isAgreedCheck: false,
      firstname: "",
      lastname: "",
      email: "",
      dob: "",
      country: "",
      nin: "",
      user: "Patient",
      regNumber: null,
      password: "",
      confirm_password: "",
      cgh: "",
      //
      insuranceName: "",
      insuranceNumber: "",
      sponsorName: "",
      relation: "",
      hmolist: [
        "Hygeia HMO",
        "Total Health Trust HMO",
        "Integrated Healthcare Limited",
        "Avon HMO",
        "AIICO Multi-Shield Nig. Ltd",
        "Princeton Health Limited",
        "Defence Health Maintenance Ltd",
        "Mediplan Healthcare Limited",
        "Clearline International Limited",
        "Reliance HMO",
        "Swift HMO",
        "Novo Health Africa",
        "Pro-Health HMO",
        "Venus Medicare Ltd",
        "Metrohealth HMO",
        "Redcare HMO",
      ],
    };
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
  }
  signup = () => {
    var details = {
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      email: this.state.email,
      country: this.state.country,
      user: "Patient",
      regNumber: null,
      password: this.state.password,
      confirm_password: this.state.confirm_password,
      cgh: this.state.userType,
      insuranceName:
        this.state.insuranceName && this.state.insuranceName
          ? this.state.insuranceName
          : "",
      insuranceNumber:
        this.state.insuranceNumber && this.state.insuranceNumber
          ? this.state.insuranceNumber
          : "",
      sponsorName:
        this.state.sponsorName && this.state.sponsorName
          ? this.state.sponsorName
          : "",
      relation:
        this.state.relation && this.state.relation ? this.state.relation : "",
    };
    if (details.firstname.trim() === "" || details.firstname.trim() === null) {
      Alert.warning("Input your first name");
    } else if (
      details.lastname.trim() === "" ||
      details.lastname.trim() === null
    ) {
      Alert.warning("Input your last name");
    } else if (details.email.trim() === "" || details.email.trim() === null) {
      Alert.warning("Input your email");
    } else if (details.country === "Select Country") {
      Alert.warning("Select a country");
    } else if (
      details.password.trim() === null ||
      details.password.trim() === "" ||
      details.password.length <= 7
    ) {
      Alert.warning(
        "Input your password & length should be atleast of 8 characters"
      );
    } else if (
      details.confirm_password.trim() === null ||
      details.confirm_password.trim() === "" ||
      details.confirm_password.length <= 7
    ) {
      Alert.warning(
        "Confirm your password & length should be atleast of 8 characters"
      );
    } else if (details.password.trim() !== details.confirm_password.trim()) {
      Alert.error("Passwords do not match");
    } else {
      this.setState({ loader: true });
      postData("register", details)
        .then((response) => {
          this.setState({ loader: false });
          if (response.status === true) {
            Alert.success(response.message);
            this.props.history.push("/urban-login");
          } else {
            Alert.error(response.message);
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
          if (error.request.status === 422) {
            Alert.error(error.response.data.errors.email);
          } else if (error.request.status === 500) {
            Alert.error(
              "Connection to internet lost, please check connection and retry"
            );
          } else {
            Alert.error("Error creating account");
          }
        });
    }
    this.registerWallet(details);
  };

  registerWallet(details) {
    postData("wallet-registration", {
      name: details.firstname + " " + details.lastname,
      email: details.email,
      password: details.password,
      user_type: "telemedics",
      currency: "NGN",
    })
      .then((response) => {
        if (response.status === true) {
          // Alert.success();
          console.log("success in creation of wallet", response);
        } else {
          console.log("error in creation of wallet");
          // Alert.error(response.message);
        }
      })
      .catch((error) => {
        // this.setState({ loader: false });
        // if (error.request.status === 422) {
        // Alert.error(error.response.data.errors.email);
        // } else {
        // Alert.error("Error creating account");
        // }
        console.log("error in creation of wallet");
      });
  }

  togglePasswordVisibility() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }
  _handleCgh = (e) => {
    if (e.target.value === "HMO") {
      this.setState({ isHmo: true });
    } else {
      this.setState({ isHmo: false });
    }
  };
  hmoLists = () => {
    const list = this.state.hmolist.map((item) => (
      <option value={item}>{item}</option>
    ));
    return list;
  };
  _handleSelectHMO = (e) => {
    if (e.target.value) {
      this.setState({
        showHmoNumber: true,
      });
    } else {
      this.setState({
        showHmoNumber: false,
      });
    }
  };

  render() {
    if (
      window.location.href.indexOf("https") == -1 &&
      window.location.href.indexOf("wavetelemedicine") > -1
    ) {
      window.location.href = "https://wavetelemedicine.com/";
    }
    return (
      <>
        <div className="container-fluid">
          <div className="row ">
            <div className="col-sm-6 head2">
              <Header2 />
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-10">
                    <div className="row">
                      <Link to="/" style={{ textDecoration: "none" }}>
                        <div className="back-btn">
                          <img
                            src={V3}
                            alt=""
                            style={{ paddingBottom: "4px" }}
                          ></img>
                          <span className="pl-3 fz20 fw4 cl1A">Back</span>
                        </div>
                      </Link>
                    </div>
                    <div className="row">
                      <div className=" mt-4">
                        <div className="fz32 fw5 cl1A">Create an account </div>
                        <div className="fz20 fw5">
                          <span>Sign up as a</span>
                          <span className="pl-2 cl00 fw5">Urban Patient</span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12  mt-4">
                        <div className="row fw4 fz20">
                          <div className="col-sm-4">First Name</div>
                          <div className="col-sm-8">
                            <input
                              type="text"
                              className="input-field"
                              placeholder="Enter your first name"
                              onChange={(e) => {
                                this.setState({
                                  firstname: e.target.value,
                                });
                              }}
                              value={this.state.firstname}
                            ></input>
                          </div>
                        </div>
                        <div className="row mt-3 fw4 fz20">
                          <div className="col-sm-4">Last Name</div>
                          <div className="col-sm-8">
                            <input
                              type="text"
                              className="input-field"
                              placeholder="Enter your last name"
                              onChange={(e) => {
                                this.setState({
                                  lastname: e.target.value,
                                });
                              }}
                              value={this.state.lastname}
                            ></input>
                          </div>
                        </div>
                        {/*  */}
                        <div className="row mt-3 fw4 fz20">
                          <div className="col-sm-4">Email</div>
                          <div className="col-sm-8">
                            <input
                              type="email"
                              className="input-field"
                              placeholder="Enter your email"
                              onChange={(e) => {
                                this.setState({
                                  email: e.target.value,
                                });
                              }}
                              value={this.state.email}
                            ></input>
                          </div>
                        </div>
                        <div className="row mt-3 fw4 fz20">
                          <div className="col-sm-4">Date of Birth</div>
                          <div className="col-sm-8">
                            <input
                              type="date"
                              className="input-field"
                              placeholder="mm/dd/yyyy"
                              onChange={(e) => {
                                this.setState({
                                  dob: e.target.value,
                                });
                              }}
                              value={this.state.dob}
                            ></input>
                          </div>
                        </div>
                        <div className="row mt-3 fw4 fz20">
                          <div className="col-sm-4">Phone Number</div>
                          <div className="col-sm-8">
                            <input
                              type="number"
                              className="input-field"
                              placeholder="Enter your phone number"
                            ></input>
                          </div>
                        </div>
                        <div className="row mt-3 fw4 fz20">
                          <div className="col-sm-4">Country</div>
                          <div className="col-sm-8">
                            <select
                              className="input-field"
                              name="country"
                              id="country"
                              onChange={(e) => {
                                this.setState({
                                  country: e.target.value,
                                });
                              }}
                              value={this.state.country}
                            >
                              <option className="input-field"></option>
                              <option value="Nigeria">Nigeria</option>
                              <option value="United Kingdom">
                                United Kingdom
                              </option>
                              <option value="USA">USA</option>
                              <option value="Kenya">Kenya</option>
                              <option value="Zambia">Zambia</option>
                              <option value="Ghana">Ghana</option>
                            </select>
                          </div>
                        </div>
                        <div className="row mt-3 fw4 fz20">
                          <div className="col-sm-4">NIN</div>
                          <div className="col-sm-8">
                            <input
                              type="number"
                              className="input-field"
                              placeholder="Enter your NIN"
                              onChange={(e) => {
                                this.setState({
                                  nin: e.target.value,
                                });
                              }}
                              value={this.state.nin}
                            ></input>
                          </div>
                        </div>
                        <div className="row mt-3 fw4 fz20">
                          <div className="col-sm-4">Password</div>
                          <div className="col-sm-8 ">
                            <div style={{ position: "relative" }}>
                              <input
                                type={
                                  this.state.showPassword ? "text" : "password"
                                }
                                className="input-field"
                                placeholder="Enter your password"
                                onChange={(e) => {
                                  this.setState({
                                    password: e.target.value,
                                  });
                                }}
                                value={this.state.password}
                              ></input>
                              <FontAwesomeIcon
                                onClick={this.togglePasswordVisibility}
                                icon={faEye}
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  backgroundColor: "transparent",
                                  border: "none",
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3 fw4 fz20">
                          <div className="col-sm-4">Confirm Password</div>
                          <div className="col-sm-8">
                            <input
                              type="password"
                              className="input-field"
                              placeholder="Enter your password"
                              onChange={(e) => {
                                this.setState({
                                  confirm_password: e.target.value,
                                });
                              }}
                              value={this.state.confirm_password}
                            ></input>
                          </div>
                        </div>
                        {/*  */}

                        <div className="row mt-3 uderline"></div>
                        <div className="row justify-content-between mt-3">
                          <div className="col-sm-6">
                            <span style={{ paddingTop: "10px" }}>
                              <input
                                type="checkbox"
                                className="form-check-input"
                              ></input>
                            </span>
                            <span style={{ paddingTop: "10px" }}>
                              {" "}
                              I accept WaveTelemedicine terms and conditions
                            </span>
                          </div>
                          <div className="col-sm-6 text-right">
                            <button
                              type="button"
                              className="bt-patient"
                              onClick={this.signup}
                              disabled={this.state.loader}
                            >
                              {this.state.loader
                                ? "Signup..."
                                : translate("Sign up")}
                            </button>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-12 text-center">
                            <span className="fw3 fz14 m-auto">
                              Already have an account?
                            </span>
                            <Link to="/urban-login">
                              <span className="fz14 fw5 cl00">login</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 img3 desktop"></div>
          </div>
        </div>
        {/*  */}
        {/* <div className="container-fluid backimg4">
        
                  <Row>
                    <Col sm={12} lg={6} className="position-relativt">
                      <label className="rem1">Password</label>
                      <input
                        type="password"
                        className="form-control bc-f5"
                        name="password"
                        id="password"
                      />
                      <FontAwesomeIcon
                        onClick={() => {
                          this.showPassword("password");
                        }}
                        icon={faEye}
                        style={{
                          marginTop: "-24px",
                          cursor: "pointer",
                          position: "absolute",
                          zIndex: 9,
                          right: "20px",
                        }}
                      />
                    </Col>
                    <Col sm={12} lg={6}>
                      <label className="rem1">Confirm Password</label>
                      <input
                        type="password"
                        className="form-control bc-f5"
                        name="confirm_password"
                        id="confirm_password"
                      />
                      <FontAwesomeIcon
                        onClick={() => {
                          this.showPassword("confirm_password");
                        }}
                        icon={faEye}
                        style={{
                          marginTop: "-24px",
                          cursor: "pointer",
                          position: "absolute",
                          zIndex: 9,
                          right: "20px",
                        }}
                      />
                    </Col>
                  </Row>
                
           
          </div>
        </Row>
      </div> */}
      </>
    );
  }
}
