import React from "react";
import LeftBar from "./leftBar";
import { postDataAuth, getAuth, postData } from "../../../services/request";
import swal from "sweetalert";

export default class AllBeneficiary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allUsers: [],
    };
  }
  componentDidMount = async () => {
    const userEmail = await sessionStorage.getItem("emailWallet");
    const users = await postData("allBeneficiary", { email: userEmail });
    console.log(users.medicalExperts);
    this.setState({
      allUsers: users.medicalExperts,
    });
  };
  ActiveUser = (id) => {
    swal({
      title: "Are you sure?",
      text: "You want to Active this Patient!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        postDataAuth("activate", {
          id: id,
        });

        swal("Poof! Your Patient has been active!", {
          icon: "success",
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        swal("Your Patient is safe!");
      }
    });
  };
  // patient deactive
  deactiveUser = (id) => {
    swal({
      title: "Are you sure?",
      text: "You want to deactive this Patient!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        postDataAuth("deactivate", {
          id: id,
        });

        swal("Poof! Your Patient has been deleted!", {
          icon: "success",
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        swal("Your  Patient is safe!");
      }
    });
  };
  render() {
    return (
      <div className="container-fluid">
        <div className="row ">
          <div className="col-2 top-bo">
            <LeftBar history={this.props.history} />
          </div>
          <div className="col-10 top-bo bc-f5">
            <div className="row page-size">
              <div className="col-12">
                <table class="table">
                  <thead className="boder-col">
                    <tr>
                      <th scope="col" className="">
                        #
                      </th>
                      <th scope="col" className="">
                        First Name
                      </th>
                      <th scope="col" className="">
                        Last Name
                      </th>
                      <th scope="col" className="">
                        Email
                      </th>
                      <th scope="col" className="">
                        Status
                      </th>
                      <th scope="col" className=""></th>
                    </tr>
                  </thead>
                  {this.state.allUsers.map((data, idx) => (
                    <tbody key={idx}>
                      <tr>
                        <td className="">{idx + 1}</td>
                        <td className="">{data.firstname}</td>
                        <td className="">{data.lastname}</td>
                        <td className="">{data.email}</td>
                        {data.status === 1 && (
                          <>
                            <td className="">Active</td>

                            <td>
                              <div
                                className="cancle-bt"
                                onClick={() => {
                                  this.deactiveUser(data.id);
                                }}
                              >
                                Deactivate
                              </div>
                            </td>
                          </>
                        )}
                        {data.status === 0 && (
                          <>
                            <td className="">Deactiva</td>

                            <td>
                              <div
                                className="act-bt"
                                onClick={() => {
                                  this.ActiveUser(data.id);
                                }}
                              >
                                Activate
                              </div>
                            </td>
                          </>
                        )}
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
