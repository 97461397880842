import React from "react";
import { faArrowCircleLeft, faBars } from "@fortawesome/free-solid-svg-icons";
import { googleTranslate } from "../utils/googleTranslate";
import Header2 from "../componenets/headers/Header2";
import {
  getAuth,
  fetchData,
  postDataAuth,
  fetchDataAuth,
  postData,
  getBank,
  getBranch,
} from "../services/request";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { captializeFirstLetter } from "./common";
import {
  faUser,
  faVideo,
  faPowerOff,
  faEllipsisV,
  faUnlink,
  faIdBadge,
  faEnvelopeOpenText,
  faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons";
import Phone from "../assets/Phone.png";
import Video from "../assets/Video.png";
import { NoChat } from "./pieces";
import io from "socket.io-client";
import { Container, Row, Col, Card, Navbar, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import translate from "../i18n/translation";
import {
  Alert,
  Tooltip,
  Whisper,
  Modal,
  Button,
  Drawer,
  Toggle,
  Loader,
  Badge,
} from "rsuite";
import { Redirect } from "react-router-dom";
import Call from "./call";
import { baseUrl } from "./../services/request";
import PatientIndex from "./patientindex";
import Axios from "axios";
import IdleTimer from "react-idle-timer";
import waveLogo from "../assets/images/fulllogo.png";
import imageInbox from "../assets/images/Image-inbox.png";
import camera from "../assets/images/camera.png";
import sendMessage from "../assets/SendVector.png";
import Dialog from "./DialogBox/Dr.Dailog";
const profileBase = "https://telemedicine-wave.s3.us-east-2.amazonaws.com/";

export default class DoctorChat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // ammount detail
      name: "",
      email: "",
      phone: "",
      bankName: "",
      branchNo: "",
      accountNo: "",
      ammount: "",
      loder: false,
      bankList: [],
      branchList: [],
      //
      redirect: false,
      isOpen: false,
      authenticated: [],
      doctors: [],
      assignedPatient: null,
      socketConnection: null,
      messages: [],
      sentMessage: false,
      show: false,
      showModal: false,
      showMenu: false,
      available: true,
      call: false,
      patientProfile: [],
      previousPrsecriptions: [],
      ptyping: false,
      prsecription: false,
      prescriptionLoader: false,
      showPatientProfile: false,
      showPatientVitalStats: false,
      showPatientMedicalHistory: false,
      docProfile: null,
      key: "home",
      walletBalance: 0,
      aiStats: null,
      showPP: false,
      showMP: false,
      languageCodes: [],
      language: sessionStorage.getItem("language")
        ? sessionStorage.getItem("language")
        : "en",
      question: sessionStorage.getItem("question")
        ? sessionStorage.getItem("question")
        : "What language do you prefer to read with?",
    };
    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
  }

  handleWalletTransactioDetails = () => {
    this.setState({
      redirect: true,
    });
  };
  sendMoney = async () => {
    const timer = (ms) => new Promise((res) => setTimeout(res, ms));

    if (this.state.walletBalance == 0) {
      alert("Your Balace is Zero");
      this.setState({ isOpen: false });
    } else {
      this.setState({ loder: true });
      const drInfo = {
        name: this.state.name,
        email: this.state.email,
        // email: "saqib.shahid44@gmail.com",
        phone: this.state.phone,
        branchNo: this.state.branchNo,
        accountNo: this.state.accountNo,
        ammount: this.state.walletBalance,
      };
      postData("flutterwave", drInfo);
      await timer(3000);
      this.setState({ loder: false, isOpen: false });
      alert("Your request to withdraw funds is send");
    }
  };

  showProfilePatient = () => {
    this.setState({ showPP: !this.state.showPP });
  };
  showMedicalPatient = () => {
    this.setState({ showMP: !this.state.showMP });
  };

  showChat = () => {
    this.setState({ call: false });
  };
  showMenu = () => {
    this.setState({ showMenu: true });
  };
  closeMenu = () => {
    this.setState({ showMenu: false });
  };
  closePatientMenu = () => {
    this.setState({ showPatientProfile: false });
  };
  closePatientVital = () => {
    this.setState({ showPatientVitalStats: false });
  };
  closePatientMedicalHistory = () => {
    this.setState({ showPatientMedicalHistory: false });
  };
  prsecriptionDrawerOpen = () => {
    this.setState({ prescription: true });
  };
  prsecriptionDrawerClose = () => {
    this.setState({ prescription: false });
  };
  show = () => {
    //    this.setState({show:true},()=>{
    //        this.startcall()
    //    })
    this.setState({
      call: true,
    });
  };
  close = () => {
    this.setState({ show: false });
  };
  componentDidMount = () => {
    getBank("bankName", { country: "NG" }).then((response) => {
      this.setState({
        bankList: response.data,
      });
      console.log("bank list ==", response.data);
    });
    var token = localStorage.getItem("med_token");
    const _this = this;

    // load all of the language options from Google Translate to your app state
    googleTranslate.getSupportedLanguages("en", function (err, languageCodes) {
      getLanguageCodes(languageCodes); // use a callback function to setState
    });
    const getLanguageCodes = (languageCodes) => {
      this.setState({ languageCodes });
    };

    getAuth("authenticated", token)
      .then((response) => {
        // Notes : here it check if any patient is assigned or not if assigned make it unavailable

        if (response.data.user.isLogin === 0) {
          Alert.error("Session finished, please relogin");
          window.location.href = "/login";
        } else {
          this.getUserWalletInformation(token, response);

          this.setState(
            {
              authenticated: response.data.user,
              name:
                response.data.user.firstname +
                " " +
                response.data.user.lastname,
              email: response.data.user.email,
              phone: response.data.user.phone,
            },
            () => {
              if (this.state.authenticated.assigned === null) {
                this.setStatus(true);
                this.setState({ available: true }, () => {
                  this.joinActiveRoom();
                });
              } else {
                this.setState({ available: false }, () => {
                  this.joinActiveRoom();
                  this.fetchPatient();
                });
              }
              this.getUserDocProfile();
              // var objDiv = document.getElementById("doctor-chat");
              // objDiv.scrollTop = objDiv.scrollHeight;
              // this.fetchPatient()

              //     this.fetchMessage()
            }
          );
        }
      })
      .catch(() => {
        Alert.error("Unable to fetch your details");
      });

    window.addEventListener("beforeunload", () => {
      //this.state.socketConnection.emit("logout", {patientId:this.state.assignedPatient.id,doctorId:this.state.authenticated.id});
    });
  };
  // rest
  // resetDropdown = (type) => {
  //   switch (type) {
  //     case "bank":
  //       this.setState({ branchList: [] });
  //       break;

  //     default:
  //       break;
  //   }
  // };
  // bank code call
  callBankCode = () => {
    // getBranch(e);
    // console.log(this.state.bankList);

    getBank("bankCode", { code: this.state.bankName }).then((response) => {
      if (response == "") {
        this.setState({
          branchList: [
            {
              branch_code: "",
              branch_name: "No branches found for specified bank id",
            },
          ],
        });
      } else {
        this.setState({
          branchList: response && response.data ? response.data : "",
        });
      }
    });
  };
  //
  getUserWalletInformation = (token, doctor) => {
    const { data } = doctor;
    // debugger
    postDataAuth(
      "getWalletBalance",
      {
        email: data.user.email,
      },
      token
    )
      .then((response) => {
        // console.log(response.medicalExperts)
        // debugger;
        this.setState({
          walletBalance: response && response.balance ? response.balance : "",
        });
      })
      .catch((error) => {});
  };

  joinActiveRoom = () => {
    var room = this.state.authenticated.id + "Wavemedics";
    this.state.socketConnection.emit(
      "joinActiveRoom",
      { room: room },
      () => {}
    );
  };
  getUserPatientProfile = () => {
    var token = localStorage.getItem("med_token");
    if (this.state.assignedPatient.id !== null) {
      var patient = this.state.assignedPatient.id;
      fetchDataAuth("getPatientProfileD", patient, token)
        .then((response) => {
          if (response.status === true) {
            this.setState({
              patientProfile: response.profile,
            });
            // this.fetchPrescriptions();
            this.fetchAiMedicalIllnessStats();
          }
        })
        .catch(() => {
          Alert.error("Error fetching your patient profile");
        });
    }
  };
  fetchPatient = () => {
    var token = localStorage.getItem("med_token");
    postDataAuth(
      "fetchPatient",
      { id: this.state.authenticated.assigned },
      token
    )
      .then((response) => {
        this.setState({ assignedPatient: response.patient }, () => {
          this.getUserPatientProfile();
          this.joinRoom();
        });
        // this.fetchPrescriptions();
      })
      .catch(() => {});
  };
  fetchAiMedicalIllnessStats = () => {
    var token = localStorage.getItem("med_token");
    postDataAuth(
      "fetchAiStats",
      {
        id: 33, //this.state.authenticated.assigned,
        Disease: ["daibetes", "hypertension"],
      },
      token
    )
      .then((response) => {
        this.setState({ aiStats: response });
      })
      .catch(() => {});
  };
  componentWillMount = () => {
    const socket = io("https://telemedicine-server.herokuapp.com/");
    // const socket = io('http://localhost:2000/');
    socket.on("connect", function () {
      console.log("connected to " + socket.id);
    });
    this.setState({ socketConnection: socket }, () => {
      this.state.socketConnection.on("response", async (response) => {
        console.log("socket message", response);
        if (response && response.message === "loggingout") {
          this.unassign();
          this.setState({
            assignedPatient: null,
            patientProfile: [],
          });
        } else {
          if (response && response.type !== "doctor") {
            const translatedMsg = await this._translateText(response);
          }

          this.setState(
            {
              messages: [...this.state.messages, response],
            },
            () => {
              var objDiv = document.getElementById("doctor-chat");
              objDiv.scrollTop = objDiv.scrollHeight;
            }
          );
        }
      });

      this.state.socketConnection.on("patientReady", (response) => {
        this.setState({ assignedPatient: response, available: false }, () => {
          Alert.success("New Patient Assigned");
          this.joinRoom();
          this.setStatus(false);
          this.getUserPatientProfile();
        });
      });

      this.state.socketConnection.on("ptyping", () => {
        this.setState({ ptyping: true });
      });
      this.state.socketConnection.on("pNtyping", () => {
        // setTimeout(() => {
        this.setState({ ptyping: false });
        // },2000);
      });
    });
  };
  setStatus = (checked) => {
    var token = localStorage.getItem("med_token");
    postDataAuth("status", { active: checked }, token)
      .then(() => {})
      .catch(() => {});
  };
  handlePatientProfile = () => {
    this.setState({
      showPatientProfile: !this.showPatientProfile,
    });
  };
  handlePatientVitalStats = () => {
    this.setState({
      showPatientVitalStats: !this.showPatientVitalStats,
    });
  };
  handlePatientMedicalHistory = () => {
    this.setState({
      showPatientMedicalHistory: !this.showPatientMedicalHistory,
    });
  };
  fetchMessage = () => {
    var token = localStorage.getItem("med_token");
    var details = {
      receiver: this.state.authenticated.assigned,
    };
    postDataAuth("getMessage", details, token)
      .then((response) => {
        this.setState(
          {
            messages: response.messages,
          },
          () => {
            var objDiv = document.getElementById("doctor-chat");
            objDiv.scrollTop = objDiv.scrollHeight;
            console.log(this.state.messages);
          }
        );
      })
      .catch(() => {});
  };
  fetchPrescriptions = () => {
    var token = localStorage.getItem("med_token");
    if (this.state.assignedPatient && this.state.assignedPatient.id) {
      getAuth(`prescription/${this.state.assignedPatient.id}`, token)
        .then((response) => {
          const { data } = response;
          this.setState({
            previousPrsecriptions: data.prescriptions,
          });
        })
        .catch(() => {
          Alert.error("Unable to fetch your prescriptions");
        });
    }
  };
  joinRoom = () => {
    var room =
      this.state.authenticated.id + "&" + this.state.assignedPatient.id;
    this.state.authenticated.assigned = this.state.assignedPatient.id;
    this.state.socketConnection.emit("join", { room: room });
  };
  sendMessage = (e) => {
    e.preventDefault();
    var details = {
      room:
        this.state.authenticated.id + "&" + this.state.authenticated.assigned,
      message: e.target.message.value,
      senderId: this.state.authenticated.id,
      receiverId: this.state.authenticated.assigned,
      type: "doctor",
    };
    e.target.message.value = "";
    this.state.socketConnection.emit("sendMessage", details, () => {
      this.setState({ sentMessage: true });
    });
  };

  handleWalletOverview = () => {
    this.childWallet.current.handlePatientVitalStats();
  };
  startcall = () => {
    var meet = new window.JitsiMeet("https://meet.covid19telemedics.com");
    var meetingName =
      this.state.authenticated.firstname +
      " and " +
      this.state.assignedPatient.firstname;
    var details = {
      room:
        this.state.authenticated.id + "&" + this.state.authenticated.assigned,
    };
    this.state.socketConnection.emit("startCall", details);
    meet.on("ready", function () {
      var interfaceConfig = {
        TOOLBAR_BUTTONS: [
          "microphone",
          "camera",
          "desktop",
          "fullscreen",
          "hangup",
          "settings",
        ],
        SETTINGS_SECTIONS: ["language", "devices"],
        DEFAULT_REMOTE_DISPLAY_NAME: "John Doe",
      };
      var options = {};
      options.interfaceConfig = interfaceConfig;
      try {
        console.log(meetingName);
        var conference = meet.join(meetingName, "#dr-call", options);
        conference.on("joined", function () {});
      } catch (error) {
        console.log(error);
      }
    });
  };

  logout = () => {
    var token = localStorage.getItem("med_token");
    postDataAuth("status", { active: false }, token)
      .then((response) => {
        if (response.status === true) {
          if (this.state.assignedPatient !== null) {
            postDataAuth("logout", { id: this.state.assignedPatient.id }, token)
              .then((response) => {
                if (response.status === true) {
                  Alert.success("Logging you out.....");
                  this.setState({ loggedIn: false }, () => {
                    setTimeout(() => {
                      localStorage.removeItem("med_token");
                    }, 1000);
                    this.state.socketConnection.emit("logout", {
                      patientId: this.state.assignedPatient.id,
                      doctorId: this.state.authenticated.id,
                    });
                  });
                } else {
                  Alert.error("Unable to log you out");
                }
              })
              .catch(() => {
                Alert.error("Error logging you out");
              });
          } else {
            this.setState({ loggedIn: false }, () => {
              setTimeout(() => {
                localStorage.removeItem("med_token");
              }, 1000);
            });
          }
        } else {
          Alert.error("Error signing out");
        }
      })
      .catch(() => {
        Alert.error("Error signing out");
      });
  };

  handleRefer = (e) => {
    e.preventDefault();
    if (
      this.state.assignedPatient !== null &&
      this.state.authenticated !== null &&
      e.target.specialaztion.value
    ) {
      var token = localStorage.getItem("med_token");
      var details = {
        id: this.state.assignedPatient.id,
        refer: e.target.specialaztion.value,
        comments: `${e.target.comments.value}`,
      };
      this.setState({ loader: true });
      postDataAuth("savePaitentRefer", details, token).then((response) => {
        if (response.status === true) {
          Alert.success("Patient Refers Succesfully");
          this.unassign();
          this.setState({ loader: false, showModal: false });
          // this.prsecriptionDrawerClose();
          // this.setState({ available: true });
        } else {
          Alert.error(
            "Something went wrong during saving please contact adminstrator"
          );
        }
      });
    }
  };

  prescribe = (e) => {
    e.preventDefault();
    if (
      this.state.assignedPatient !== null &&
      this.state.authenticated !== null
    ) {
      var token = localStorage.getItem("med_token");
      var details = {
        patient: this.state.assignedPatient.id,
        patient_name:
          captializeFirstLetter(this.state.assignedPatient.firstname) +
          " " +
          captializeFirstLetter(this.state.assignedPatient.lastname),
        doctor_name:
          captializeFirstLetter(this.state.authenticated.firstname) +
          " " +
          captializeFirstLetter(this.state.authenticated.lastname),
        doctor: this.state.authenticated.id,
        prescription: e.target.prescription.value,
        comments: `${e.target.medicalComments.value}: Dr. ${this.state.authenticated.firstname} ${this.state.authenticated.lastname}`,
      };

      if (details.patient === null || details.patient === "") {
        Alert.warning("You have no patient assigned");
      } else if (details.doctor === null || details.doctor === "") {
        Alert.warning("You are not authenticated");
      } else if (details.prescription === null || details.prescription === "") {
        Alert.warning("Input the prescription you want to send");
      } else {
        this.setState({ prescriptionLoader: true });
        postDataAuth("prescription", details, token).then((response) => {
          if (response.status === true) {
            const prescriptions = this.state.previousPrsecriptions;
            prescriptions.push({ prescription: details.prescription });

            this.setState({
              prescriptionLoader: false,
              previousPrsecriptions: prescriptions,
            });
            Alert.success(response.message);
            this.prsecriptionDrawerClose();
            // this.setState({ available: true });
          } else {
            Alert.error(
              "Something went wrong during saving please contact adminstrator"
            );
          }
        });
      }
    } else {
      Alert.error(
        "Make sure you have a patient assigned and you are authenticated"
      );
    }
  };
  unassign = () => {
    var token = localStorage.getItem("med_token");
    if (this.state.assignedPatient !== null) {
      postDataAuth("unassign", { id: this.state.assignedPatient.id }, token)
        .then((response) => {
          if (response.status === true) {
            Alert.success(response.Message);
            this.setState({ available: true });
          } else {
            Alert.error(response.Message);
          }
        })
        .catch(() => {
          Alert.error("Error Unassigning patient");
        });
    }
  };

  typing = () => {
    var details = {
      room:
        this.state.authenticated.id + "&" + this.state.authenticated.assigned,
    };

    this.state.socketConnection.emit("doctor_typing", details);
  };
  notTyping = () => {
    var details = {
      room:
        this.state.authenticated.id + "&" + this.state.authenticated.assigned,
    };
    setTimeout(() => {
      this.state.socketConnection.emit("doctor_not_typing", details);
    }, 2000);
  };
  changeHandler = (language) => {
    let { question } = this.state;
    let cookieLanguage = language;
    let transQuestion = "";
    // debugger;
    const translating = (transQuestion) => {
      this.setState({ question: transQuestion });
    };
    googleTranslate.translate(question, language, function (err, translation) {
      transQuestion = translation.translatedText;
      translating(transQuestion);
    });
  };
  _translateText = (textObj) => {
    return textObj.message;
    // return new Promise((resolve, reject) => {
    //   try {
    //     const language = sessionStorage.getItem("language")
    //       ? sessionStorage.getItem("language")
    //       : "en";
    //     let transQuestion = null;

    //     if (textObj.langauge !== language) {
    //       const translating = (transQuestion) => {
    //         this.setState({ question: transQuestion });
    //       };
    //       googleTranslate.translate(
    //         textObj.message,
    //         language,
    //         function (err, translation) {
    //           transQuestion = translation.translatedText;

    //           textObj["message"] = transQuestion;
    //           resolve(textObj);
    //         }
    //       );
    //     }
    //   } catch (error) {
    //     console.error(error);
    //     reject(false);
    //   }
    // });
  };
  handleOnAction(event) {
    console.log("user did something", event);
  }

  handleOnActive(event) {
    console.log("user is active", event);
    console.log("time remaining", this.idleTimer.getRemainingTime());
  }

  // trigger after every 15mins if idle
  handleOnIdle(event) {
    this.state.socketConnection.emit("logout", {
      patientId: this.state.assignedPatient.id,
      doctorId: this.state.authenticated.id,
    });
    Alert.error("Logging out due to inactivity from past 15mins");
    setTimeout(() => {
      window.location.href = "/";
    }, 500);
    // console.log('user is idle', event)
    // console.log('last active', this.idleTimer.getLastActiveTime())
  }
  generateMedicalHistory() {
    return this.state.previousPrsecriptions.map((each) => {
      return (
        <>
          <div
            className="menu-items p-3 mt-2 shadow-sm rounded"
            style={{ backgroundColor: "#f2f3f8" }}
          >
            {each.comments ? each.comments.split(":")[0] : ""}
            <br />
            <strong className="h6">
              {each.comments ? each.comments.split(":")[1] : ""}
            </strong>
          </div>
          <hr />
        </>
      );
    });
  }

  getUserDocProfile = () => {
    var token = localStorage.getItem("med_token");
    var doctor = this.state.authenticated.id;
    fetchDataAuth("getDoctorProfile", doctor, token)
      .then((response) => {
        // debugger;
        if (response.status === true) {
          this.setState({
            docProfile: response.profile,
            branchNo: response.profile.bankAccount,
            accountNo: response.profile.accountNumber,
          });
        }
      })
      .catch(() => {
        Alert.error("Error fetching your patient profile");
      });
  };

  render() {
    // console.log('lllllll', this.state.authenticated)
    if (this.state.loggedIn === false) {
      return <Redirect to="/login" />;
    }
    if (this.state.redirect === true) {
      return <Redirect to="/viewTransactions" />;
    }
    var pres = this.state.previousPrsecriptions.map((item, idx) => {
      return (
        <>
          <Card key={idx} style={{ width: "70%" }} className="mb-3">
            <Card.Body>
              <Card.Text>{item.prescription}</Card.Text>
            </Card.Body>
          </Card>
        </>
      );
    });
    var msg = this.state.messages.map((item, key) => {
      return (
        <>
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            timeout={1000 * 60 * 15}
            onActive={this.handleOnActive}
            onIdle={this.handleOnIdle}
            onAction={this.handleOnAction}
            debounce={250}
          />
          <Row>
            <Col xs={12}>
              <div
                className={
                  item.senderId === this.state.authenticated.id
                    ? "bubble sender  bg-white"
                    : "bubble receiver  bg-white"
                }
                key={key}
              >
                {item.senderId === this.state.authenticated.id ? (
                  <div className="chatowner float-right">
                    <img
                      alt="pic"
                      className="profile-pic rounded pic1"
                      style={{ width: "50px", height: "50px" }}
                      src={
                        this.state.docProfile.picture.indexOf(
                          "telemedicine-wave.s3"
                        ) > -1
                          ? this.state.docProfile.picture
                          : "https://telemedicine-wave.s3.us-east-2.amazonaws.com/" +
                            this.state.docProfile.picture
                      }
                    />
                    {/* {this.state.authenticated.firstname} */}
                  </div>
                ) : (
                  <div className="chatowner float-left">
                    <img
                      alt="pic"
                      className="profile-pic rounded pic2"
                      style={{ width: "50px", height: "50px" }}
                      src={
                        this.state.patientProfile.picture.indexOf(
                          "telemedicine-wave.s3"
                        ) > -1
                          ? this.state.patientProfile.picture
                          : "https://telemedicine-wave.s3.us-east-2.amazonaws.com/" +
                            this.state.patientProfile.picture
                      }
                    />
                    {/* <div>{this.state.assignedPatient.firstname}</div> */}
                  </div>
                )}
                <div
                  className={
                    item.senderId === this.state.authenticated.id
                      ? "message sender text-dark p-2 float-left doc-panel"
                      : "message receiver text-dark p-2  doc-panel"
                  }
                >
                  {item.message}{" "}
                </div>
              </div>
            </Col>
          </Row>
        </>
      );
    });

    const tooltip = <Tooltip>Start video call with patient</Tooltip>;
    const tooltip2 = <Tooltip>Send Money in Account</Tooltip>;
    const { languageCodes, language, question } = this.state;
    return (
      <Container className="container-fluid cont" fluid>
        <Header2 />

        {this.state.call === true &&
        this.state.patientProfile &&
        this.state.previousPrsecriptions ? (
          <Call
            authenticated={this.state.authenticated}
            assignedPatient={this.state.assignedPatient}
            patientProfile={this.state.patientProfile}
            socketConnection={this.state.socketConnection}
            previousPrsecriptions={this.state.previousPrsecriptions}
            from="Doc"
            backToChat={this.showChat}
          />
        ) : (
          <div className="whole-box" style={{ height: "auto" }}>
            {/* Start modal here */}
            <div className="modal-container">
              <Modal
                backdrop="static"
                show={this.state.showModal}
                onHide={() => {
                  this.setState({ showModal: false });
                }}
                size="xs"
              >
                <Modal.Header>
                  <Modal.Title>
                    {translate("referpatient")}{" "}
                    {this.state.assignedPatient &&
                      captializeFirstLetter(
                        this.state.assignedPatient.firstname
                      ) +
                        " " +
                        captializeFirstLetter(
                          this.state.assignedPatient.lastname
                        )}
                    <hr />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <form onSubmit={this.handleRefer} id="signupqid">
                      <div style={{ overflowY: "auto", maxHeight: "77vh" }}>
                        <Row>
                          <Col>
                            <select
                              className="text-boxe"
                              onChange={this._handleRole}
                              name="specialaztion"
                              id="type"
                            >
                              <option className="text-boxe">Specialty</option>
                              <option value="Physician">
                                Genral Physician
                              </option>
                              <option value="Pediatrician">Pediatrician</option>
                              <option value="Gynecologist">Gynecologist</option>
                              <option value="Surgeon"> Surgeon</option>
                              <option value="Psychiatrist">
                                {" "}
                                Psychiatrist
                              </option>
                              <option value="Cardiologist">
                                {" "}
                                Cardiologist
                              </option>
                              <option value="Dermatologist">
                                {" "}
                                Dermatologist
                              </option>
                              <option value="Endocrinologist">
                                {" "}
                                Endocrinologist
                              </option>
                              <option value="Gastroenterologist">
                                {" "}
                                Gastroenterologist
                              </option>
                              <option value="Endocrinologist">
                                {" "}
                                Nephrologist
                              </option>
                              <option value="Ophthalmologist">
                                {" "}
                                Ophthalmologist
                              </option>
                              <option value="Otolaryngologist">
                                {" "}
                                Otolaryngologist
                              </option>
                              <option value="Pulmonologist">
                                {" "}
                                Pulmonologist
                              </option>
                              <option value="Radiologist"> Radiologist</option>
                              <option value="Anesthesiologist">
                                {" "}
                                Anesthesiologist
                              </option>
                              <option value="Oncologist"> Oncologist</option>
                            </select>
                            <br />
                            <br />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <span className="h5">Comment</span>
                            <br />
                            <br />
                            <textarea
                              className="text-boxe"
                              id="comment"
                              name="comments"
                              placeholder="Any comments"
                            ></textarea>
                            <br />
                          </Col>
                        </Row>
                        <br />
                      </div>
                      {this.state.loader === true ? (
                        <Loader
                          type="TailSpin"
                          width={60}
                          height={60}
                          color="#2c6cb0"
                        />
                      ) : (
                        <button
                          className="buttons"
                          style={{
                            backgroundColor: "#2c6cb0",
                            color: "white",
                            position: "relative",
                            top: "32px",
                          }}
                        >
                          {translate("submit")}
                        </button>
                      )}
                    </form>
                  </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
              </Modal>
            </div>

            <Drawer
              show={this.state.showMenu}
              onHide={this.closeMenu}
              size="xs"
              placement="left"
            >
              <Drawer.Header>
                <Drawer.Title>Menu</Drawer.Title>
              </Drawer.Header>
              <Drawer.Body>
                <hr />
                <div className="menu-items" onClick={this.showProfilePatient}>
                  {" "}
                  Patient Profile
                </div>
                <hr />
                <div className="menu-items" onClick={this.showMedicalPatient}>
                  {" "}
                  Patient Medical Detail{" "}
                </div>
                <hr />
                <div className="menu-items" onClick={this.logout}>
                  <FontAwesomeIcon icon={faPowerOff} /> Logout
                </div>
                <hr />
                <div className="walletInfo">
                  <Whisper placement="left" trigger="hover" speaker={tooltip}>
                    <span
                      className="btn btn-light btn-outline-secondary float-right"
                      onClick={() => {
                        console.log("withdraw window open for doctor");
                      }}
                    >
                      <FontAwesomeIcon icon={faWallet} /> Current Balance :{" "}
                      <strong>{this.state.walletBalance} (NGN)</strong>{" "}
                    </span>
                  </Whisper>
                </div>

                <br />
              </Drawer.Body>
              <Drawer.Footer></Drawer.Footer>
            </Drawer>

            {this.state.assignedPatient &&
              this.state.patientProfile &&
              this.state.aiStats && (
                <>
                  <Drawer
                    show={this.state.showPatientProfile}
                    onHide={this.closePatientMenu}
                    size="xs"
                    placement="left"
                  >
                    <Drawer.Header>
                      <Drawer.Title></Drawer.Title>
                    </Drawer.Header>
                    <Drawer.Body>
                      <div className="menu-items">
                        <strong>{translate("patientName")} </strong>:{" "}
                        {captializeFirstLetter(
                          this.state.assignedPatient.firstname
                        )}{" "}
                        {captializeFirstLetter(
                          this.state.assignedPatient.lastname
                        )}
                      </div>
                      <hr />
                      <div className="menu-items">
                        <strong>Diabetes </strong>:{" "}
                        {this.state.aiStats.daibetes}
                      </div>
                      <hr />
                      <div className="menu-items">
                        <strong>Hypertension </strong>:{" "}
                        {this.state.aiStats.hypertension}
                      </div>
                      <hr />
                      <div className="menu-items">
                        <strong>{translate("patientName")} </strong>:{" "}
                        {captializeFirstLetter(
                          this.state.assignedPatient.firstname
                        )}
                      </div>
                      <hr />

                      <br />
                    </Drawer.Body>
                    <Drawer.Footer></Drawer.Footer>
                  </Drawer>
                </>
              )}

            {/* Patient Medical History drawer */}
            {this.generateMedicalHistor &&
              this.state.assignedPatient &&
              this.state.patientProfile && (
                <>
                  <Drawer
                    show={this.state.showPatientMedicalHistory}
                    onHide={this.closePatientMedicalHistory}
                    size="xs"
                    placement="left"
                  >
                    <Drawer.Header>
                      <Drawer.Title>{translate("medical")}</Drawer.Title>
                    </Drawer.Header>
                    <Drawer.Body>
                      {this.state.previousPrsecriptions &&
                        this.state.previousPrsecriptions.length > 0 &&
                        this.generateMedicalHistory()}

                      {this.state.previousPrsecriptions &&
                        this.state.previousPrsecriptions.length === 0 && (
                          <div className="menu-items">
                            <strong>{translate("nomedicalhistory")}</strong>
                          </div>
                        )}

                      <br />
                    </Drawer.Body>
                    <Drawer.Footer></Drawer.Footer>
                  </Drawer>
                </>
              )}

            {/* menu drawer ends here */}

            {/* Video call modal starts here */}
            <Drawer
              show={false}
              onHide={this.close}
              size="lg"
              placement="right"
            >
              <Drawer.Header>
                <Drawer.Title>Drawer Title</Drawer.Title>
              </Drawer.Header>
              <Drawer.Body>
                <div>
                  <Row>
                    <Col xs={12}>
                      <div id="dr-call"></div>
                    </Col>
                  </Row>
                </div>
              </Drawer.Body>
              <Drawer.Footer>
                <Button onClick={this.close} appearance="primary">
                  Confirm
                </Button>
                <Button onClick={this.endCall} appearance="subtle">
                  Cancel
                </Button>
              </Drawer.Footer>
            </Drawer>
            {/* video call modal ends here */}
            {/* prescription drawer */}
            <Drawer
              show={this.state.prescription}
              onHide={this.prsecriptionDrawerClose}
            >
              <Drawer.Header>
                <Drawer.Title>Send prescription</Drawer.Title>
              </Drawer.Header>
              <Drawer.Body>
                <form onSubmit={this.prescribe}>
                  <textarea
                    className="textArea border"
                    name="prescription"
                    placeholder="Enter Prescription details here"
                  ></textarea>
                  <br />
                  <textarea
                    className="textArea border"
                    name="medicalComments"
                    placeholder="Comments Regarding Patience Medical Status"
                  ></textarea>
                  <br />
                  {this.state.prescriptionLoader === true ? (
                    <Loader
                      type="TailSpin"
                      width={60}
                      height={60}
                      color="white"
                    />
                  ) : (
                    <button
                      className="buttons"
                      style={{
                        backgroundColor: "rgb(31, 43, 75)",
                        color: "white",
                      }}
                    >
                      Send
                    </button>
                  )}
                </form>

                <div className="mt-4">
                  <h5 className="mb-2">Previous Prescribed Medicine</h5>
                  {this.state.previousPrsecriptions.length < 1 ? (
                    <></>
                  ) : (
                    pres
                  )}{" "}
                </div>
              </Drawer.Body>
              <Drawer.Footer>
                <Button
                  onClick={this.prsecriptionDrawerClose}
                  appearance="subtle"
                >
                  Cancel
                </Button>
              </Drawer.Footer>
            </Drawer>
            <Drawer
              show={this.state.showPP}
              onHide={this.showProfilePatient}
              size="xs"
              className="responsive-drawer"
              placement="left"
            >
              <Drawer.Header>
                <Drawer.Title></Drawer.Title>
              </Drawer.Header>
              <Drawer.Body>
                <div className="doc-details">
                  <div
                    id="patientProfileHeader"
                    className="bc2s"
                    style={{
                      height: 60,
                      marginBottom: 30,
                      paddingTop: 15,
                      textAlign: "center",
                    }}
                  >
                    <span
                      className="h4 font-weight-bold"
                      style={{ color: "white" }}
                    >
                      {translate("patientProfile")}
                    </span>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginBottom: 40,
                    }}
                  >
                    <img
                      className="profile-pic"
                      src={
                        this.state.patientProfile &&
                        this.state.patientProfile.picture
                          ? this.state.patientProfile.picture
                          : ""
                      }
                      alt="patient"
                      style={{ width: 112, height: 112 }}
                    />
                    <br />
                    <Button
                      className="btn bc-green2 mt-2"
                      onClick={() => {
                        this.setState({ showModal: true });
                      }}
                    >
                      Refer Patient
                    </Button>
                    <br />
                    <div className="userheader2">
                      {this.state.assignedPatient !== null ? (
                        <p>
                          {captializeFirstLetter(
                            this.state.assignedPatient.firstname
                          ) +
                            " " +
                            captializeFirstLetter(
                              this.state.assignedPatient.lastname
                            )}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="userDetail ml-5 fz14 mt-4">
                    <div className="mt-2">
                      {translate("dob")} :{" "}
                      <b>
                        {this.state.patientProfile &&
                        this.state.patientProfile.date_of_birth
                          ? this.state.patientProfile.date_of_birth
                          : "N/A"}
                      </b>
                    </div>
                    <div className="mt-2">
                      {translate("bloodGroup")}:
                      <b>
                        {this.state.patientProfile &&
                        this.state.patientProfile.blood_group
                          ? this.state.patientProfile.blood_group
                          : "N/A"}
                      </b>
                    </div>
                    <div className="mt-2">
                      {translate("genotype")} :
                      <b>
                        {this.state.patientProfile &&
                        this.state.patientProfile.genotype
                          ? this.state.patientProfile.genotype
                          : "N/A"}
                      </b>
                    </div>
                    <div className="mt-2">
                      {translate("anitbodies")}:
                      <b>
                        {this.state.patientProfile &&
                        this.state.patientProfile.antibodies
                          ? this.state.patientProfile.antibodies
                          : "N/A"}
                      </b>
                    </div>
                    <div className="mt-2">
                      {translate("currentIllnes")}:
                      <b>
                        {this.state.patientProfile &&
                        this.state.patientProfile.illness
                          ? this.state.patientProfile.illness
                          : "N/A"}
                      </b>
                    </div>
                    <div className="mt-2">
                      {translate("anyRespiratory")} :
                      <b>
                        {this.state.patientProfile &&
                        this.state.patientProfile.respiratory
                          ? captializeFirstLetter(
                              this.state.patientProfile.respiratory
                            )
                          : "N/A"}
                      </b>
                    </div>
                    <div className="mt-2">
                      {translate("diabetic")}:
                      <b>
                        {this.state.patientProfile &&
                        this.state.patientProfile.diabetes
                          ? captializeFirstLetter(
                              this.state.patientProfile.diabetes
                            )
                          : "N/A"}
                      </b>
                    </div>
                    <div className="mt-2">
                      {translate("familyMedical")}:
                      <b>
                        {this.state.patientProfile &&
                        this.state.patientProfile.family_medical_history
                          ? this.state.patientProfile.family_medical_history
                          : "N/A"}
                      </b>
                    </div>
                  </div>
                </div>
              </Drawer.Body>
              <Drawer.Footer></Drawer.Footer>
            </Drawer>

            <Drawer
              show={this.state.showMP}
              onHide={this.showMedicalPatient}
              size="xs"
              className="responsive-drawer"
              placement="left"
            >
              <Drawer.Header>
                <Drawer.Title></Drawer.Title>
              </Drawer.Header>
              <Drawer.Body>
                <div className="side-bar-chat">
                  <div className="doc-details">
                    <div
                      id="patientProfileHeader"
                      style={{
                        backgroundColor: "#EBEBEB",
                        height: 60,
                        marginBottom: 30,
                        paddingTop: 15,
                        textAlign: "center",
                      }}
                    >
                      <span
                        className="h4 font-weight-bold"
                        style={{ color: "black" }}
                      >
                        1{translate("vitalStatistics")}
                      </span>
                    </div>
                    {/* <hr /> */}
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        marginBottom: 40,
                      }}
                    >
                      <img
                        className="profile-pic"
                        src={
                          this.state.patientProfile &&
                          this.state.patientProfile.picture
                            ? this.state.patientProfile.picture
                            : ""
                        }
                        alt="patient"
                        style={{ width: 112, height: 112 }}
                      />
                    </div>
                    <div>
                      <Tabs
                        id="controlled-tab-example"
                        activeKey={this.state.key}
                        onSelect={(k) => this.setState({ key: k })}
                        transition={false}
                      >
                        <Tab eventKey="home" title="Vital Statistics2">
                          <div
                            style={{
                              paddingLeft: 5,
                              paddingRight: 5,
                              overflowY: "auto",
                            }}
                          >
                            <div style={{ marginBottom: 5 }}>
                              {this.state.assignedPatient !== null ? (
                                <div
                                  className="p-3 mt-2 shadow-sm rounded"
                                  style={{
                                    flexDirection: "row",
                                    backgroundColor: "#f2f3f8",
                                    padding: 10,
                                    fontSize: 15,
                                    borderRadius: 3,
                                  }}
                                >
                                  <span style={{ marginRight: 5 }}>
                                    {translate("bpm")}:
                                  </span>
                                  <span>
                                    {this.state.patientProfile &&
                                    this.state.patientProfile
                                      .blood_pressure_measurement
                                      ? this.state.patientProfile
                                          .blood_pressure_measurement
                                      : "N/A"}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                            <div style={{ marginBottom: 5 }}>
                              {this.state.assignedPatient !== null ? (
                                <div
                                  className="p-3 mt-2 shadow-sm rounded"
                                  style={{
                                    flexDirection: "row",
                                    backgroundColor: "#f2f3f8",
                                    padding: 10,
                                    fontSize: 15,
                                    borderRadius: 3,
                                  }}
                                >
                                  <span style={{ marginRight: 5 }}>
                                    {translate("bsl")}:
                                  </span>
                                  <span>
                                    {this.state.patientProfile &&
                                    this.state.patientProfile.blood_sugar_level
                                      ? this.state.patientProfile
                                          .blood_sugar_level
                                      : "N/A"}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                            <div style={{ marginBottom: 5 }}>
                              {this.state.assignedPatient !== null ? (
                                <div
                                  className="p-3 mt-2 shadow-sm rounded"
                                  style={{
                                    flexDirection: "row",
                                    backgroundColor: "#f2f3f8",
                                    padding: 10,
                                    fontSize: 15,
                                    borderRadius: 3,
                                  }}
                                >
                                  <span style={{ marginRight: 5 }}>
                                    {translate("ct")}:
                                  </span>
                                  <span>
                                    {this.state.patientProfile &&
                                    this.state.patientProfile
                                      .current_body_temperature
                                      ? this.state.patientProfile
                                          .current_body_temperature
                                      : "N/A"}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                            <div style={{ marginBottom: 5 }}>
                              {this.state.assignedPatient !== null ? (
                                <div
                                  className="p-3 mt-2 shadow-sm rounded"
                                  style={{
                                    flexDirection: "row",
                                    backgroundColor: "#f2f3f8",
                                    padding: 10,
                                    fontSize: 15,
                                    borderRadius: 3,
                                  }}
                                >
                                  <span style={{ marginRight: 5 }}>
                                    {translate("cc")}:
                                  </span>
                                  <span>
                                    {this.state.patientProfile &&
                                    this.state.patientProfile.cough
                                      ? this.state.patientProfile.cough
                                      : "N/A"}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                            <div style={{ marginBottom: 5 }}>
                              {this.state.assignedPatient !== null ? (
                                <div
                                  className="p-3 mt-2 shadow-sm rounded"
                                  style={{
                                    flexDirection: "row",
                                    backgroundColor: "#f2f3f8",
                                    padding: 10,
                                    fontSize: 15,
                                    borderRadius: 3,
                                  }}
                                >
                                  <span style={{ marginRight: 5 }}>
                                    {translate("los")}:
                                  </span>
                                  <span>
                                    {this.state.patientProfile &&
                                    this.state.patientProfile.loss_of_smell
                                      ? this.state.patientProfile.loss_of_smell
                                      : "N/A"}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                            <div style={{ marginBottom: 5 }}>
                              {this.state.assignedPatient !== null ? (
                                <div
                                  className="p-3 mt-2 shadow-sm rounded"
                                  style={{
                                    flexDirection: "row",
                                    backgroundColor: "#f2f3f8",
                                    padding: 10,
                                    fontSize: 15,
                                    borderRadius: 3,
                                  }}
                                >
                                  <span style={{ marginRight: 5 }}>
                                    {translate("aop")}:
                                  </span>
                                  <span>
                                    {this.state.patientProfile &&
                                    this.state.patientProfile
                                      .area_of_pain_in_body
                                      ? this.state.patientProfile
                                          .area_of_pain_in_body
                                      : "N/A"}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                            <div style={{ marginBottom: 5 }}>
                              {this.state.assignedPatient !== null ? (
                                <div
                                  className="p-3 mt-2 shadow-sm rounded"
                                  style={{
                                    flexDirection: "row",
                                    backgroundColor: "#f2f3f8",
                                    padding: 10,
                                    fontSize: 15,
                                    borderRadius: 3,
                                  }}
                                >
                                  <span style={{ marginRight: 5 }}>
                                    {translate("dizziness")}:
                                  </span>
                                  <span>
                                    {this.state.patientProfile &&
                                    this.state.patientProfile.dizziness
                                      ? this.state.patientProfile.dizziness
                                      : "N/A"}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                            <div style={{ marginBottom: 5 }}>
                              {this.state.assignedPatient !== null ? (
                                <div
                                  className="p-3 mt-2 shadow-sm rounded"
                                  style={{
                                    flexDirection: "row",
                                    backgroundColor: "#f2f3f8",
                                    padding: 10,
                                    fontSize: 15,
                                    borderRadius: 3,
                                  }}
                                >
                                  <span style={{ marginRight: 5 }}>
                                    {translate("lob")}:
                                  </span>
                                  <span>
                                    {this.state.patientProfile &&
                                    this.state.patientProfile.loss_of_balance
                                      ? this.state.patientProfile
                                          .loss_of_balance
                                      : "N/A"}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                            <div style={{ marginBottom: 5 }}>
                              {this.state.assignedPatient !== null ? (
                                <div
                                  className="p-3 mt-2 shadow-sm rounded"
                                  style={{
                                    flexDirection: "row",
                                    backgroundColor: "#f2f3f8",
                                    padding: 10,
                                    fontSize: 15,
                                    borderRadius: 3,
                                  }}
                                >
                                  <span style={{ marginRight: 5 }}>
                                    {translate("travel")}:
                                  </span>
                                  <span>
                                    {this.state.patientProfile &&
                                    this.state.patientProfile
                                      .travel_in_last_days
                                      ? this.state.patientProfile
                                          .travel_in_last_days
                                      : "N/A"}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                            <div style={{ marginBottom: 5 }}>
                              {this.state.assignedPatient !== null ? (
                                <div
                                  className="p-3 mt-2 shadow-sm rounded"
                                  style={{
                                    flexDirection: "row",
                                    backgroundColor: "#f2f3f8",
                                    padding: 10,
                                    fontSize: 15,
                                    borderRadius: 3,
                                  }}
                                >
                                  <span style={{ marginRight: 5 }}>
                                    {translate("meet")}:
                                  </span>
                                  <span>
                                    {this.state.patientProfile &&
                                    this.state.patientProfile.visitor_interacts
                                      ? this.state.patientProfile
                                          .visitor_interacts
                                      : "N/A"}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </Tab>
                        <Tab eventKey="profile" title="Make Prescription">
                          <>
                            <form className="p-2" onSubmit={this.prescribe}>
                              <textarea
                                className="textArea border shadow-sm"
                                name="prescription"
                                placeholder="Enter Prescription details here"
                                style={{ backgroundColor: "#f2f3f8" }}
                              ></textarea>
                              <br />
                              <textarea
                                className="textArea border shadow-sm"
                                name="medicalComments"
                                placeholder="Comments Regarding Patience Medical Status"
                                style={{ backgroundColor: "#f2f3f8" }}
                              ></textarea>
                              <br />
                              {this.state.prescriptionLoader === true ? (
                                <Loader
                                  type="TailSpin"
                                  width={60}
                                  height={60}
                                  color="white"
                                />
                              ) : (
                                <button
                                  className="btn darkblue-btn pr-4 pl-4 mt-3"
                                  style={{
                                    color: "white",
                                  }}
                                >
                                  Send
                                </button>
                              )}
                            </form>

                            <div className="mt-4 p-2">
                              <h5 className="mb-2">
                                Previous Prescribed Medicine
                              </h5>
                              {this.state.previousPrsecriptions.length < 1 ? (
                                <></>
                              ) : (
                                pres
                              )}{" "}
                            </div>
                          </>
                        </Tab>
                        <Tab eventKey="setting" title="Medical History">
                          <div className="p-2">
                            {this.state.previousPrsecriptions &&
                              this.state.previousPrsecriptions.length > 0 &&
                              this.generateMedicalHistory()}
                          </div>
                          {this.state.previousPrsecriptions &&
                            this.state.previousPrsecriptions.length === 0 && (
                              <div className="menu-items">
                                <strong>{translate("nomedicalhistory")}</strong>
                              </div>
                            )}
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                  <br />
                </div>
              </Drawer.Body>
              <Drawer.Footer></Drawer.Footer>
            </Drawer>

            {/* prsecription drawer ends here */}
            <div className="vertical-nav bg-white pt-4 float-left" id="sidebar">
              <ul className="nav flex-column bg-white mb-0">
                <li className="nav-item p-3 cursor" onClick={this.show}>
                  <Whisper placement="right" trigger="hover" speaker={tooltip}>
                    <img src={camera} alt="inbox" />
                  </Whisper>
                </li>
                <li className="nav-item p-3">
                  <img src={imageInbox} alt="inbox" />
                </li>
                <li className="nav-item p-3">
                  <FontAwesomeIcon
                    // onClick={(e) => this.setState({ isOpen: true })}
                    icon={faWallet}
                    style={{
                      color: "#061890",
                      cursor: "pointer",
                      fontSize: "40px",
                    }}
                    onClick={this.handleWalletTransactioDetails}
                  />
                </li>
                <li>
                  <div className="walletInfo">
                    <Whisper
                      placement="left"
                      trigger="hover"
                      speaker={tooltip2}
                    >
                      <span
                        className="btn btn-light btn-outline-secondary float-right"
                        onClick={(e) => this.setState({ isOpen: true })}
                      >
                        <FontAwesomeIcon icon={faWallet} />
                        {this.state.walletBalance}
                        <span style={{ fontSize: "12px" }}>(NGN)</span>
                      </span>
                    </Whisper>
                  </div>
                </li>
              </ul>
            </div>
            <div className="page-content">
              <Row noGutters={true}>
                <Col md={3}>
                  <div className="saq-font">
                    <FontAwesomeIcon
                      size="2x"
                      icon={faBars}
                      onClick={this.showMenu}
                    />
                  </div>
                  <div className="side-bar-chat saq-doc-chat1">
                    <div className="doc-details">
                      <div
                        id="patientProfileHeader"
                        className="bc2s"
                        style={{
                          height: 60,
                          marginBottom: 30,
                          paddingTop: 15,
                          textAlign: "center",
                        }}
                      >
                        <span
                          className="h4 font-weight-bold"
                          style={{ color: "white" }}
                        >
                          {translate("patientProfile")}
                        </span>
                      </div>

                      <div
                        style={{
                          width: "100%",
                          textAlign: "center",
                          marginBottom: 40,
                        }}
                      >
                        <img
                          className="profile-pic"
                          src={
                            this.state.patientProfile &&
                            this.state.patientProfile.picture
                              ? this.state.patientProfile.picture
                              : ""
                          }
                          alt="patient"
                          style={{ width: 112, height: 112 }}
                        />
                        <br />
                        <Button
                          className="btn bc-green2 mt-2"
                          style={{ color: "white" }}
                          onClick={() => {
                            this.setState({ showModal: true });
                          }}
                        >
                          Refer Patient
                        </Button>
                        <br />
                        <div className="userheader2">
                          {this.state.assignedPatient !== null ? (
                            <p>
                              {captializeFirstLetter(
                                this.state.assignedPatient.firstname
                              ) +
                                " " +
                                captializeFirstLetter(
                                  this.state.assignedPatient.lastname
                                )}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="userDetail ml-5 fz14 mt-4">
                        <div className="mt-2">
                          {translate("dob")} :{" "}
                          <b>
                            {this.state.patientProfile &&
                            this.state.patientProfile.date_of_birth
                              ? this.state.patientProfile.date_of_birth
                              : "N/A"}
                          </b>
                        </div>
                        <div className="mt-2">
                          {translate("bloodGroup")}:
                          <b>
                            {this.state.patientProfile &&
                            this.state.patientProfile.blood_group
                              ? this.state.patientProfile.blood_group
                              : "N/A"}
                          </b>
                        </div>
                        <div className="mt-2">
                          {translate("genotype")} :
                          <b>
                            {this.state.patientProfile &&
                            this.state.patientProfile.genotype
                              ? this.state.patientProfile.genotype
                              : "N/A"}
                          </b>
                        </div>
                        <div className="mt-2">
                          {translate("anitbodies")}:
                          <b>
                            {this.state.patientProfile &&
                            this.state.patientProfile.antibodies
                              ? this.state.patientProfile.antibodies
                              : "N/A"}
                          </b>
                        </div>
                        <div className="mt-2">
                          {translate("currentIllnes")}:
                          <b>
                            {this.state.patientProfile &&
                            this.state.patientProfile.illness
                              ? this.state.patientProfile.illness
                              : "N/A"}
                          </b>
                        </div>
                        <div className="mt-2">
                          {translate("anyRespiratory")} :
                          <b>
                            {this.state.patientProfile &&
                            this.state.patientProfile.respiratory
                              ? captializeFirstLetter(
                                  this.state.patientProfile.respiratory
                                )
                              : "N/A"}
                          </b>
                        </div>
                        <div className="mt-2">
                          {translate("diabetic")}:
                          <b>
                            {this.state.patientProfile &&
                            this.state.patientProfile.diabetes
                              ? captializeFirstLetter(
                                  this.state.patientProfile.diabetes
                                )
                              : "N/A"}
                          </b>
                        </div>
                        <div className="mt-2">
                          {translate("familyMedical")}:
                          <b>
                            {this.state.patientProfile &&
                            this.state.patientProfile.family_medical_history
                              ? this.state.patientProfile.family_medical_history
                              : "N/A"}
                          </b>
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="chat-box">
                    <div className="chat-header position-relative">
                      <>
                        {this.state.ptyping === true ? (
                          <div style={{ display: "inline" }}>
                            <p
                              style={{
                                color: "#0000a0",
                                fontSize: "12px",
                                marginTop: "15px",
                                marginLeft: "5px",
                                fontStyle: "italic",
                              }}
                            >
                              {this.state.assignedPatient &&
                              this.state.assignedPatient.firstname
                                ? this.state.assignedPatient.firstname
                                : "Patient"}{" "}
                              is typing....
                            </p>
                          </div>
                        ) : null}
                      </>
                      <div className="chatheader">
                        <div className="row" style={{ paddingTop: "10px" }}>
                          <div className="col-2 text-center">
                            {/* <img
                              className="rounded img-thumbnail "
                              src={
                                (this.state.patientProfile &&
                                this.state.patientProfile.picture
                                  ? this.state.patientProfile.picture
                                  : "")
                              }
                              alt="patient"
                            /> */}
                          </div>
                          <div className="col-7 fz16">
                            <div>
                              {this.state.assignedPatient !== null ? (
                                <p>
                                  {captializeFirstLetter(
                                    this.state.assignedPatient.firstname
                                  ) +
                                    " " +
                                    captializeFirstLetter(
                                      this.state.assignedPatient.lastname
                                    )}
                                </p>
                              ) : null}
                            </div>
                            <div>Online</div>
                          </div>

                          <div className="col-3" style={{ paddingTop: "10px" }}>
                            <span>
                              <img Src={Phone} alt="" onClick={this.show} />
                            </span>
                            <span style={{ marginLeft: "25px" }}>
                              <img
                                Src={Video}
                                alt=""
                                speaker={tooltip}
                                onClick={this.show}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* <Row>
                        <Col xs={6}>
                          <h3 className="h2 ml-3 font-weight-bold mt-2">
                            {translate("medical Expert")} Inbox
                          </h3>
                        </Col>
                        <Col xs={2}>
                          <div className="walletInfo">
                            <Whisper
                              placement="left"
                              trigger="hover"
                              speaker={tooltip2}
                            >
                              <span
                                className="btn btn-light btn-outline-secondary float-right"
                                // onClick={() => {
                                //   console.log(
                                //     "withdraw window open for doctor"
                                //   );
                                // }}
                                onClick={(e) => this.setState({ isOpen: true })}
                              >
                                <FontAwesomeIcon icon={faWallet} />
                                {this.state.walletBalance}
                                <span style={{ fontSize: "12px" }}>(NGN)</span>
                              </span>
                            </Whisper>
                          </div>
                        </Col>
                        <Col xs={2}>
                          <Toggle
                            checkedChildren="Available"
                            unCheckedChildren="Unavailable"
                            style={{
                              float: "right",
                              marginTop: "13px",
                              color: "#061890",
                            }}
                            className="chat-status bg-white "
                            defaultChecked={this.state.available}
                            checked={this.state.available}
                            onChange={this.setStatus}
                          />
                        </Col>
                        <Col xs={2} className="pt-1">
                          <span
                            className="cursor badge badge-pill badge-primary bg-white dark-blue-border darkblue-color pl-4 pr-4 mt-2 pt-2 pb-2 d-block saq-call"
                            style={{
                              border: "1px solid",
                              width: "85px",
                              margin: "0 auto",
                            }}
                            onClick={this.show}
                          >
                            Start Call
                          </span>
                          <FontAwesomeIcon
                            icon={faEllipsisV}
                            className="menu"
                            onClick={this.showMenu}
                          />
                        </Col>
                      </Row> */}

                      {/* Dialog */}
                      <Dialog
                        isOpen={this.state.isOpen}
                        onClose={(e) => this.setState({ isOpen: false })}
                      >
                        <div className="row justify-content-center">
                          <div className="col-6">
                            <label className="form-label">Name</label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.name}
                              disabled="true"
                            ></input>
                            <label className="form-label mt-2">Email</label>
                            <input
                              type="email"
                              className="form-control"
                              disabled="true"
                              value={this.state.email}
                            ></input>
                            <label className="form-label mt-2">Phone No</label>
                            <input
                              type="number"
                              className="form-control"
                              disabled="true"
                              value={this.state.phone}
                            ></input>
                            <label className="form-label mt-2">Bank Name</label>
                            {/* <input
                              type="text"
                              className="form-control"
                              onChange={(e) => {
                                this.setState({
                                  branchNo: e.target.value,
                                });
                              }}
                              value={this.state.branchNo}
                            ></input> */}
                            <select
                              className="form-control"
                              onChange={(e) => {
                                this.setState(
                                  { bankName: e.target.value },
                                  () => {
                                    // this.resetDropdown();
                                    this.callBankCode();
                                  }
                                );
                              }}
                              value={this.state.bankName}
                            >
                              <option selected></option>
                              {this.state.bankList.map((x, y) => (
                                <option key={y} value={x.code}>
                                  {x.name}
                                </option>
                              ))}
                            </select>

                            <label className="form-label mt-2">
                              Branch Name
                            </label>

                            <select
                              className="form-control"
                              onChange={(e) => {
                                this.setState({ branchNo: e.target.value });
                              }}
                              value={this.state.branchNo}
                            >
                              <option selected></option>
                              {this.state.branchList.map((x, y) => (
                                <option key={y} value={x.branch_code}>
                                  {x.branch_name}
                                </option>
                              ))}
                            </select>
                            <label className="form-label mt-2">
                              Account No
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => {
                                this.setState({
                                  accountNo: e.target.value,
                                });
                              }}
                              value={this.state.accountNo}
                            ></input>
                            <label className="form-label mt-2">Ammount</label>
                            <input
                              type="number"
                              className="form-control"
                              disabled="true"
                              value={this.state.walletBalance}
                            ></input>

                            {this.state.loder === false && (
                              <button
                                type="button"
                                class="btn btn-primary mt-2"
                                onClick={this.sendMoney}
                              >
                                Send
                              </button>
                            )}
                            {this.state.loder === true && (
                              <button
                                className="btn btn-success mt-2"
                                type="button"
                                disabled
                                // style={{ width: "20%" }}
                              >
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                Sending...
                              </button>
                            )}
                          </div>
                        </div>
                      </Dialog>
                      {/* Dialog end */}
                    </div>
                    <div
                      className="chat"
                      style={{ height: "88.5%" }}
                      id="doctor-chat"
                    >
                      {this.state.messages.length < 1 ? <NoChat /> : msg}{" "}
                    </div>
                    <form
                      onSubmit={this.sendMessage}
                      acceptCharset="utf-8"
                      style={{ backgroundColor: "#fff", padding: 17 }}
                    >
                      <div
                        className="chat-textbox p-0 responsive-chatbox"
                        style={{ flexDirection: "row", borderRadius: 3 }}
                      >
                        <div className="text-div p-2">
                          <div className="container saq-vital"></div>

                          <input
                            type="text"
                            style={{
                              resize: "none",
                              minHeight: "45px",

                              width: "90%",

                              borderRadius: "2px",
                              border: "none",
                              padding: "7px",
                            }}
                            onKeyPress={this.typing}
                            onKeyUp={this.notTyping}
                            placeholder="Type a message"
                            id="message"
                            name="message"
                          />
                          <button className="bg-white" style={{ width: "10%" }}>
                            <img src={sendMessage} alt="send" />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </Col>
                <Col md={3}>
                  {/* Right Tabs */}
                  <div className="side-bar-chat saq-doc-chat2">
                    <div className="doc-details">
                      <div
                        id="patientProfileHeader"
                        className="bc2s"
                        style={{
                          height: 60,
                          marginBottom: 30,
                          paddingTop: 15,
                          textAlign: "center",
                        }}
                      >
                        <span
                          className="h4 font-weight-bold"
                          style={{ color: "white" }}
                        >
                          {translate("vitalStatistics")}
                        </span>
                      </div>

                      {/* <hr /> */}
                      <div
                        style={{
                          width: "100%",
                          textAlign: "center",
                          marginBottom: 40,
                        }}
                      >
                        <img
                          className="profile-pic"
                          src={
                            this.state.patientProfile &&
                            this.state.patientProfile.picture
                              ? this.state.patientProfile.picture
                              : ""
                          }
                          alt="patient"
                          style={{ width: 112, height: 112 }}
                        />
                      </div>
                      <div>
                        <Tabs
                          id="controlled-tab-example"
                          activeKey={this.state.key}
                          onSelect={(k) => this.setState({ key: k })}
                          transition={false}
                        >
                          <Tab eventKey="home" title="Vital Statistics1">
                            <div
                              style={{
                                paddingLeft: 5,
                                paddingRight: 5,
                                overflowY: "auto",
                              }}
                            >
                              <div style={{ marginBottom: 5 }}>
                                {this.state.assignedPatient !== null ? (
                                  <div
                                    className="p-3 mt-2 shadow-sm rounded"
                                    style={{
                                      flexDirection: "row",
                                      backgroundColor: "#f2f3f8",
                                      padding: 10,
                                      fontSize: 15,
                                      borderRadius: 3,
                                    }}
                                  >
                                    <span style={{ marginRight: 5 }}>
                                      {translate("bpm")}:
                                    </span>
                                    <span>
                                      {this.state.patientProfile &&
                                      this.state.patientProfile
                                        .blood_pressure_measurement
                                        ? this.state.patientProfile
                                            .blood_pressure_measurement
                                        : "N/A"}
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                              <div style={{ marginBottom: 5 }}>
                                {this.state.assignedPatient !== null ? (
                                  <div
                                    className="p-3 mt-2 shadow-sm rounded"
                                    style={{
                                      flexDirection: "row",
                                      backgroundColor: "#f2f3f8",
                                      padding: 10,
                                      fontSize: 15,
                                      borderRadius: 3,
                                    }}
                                  >
                                    <span style={{ marginRight: 5 }}>
                                      {translate("bsl")}:
                                    </span>
                                    <span>
                                      {this.state.patientProfile &&
                                      this.state.patientProfile
                                        .blood_sugar_level
                                        ? this.state.patientProfile
                                            .blood_sugar_level
                                        : "N/A"}
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                              <div style={{ marginBottom: 5 }}>
                                {this.state.assignedPatient !== null ? (
                                  <div
                                    className="p-3 mt-2 shadow-sm rounded"
                                    style={{
                                      flexDirection: "row",
                                      backgroundColor: "#f2f3f8",
                                      padding: 10,
                                      fontSize: 15,
                                      borderRadius: 3,
                                    }}
                                  >
                                    <span style={{ marginRight: 5 }}>
                                      {translate("ct")}:
                                    </span>
                                    <span>
                                      {this.state.patientProfile &&
                                      this.state.patientProfile
                                        .current_body_temperature
                                        ? this.state.patientProfile
                                            .current_body_temperature
                                        : "N/A"}
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                              <div style={{ marginBottom: 5 }}>
                                {this.state.assignedPatient !== null ? (
                                  <div
                                    className="p-3 mt-2 shadow-sm rounded"
                                    style={{
                                      flexDirection: "row",
                                      backgroundColor: "#f2f3f8",
                                      padding: 10,
                                      fontSize: 15,
                                      borderRadius: 3,
                                    }}
                                  >
                                    <span style={{ marginRight: 5 }}>
                                      {translate("cc")}:
                                    </span>
                                    <span>
                                      {this.state.patientProfile &&
                                      this.state.patientProfile.cough
                                        ? this.state.patientProfile.cough
                                        : "N/A"}
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                              <div style={{ marginBottom: 5 }}>
                                {this.state.assignedPatient !== null ? (
                                  <div
                                    className="p-3 mt-2 shadow-sm rounded"
                                    style={{
                                      flexDirection: "row",
                                      backgroundColor: "#f2f3f8",
                                      padding: 10,
                                      fontSize: 15,
                                      borderRadius: 3,
                                    }}
                                  >
                                    <span style={{ marginRight: 5 }}>
                                      {translate("los")}:
                                    </span>
                                    <span>
                                      {this.state.patientProfile &&
                                      this.state.patientProfile.loss_of_smell
                                        ? this.state.patientProfile
                                            .loss_of_smell
                                        : "N/A"}
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                              <div style={{ marginBottom: 5 }}>
                                {this.state.assignedPatient !== null ? (
                                  <div
                                    className="p-3 mt-2 shadow-sm rounded"
                                    style={{
                                      flexDirection: "row",
                                      backgroundColor: "#f2f3f8",
                                      padding: 10,
                                      fontSize: 15,
                                      borderRadius: 3,
                                    }}
                                  >
                                    <span style={{ marginRight: 5 }}>
                                      {translate("aop")}:
                                    </span>
                                    <span>
                                      {this.state.patientProfile &&
                                      this.state.patientProfile
                                        .area_of_pain_in_body
                                        ? this.state.patientProfile
                                            .area_of_pain_in_body
                                        : "N/A"}
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                              <div style={{ marginBottom: 5 }}>
                                {this.state.assignedPatient !== null ? (
                                  <div
                                    className="p-3 mt-2 shadow-sm rounded"
                                    style={{
                                      flexDirection: "row",
                                      backgroundColor: "#f2f3f8",
                                      padding: 10,
                                      fontSize: 15,
                                      borderRadius: 3,
                                    }}
                                  >
                                    <span style={{ marginRight: 5 }}>
                                      {translate("dizziness")}:
                                    </span>
                                    <span>
                                      {this.state.patientProfile &&
                                      this.state.patientProfile.dizziness
                                        ? this.state.patientProfile.dizziness
                                        : "N/A"}
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                              <div style={{ marginBottom: 5 }}>
                                {this.state.assignedPatient !== null ? (
                                  <div
                                    className="p-3 mt-2 shadow-sm rounded"
                                    style={{
                                      flexDirection: "row",
                                      backgroundColor: "#f2f3f8",
                                      padding: 10,
                                      fontSize: 15,
                                      borderRadius: 3,
                                    }}
                                  >
                                    <span style={{ marginRight: 5 }}>
                                      {translate("lob")}:
                                    </span>
                                    <span>
                                      {this.state.patientProfile &&
                                      this.state.patientProfile.loss_of_balance
                                        ? this.state.patientProfile
                                            .loss_of_balance
                                        : "N/A"}
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                              <div style={{ marginBottom: 5 }}>
                                {this.state.assignedPatient !== null ? (
                                  <div
                                    className="p-3 mt-2 shadow-sm rounded"
                                    style={{
                                      flexDirection: "row",
                                      backgroundColor: "#f2f3f8",
                                      padding: 10,
                                      fontSize: 15,
                                      borderRadius: 3,
                                    }}
                                  >
                                    <span style={{ marginRight: 5 }}>
                                      {translate("travel")}:
                                    </span>
                                    <span>
                                      {this.state.patientProfile &&
                                      this.state.patientProfile
                                        .travel_in_last_days
                                        ? this.state.patientProfile
                                            .travel_in_last_days
                                        : "N/A"}
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                              <div style={{ marginBottom: 5 }}>
                                {this.state.assignedPatient !== null ? (
                                  <div
                                    className="p-3 mt-2 shadow-sm rounded"
                                    style={{
                                      flexDirection: "row",
                                      backgroundColor: "#f2f3f8",
                                      padding: 10,
                                      fontSize: 15,
                                      borderRadius: 3,
                                    }}
                                  >
                                    <span style={{ marginRight: 5 }}>
                                      {translate("meet")}:
                                    </span>
                                    <span>
                                      {this.state.patientProfile &&
                                      this.state.patientProfile
                                        .visitor_interacts
                                        ? this.state.patientProfile
                                            .visitor_interacts
                                        : "N/A"}
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </Tab>
                          <Tab eventKey="profile" title="Make Prescription">
                            <>
                              <form className="p-2" onSubmit={this.prescribe}>
                                <textarea
                                  className="textArea border shadow-sm"
                                  name="prescription"
                                  placeholder="Enter Prescription details here"
                                  style={{ backgroundColor: "#f2f3f8" }}
                                ></textarea>
                                <br />
                                <textarea
                                  className="textArea border shadow-sm"
                                  name="medicalComments"
                                  placeholder="Comments Regarding Patience Medical Status"
                                  style={{ backgroundColor: "#f2f3f8" }}
                                ></textarea>
                                <br />
                                {this.state.prescriptionLoader === true ? (
                                  <Loader
                                    type="TailSpin"
                                    width={60}
                                    height={60}
                                    color="white"
                                  />
                                ) : (
                                  <button
                                    className="btn bc-green2 pr-4 pl-4 mt-3"
                                    style={{
                                      color: "black",
                                    }}
                                  >
                                    Send
                                  </button>
                                )}
                              </form>

                              <div className="mt-4 p-2">
                                <h5 className="mb-2">
                                  Previous Prescribed Medicine
                                </h5>
                                {this.state.previousPrsecriptions.length < 1 ? (
                                  <></>
                                ) : (
                                  pres
                                )}{" "}
                              </div>
                            </>
                          </Tab>
                          <Tab eventKey="setting" title="Medical History">
                            <div className="p-2">
                              {this.state.previousPrsecriptions &&
                                this.state.previousPrsecriptions.length > 0 &&
                                this.generateMedicalHistory()}
                            </div>
                            {this.state.previousPrsecriptions &&
                              this.state.previousPrsecriptions.length === 0 && (
                                <div className="menu-items">
                                  <strong>
                                    {translate("nomedicalhistory")}
                                  </strong>
                                </div>
                              )}
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                    <br />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Container>
    );
  }
}
