import { LOCALES } from "../locales";

export default {
  [LOCALES.YORUBA]: {
    hello: "Pẹlẹ o",
    welcome: "Kaabo Lati Wave Telemedicine App",
    platformInfo1: `Syeed ti o gba alaisan laaye lati sopọ si Awọn dokita to dara julọ ni aaye Iṣoogun ni itunu ti ile rẹ. O le sanwo lori lilọ tabi ṣeto iṣeduro Iṣoogun pẹlu awọn HMO ti alabaṣepọ pẹlu wa.
        Ohunkohun ti awọn ifiyesi ilera rẹ wa ni a ti bo ọ.
       `,
    platformInfo2: `
        Nipasẹ apejọ fidio wa o le wa ni kikun si ati iwe-aṣẹ ti a firanṣẹ si ile elegbogi kan ni agbegbe rẹ.
        `,
    platformInfo3: `
        Ipo ti Covid-19 ti ni ipa lori ọna ti a ṣe n ṣe alabapin eyiti o ti kan aladani Iṣoogun.`,
    patient: `Alaisan`,
    "medical Expert": "Amoye Iṣoogun",
    "sign In": "WỌLE",
    register: "Forukọsilẹ",
    rural: "Igberiko",
    login: "Wo ile",
    "sign Up": "Forukọsilẹ",
    urban: "Ilu",
    Profile: "Profaili",
    "date of birth": "Ojo ibi",
    genotype: "Genotype",
    "blood Group": "Ẹgbẹ Ẹjẹ",
    picture: "Aworan",
    submit: "Firanṣẹ",
    "rural users": "AWON OLUGBO RUGBO",
    user: "Olumulo",
    registration: "Iforukọsilẹ",
    "already have an account?": "Ṣe o ti ni akọọlẹ kan?",
    select: "Yan",
    country: "Orilẹ-ede",
    nigeria: "Nigeria",
    "united Kingdom": "United Kingdom",
    usa: "USA",
    "welcome to wave":
      "Kaabo si WaveTelemedicine. Syeed ti o fun ọ laaye lati pade pẹlu dokita kan ni itunu ti ile rẹ. Jọwọ pari fọọmu yii ni isalẹ. Alaye naa gba dokita laaye lati ni oye ipo rẹ daradara",
    areUMedication: "Ṣe o wa lori oogun?",
    anyIllness: "Eyikeyi aisan lọwọlọwọ?",
    doUSuffer: "Ṣe o jiya lati Diabetes?",
    anyRespiratiory: "Eyikeyi Aisan ti o ni ibatan Respiratiory?",
    no: "Rara",
    yes: "Bẹẹni",
    nodoctor:
      "Ko si dokita ti o wa lati orilẹ-ede rẹ ni akoko yii. Ṣe iwọ yoo fẹ lati sopọ mọ dokita kan lati orilẹ-ede miiran?",
    startconversation: "O le bẹrẹ ibaraẹnisọrọ pẹlu",
    fetchingdoctors: "Wiwa awọn dokita wa ni orilẹ ede rẹ",
    connectedDoctor: "Dokita ti o ni asopọ",
    yourconnected: "O ti sopọ mọ Dr.",
    typeMessage: "Tẹ ifiranṣẹ kan",
    patientName: "Oruko Alaisan",
    dob: "Ojo ibi",
    bloodGroup: "Ẹgbẹ Ẹjẹ",
    genotype: "Genotype",
    anitbodies: "Awọn egboogi",
    currentIllnes: "Arun Lọwọlọwọ",
    anyRespiratory: "Atẹgun eyikeyi",
    diabetic: "Àtọgbẹ",
    familyMedical: "Itan Egbogi Ebi",
    bpm: "Iwọn wiwọn ẹjẹ",
    bsl: "Ipele suga ẹjẹ",
    ct: "Iwọn otutu lọwọlọwọ",
    cc: "Ikọaláìdúró Dédé",
    los: "Isonu Smrùn",
    aop: "Awọn irora tabi Awọn irora?",
    dizziness: "Dizziness",
    lob: "Isonu ti iwontunwonsi",
    travel: "Njẹ o ti jade lati orilẹ-ede ni awọn ọjọ 7 sẹhin",
    meet: "Ibasepo eyikeyi pẹlu ẹnikẹni ti o ti pada lati oke-okun ni awọn ọjọ 7 to kẹhin?",
    medical: "Itan Iṣoogun",
    nomedicalhistory: "Ko si Imọ Itan Iṣoogund",
    connectedPatient: "Alaisan ti o ni asopọ",
    viewpatientprofile: "Wo Profaili Alaisan",
    makePrescription: "Ṣe Ilana",
    "Hello Doc": "Hello Doc",
    "I have the Flu": "Mo ni arun ajakalẹ",
    "I have Sore throat": "Mo ni Ọfun ọgbẹ",
    "I have Stomach Cramps": "Mo ni Ìyọnu Ìyọnu",
    "I feel Dizzy": "Oyi nko mi loju",
    "I feel unwell": "Ara mi ko dara",
    "I have a Headache": "Ori nfo mi",
    "I am feeling Feverish": "Mo n rilara iba",
  },
};
