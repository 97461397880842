import React from "react";
import Delete from "../../assets/admin/delete.png";
import More from "../../assets/admin/more.png";

const Table = () => {
  const data = [
    {
      name: "ss",
      email: "ss@gmail.com",
      regNo: "30320",
      status: 1,
      platform: "Mobilemed",
    },
    {
      name: "BB",
      email: "bb@gmail.com",
      regNo: "30320",
      status: 0,
      platform: "Mobilemed",
    },
    {
      name: "CC",
      email: "cc@gmail.com",
      regNo: "30320",
      status: 1,
      platform: "Mobilemed",
    },
  ];
  return (
    <div className="row page-size mt-3">
      <div className="col-12">
        <table class="table">
          <thead className="boder-col">
            <tr>
              <th>
                <input className="all-check" type="checkbox"></input>
              </th>
              <th scope="col" className="">
                S/N
              </th>
              <th scope="col" className="">
                Name
              </th>

              <th scope="col" className="">
                Email
              </th>
              <th scope="col" className="">
                Registration No
              </th>
              <th scope="col" className="">
                Status
              </th>

              <th scope="col" className="">
                Platform
              </th>
              <th scope="col" className="">
                Action
              </th>
            </tr>
          </thead>
          {data.map((data, idx) => (
            <tbody key={idx}>
              <tr>
                <td>
                  <input className="check-box" type="checkbox"></input>
                </td>
                <td className="">{idx + 1}</td>
                <td className="">{data.name}</td>
                <td className="">{data.email}</td>
                <td className="">{data.regNo}</td>

                {data.status === 1 && (
                  <>
                    <td>
                      <div className="active-bt">.Activate</div>
                    </td>
                  </>
                )}
                {data.status === 0 && (
                  <>
                    <td>
                      <div className="inactive-bt">.Inactivate</div>
                    </td>
                  </>
                )}
                <td className="">{data.platform}</td>
                <td>
                  <span>
                    <img src={Delete} alt="Delete" className="cur"></img>
                  </span>
                  <span>
                    <img
                      src={More}
                      alt="More"
                      className="cur"
                      style={{ marginLeft: "5px" }}
                    ></img>
                  </span>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </div>
    </div>
  );
};

export default Table;
