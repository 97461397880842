import React from "react"
import { Container,Row,Col } from "react-bootstrap"
import { SelectPicker,DatePicker,Uploader,Alert } from 'rsuite';
import { postDataAuth } from './../services/request';
import Loader from "react-loader-spinner";
import translate from "../i18n/translation";
export default class Profile extends React.Component{
constructor(props){
    super(props)
    this.state = {
            genotype:"",
            blood:"",
            date:"",
            pic:"",
            loader:false
    }
}
    fileChange = (value) =>{
        this.setState({pic:value},()=>{
            if(value.length > 1){
                Alert.error("You can't upload more than a single picture")
                this.state.pic.pop()
                this.setState({pic:value})
            }
        })
  
    }
    submit = (e) =>{
        e.preventDefault()
       var token = localStorage.getItem("med_token")
           var details = {
               genotype:this.state.genotype,
               blood:this.state.blood,
               dates:this.state.date,
               pic:this.state.pic
           }
// console.log(details.pic[0])
           if(details.dates === "" || details.dates === null ){
            Alert.warning("Input your date of birth")
           }else if(details.genotype === "" || details.genotype === null){
            Alert.warning("Select your genotype")
           }else if(details.blood.trim() === "" || details.blood === null){
            Alert.warning("Select your blood group")
           }else if(details.pic === "" || details.pic === null){
            Alert.warning("select a picture for your profile")
           }else{
         var date =  details.dates.getDay()+"-"+details.dates.getMonth()+"-"+details.dates.getFullYear()
         var formData = new FormData();
         formData.append("date_of_birth", date);
         formData.append("genotype", details.genotype);
         formData.append("blood_group", details.blood);
            var file = details.pic[0].blobFile
                formData.append("profile_pic",file)
this.setState({loader:true})
         postDataAuth("upload",formData,token).then((response)=>{
            this.setState({loader:false})
            if(response.status === true){
                Alert.success(response.message)
                this.props.questions()
            }else{
                Alert.error(response.message)
            }
        }).catch((reponse)=>{
            this.setState({loader:false})
            Alert.error("Error updating details, please try again")
        })
           }

       
    }
    render(){
        const styles = {
            width: 150,
            height: 150
          };
        return(
            <Container className="container-fluid" fluid>
                    <Row>
                        <Col md="2">
                        </Col>
                    <Col md="8">
                    <form onSubmit={this.submit} className="signup-form-q">
<h3>{translate('profile')}</h3>
<label>{translate('date of birth')}</label>
<DatePicker style={{ width: "100%" }} onChange={(date)=>{
    this.setState({date:date})
}} /><br/><br/>
<label>{translate('genotype')}</label>
<SelectPicker onSelect={(value)=>{
    this.setState({genotype:value})
}} data={[{"label":"AA",value:"AA"},{"label":"AB",value:"AB"},{"label":"AC",value:"AC"},{"label":"BB",value:"BB"},{"label":"BC",value:"BC"},{"label":"CC",value:"CC"}]} style={{ width: "100%" }} /><br/>
<br/>
<label>{translate('blood Group')}</label>
<SelectPicker onSelect={(value)=>{
    this.setState({blood:value})
}} data={[{"label":"O+",value:"O+"},{"label":"O-",value:"O-"},{"label":"A+",value:"A+"},{"label":"A-",value:"A-"},{"label":"B+",value:"B+"},{"label":"B-",value:"B-"},{"label":"AB+",value:"AB+"},{"label":"AB",value:"AB"}]} style={{ width: "100%" }} />
<br/><br/>
<label>{translate('profile')} {translate('picture')}</label>
<Uploader
          autoUpload={false}
          onChange={this.fileChange}
          ref={ref => {
            this.uploader = ref;
          }}
        />
<br/>
{this.state.loader === true ?<Loader type="TailSpin" width={60} height={60} color="#2c6cb0"/> : <button className="buttons" style={{backgroundColor:"rgb(46, 59, 95)",color:"white"}}>{translate('submit')}</button> }  
       
</form>
</Col>
<Col md="2">
                        </Col>
                    </Row>
            </Container>
        )
    }
}