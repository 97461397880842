import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faWallet } from "@fortawesome/free-solid-svg-icons";

export default class LeftBar extends React.Component {
  constructor(props) {
    super(props);
    const activeNav = sessionStorage.getItem("active-nav");
    this.state = {
      activeLink: activeNav ? activeNav : "userpanel",
    };
  }

  _HandleClick = (value) => {
    sessionStorage.setItem("active-nav", value);
    this.setState({
      activeLink: value,
    });
  };
  render() {
    return (
      <div className="container-fluid bar-border">
        <div
          className={`row hov ${
            this.state.activeLink === "userpanel" ? "act-link" : ""
          }`}
          onClick={() => {
            this._HandleClick("userpanel");
            this.props.history.push("/userpanel");
          }}
        >
          <span>
            <FontAwesomeIcon
              icon={faWallet}
              style={{
                color: "#1c2579",
                fontSize: "28px",
                marginLeft: "30px",
              }}
            />
          </span>
          <span className="nav-text">Transection</span>
        </div>
        <div
          className={`row hov ${
            this.state.activeLink === "allbeneficiary" ? "act-link" : ""
          }`}
          onClick={() => {
            this._HandleClick("allbeneficiary");
            this.props.history.push("/allbeneficiary");
          }}
        >
          <span>
            <FontAwesomeIcon
              icon={faUserCircle}
              style={{
                color: "#1c2579",
                fontSize: "28px",
                marginLeft: "30px",
              }}
            />
          </span>
          <span className="nav-text">All Beneficiary</span>
        </div>
        <div
          className={`row hov ${
            this.state.activeLink === "addbeneficiary" ? "act-link" : ""
          }`}
          onClick={() => {
            this._HandleClick("addbeneficiary");
            this.props.history.push("/addbeneficiary");
          }}
        >
          <span>
            <FontAwesomeIcon
              icon={faUserCircle}
              style={{
                color: "#1c2579",
                fontSize: "28px",
                marginLeft: "30px",
              }}
            />
          </span>
          <span className="nav-text">Add Beneficiary</span>
        </div>
      </div>
    );
  }
}
