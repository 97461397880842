import { LOCALES } from "../locales";

export default {
  [LOCALES.HAUSA]: {
    hello: "Barka dai",
    welcome: "Maraba da Wave Telemedicine App",
    platformInfo1: `Tsarin da ke ba mai haƙuri damar haɗawa da mafi kyawun Likitoci a cikin fannin Kiwon lafiya a cikin jin daɗin gidanka. Kuna iya biyan kuɗin tafiya ko saita inshorar lafiya tare da HMOs waɗanda ke tarayya da mu.
    Duk abin da ya shafi lafiyar ka mun sanar da kai.
    `,
    platformInfo2: `
    Ta hanyar taron mu na bidiyo za a iya halartar ku da kuma takardar sayan magani zuwa kantin magani a yankin ku.
    `,
    platformInfo3: `
    Halin da ake ciki na Covid-19 ya shafi yadda muke hulɗa wanda ya shafi ɓangaren Kiwan lafiya.`,
    patient: `Mai haƙuri`,
    "medical Expert": "Kwararren Likita",
    "sign In": "Shiga ciki",
    register: "Yi rijista",
    rural: "Karkara",
    login: "Shiga",
    "sign Up": "Yi Rajista",
    urban: "Birni",
    Profile: "Bayani",
    "date of birth": "Ranar haifuwa",
    genotype: "Genotype",
    "blood Group": "Groupungiyar Jini",
    picture: "Hoto",
    submit: "Sallama",
    "rural users": "MASU AMFANI DA KARI",
    user: "Mai amfani",
    registration: "Rijista",
    "already have an account?": "Dama kuna da asusu?",
    select: "Zaɓi",
    country: "Kasa",
    nigeria: "Najeriya",
    "united Kingdom": "Kingdomasar Ingila",
    usa: "Amurka",
    "welcome to wave":
      "Barka da zuwa WaveTelemedicine. Dandalin da zai baka damar haduwa da likita a cikin kwanciyar hankalin gidanka. Da fatan za a cika wannan fom ɗin a ƙasa. Bayanin ya bawa likita damar fahimtar halin da kake ciki",
    areUMedication: "Kuna kan magani?",
    anyIllness: "Wani rashin lafiya na yanzu?",
    doUSuffer: "Kuna fama da Ciwon Suga?",
    anyRespiratiory: "Duk wata cuta da ta shafi Numfashi?",
    no: `A'a`,
    yes: "Ee",
    nodoctor:
      "Babu wadataccen likita daga ƙasarku a halin yanzu. Kuna so a haɗa ku da likita daga wata ƙasa?",
    startconversation: "Kuna iya fara tattaunawa da",
    fetchingdoctors: "Samun likitoci a cikin ƙasarku",
    connectedDoctor: "Likita mai haɗawa",
    yourconnected: "An haɗa ku da Dr.",
    typeMessage: "Buga sako",
    patientName: "Sunan Mara lafiya",
    dob: "Ranar haifuwa",
    bloodGroup: "Groupungiyar Jini",
    genotype: "Genotype",
    anitbodies: "Antibodies",
    currentIllnes: "Ciwon Yanzu",
    anyRespiratory: "Duk wani Numfashi",
    diabetic: "Ciwon suga",
    familyMedical: "Tarihin Magungunan Iyali",
    bpm: "Gwajin bugun jini",
    bsl: "Matakan sikari na jini",
    ct: "Yanayin yanzu",
    cc: "Tari mai dacewa",
    los: "Rashin ofanshi",
    aop: "Ciwo ko Ciwo",
    dizziness: "Dizziness",
    lob: "Rashin daidaituwa",
    travel: "Shin kun yi tafiya daga ƙasar cikin kwanaki 7 na ƙarshe",
    meet: "Duk wata hulɗa da kowa wanda ya dawo daga ƙasashen ƙetare a cikin kwanaki 7 da suka gabata?",
    medical: "Tarihin Likita",
    nomedicalhistory: "Babu Tarihin Likita da Aka Samu",
    connectedPatient: "Haɗin Haƙuri",
    viewpatientprofile: "Duba Bayanin Mai haƙuri",
    makePrescription: "Yi Takaddun",
    "Hello Doc": "Hello Doc",
    "I have the Flu": "Ina da mura",
    "I have Sore throat": "Ina da Ciwon makogwaro",
    "I have Stomach Cramps": "Ina da Ciwon Ciki",
    "I feel Dizzy": "Ina jin jiri",
    "I feel unwell": "Ina jin ba lafiya",
    "I have a Headache": "Ina da ciwon kai",
    "I am feeling Feverish": "Ina jin Zazzabi",
  },
};
