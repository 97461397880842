import { LOCALES } from "../locales";

export default {
  [LOCALES.FRENCH]: {
    hello: "Bonjour",
    welcome: `Bienvenue sur l'application de télémédecine Wave`,
    platformInfo1: `La plateforme qui permet au patient de se connecter aux meilleurs médecins du domaine médical dans le confort de votre domicile. Vous pouvez payer en déplacement ou souscrire une assurance médicale avec les HMO partenaires avec nous.
    Quels que soient vos problèmes de santé, nous sommes là pour vous.
    
    `,
    platformInfo2: `Grâce à notre visioconférence, vous pouvez être entièrement pris en charge et une ordonnance envoyée à une pharmacie de votre région.
    `,
    platformInfo3: `
    La situation du Covid-19 a affecté la façon dont nous interagissons, ce qui a affecté le secteur médical.`,
    patient: `Patient`,
    "medical Expert": "Expert Médical",
    "sign In": "Se Connecter",
    register: `S'inscrire`,
    rural: "Rural",
    login: `S'identifier`,
    "sign Up": `S'inscrire`,
    urban: "Urbain",
    Profile: "Profil",
    "date of birth": "Date de naissance",
    genotype: "Génotype",
    "blood Group": "Groupe Sanguin",
    picture: "Image",
    submit: "Soumettre",
    "rural users": "UTILISATEURS RURAUX",
    user: "Utilisateur",
    registration: "Enregistrement",
    "already have an account?": "Vous avez déjà un compte?",
    select: "Sélectionner",
    country: "Pays",
    nigeria: "Nigeria",
    "united Kingdom": "Royaume-Uni",
    usa: "Etats-Unis",
    "welcome to wave":
      "Bienvenue chez WaveTelemedicine. La plateforme qui vous permet de rencontrer un médecin dans le confort de votre foyer. Veuillez remplir ce formulaire ci-dessous. Les informations permettent au médecin de bien comprendre votre situation",
    areUMedication: "Prenez-vous des médicaments?",
    anyIllness: "Une maladie actuelle?",
    doUSuffer: "Souffrez-vous de diabète?",
    anyRespiratiory: "Une maladie respiratoire?",
    no: "Non",
    yes: "Oui",
    nodoctor: `Il n'y a pas de médecin disponible dans votre pays pour le moment. Souhaitez-vous être connecté à un médecin d'un autre pays?`,
    startconversation: "Vous pouvez démarrer une conversation avec",
    fetchingdoctors: "Aller chercher des médecins disponibles dans votre pays",
    connectedDoctor: "Docteur connecté",
    yourconnected: "Vous êtes connecté au Dr.",
    typeMessage: "Tapez un message",
    patientName: "Nom du patient",
    dob: "Date de naissance",
    bloodGroup: "Groupe sanguin",
    genotype: "Génotype",
    anitbodies: "Anticorps",
    currentIllnes: "Maladie actuelle",
    anyRespiratory: "Tout respiratoire",
    diabetic: "Diabétique",
    familyMedical: "Antécédents médicaux familiaux",
    bpm: "Mesure de la pression artérielle",
    bsl: "Niveau de sucre dans le sang",
    ct: "Température actuelle",
    cc: "Toux constante",
    los: "Perte d'odeur",
    aop: "Maux ou douleurs?",
    dizziness: "Vertiges",
    lob: "Perte d'équilibre",
    travel: "Avez-vous voyagé hors du pays au cours des 7 derniers jours",
    meet: "Une interaction avec quelqu'un qui vient de rentrer de l'étranger au cours des 7 derniers jours?",
    medical: "Antécédents médicaux",
    nomedicalhistory: "Aucun antécédent médical trouvé",
    connectedPatient: "Patient connecté",
    viewpatientprofile: "Voir le profil des patients",
    makePrescription: "Faire une ordonnance",
    "Hello Doc": "Bonjour Doc",
    "I have the Flu": "J'ai la grippe",
    "I have Sore throat": "J'ai des douleurs à la gorge",
    "I have Stomach Cramps": "J'ai des crampes d'estomac",
    "I feel Dizzy": "Je me sens étourdi",
    "I feel unwell": "Je ne me sens pas bien",
    "I have a Headache": "J'ai mal à la tête",
    "I am feeling Feverish": "Je me sens fiévreux",
  },
};
