import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import waveLogo from "../../assets/images/fulllogo.png";
import { postDr } from "../../services/request";

export default class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      retryPassword: "",
      btn: false,
      token: "",
    };
  }
  componentDidMount = () => {
    const query = new URLSearchParams(this.props.location.search);
    for (let params of query.entries()) {
      query[params[0]] = params[1];
    }
    this.setState({
      token: query.token,
    });
  };
  setPassword = () => {
    if (this.state.password === "") {
      alert("Please eneter your new password");
    } else if (this.state.password != this.state.retryPassword) {
      alert("Your password is not match");
    } else {
      this.setState({
        btn: true,
      });
      postDr("updatePassword", {
        password: this.state.password,
        otp: this.state.token,
      }).then((result) => {
        alert("Your password is now set");
        this.props.history.push("/dr-login");
      });
    }
  };
  render() {
    return (
      <div className="container-fluid backimg1">
        <Row className="justify-content-center">
          <div className="col-10 col-sm-4 welcome-container">
            <Row className="justify-content-center">
              <div className="col-10 col-sm-8 pt-4">
                <img src={waveLogo} alt="1" className="doc-images" />
              </div>
              <div className="col-10 col-sm-8 px23 mt-4 fw-b">
                Account Recovery
              </div>
              <br />
              <div className="col-10 col-sm-8">
                <Row>
                  <Col>
                    <br />
                    <label className="rem1">Enter New Password</label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      className="form-control bc-f5"
                      onChange={(e) =>
                        this.setState({
                          password: e.target.value,
                        })
                      }
                      value={this.state.password}
                    />
                    <br />
                    <label className="rem1">Retry Password</label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      className="form-control bc-f5"
                      onChange={(e) =>
                        this.setState({
                          retryPassword: e.target.value,
                        })
                      }
                      value={this.state.retryPassword}
                    />
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  {this.state.btn ? (
                    <div className="col-6 mt-3">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      ></div>
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      ></div>
                    </div>
                  ) : (
                    <div
                      className="col-6 green-btn mt-3 mb-5"
                      onClick={this.setPassword}
                    >
                      Set Password
                    </div>
                  )}
                </Row>
              </div>
            </Row>
          </div>
        </Row>
      </div>
    );
  }
}
