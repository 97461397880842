import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { postData } from "./../services/request";
import Loader from "react-loader-spinner";
import { Redirect, Link } from "react-router-dom";
import { Alert } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import waveLogo from "../assets/images/fulllogo.png";
import translate from "../i18n/translation";

Alert.config({
  duration: 4000,
  top: 10,
});
export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      authenticated: false,
    };
  }

  login = (e) => {
    e.preventDefault();
    var details = {
      email: e.target.email.value,
      password: e.target.password.value,
    };
    if (details.email.trim() === null || details.email.trim() === "") {
      Alert.warning("Input your email address");
    } else if (
      details.password.trim() === null ||
      details.password.trim() === ""
    ) {
      Alert.warning("Input your password");
    } else {
      this.setState({ loader: true });
      postData("doctor-login", details)
        .then((response) => {
          this.setState({ loader: false });
          console.log(response);
          if (response.status === true) {
            localStorage.setItem("med_token", response.token);
            Alert.success("Logging you in......");
            setTimeout(() => {
              this.setState({ authenticated: true });
            }, 5000);
          } else {
            Alert.error("Error logging you in");
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
          Alert.error(
            "Error logging you in, please check your account is activated"
          );
        });
    }
  };
  showPassword = () => {
    var password = document.getElementById("password");
    if (password.type === "password") {
      password.type = "text";
    } else {
      password.type = "password";
    }
  };
  render() {
    if (this.state.authenticated === true) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <Container className="container-fluid" fluid>
        <div className="backdrop-doc">
          <div>
            {/* Normal Version */}
            <Row className="d-none d-sm-block">
              <Col md="4" className="bg-white pt-3" style={{ height: "100vh" }}>
                <Row>
                  <Col md={2} className="pt-3">
                    <Link to="/medicalIndex">
                      <FontAwesomeIcon
                        size="2x"
                        icon={faArrowCircleLeft}
                        className="icons"
                        style={{ color: "#020197" }}
                      />
                    </Link>
                  </Col>
                  <Col md={10} className="pt-2">
                    <img
                      src={waveLogo}
                      alt="1"
                      className="doc-images float-right"
                    />
                  </Col>
                </Row>
                {/* <span className="pull-right"><img src={waveLogo} alt="1" className="doc-images"/></span>
                        <Link to="/medicalIndex">
                        <FontAwesomeIcon
                            size="2x"
                            icon={faArrowCircleLeft}
                            className="icons"
                            style={{"color":"#020197"}}
                        />
                        </Link> */}

                <br />
                <br />
                <br />
                <p
                  className="text-center font-weight-bold"
                  style={{ fontSize: "36px" }}
                >
                  {translate("medical Expert")} {translate("login")}
                </p>
                <br />
                <div style={{ width: "80%", margin: "auto" }}>
                  <form onSubmit={this.login}>
                    <Row>
                      <Col>
                        <input
                          type="text"
                          placeholder="Email"
                          id="email"
                          name="email"
                          className="text-boxe"
                        />
                      </Col>
                    </Row>
                    <br />
                    <br />
                    <Row className="no-gutters">
                      <Col>
                        <input
                          type="password"
                          id="password"
                          placeholder="Password"
                          name="password"
                          className="text-boxe"
                        />
                        <FontAwesomeIcon
                          onClick={this.showPassword}
                          icon={faEye}
                          style={{
                            float: "right",
                            marginTop: "-22px",
                            marginRight: "10px",
                            cursor: "pointer",
                            position: "relative",
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    <br />
                    <div className="err" id="error-div"></div>
                    <div className="succ" id="success-div"></div>
                    {this.state.loader === true ? (
                      <Loader
                        type="TailSpin"
                        width={60}
                        height={60}
                        color="white"
                      />
                    ) : (
                      <button
                        className="blue-btn pt-2 pb-2 pl-5 pr-5 rounded d-block bg-white"
                        style={{
                          color: "white",
                          fontSize: "25px",
                          width: "200px",
                          margin: "0 auto",
                        }}
                      >
                        {translate("login")}
                      </button>
                    )}
                  </form>
                  <br />
                  <Link
                    className="d-block text-center text-dark"
                    to="/doctorsignup"
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      Don't have an account ? {translate("register")}
                    </span>{" "}
                  </Link>
                </div>
              </Col>
              <Col md="9"></Col>
            </Row>
            {/* Mobile Version */}
            <Row className="d-block d-sm-none">
              <Col className="align-self-center p-4">
                <div className="bg-white mt-5">
                  <Row>
                    <Col md={2} className="pt-3">
                      <Link to="/medicalIndex">
                        <FontAwesomeIcon
                          size="2x"
                          icon={faArrowCircleLeft}
                          className="icons"
                          style={{ color: "#020197" }}
                        />
                      </Link>
                    </Col>
                    {/* <Col md={10} className="pt-2">
                                <img src={waveLogo} alt="1" className="doc-images float-right"/>
                            </Col> */}
                    <div>
                      <p
                        className="text-center font-weight-bold"
                        style={{ fontSize: "36px" }}
                      >
                        {translate("medical Expert")} {translate("login")}
                      </p>
                      <br />
                      <div style={{ width: "80%", margin: "auto" }}>
                        <form onSubmit={this.login}>
                          <Row>
                            <Col>
                              <input
                                type="text"
                                placeholder="Email"
                                id="email"
                                name="email"
                                className="text-boxe"
                              />
                            </Col>
                          </Row>
                          <br />
                          <br />
                          <Row className="no-gutters">
                            <Col>
                              <input
                                type="password"
                                id="password"
                                placeholder="Password"
                                name="password"
                                className="text-boxe"
                              />
                              <FontAwesomeIcon
                                onClick={this.showPassword}
                                icon={faEye}
                                style={{
                                  float: "right",
                                  marginTop: "-22px",
                                  marginRight: "10px",
                                  cursor: "pointer",
                                  position: "relative",
                                }}
                              />
                            </Col>
                          </Row>
                          <br />
                          <br />
                          <div className="err" id="error-div"></div>
                          <div className="succ" id="success-div"></div>
                          {this.state.loader === true ? (
                            <Loader
                              type="TailSpin"
                              width={60}
                              height={60}
                              color="white"
                            />
                          ) : (
                            <button
                              className="blue-btn pt-2 pb-2 pl-5 pr-5 rounded d-block bg-white"
                              style={{
                                color: "white",
                                fontSize: "25px",
                                width: "200px",
                                margin: "0 auto",
                              }}
                            >
                              {translate("login")}
                            </button>
                          )}
                        </form>
                        <br />
                        <Link
                          className="d-block text-center text-dark"
                          to="/doctorsignup"
                        >
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                          >
                            Don't have an account ? {translate("register")}
                          </span>{" "}
                        </Link>
                      </div>
                    </div>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    );
  }
}
