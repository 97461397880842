import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faArrowCircleLeft,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { fetchData, postDataAuth } from "../../services/request";
import { Link, Redirect } from "react-router-dom";
import waveLogo from "../../assets/images/fulllogo.png";
import NavBar from "./navbar";
import { Alert } from "rsuite";

export default class Setting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: "",
      confirmPassword: "",
    };
  }

  goNext = () => {
    if (this.state.newPassword === "") {
      Alert.warning("Input your NewPassword");
    } else if (this.state.confirmPassword === "") {
      Alert.warning("Input your ConfirmPassword");
    } else if (this.state.confirmPassword != this.state.newPassword) {
      Alert.warning("NewPassword and ConfirmPassword not Match");
    } else {
      postDataAuth("change-password", {
        newPassword: this.state.newPassword,
        confirmPassword: this.state.confirmPassword,
      });
      Alert.success("Your Password is Updated");
    }
  };
  render() {
    return (
      <>
        <div className="container-fluid bo-top">
          <div className="row">
            <div className="col-2 bo-right">
              <NavBar history={this.props.history} />
            </div>
            <div className="col-10 bc-f5">
              <div className="row page-head">Settings</div>
              <div className="row page-size justify-content-center mt-5">
                <div className="col-3">
                  <div className="row">
                    <label className="rem1">New Password</label>
                    <input
                      type="password"
                      id="email"
                      name="email"
                      className="form-control bc-f5"
                      onChange={(e) => {
                        this.setState({ newPassword: e.target.value });
                      }}
                      value={this.state.newPassword}
                    />
                  </div>
                  <div className="row mt-2">
                    <label className="rem1">Confirm Password</label>
                    <input
                      type="password"
                      id="email"
                      name="email"
                      className="form-control bc-f5"
                      onChange={(e) => {
                        this.setState({ confirmPassword: e.target.value });
                      }}
                      value={this.state.confirmPassword}
                    />
                  </div>
                  <div className="row mt-3">
                    <button className="btn-update" onClick={this.goNext}>
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
