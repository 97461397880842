import React from "react";
import { Container } from "react-bootstrap";

import { Link } from "react-router-dom";
import Header3 from "../componenets/headers/Header3";
import V3 from "../assets/Vector3.png";
import Wallet from "../assets/Wallet.png";
import Coin from "../assets/Coin.png";
import Cash from "../assets/Cash.png";

import { getAuth, postDataAuth } from "../services/request";
export default class RuralReg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      transactions: null,
      authenticated: null,
      wallet_id: null,
      walletBalance: null,
      currency: null,
      userType: "",
    };
  }

  componentDidMount = () => {
    var token = localStorage.getItem("med_token");
    getAuth("authenticated", token).then((response) => {
      this.setState(
        {
          authenticated: response.data.user,
        },
        () => {
          if (this.state.authenticated.usertype === "Patient") {
            this.setState({
              userType: "Patient",
            });
          }
        }
      );
      this.getWalletInfo();
    });
  };

  getWalletInfo() {
    var token = localStorage.getItem("med_token");
    if (this.state.authenticated && this.state.authenticated.email) {
      postDataAuth(
        "getWalletInfo",
        {
          email: this.state.authenticated.email,
        },
        token
      )
        .then((response) => {
          this.setState({
            wallet_id:
              response && response.length > 0 && response[0].wallet_id
                ? response[0].wallet_id
                : "",
            currency:
              this.state.authenticated.country === "Nigeria" ? "NGN" : "USD",
          });
        })
        .catch((error) => {});

      postDataAuth(
        "getWalletBalance",
        {
          email: this.state.authenticated.email,
        },
        token
      )
        .then((response) => {
          this.setState({
            walletBalance: response && response.balance ? response.balance : "",
          });
        })
        .catch((error) => {});

      postDataAuth(
        "getWalletTransactions",
        {
          email: this.state.authenticated.email,
        },
        token
      )
        .then((response) => {
          const transactions = response && response.length > 0 ? response : [];
          if (transactions.length > 0) {
            this.setState({
              transactions: transactions,
            });
          }
        })
        .catch((error) => {});
    }
  }
  render() {
    return (
      <>
        <Container fluid className="container-fluid">
          <div className="row head2">
            <Header3 title="Transaction History" />
          </div>

          <div className="row justify-content-center">
            <div className="col-11">
              <Link to="/dashboard" style={{ textDecoration: "none" }}>
                <div className="back-btn">
                  <img src={V3} alt="" style={{ paddingBottom: "4px" }}></img>
                  <span className="pl-3 fz20 fw4 cl1A">Back</span>
                </div>
              </Link>
            </div>
            <div className="col-11 mt-3 justify-content-center">
              <div className="mt-2">
                <img src={Wallet} alt=""></img>
                <span className="ml-2 fz16 ">
                  <b>Wallet ID :</b> {this.state.wallet_id}
                </span>
              </div>
              <div className="mt-2">
                <img src={Coin} alt=""></img>
                <span className="ml-2 fz16 ">
                  <b>Current Currency :</b> {this.state.currency}
                </span>
              </div>
              <div className="mt-2">
                <img src={Cash} alt=""></img>
                <span className="ml-2 fz16">
                  <b>Avalaible Balance :</b> {this.state.walletBalance}
                </span>
              </div>
            </div>
          </div>

          <div
            className="row mt-3 justify-content-center"
            style={{ overflowX: "auto" }}
          >
            <div className="col-11">
              <table class="table">
                <thead className="transcation bc2s clff">
                  <tr>
                    <th scope="col">Transaction ID</th>
                    <th scope="col">Transaction Type</th>
                    <th scope="col">Description</th>
                    <th scope="col">Amount (NGN)</th>
                    <th scope="col">Balance (NGN)</th>
                    <th scope="col">Transaction Date</th>
                  </tr>
                </thead>
                <tbody className="transcation-row">
                  {this.state.transactions &&
                    this.state.transactions.map((transaction, index) => (
                      <tr className="h6">
                        <td>{transaction.transaction_id}</td>
                        <td>{transaction.transaction_type}</td>
                        <td>{transaction.description}</td>
                        <td>{transaction.amount}</td>
                        <td>{transaction.balance}</td>
                        <td>{transaction.created_at}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </Container>
      </>
    );
  }
}
