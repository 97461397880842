import React from "react";
import Loader from "react-loader-spinner";
import { Alert } from "rsuite";
import { postData } from "../../services/request";
import { Link, Redirect } from "react-router-dom";
import translate from "../../i18n/translation";
import Header2 from "../../componenets/headers/Header2";
import V3 from "../../assets/Vector3.png";
Alert.config({
  duration: 4000,
  top: 10,
});

export default class MadicalExpertLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loader: false,
      authenticated: false,
    };
  }

  login = () => {
    var details = {
      email: this.state.email,
      password: this.state.password,
    };
    if (details.email.trim() === null || details.email.trim() === "") {
      Alert.warning("Input your email address");
    } else if (
      details.password.trim() === null ||
      details.password.trim() === ""
    ) {
      Alert.warning("Input your password");
    } else {
      this.setState({ loader: true });
      postData("doctor-login", details)
        .then((response) => {
          this.setState({ loader: false });
          console.log(response);
          if (response.status === true) {
            localStorage.setItem("med_token", response.token);
            Alert.success("Logging you in......");
            setTimeout(() => {
              this.setState({ authenticated: true });
            }, 5000);
          } else {
            Alert.error("Error logging you in");
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
          Alert.error(
            "Error logging you in, please check your account is activated"
          );
        });
    }
  };
  showPassword = () => {
    var password = document.getElementById("password");
    if (password.type === "password") {
      password.type = "text";
    } else {
      password.type = "password";
    }
  };
  render() {
    if (this.state.authenticated === true) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <>
        <div className="container-fluid">
          <div className="row ">
            <div className="col-sm-6 head2">
              <Header2 />
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-10">
                    <div className="row">
                      <Link to="/" style={{ textDecoration: "none" }}>
                        <div className="back-btn">
                          <img
                            src={V3}
                            alt=""
                            style={{ paddingBottom: "4px" }}
                          ></img>
                          <span className="pl-3 fz20 fw4 cl1A">Back</span>
                        </div>
                      </Link>
                    </div>
                    <div className="row">
                      <div className=" mt-4">
                        <div className="fz32 fw5 cl1A">Welcome </div>
                        <div className="fz20 fw5">
                          <span>Login as an</span>
                          <span className="pl-2 cl00 fw5">
                            {" "}
                            {translate("medical Expert")}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12  mt-4">
                        <div className="row fw4 fz20">
                          <div className="col-sm-4">Email</div>
                          <div className="col-sm-8">
                            <input
                              type="email"
                              className="input-field"
                              placeholder="Enter your email address "
                              onChange={(e) => {
                                this.setState({
                                  email: e.target.value,
                                });
                              }}
                              value={this.state.email}
                            ></input>
                          </div>
                        </div>
                        <div className="row mt-3 fw4 fz20">
                          <div className="col-sm-4">Password</div>
                          <div className="col-sm-8">
                            <input
                              type="password"
                              className="input-field"
                              placeholder="Enter your password "
                              onChange={(e) => {
                                this.setState({
                                  password: e.target.value,
                                });
                              }}
                              value={this.state.password}
                            ></input>
                          </div>
                        </div>
                        <div className="row mt-3 forgetPaswd ">
                          <div className="col-12 text-end">
                            Forgot your Password?
                          </div>
                        </div>
                        <div className="row mt-3 uderline"></div>
                        <div className="row justify-content-between mt-3">
                          <div className="col-6">
                            <span style={{ paddingTop: "10px" }}>
                              <input
                                type="checkbox"
                                className="form-check-input"
                              ></input>
                            </span>
                            <span style={{ paddingTop: "10px" }}>
                              {" "}
                              Remember me
                            </span>
                          </div>
                          <div className="col-6 text-right">
                            <button
                              type="button"
                              className="bt-patient"
                              onClick={this.login}
                              disabled={this.state.loader}
                            >
                              {this.state.loader
                                ? "Loging..."
                                : translate("login")}
                            </button>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-12 text-center">
                            <span className="fw3 fz14 m-auto">
                              Don’t have an account?{" "}
                            </span>
                            <a href="https://telemed.wavepalmapps.com/">
                              <span className="fz14 fw5 cl00">
                                {translate("sign Up")}
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 img6 desktop"></div>
          </div>
        </div>
      </>
    );
  }
}
