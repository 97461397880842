import React from "react";
import "../../App.css";
import translate from "../../i18n/translation";
import Logo from "../../assets/Logo.png";
import Menu from "../../assets/Menu.png";
import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import G1 from "../../assets/Group1.png";

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal2: false,
    };
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  toggle2 = () => {
    this.setState({
      modal2: !this.state.modal2,
    });
  };
  render() {
    return (
      <>
        <div className="container-fluid desktop">
          <div className="row justify-content-between header">
            <div className="col-4">
              <img
                src={Logo}
                alt="wavetelemedicine"
                style={{ paddingTop: "10px", paddingLeft: "25px" }}
              ></img>
            </div>
            <div className="col-3">
              <button
                type="button"
                className="bt-signUp"
                onClick={this.toggle2}
              >
                {translate("patient")}
              </button>
              <Link to="/expert">
                <button type="button" className="bt-login">
                  {translate("medical Expert")}
                </button>
              </Link>
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.modal2} toggle={this.toggle2} className="">
          <ModalHeader toggle={this.toggle2} className=""></ModalHeader>
          <ModalBody>
            <div className="row text-center mb-4">
              <div className="col-12">
                <img src={G1} alt=""></img>
              </div>
              <div className="col-12 mt-2 fw4 fz20">
                Select your user type below
              </div>
              <div className="col-12 mt-3">
                <Link to="/urban-login">
                  <button type="button" className="bt-UR">
                    Urban User
                  </button>
                </Link>
                <Link to="/rural-login">
                  <button type="button" className="bt-UR">
                    Rural User
                  </button>
                </Link>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/* Mobile Header */}
        <div className="container-fluid mobile">
          <div className="row justify-content-between header">
            {/* <div className="col-2">
              <img
                src={Menu}
                alt=""
                style={{ width: "100%", paddingTop: "18px" }}
                onClick={this.toggle}
              ></img>
            </div> */}
            <div className="col-10 ml-3">
              <img
                src={Logo}
                alt="wavetelemedicine"
                style={{ paddingTop: "10px", width: "100%" }}
              ></img>
            </div>
          </div>
          {/* <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className="menu"
          >
            <ModalHeader toggle={this.toggle2}></ModalHeader>
            <ModalBody>
              <div className="">
                <button type="button" className="bt-menu-login">
                  {translate("login")}
                </button>
              </div>
              <div className="">
                <button type="button" className="bt-menu-signup">
                  {translate("sign Up")}
                </button>
              </div>
            </ModalBody>
          </Modal> */}
        </div>
      </>
    );
  }
}
