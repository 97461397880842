import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faArrowCircleLeft,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Alert } from "rsuite";
import { postData } from "../../../services/request";
import { Link, Redirect } from "react-router-dom";
import waveLogo from "../../../assets/images/fulllogo.png";
import translate from "../../../i18n/translation";
export default class UserLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
    };
  }

  login = (e) => {
    e.preventDefault();
    var details = {
      email: e.target.email.value,
      password: e.target.password.value,
    };
    if (details.email.trim() === null || details.email.trim() === "") {
      Alert.warning("Input your email address");
    } else if (
      details.password.trim() === null ||
      details.password.trim() === ""
    ) {
      Alert.warning("Input your password");
    } else {
      this.setState({ loader: true });
      postData("patient-login", details)
        .then((response) => {
          this.setState({ loader: false });
          console.log(response);

          if (response.status === true) {
            localStorage.setItem("med_token", response.token);
            Alert.success("Logging you in......");
            setTimeout(() => {
              this.props.history.push("/userpanel");
            }, 5000);
          } else {
            Alert.error("Error logging you in");
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
          if (error.request.status == 401) {
            Alert.error("Authentication Failed");
          } else {
            Alert.error("Error connecting to server");
          }
        });
    }
  };
  showPassword = () => {
    var password = document.getElementById("password");
    if (password.type === "password") {
      password.type = "text";
    } else {
      password.type = "password";
    }
  };
  render() {
    return (
      <div className="container-fluid backimg1">
        <Row className="justify-content-center">
          <div className="col-10 col-sm-4 welcome-container">
            <Row>
              <div className="col-4 mt-3">
                <div
                  className="cur"
                  onClick={() => {
                    this.props.history.push("/usertype");
                  }}
                >
                  <span>
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      color="#195773"
                      size="1x"
                    />
                  </span>
                  <span className="cl-19 px16 ml-2">Back</span>
                </div>
              </div>
            </Row>
            <Row className="justify-content-center">
              <div className="col-10 col-sm-8 pt-4">
                <img src={waveLogo} alt="1" className="doc-images" />
              </div>
              <div className="col-10 col-sm-8 px23 mt-4 fw-b">User Login</div>
              <br />
              <div className="col-10 col-sm-8">
                <form style={{ width: "100%" }} onSubmit={this.login}>
                  <Row>
                    <Col>
                      <br />
                      <label className="rem1">Email Address</label>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        className="form-control bc-f5"
                      />
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col className="position-relativt">
                      <label className="rem1">Password</label>
                      <input
                        type="password"
                        className="form-control bc-f5"
                        name="password"
                        id="password"
                      />
                      <FontAwesomeIcon
                        onClick={this.showPassword}
                        icon={faEye}
                        style={{
                          marginTop: "-24px",
                          cursor: "pointer",
                          position: "absolute",
                          zIndex: 9,
                          right: "20px",
                        }}
                      />
                    </Col>
                  </Row>
                  <br />

                  {this.state.loader === true ? (
                    <Loader
                      type="TailSpin"
                      width={60}
                      height={60}
                      color="blue"
                    />
                  ) : (
                    <>
                      <button className="signup-btn mt-3 mb-3">
                        {translate("login")}
                      </button>
                    </>
                  )}
                </form>
              </div>
            </Row>
          </div>
        </Row>
      </div>
    );
  }
}
