import React from "react"
import translate from "../i18n/translation";
export default class RuraLogin extends React.Component{
render(){
    return(
        <div>
             <input type="text" placeholder="NIN" className="text-boxe"/><br/><br/>
                <input type="text" placeholder="Password" className="text-boxe"/><br/><br/>
    <button style={{float:"left",backgroundColor:"rgb(46, 59, 95)",color:"white"}} className="buttons" >{translate('register')}</button>
        </div>
    )
}
}