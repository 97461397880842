import React from "react";
import { Input } from "reactstrap";
import { fetchData, postDataAuth } from "../../services/request";
import NavBar from "./navbar";
import swal from "sweetalert";

export default class AdminHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      patients: [],
      patients2: [],
      listOfArray: [],
      checkAll: true,
    };
  }
  componentDidMount = async () => {
    const data = await fetchData("all-users?item=%7B%22item%22:%22%22%7D");
    const list = data.users;
    const listPatient = [];
    for (let a = 0; a < list.length; a++) {
      if (list[a].usertype === "Patient") {
        listPatient.push(list[a]);
      }
    }
    this.setState({
      patients: listPatient,
      patients2: listPatient,
    });
  };
  deactiveUser = (id) => {
    swal({
      title: "Are you sure?",
      text: "You want to deactive this Patient!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        postDataAuth("deactivate", {
          id: id,
        });

        swal("Poof! Your Patient has been deleted!", {
          icon: "success",
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        swal("Your  Patient is safe!");
      }
    });
  };
  ActiveUser = (id) => {
    swal({
      title: "Are you sure?",
      text: "You want to Active this Patient!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        postDataAuth("activate", {
          id: id,
        });

        swal("Poof! Your Patient has been active!", {
          icon: "success",
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        swal("Your Patient is safe!");
      }
    });
  };
  // patient delete
  deleteUser = (id) => {
    swal({
      title: "Are you sure?",
      text: "You want to delete this Patient!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        postDataAuth("deleteUser", {
          id: id,
        });

        swal("Poof! Your Patient has been deleted!", {
          icon: "success",
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        swal("Your  Patient is safe!");
      }
    });
  };

  // search name
  nameSearch = (e) => {
    const name = this.state.patients2;
    const getName = name.filter((f) => f.firstname.toLowerCase().startsWith(e));
    this.setState({
      patients: getName,
    });
  };
  // add check list
  arryAdd = (id) => {
    let arr = this.state.listOfArray;
    const found = arr.find((element) => element === id);
    if (found === undefined) {
      arr.push(id);
    } else {
      const newArr = arr.filter((e) => e !== id);
      this.setState({
        listOfArray: newArr,
      });
    }
  };
  // active all
  activeAll = () => {
    swal({
      title: "Are you sure?",
      text: "You want to active selected Patients!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const listOfPatient = this.state.listOfArray;
        for (let a = 0; a < listOfPatient.length; a++) {
          postDataAuth("activate", {
            id: listOfPatient[a],
          });
        }
        swal("Poof! Your Patients have been active!", {
          icon: "success",
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        swal("Your Patients are safe!");
      }
    });
  };
  //  deactive all
  deactiveAll = () => {
    swal({
      title: "Are you sure?",
      text: "You want to deactive selected Patients!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const listOfPatient = this.state.listOfArray;
        for (let a = 0; a < listOfPatient.length; a++) {
          postDataAuth("deactivate", {
            id: listOfPatient[a],
          });
        }

        swal("Poof! Your Patients have been deactive!", {
          icon: "success",
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        swal("Your Patients are safe!");
      }
    });
  };
  // delete all
  deleteAll = () => {
    swal({
      title: "Are you sure?",
      text: "You want to delete selected Patients!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const listOfPatient = this.state.listOfArray;
        for (let a = 0; a < listOfPatient.length; a++) {
          postDataAuth("deleteUser", {
            id: listOfPatient[a],
          });
        }
        swal("Poof! Your Patients have been deleted!", {
          icon: "success",
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        swal("Your Patients are safe!");
      }
    });
  };
  // check all
  checkAllBox = () => {
    this.setState({ checkAll: !this.state.checkAll });
    const list = this.state.patients;
    if (this.state.checkAll === true) {
      const listId = [];
      for (let a = 0; a < list.length; a++) {
        document.getElementsByClassName("check-box")[a].checked = true;
        listId.push(list[a].id.toString());
      }
      this.setState({
        listOfArray: listId,
      });
    } else {
      for (let a = 0; a < list.length; a++) {
        document.getElementsByClassName("check-box")[a].checked = false;
      }
      this.setState({
        listOfArray: [],
      });
    }
  };
  render() {
    return (
      <>
        <div className="container-fluid bo-top">
          <div className="row">
            <div className="col-2 bo-right">
              <NavBar history={this.props.history} />
            </div>
            <div className="col-10 bc-f5">
              <div className="row">
                <div className="col-6 page-head">Patients</div>
                <div className="col-4 pt-1">
                  <Input
                    id="search-input"
                    placeholder="Search Name"
                    style={{
                      borderBottomLeftRadius: 4,
                      borderTopLeftRadius: 4,
                    }}
                    onChange={(e) => {
                      this.nameSearch(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-2">
                  <button className="btn btn-primary" onClick={this.activeAll}>
                    Activate Selection
                  </button>
                </div>
                <div className="col-2">
                  <button
                    className="btn btn-primary"
                    onClick={this.deactiveAll}
                  >
                    Deactivate Selection
                  </button>
                </div>
                <div className="col-2">
                  <button className="btn btn-primary" onClick={this.deleteAll}>
                    Delete Selection
                  </button>
                </div>
              </div>
              <div className="row page-size">
                <div className="col-12">
                  <table class="table">
                    <thead className="boder-col">
                      <tr>
                        <th scope="col">
                          <input
                            className="all-check"
                            type="checkbox"
                            onClick={this.checkAllBox}
                          ></input>
                        </th>
                        <th scope="col" className="">
                          #
                        </th>
                        <th scope="col" className="">
                          First Name
                        </th>
                        <th scope="col" className="">
                          Last Name
                        </th>
                        <th scope="col" className="">
                          Email
                        </th>
                        <th scope="col" className="">
                          Country
                        </th>
                        <th scope="col" className="">
                          Status
                        </th>
                        <th scope="col" className=""></th>
                        <th scope="col" className="">
                          Delete
                        </th>
                      </tr>
                    </thead>
                    {this.state.patients.map((data, idx) => (
                      <tbody key={idx}>
                        <tr>
                          <td>
                            <input
                              className="check-box"
                              type="checkbox"
                              value={data.id}
                              onClick={(e) => {
                                this.arryAdd(e.target.value);
                              }}
                            ></input>
                          </td>
                          <td className="">{idx + 1}</td>
                          <td className="">{data.firstname}</td>
                          <td className="">{data.lastname}</td>
                          <td className="">{data.email}</td>
                          <td className="">{data.country}</td>

                          {data.status === 1 && (
                            <>
                              <td className="">Active</td>

                              <td>
                                <div
                                  className="cancle-bt"
                                  onClick={() => {
                                    this.deactiveUser(data.id);
                                  }}
                                >
                                  Deactivate
                                </div>
                              </td>
                            </>
                          )}
                          {data.status === 0 && (
                            <>
                              <td className="">Deactiva</td>

                              <td>
                                <div
                                  className="act-bt"
                                  onClick={() => {
                                    this.ActiveUser(data.id);
                                  }}
                                >
                                  Activate
                                </div>
                              </td>
                            </>
                          )}
                          <td>
                            <button
                              className="btn btn-danger"
                              onClick={() => {
                                this.deleteUser(data.id);
                              }}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
