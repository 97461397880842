import React from "react";
import { Container, Row, Col, Card, Button, Badge } from "react-bootstrap";
import { Uploader, Alert } from "rsuite";
import {
  postDataAuth,
  getAuth,
  fetchDataAuth,
  postData,
} from "../services/request";
import Loader from "react-loader-spinner";

export default class PatientHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: [],
      attendedDoctors: [],
    };
  }

  componentDidMount = () => {
    var token = localStorage.getItem("med_token");
    const _this = this;
    getAuth("authenticated", token).then((response) => {
      // Notes : here it check if any patient is assigned or not if assigned make it unavailable
      if (response.data.user.isLogin === 0) {
        //Alert.error("Session finished, please relogin");
        //window.location.href="/login"
      } else {
        this.setState({ authenticated: response.data.user }, () => {
          this.getPreviousDoctorsAttended(token);
        });
      }
    });
  };

  getPreviousDoctorsAttended = (token) => {
    fetchDataAuth(`patient-history/${this.state.authenticated.id}`, null, token)
      .then((response) => {
        // console.log(response.medicalExperts)
        const doctors = [];
        const history = response.details;

        response.details.forEach((item) => {
          if (doctors.indexOf(item.doctorId) == -1) {
            doctors.push(item.doctorId);
          }
        });
        postData("assigned-doctors", { doctorIds: doctors })
          .then((result) => {
            const doctorProfiles = result.data[0].profile;
            const doctorPicture = result.data[1].picture;
            const doctors = [];

            doctorProfiles.forEach((item) => {
              const index = doctorPicture.findIndex(
                (each) => each.docID === item.id
              );
              item["profilePic"] = doctorPicture[index].picture;
              item["lastConnected"] = history[index].updatedAt;
              doctors.push(item);
            });
            this.setState({ attendedDoctors: doctors });
            // console.log(result);
            // debugger;
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((error) => {});
  };

  submit = (e) => {
    e.preventDefault();
    var token = localStorage.getItem("med_token");
    if (this.state.pic === null || this.state.pic === "") {
      Alert.warning("Plase select a file to upload");
    } else {
      var formData = new FormData();
      var file = this.state.pic[0].blobFile;
      formData.append("doc_profile_pic", file);
      formData.append("accountNumber", e.target.accountNumber.value);
      formData.append("bankAccount", e.target.bankAccount.value);
      formData.append(
        "beneficiaryName",
        this.props.authenticated.firstname +
          " " +
          this.props.authenticated.lastname
      );
      this.setState({ loader: true });
      postDataAuth("doc-profile", formData, token)
        .then((response) => {
          this.setState({ loader: false });
          if (response.status === true) {
            Alert.success(response.message);
            this.props.chat();
          } else {
            Alert.error(response.message);
          }
        })
        .catch(() => {
          this.setState({ loader: false });
          Alert.error("Error updating profile");
        });
    }
  };
  formatDate(lastConnected) {
    const dt = new Date(lastConnected);
    return dt.getDate() + "/" + (dt.getMonth() + 1) + "/" + dt.getFullYear();
  }
  captializeFirstLetter = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };
  render() {
    return (
      <Container className="container-fluid">
        <Row>
          <Col md={12}>
            <form onSubmit={this.submit} className="signup-form-q">
              <h4>Following doctors have attended you.</h4>
              <hr />
              <Row>
                <Col xs={12}>
                  {this.state.attendedDoctors.map((doctor, index) => (
                    <Card
                      style={{ width: "" }}
                      className="card d-inline-block ml-2"
                    >
                      <Card.Img
                        variant="top"
                        style={{
                          maxWidth: "286px",
                          maxHeight: "286px",
                          overflow: "hidden",
                        }}
                        src={
                          "https://telemedicine-wave.s3.us-east-2.amazonaws.com/" +
                          doctor.profilePic
                        }
                      />
                      <Card.Body>
                        <Card.Title>
                          {" "}
                          {this.captializeFirstLetter(doctor.firstname)}{" "}
                          {this.captializeFirstLetter(doctor.lastname)}
                        </Card.Title>
                        <Card.Text>
                          {this.formatDate(doctor.lastConnected)}
                          {doctor.isLogin == "1" ? (
                            <Badge variant="success" className="float-right">
                              Available
                            </Badge>
                          ) : (
                            <Badge variant="danger" className="float-right">
                              Not available
                            </Badge>
                          )}
                        </Card.Text>
                        {doctor.isLogin == "1" ? (
                          <Button
                            variant="primary"
                            onClick={this.props.loadChat}
                          >
                            Get Connected
                          </Button>
                        ) : (
                          <Button variant="secondary">Get Connected</Button>
                        )}
                      </Card.Body>
                    </Card>
                  ))}
                </Col>
                <Col xs={1}></Col>
              </Row>
              <br />
              <Row>
                <Col xs={11}></Col>
                <Col xs={1}></Col>
              </Row>
              <br />
              <br />
            </form>
          </Col>
          <Col md={2}></Col>
        </Row>
      </Container>
    );
  }
}
