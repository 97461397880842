import React from "react";
import { Radio, RadioGroup } from "rsuite";
import { Container, Col, Row, Navbar } from "react-bootstrap";
import { Modal, Button } from "rsuite";
import { Alert } from "rsuite";
import { postDataAuth } from "../services/request";
import Loader from "react-loader-spinner";
import translate from "../i18n/translation";
import { captializeFirstLetter } from "./common";
// import waveLogo from "../assets/images/fulllogo.png";
import waveLogo from "../assets/Logo.png";
import Header2 from "../componenets/headers/Header2";
import Pulse from "react-reveal/Pulse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default class SignupQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      medication: null,
      illness: null,
      diabetes: null,
      respiratory: null,
      show: false,
      size: null,
      loader: false,
      pain_in_body: null,
      cough: null,
      loss_of_smell: null,
      pain_in_body: null,
      dizziness: null,
      loss_of_balance: null,
      travel_in_last_days: null,
      visitor_interacts: null,
    };
  }
  close = () => {
    this.setState({ show: false });
  };
  open = () => {
    this.setState({ show: true });
  };
  componentDidMount = () => {
    if (!this.props.hideVitalStatics) {
      this.open();
    } else {
      this.props.next();
    }
  };
  send = (e) => {
    e.preventDefault();
    var details = {
      medication:
        this.state.medication === "yes"
          ? e.target.medicationText.value
          : this.state.medication,
      illness:
        this.state.illness === "yes"
          ? e.target.illnessText.value
          : this.state.illness,
      diabetes: this.state.diabetes,
      respiratory:
        this.state.respiratory === "yes"
          ? e.target.respiratoryText.value
          : this.state.respiratory,
      blood_pressure_measurement:
        e.target.blood_pressure_measurement.value || "",
      current_body_temperature: e.target.current_body_temperature.value || "",
      blood_sugar_level: this.state.blood_sugar_level || "",
      cough: this.state.cough || "",
      loss_of_smell: this.state.loss_of_smell || "",
      pain_in_body: this.state.pain_in_body || "",
      area_of_pain_in_body:
        (e.target.area_of_pain_in_body &&
          e.target.area_of_pain_in_body.value) ||
        "",
      dizziness: this.state.dizziness || "",
      loss_of_balance: this.state.loss_of_balance || "",
      travel_in_last_days: this.state.travel_in_last_days || "",
      visitor_interacts: this.state.visitor_interacts || "",
    };

    if (details.medication === null) {
      Alert.warning("Please answer all questions");
    } else if (
      details.medication !== "no" &&
      e.target.medicationText.value.trim() === ""
    ) {
      Alert.warning("Provide all medication details");
    } else if (details.illness === null) {
      Alert.warning("Please answer all questions");
    } else if (
      details.illness !== "no" &&
      e.target.illnessText.value.trim() === ""
    ) {
      Alert.warning("Provide details for illness");
    } else if (details.diabetes === null) {
      Alert.warning("Please answer all questions");
    }
    if (details.respiratory === null) {
      Alert.warning("Please answer all questions");
    } else if (
      details.respiratory !== "no" &&
      e.target.respiratoryText.value.trim() === ""
    ) {
      Alert.warning("Please provide respiratory illness details");
    } else {
      var token = localStorage.getItem("med_token");
      this.setState({ loader: true });
      postDataAuth("signupQuestions", details, token)
        .then((response) => {
          this.setState({ loader: false });
          if (response.status === true) {
            Alert.success(response.Message);
            setTimeout(() => {
              this.props.next();
            }, 3000);
          } else {
            Alert.error(response.Message);
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
          Alert.error("Unable to connect to server");
        });
    }
  };
  render() {
    return (
      <Container className="container-fluid" fluid>
        <Header2 />
        <div className="vital-statis">Vital Statistics </div>

        <div className="modal-container">
          <Modal
            backdrop="static"
            show={this.state.show}
            onHide={this.close}
            size="xs"
          >
            <Modal.Header>
              <Modal.Title>
                {translate("hello")}{" "}
                {captializeFirstLetter(this.props.authenticated.firstname)}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{translate("welcome to wave")}</div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>

        <Row>
          <Col md="6" className="bg-white pt-3" style={{ height: "100vh" }}>
            <div style={{ width: "90%", margin: "auto" }}>
              <form onSubmit={this.send} id="signupqid">
                <div
                  className="saq-ovr"
                  style={{ overflowY: "auto", maxHeight: "77vh" }}
                >
                  <Row>
                    <Col>
                      <span className="h5">{translate("areUMedication")}</span>
                      <RadioGroup
                        name="radioList"
                        inline
                        onChange={(value, event) => {
                          this.setState({ medication: value });
                        }}
                      >
                        <Radio className="h5" value="yes">
                          {translate("yes")}
                        </Radio>
                        <Radio className="h5" value="no">
                          {translate("no")}
                        </Radio>
                      </RadioGroup>
                      <br />
                      {this.state.medication === "yes" ? (
                        <textarea
                          className="text-boxe"
                          name="medicationText"
                          placeholder="Description"
                        ></textarea>
                      ) : null}
                      <br />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <span className="h5">{translate("anyIllness")}</span>
                      <RadioGroup
                        name="radioList"
                        inline
                        onChange={(value, event) => {
                          this.setState({ illness: value });
                        }}
                      >
                        <Radio className="h5" value="yes">
                          {translate("yes")}
                        </Radio>
                        <Radio className="h5" value="no">
                          {translate("no")}
                        </Radio>
                      </RadioGroup>
                      <br />
                      {this.state.illness === "yes" ? (
                        <textarea
                          className="text-boxe"
                          name="illnessText"
                          placeholder="Description"
                        ></textarea>
                      ) : null}
                      <br />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <span className="h5">{translate("doUSuffer")}</span>
                      <RadioGroup
                        name="radioList"
                        inline
                        onChange={(value, event) => {
                          this.setState({ diabetes: value });
                        }}
                      >
                        <Radio className="h5" value="yes">
                          {translate("yes")}
                        </Radio>
                        <Radio className="h5" value="no">
                          {translate("no")}
                        </Radio>
                      </RadioGroup>
                      <br />
                      {this.state.diabetes === "yes" ? (
                        <textarea
                          className="text-boxe"
                          id="blood_sugar_level"
                          name="blood_sugar_level"
                          placeholder="Blood sugar level"
                        ></textarea>
                      ) : null}
                      <br />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <span className="h5">{translate("anyRespiratiory")}</span>
                      <RadioGroup
                        name="radioList"
                        inline
                        onChange={(value, event) => {
                          this.setState({ respiratory: value });
                        }}
                      >
                        <Radio className="h5" value="yes">
                          {translate("yes")}
                        </Radio>
                        <Radio className="h5" value="no">
                          {translate("no")}
                        </Radio>
                      </RadioGroup>
                      <br />
                      {this.state.respiratory === "yes" ? (
                        <textarea
                          className="text-boxe"
                          id="respiratoryId"
                          name="respiratoryText"
                          placeholder="Description"
                        ></textarea>
                      ) : null}
                      <br />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span className="h5">Blood pressure measurement</span>
                      <br />
                      <br />
                      <textarea
                        className="text-boxe"
                        id="blood_pressure_measurement"
                        name="blood_pressure_measurement"
                        placeholder="Blood pressure"
                      ></textarea>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <span className="h5">Current temperature</span>
                      <br />
                      <br />
                      <textarea
                        className="text-boxe"
                        id="current_body_temperature"
                        name="current_body_temperature"
                        placeholder="Current temperature"
                      ></textarea>
                      <br />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <span className="h5">Aches or Pains?</span>
                      <br />
                      <RadioGroup
                        name="radioList"
                        inline
                        onChange={(value, event) => {
                          this.setState({ pain_in_body: value });
                        }}
                      >
                        <Radio className="h5" value="yes">
                          {translate("yes")}
                        </Radio>
                        <Radio className="h5" value="no">
                          {translate("no")}
                        </Radio>
                      </RadioGroup>

                      <br />
                      {this.state.pain_in_body === "yes" ? (
                        <textarea
                          className="text-boxe"
                          name="area_of_pain_in_body"
                          placeholder="If Yes where in the body"
                        ></textarea>
                      ) : null}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <div className="col-sm">
                      <span className="h5">Consistent Cough?</span>
                      <br />
                      <RadioGroup
                        name="radioList"
                        inline
                        onChange={(value, event) => {
                          this.setState({ cough: value });
                        }}
                      >
                        <Radio className="h5" value="yes">
                          {translate("yes")}
                        </Radio>
                        <Radio className="h5" value="no">
                          {translate("no")}
                        </Radio>
                      </RadioGroup>
                    </div>
                    <div className="col-sm">
                      <span className="h5">Loss of Smell?</span>
                      <br />
                      <RadioGroup
                        name="radioList"
                        inline
                        onChange={(value, event) => {
                          this.setState({ loss_of_smell: value });
                        }}
                      >
                        <Radio className="h5" value="yes">
                          {translate("yes")}
                        </Radio>
                        <Radio className="h5" value="no">
                          {translate("no")}
                        </Radio>
                      </RadioGroup>
                    </div>
                  </Row>
                  <br />
                  <Row>
                    <div className="col-sm">
                      <span className="h5">Dizziness?</span>
                      <br />
                      <RadioGroup
                        name="radioList"
                        inline
                        onChange={(value, event) => {
                          this.setState({ dizziness: value });
                        }}
                      >
                        <Radio className="h5" value="yes">
                          {translate("yes")}
                        </Radio>
                        <Radio className="h5" value="no">
                          {translate("no")}
                        </Radio>
                      </RadioGroup>
                    </div>
                    <div className="col-sm">
                      <span className="h5">Loss of balance?</span>
                      <br />
                      <RadioGroup
                        name="radioList"
                        inline
                        onChange={(value, event) => {
                          this.setState({ loss_of_balance: value });
                        }}
                      >
                        <Radio className="h5" value="yes">
                          {translate("yes")}
                        </Radio>
                        <Radio className="h5" value="no">
                          {translate("no")}
                        </Radio>
                      </RadioGroup>
                    </div>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <span className="h5">
                        Have you travelled out of the country in the last 7
                        days?
                      </span>
                      <br />
                      <RadioGroup
                        name="radioList"
                        inline
                        onChange={(value, event) => {
                          this.setState({ travel_in_last_days: value });
                        }}
                      >
                        <Radio className="h5" value="yes">
                          {translate("yes")}
                        </Radio>
                        <Radio className="h5" value="no">
                          {translate("no")}
                        </Radio>
                      </RadioGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <span className="h5">
                        Any interaction with anyone that has just return from
                        overseas in the last 7days?{" "}
                      </span>
                      <br />
                      <RadioGroup
                        name="radioList"
                        inline
                        onChange={(value, event) => {
                          this.setState({ visitor_interacts: value });
                        }}
                      >
                        <Radio className="h5" value="yes">
                          {translate("yes")}
                        </Radio>
                        <Radio className="h5" value="no">
                          {translate("no")}
                        </Radio>
                      </RadioGroup>
                    </Col>
                  </Row>
                  <br />
                </div>
                {this.state.loader === true ? (
                  <Loader
                    type="TailSpin"
                    width={60}
                    height={60}
                    color="#2c6cb0"
                  />
                ) : (
                  <>
                    <button
                      className="buttons"
                      style={{
                        backgroundColor: "#2c6cb0",
                        color: "white",
                        position: "relative",
                        top: "32px",
                      }}
                    >
                      {translate("submit")}
                    </button>
                    <a
                      onClick={() => {
                        this.props.next();
                      }}
                      className="buttons"
                      style={{
                        color: "#2c6cb0",
                        float: "right",
                        position: "relative",
                        top: "32px",
                        cursor: "pointer",
                      }}
                    >
                      SKIP
                    </a>
                  </>
                )}
              </form>
            </div>
          </Col>
          <Col md="6" className="vital-img"></Col>
        </Row>
      </Container>
    );
  }
}
