import React from "react";
import { Row } from "react-bootstrap";
import waveLogo from "../../assets/images/fulllogo.png";
import "rsuite/dist/styles/rsuite-default.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import translate from "../../i18n/translation";

export default class PatientHome extends React.Component {
  render() {
    if (
      window.location.href.indexOf("https") == -1 &&
      window.location.href.indexOf("wavetelemedicine") > -1
    ) {
      window.location.href = "https://wavetelemedicine.com/";
    }
    return (
      <div className="container-fluid backimg2">
        <Row className="justify-content-center">
          <div className="col-10 col-sm-4 welcome-container">
            <Row>
              <div className="col-4 mt-3">
                <div
                  className="cur"
                  onClick={() => {
                    this.props.history.push("/");
                  }}
                >
                  <span>
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      color="#195773"
                      size="1x"
                    />
                  </span>
                  <span className="cl-19 px16 ml-2">Back</span>
                </div>
              </div>
            </Row>
            <Row className="justify-content-center">
              <div className="col-8 pt-4">
                <img src={waveLogo} alt="1" className="doc-images" />
              </div>
              <div className="col-8 px23 mt-4">
                {translate("select user type")}
              </div>
              <div className="col-8 green-btn mt-4">
                <div onClick={() => this.props.history.push("/urban")}>
                  {translate("urban user")}
                </div>
              </div>
              <div className="col-8 green-btn mt-4 ">
                <div onClick={() => this.props.history.push("/rural")}>
                  {translate("rural user")}
                </div>
              </div>
              <div className="col-8 green-btn mt-3 mb-5">
                <div onClick={() => this.props.history.push("/ngo")}>
                  Organisation
                </div>
              </div>
              {/* <div className="col-8 green-btn mt-3 mb-5">
                <div onClick={() => this.props.history.push("/userlogin")}>
                  User Panel
                </div>
              </div> */}
            </Row>
          </div>
        </Row>
      </div>
    );
  }
}
