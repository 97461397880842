import React from "react";
import LeftBar from "./leftBar";
import { Row, Col, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faArrowCircleLeft,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { postDataAuth, getAuth, postData } from "../../../services/request";

export default class UserPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      balance: "",
      wallet: [],
      wallet_id: "",
    };
  }
  componentDidMount = () => {
    var token = localStorage.getItem("med_token");
    getAuth("authenticated", token).then((response) => {
      // console.log("authenticated==", response);
      postDataAuth(
        "getWalletInfo",
        {
          email: response.data.user.email,
        },
        token
      ).then((response) => {
        // console.log("get walet==", response);
        sessionStorage.setItem("emailWallet", response[0].email);
        this.setState({
          name: response[0].name,
          email: response[0].email,
          wallet_id: response[0].wallet_id,
        });
      });
      //
      postDataAuth("getWalletTransactions", {
        email: response.data.user.email,
      }).then((response) => {
        // console.log("walettrans==", response);
        const sortArr = response.sort(function (a, b) {
          var dateA = new Date(a.created_at.toString()),
            dateB = new Date(b.created_at.toString());
          return dateA - dateB;
        });

        this.setState({
          wallet: sortArr,
        });
      });
      //
      postDataAuth(
        "getWalletBalance",
        {
          email: response.data.user.email,
        },
        token
      ).then((response) => {
        // console.log(response);
        this.setState({
          balance: response.balance,
        });
      });
    });
  };
  render() {
    return (
      <div className="container-fluid">
        <div className="row ">
          <div className="col-2 top-bo">
            <LeftBar history={this.props.history} />
          </div>
          <div className="col-10 top-bo bc-f5">
            <div className="row mt-3">
              <div className="col-4 user-header">
                Name:<span className="px20 ml-2">{this.state.name}</span>
              </div>
              <div className="col-4 user-header">
                Email:<span className="px20 ml-2">{this.state.email}</span>
              </div>
              <div className="col-4 user-header">
                Balance:<span className="px20 ml-2">{this.state.balance}</span>
              </div>
            </div>
            <div className="row page-size">
              <div className="col-12">
                <table class="table">
                  <thead className="boder-col">
                    <tr>
                      <th scope="col" className="">
                        #
                      </th>
                      <th scope="col" className="">
                        Date
                      </th>
                      <th scope="col" className="">
                        Type
                      </th>
                      <th scope="col" className="">
                        Ammount
                      </th>
                      <th scope="col" className="">
                        Balance
                      </th>
                    </tr>
                  </thead>
                  {this.state.wallet.map((data, idx) => (
                    <tbody key={idx}>
                      <tr>
                        <td className="">{idx + 1}</td>
                        <td className="">{data.created_at}</td>
                        <td className="">{data.transaction_type}</td>
                        <td className="">{data.amount}</td>
                        <td className="">{data.balance}</td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
