import React from "react";
import "rsuite/dist/styles/rsuite-default.css";
import translate from "../i18n/translation";
import Header from "../componenets/headers/Header";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import Image1 from "../assets/image1.png";
import vector1 from "../assets/Vector1.png";
import V2 from "../assets/Vector2.png";
import G1 from "../assets/Group1.png";
import V3 from "../assets/GroupHome.png";
import V4 from "../assets/Group-.png";
import V5 from "../assets/GroupCam.png";

export default class IndexComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  render() {
    if (
      window.location.href.indexOf("https") == -1 &&
      window.location.href.indexOf("wavetelemedicine") > -1
    ) {
      window.location.href = "https://wavetelemedicine.com/";
    }
    return (
      <>
        <Header />
        <div className="container-fluid desktop">
          <div className="row justify-content-center mt-5">
            <div className="col-5">
              <div className="row">
                <div className="agencyLine">
                  <div className="d-flex align-items-center p-1">
                    <img
                      src={vector1}
                      alt="v1"
                      className="mr-2"
                      style={{ height: "20px", width: "20px" }}
                    />
                    <span className="fz14 fw4">A Trusted Medical Solution</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="fz56 fw5 pr">
                  <span className="cl00">Consultation </span>
                  <span className="cl1A">
                    made <br /> easy at your comfort.
                  </span>
                  <span
                    className="pa"
                    style={{ right: "0px", bottom: "-30px" }}
                  >
                    <img src={V2} alt="v2"></img>
                  </span>
                </div>
              </div>
              <div className="row homeParagraph mt-4">
                <ul>
                  <li>{translate("platformInfo1")}</li>
                  <li>{translate("platformInfo2")}</li>
                  <li>{translate("platformInfo3")}</li>
                </ul>
              </div>
              <div className="row mt-5">
                <span>
                  <button
                    type="button"
                    className="bt-patient"
                    onClick={this.toggle}
                  >
                    {translate("patient")}
                  </button>
                </span>
                <Link to="/expert">
                  <span>
                    <button type="button" className="bt-medicalEx">
                      {translate("medical Expert")}
                    </button>
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-5">
              <img
                src={Image1}
                alt="doctor_image"
                style={{ borderRadius: "5px" }}
              ></img>
            </div>
          </div>
          <div className="row fz14 fw3 text-center mt-3">
            <div className="col">
              © 2020 WaveTelemedicine. All Rights Reserved
            </div>
          </div>
          <Modal isOpen={this.state.modal} toggle={this.toggle} className="">
            <ModalHeader toggle={this.toggle} className=""></ModalHeader>
            <ModalBody>
              <div className="row text-center mb-4">
                <div className="col-12">
                  <img src={G1} alt=""></img>
                </div>
                <div className="col-12 mt-2 fw4 fz20">
                  Select your user type below
                </div>
                <div className="col-12 mt-3">
                  <Link to="/urban-login">
                    <button type="button" className="bt-UR">
                      Urban User
                    </button>
                  </Link>
                  <Link to="/rural-login">
                    <button type="button" className="bt-UR">
                      Rural User
                    </button>
                  </Link>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
        {/* Mobile */}
        <div className="container-fluid mobile home1">
          <div className="row ">
            <div className="col-12 mt-4 pl-5">
              <div className="row">
                <div className="agencyLine">
                  <div className="d-flex align-items-center p-1">
                    <img
                      src={vector1}
                      alt="v1"
                      className="mr-2"
                      style={{ height: "20px", width: "20px" }}
                    />
                    <span className="fz14 fw4">A Trusted Medical Solution</span>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="fz36 fw5 pr">
                  <span className="cl00">Consultation </span>
                  <span className="clff">
                    made <br /> easy at your <br />
                    comfort.
                  </span>
                  <span
                    className="pa"
                    style={{ right: "0px", bottom: "-30px" }}
                  >
                    <img src={V2} alt="v2"></img>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <Modal isOpen={this.state.modal} toggle={this.toggle} className="">
            <ModalHeader toggle={this.toggle} className=""></ModalHeader>
            <ModalBody>
              <div className="row text-center mb-4">
                <div className="col-12">
                  <img src={G1} alt=""></img>
                </div>
                <div className="col-12 mt-2 fw4 fz20">
                  Select your user type below
                </div>
                <div className="col-12 mt-3">
                  <Link to="/urban-login">
                    <button type="button" className="bt-UR">
                      Urban User
                    </button>
                  </Link>
                  <Link to="/rural-login">
                    <button type="button" className="bt-UR mt-2">
                      Rural User
                    </button>
                  </Link>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
        <div className="container-fluid mobile mt-5">
          <div className="row  justify-content-center">
            <div className="col-11">
              <div className="row ">
                <div className="col-2">
                  <img src={V3} alt="v3"></img>
                </div>
                <div className="col-10">
                  The platform that connects patients with the top doctors in
                  the medical field from the convenience of their own homes.
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-2">
                  <img src={V4} alt="v4"></img>
                </div>
                <div className="col-10 ">
                  You can set up mobile payments or medical insurance through
                  the HMOs that work with us. We have you covered no matter what
                  your health concerns are.
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-2 ">
                  <img src={V5} alt="v5"></img>
                </div>
                <div className="col-10 ">
                  You can receive complete attention through our video
                  conferencing and have a prescription sent to a pharmacy
                  nearby. The Covid-19 situation has changed how we communicate,
                  which has changed how the medical industry operates.
                </div>
              </div>
              <div className="row mt-5 pl-3">
                <span>
                  <button
                    type="button"
                    className="bt-patient"
                    onClick={this.toggle}
                  >
                    {translate("patient")}
                  </button>
                </span>
                <Link to="/expert">
                  <span>
                    <button type="button" className="bt-medicalEx">
                      {translate("medical Expert")}
                    </button>
                  </span>
                </Link>
              </div>
              <div className="row fz14 fw3 text-center mt-3">
                <div className="col">
                  © 2020 WaveTelemedicine. All Rights Reserved
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
