import React from "react";
import Header2 from "../../componenets/headers/Header2";
import V3 from "../../assets/Vector3.png";
import "rsuite/dist/styles/rsuite-default.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Alert } from "rsuite";
import { postData } from "../../services/request";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import translate from "../../i18n/translation";

export default class RuralSignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      redirect: false,
      isBVNShow: false,
      isAgreedCheck: false,
      formEvent: null,
      firstname: "",
      lastname: "",
      email: "",
      dob: "",
      country: "",
      nin: "",
      user: "Patient",
      bvn: "",
      regNumber: null,
      password: "",
      confirm_password: "",
      phone: "",
    };
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
  }

  signup = (e) => {
    const formEvent = e.target;
    var details = {
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      email: this.state.email,
      country: this.state.country,
      dob: this.state.dob,
      user: "Patient",
      bvn: this.state.bvn,
      regNumber: this.state.nin,
      nin: this.state.nin,
      phone: this.state.phone,
      password: this.state.password,
      confirm_password: this.state.confirm_password,
    };
    if (details.firstname.trim() === "" || details.firstname.trim() === null) {
      Alert.warning("Input your first name");
    } else if (
      details.lastname.trim() === "" ||
      details.lastname.trim() === null
    ) {
      Alert.warning("Input your last name");
    } else if (details.email.trim() === "" || details.email.trim() === null) {
      Alert.warning("Input your email");
    } else if (details.country === "Select Country") {
      Alert.warning("Select a country");
    } else if (details.nin.trim() === "" || details.nin.trim() === null) {
      Alert.warning("Provide a valid NIN number");
    } else if (
      details.password.trim() === null ||
      details.password.trim() === ""
    ) {
      Alert.warning("Input your password");
    } else if (
      details.confirm_password.trim() === null ||
      details.confirm_password.trim() === ""
    ) {
      Alert.warning("Confirm your password");
    } else if (details.password.trim() !== details.confirm_password.trim()) {
      Alert.error("Passwords do not match");
    } else {
      this.setState({ loader: true });
      // postData("register", details)
      if (this.state.isBVNShow) {
        this.verifyUserByBVN(details);
      } else {
        postData("verify-nin", { nin: details.nin })
          .then((response) => {
            this.setState({ loader: false, formEvent: formEvent });
            if (response.info && response.info.length > 0) {
              Alert.success(
                "A verified NIN found, Validating User Information"
              );
              var data = response.info[0];
              let isDataValid = true; // make it false
              if (
                data.firstname &&
                details.firstname &&
                data.lastname &&
                details.lastname &&
                data.firstname.toLowerCase().trim() ==
                  details.firstname.toLowerCase().trim() &&
                data.lastname.toLowerCase().trim() ==
                  details.lastname.toLowerCase().trim() &&
                new Date(
                  data.Birth_date.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
                ) == new Date(details.dob)
              ) {
                isDataValid = true;
              }

              if (!isDataValid) {
                Alert.error(
                  "NIN Not Matched, please enter your BVN to register",
                  4000
                );
                // setTimeout(() => {window.location.href= "https://idverify.ng"}, 1000)
                this.setState({ isBVNShow: true, formEvent: formEvent });
                return false;
              }

              const dataObj = {
                firstname: data.firstname,
                lastname: data.lastname,
                email: data.NIN + "@telemedics.com",
                user: "Patient",
                reg_number: 0,
                nin: "",
                phone: details.phone,
                country: details.country,
                password: details.password,
                confirm_password: details.confirm_password,
                source: "nin",
              };
              this.registerUser(dataObj);
            } else {
              Alert.error("No NIN found, please enter your BVN to register");
              // setTimeout(() => {window.location.href= "https://idverify.ng"}, 1000)
              this.setState({ isBVNShow: true });
            }
          })
          .catch((error) => {
            this.setState({ loader: false });
            if (error.request.status === 422) {
              Alert.error(error.response.data.errors.email);
            } else if (error.request.status === 404) {
              Alert.error("No NIN found, please enter your BVN to register");
              this.setState({ isBVNShow: true });
            } else {
              Alert.error("Error creating account");
            }
          });
      }
    }
  };

  verifyUserByBVN = (details) => {
    postData("verify-bvn", { bvn: details.bvn })
      .then((response) => {
        this.setState({ loader: false });
        if (response && response.ResponseData !== null) {
          Alert.success("A verified BVN found, Validating User Information");
          var data = response.ResponseData;
          let isDataValid = false;
          if (
            data.FirstName &&
            details.firstname &&
            data.LastName &&
            details.lastname &&
            data.FirstName.toLowerCase().trim() ==
              details.firstname.toLowerCase().trim() &&
            data.LastName.toLowerCase().trim() ==
              details.lastname.toLowerCase().trim() &&
            new Date(data.DateOfBirth) == new Date(details.dob)
          ) {
            isDataValid = true;
          }

          if (!isDataValid) {
            Alert.error("BVN Not Matched, We can not proceed further", 4000);
            // setTimeout(() => {window.location.href= "https://idverify.ng"}, 1000)
            // this.setState({isBVNShow:true})
            return false;
          }
          this.state.formEvent.firstname.value = data.FirstName;
          this.state.formEvent.lastname.value = data.LastName;
          this.state.formEvent.email.value = data.email;
          this.state.formEvent.nin.value = data.nin;
          this.state.formEvent.country.value = data.nationality;
          this.state.formEvent.dob.value = data.DateOfBirth;
          const dataObj = {
            firstname: data.FirstName,
            lastname: data.LastName,
            email: data.email,
            user: "Patient",
            reg_number: 0,
            phone: details.phone,
            nin: "",
            country: data.nationality,
            password: details.password,
            confirm_password: details.confirm_password,
            source: "bvn",
          };
          this.registerUser(dataObj);
        } else {
          Alert.error("No BVN found, We Can Not Proceed with registration");
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loader: false });
        Alert.error("Error creating account");
      });
  };
  registerUser = (data) => {
    var details = data;
    postData("register", details)
      .then((response) => {
        this.setState({ loader: false });
        if (response.status === true) {
          Alert.success(response.message);
        } else {
          Alert.error(response.message);
        }
      })
      .catch((error) => {
        this.setState({ loader: false });
        if (error.request.status === 422) {
          Alert.error(error.response.data.errors.email);
        } else {
          Alert.error("Error creating account");
        }
      });

    this.registerWallet(details);
  };

  registerWallet(details) {
    postData("wallet-registration", {
      name: details.firstname + " " + details.lastname,
      email: details.email,
      password: details.password,
      user_type: "telemedics",
    })
      .then((response) => {
        if (response.status === true) {
          // Alert.success();
          console.log("success in creation of wallet", response);
        } else {
          console.log("error in creation of wallet");
          // Alert.error(response.message);
        }
      })
      .catch((error) => {
        // this.setState({ loader: false });
        // if (error.request.status === 422) {
        // Alert.error(error.response.data.errors.email);
        // } else {
        // Alert.error("Error creating account");
        // }
        console.log("error in creation of wallet");
      });
  }
  togglePasswordVisibility() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }
  render() {
    if (
      window.location.href.indexOf("https") == -1 &&
      window.location.href.indexOf("wavetelemedicine") > -1
    ) {
      window.location.href = "https://wavetelemedicine.com/";
    }
    return (
      <>
        <div className="container-fluid">
          <div className="row ">
            <div className="col-sm-6 head2">
              <Header2 />
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-10">
                    <div className="row">
                      <Link to="/" style={{ textDecoration: "none" }}>
                        <div className="back-btn">
                          <img
                            src={V3}
                            alt=""
                            style={{ paddingBottom: "4px" }}
                          ></img>
                          <span className="pl-3 fz20 fw4 cl1A">Back</span>
                        </div>
                      </Link>
                    </div>
                    <div className="row">
                      <div className=" mt-4">
                        <div className="fz32 fw5 cl1A">Create an account </div>
                        <div className="fz20 fw5">
                          <span>Sign up as a</span>
                          <span className="pl-2 cl00 fw5">Rural Patient</span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12  mt-4">
                        <div className="row fw4 fz20">
                          <div className="col-sm-4">First Name</div>
                          <div className="col-sm-8">
                            <input
                              type="text"
                              className="input-field"
                              placeholder="Enter your first name"
                              onChange={(e) => {
                                this.setState({
                                  firstname: e.target.value,
                                });
                              }}
                              value={this.state.firstname}
                            ></input>
                          </div>
                        </div>
                        <div className="row mt-3 fw4 fz20">
                          <div className="col-sm-4">Last Name</div>
                          <div className="col-sm-8">
                            <input
                              type="text"
                              className="input-field"
                              placeholder="Enter your last name"
                              onChange={(e) => {
                                this.setState({
                                  lastname: e.target.value,
                                });
                              }}
                              value={this.state.lastname}
                            ></input>
                          </div>
                        </div>
                        <div className="row mt-3 fw4 fz20">
                          <div className="col-sm-4">Email</div>
                          <div className="col-sm-8">
                            <input
                              type="email"
                              className="input-field"
                              placeholder="Enter your email"
                              onChange={(e) => {
                                this.setState({
                                  email: e.target.value,
                                });
                              }}
                              value={this.state.email}
                            ></input>
                          </div>
                        </div>
                        <div className="row mt-3 fw4 fz20">
                          <div className="col-sm-4">Date of Birth</div>
                          <div className="col-sm-8">
                            <input
                              type="date"
                              className="input-field"
                              placeholder="mm/dd/yyyy"
                              onChange={(e) => {
                                this.setState({
                                  dob: e.target.value,
                                });
                              }}
                              value={this.state.dob}
                            ></input>
                          </div>
                        </div>
                        <div className="row mt-3 fw4 fz20">
                          <div className="col-sm-4">Phone Number</div>
                          <div className="col-sm-8">
                            <input
                              type="number"
                              className="input-field"
                              placeholder="Enter your phone number"
                            ></input>
                          </div>
                        </div>
                        <div className="row mt-3 fw4 fz20">
                          <div className="col-sm-4">Country</div>
                          <div className="col-sm-8">
                            <select
                              className="input-field"
                              name="country"
                              id="country"
                              onChange={(e) => {
                                this.setState({
                                  country: e.target.value,
                                });
                              }}
                              value={this.state.country}
                            >
                              <option className="input-field"></option>
                              <option value="Nigeria">Nigeria</option>
                              <option value="United Kingdom">
                                United Kingdom
                              </option>
                              <option value="USA">USA</option>
                              <option value="Kenya">Kenya</option>
                              <option value="Zambia">Zambia</option>
                              <option value="Ghana">Ghana</option>
                            </select>
                          </div>
                        </div>
                        <div className="row mt-3 fw4 fz20">
                          <div className="col-sm-4">NIN</div>
                          <div className="col-sm-8">
                            <input
                              type="number"
                              className="input-field"
                              placeholder="Enter your NIN"
                              onChange={(e) => {
                                this.setState({
                                  nin: e.target.value,
                                });
                              }}
                              value={this.state.nin}
                            ></input>
                          </div>
                        </div>
                        <div className="row mt-3 fw4 fz20">
                          <div className="col-sm-4">Password</div>
                          <div className="col-sm-8">
                            <div style={{ position: "relative" }}>
                              <input
                                type={
                                  this.state.showPassword ? "text" : "password"
                                }
                                className="input-field"
                                placeholder="Enter your password"
                                onChange={(e) => {
                                  this.setState({
                                    password: e.target.value,
                                  });
                                }}
                                value={this.state.password}
                              ></input>
                              <FontAwesomeIcon
                                onClick={this.togglePasswordVisibility}
                                icon={faEye}
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  backgroundColor: "transparent",
                                  border: "none",
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3 fw4 fz20">
                          <div className="col-sm-4">Confirm Password</div>
                          <div className="col-sm-8">
                            <input
                              type="password"
                              className="input-field"
                              placeholder="Enter your password"
                              onChange={(e) => {
                                this.setState({
                                  confirm_password: e.target.value,
                                });
                              }}
                              value={this.state.confirm_password}
                            ></input>
                          </div>
                        </div>
                        <div className="row mt-3 forgetPaswd ">
                          <div className="col-12 text-end">
                            Forgot your Password?
                          </div>
                        </div>
                        <div className="row mt-3 uderline"></div>
                        <div className="row justify-content-between mt-3">
                          <div className="col-6">
                            <span style={{ paddingTop: "10px" }}>
                              <input
                                type="checkbox"
                                className="form-check-input"
                              ></input>
                            </span>
                            <span style={{ paddingTop: "10px" }}>
                              {" "}
                              Remember me
                            </span>
                          </div>
                          <div className="col-6 text-right">
                            <button
                              type="button"
                              className="bt-patient"
                              onClick={this.signup}
                              disabled={this.state.loader}
                            >
                              {this.state.loader
                                ? "signup..."
                                : translate("Signup")}
                            </button>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-12 text-center">
                            <span className="fw3 fz14 m-auto">
                              Already have an account?
                            </span>
                            <Link to="/rural-login">
                              <span className="fz14 fw5 cl00">Login</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 img5 desktop"></div>
          </div>
        </div>
      </>
    );
  }
}
