import React from "react";
import Edit from "../../assets/admin/edit.png";
import { Avatar } from "@material-ui/core";
import getInitials from "../../utils/getInitials";
const PersonDetail = () => {
  return (
    <>
      <div className="row justify-content-center mt-3">
        <div className="col-11 pt-3 bc-ff bd20 pb-4">
          <div className="row justify-content-between">
            <div className="col-4 ">
              <Avatar sx={{ mr: 2 }}>{getInitials("Shah xee")}</Avatar>
            </div>
            <div className="col-2">
              <div className="edit-box">
                <span>
                  <img src={Edit} alt="Edit"></img>
                </span>
                <span className="edit-text">Edit Profile</span>
              </div>
            </div>
          </div>
          <div className="row pl-3 mt-3">
            <div className="col-12">
              <div className="row">
                <span className="person-text">Name :</span>
                <span className="person-info"></span>
              </div>
              <div className="row">
                <span className="person-text">Gender : :</span>
                <span className="person-info"></span>
              </div>
              <div className="row">
                <span className="person-text">Email :</span>
                <span className="person-info"></span>
              </div>
              <div className="row">
                <span className="person-text">Phone Number :</span>
                <span className="person-info"></span>
              </div>
              <div className="row">
                <span className="person-text">Country :</span>
                <span className="person-info"></span>
              </div>
              <div className="row">
                <span className="person-text">Type of patient :</span>
                <span className="person-info"></span>
              </div>
              <div className="row">
                <span className="person-text">Transaction ID :</span>
                <span className="person-info"></span>
              </div>
              <div className="row">
                <span className="person-text">Subscription Plan :</span>
                <span className="person-info"></span>
              </div>
              <div className="row">
                <span className="person-text">Nam of Pharmacy :</span>
                <span className="person-info"></span>
              </div>
              <div className="row">
                <span className="person-text">Platform :</span>
                <span className="person-info"></span>
              </div>
              <div className="row">
                <span className="person-text">Cost/Transaction :</span>
                <span className="person-info"></span>
              </div>
              <div className="row">
                <span className="person-text">Discount :</span>
                <span className="person-info"></span>
              </div>
              <div className="row">
                <span className="person-text">Status :</span>
                <span className="person-info"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonDetail;
