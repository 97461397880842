import React from "react";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { googleTranslate } from "../utils/googleTranslate";
import {
  getAuth,
  fetchData,
  postDataAuth,
  fetchDataAuth,
} from "../services/request";
import Header2 from "../componenets/headers/Header2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft, faEye } from "@fortawesome/free-solid-svg-icons";
import { captializeFirstLetter } from "./common";
import { faEllipsisV, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { NoChat } from "./pieces";
import io from "socket.io-client";
import { Container, Row, Col } from "react-bootstrap";
import { Alert, Modal, Button, Drawer } from "rsuite";
import { Redirect } from "react-router-dom";
import Call from "./call";

import translate from "../i18n/translation";

import camera from "../assets/images/camera.png";
import sendMessage from "../assets/SendVector.png";
import imageInbox from "../assets/images/Image-inbox.png";
import Phone from "../assets/Phone.png";
import Video from "../assets/Video.png";

const profileBase = "https://telemedicine-wave.s3.us-east-2.amazonaws.com/";

export default class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: [],
      doctors: [],
      assignedDoctor: null,
      socketConnection: null,
      messages: [],
      sentMessage: false,
      show: false,
      showD: true,
      showMenu: false,
      call: false,
      profile: [],
      showProfile: false,
      docProfile: "",
      languageCodes: [],
      language: sessionStorage.getItem("language")
        ? sessionStorage.getItem("language")
        : "en",
      dtyping: false,
      suggestions: [
        "Hello Doc",
        "I have the Flu",
        "I have Sore throat",
        "I have Stomach Cramps",
      ],
      suggestions2: [
        "I feel Dizzy",
        "I feel unwell",
        "I have a Headache",
        "I am feeling Feverish",
      ],
    };
  }

  showprofile = () => {
    this.setState({ showProfile: true });
    this.closeMenu();
  };
  closeprofile = () => {
    this.setState({ showProfile: false });
  };
  showDprofile = () => {
    this.setState({ showDoctorProfile: true });
    this.closeMenu();
  };
  closeDprofile = () => {
    this.setState({ showDoctorProfile: false });
  };
  showChat = () => {
    this.setState({ call: false });
  };

  showMenu = () => {
    this.setState({ showMenu: true });
  };
  closeMenu = () => {
    this.setState({ showMenu: false });
  };

  show = () => {
    this.setState({ show: true });
  };
  closeFinally = () => {
    this.setState({ show: false });
  };
  close = () => {
    // this.setState({show:false,showD:true},()=>{
    //     this.startcall()
    // })
    this.setState({ call: true });
  };
  closeD = () => {
    this.setState({ showD: false });
  };
  componentDidMount = () => {
    var token = localStorage.getItem("med_token");
    getAuth("authenticated", token)
      .then((response) => {
        this.setState({ authenticated: response.data.user }, () => {
          this.getUserProfile();
          postDataAuth(
            "assignedDoc",
            { id: this.state.authenticated.assigned },
            token
          )
            .then((response) => {
              if (response.status === true) {
                this.setState({ assignedDoctor: response.doc }, () => {
                  this.savepaitentHistory();
                  this.joinRoom();
                  this.joinActiveRoom();
                  this.getUserDocProfile();
                  var objDiv = document.getElementById("patient-chat");
                  objDiv.scrollTop = objDiv.scrollHeight;
                });
              } else {
                Alert.error("Unable to fetch assigned doctor");
              }
            })
            .catch(() => {
              Alert.error("Unable to fetch assigned doctor");
            });
        });
      })
      .catch(() => {
        Alert.error("Unable to fetch your details");
      });
    // load all of the language options from Google Translate to your app state
    googleTranslate.getSupportedLanguages("en", function (err, languageCodes) {
      getLanguageCodes(languageCodes); // use a callback function to setState
    });
    const getLanguageCodes = (languageCodes) => {
      this.setState({ languageCodes });
    };
  };
  getUserDocProfile = () => {
    var token = localStorage.getItem("med_token");
    var patient = this.state.assignedDoctor.id;
    fetchDataAuth("getDoctorProfile", patient, token)
      .then((response) => {
        if (response.status === true) {
          this.setState({
            docProfile: response.profile,
          });
        }
      })
      .catch(() => {
        Alert.error("Error fetching your patient profile");
      });
  };
  savepaitentHistory = () => {
    var token = localStorage.getItem("med_token");
    postDataAuth(
      "patient-history",
      {
        patientId: this.state.authenticated.id,
        doctorId: this.state.authenticated.assigned,
      },
      token
    )
      .then((response) => {
        if (response.status === true) {
          console.log(response.details);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getUserProfile = () => {
    var token = localStorage.getItem("med_token");
    fetchDataAuth("getPatientProfile", {}, token)
      .then((response) => {
        if (response.status === true) {
          this.setState({
            profile: response.profile,
          });
        }
      })
      .catch(() => {
        Alert.error("Error fetching your profile");
      });
  };
  componentWillMount = () => {
    const socket = io("https://telemedicine-server.herokuapp.com/");
    // const socket = io('http://localhost:2000/');
    socket.on("connect", function () {
      console.log("connected to " + socket.id);
    });
    this.setState(
      {
        socketConnection: socket,
      },
      () => {
        this.state.socketConnection.on("response", async (response) => {
          if (response && response.type !== "patient") {
            const msg = await this._translateText(response);
          }
          this.setState(
            {
              messages: [...this.state.messages, response],
            },
            () => {
              var objDiv = document.getElementById("patient-chat");
              objDiv.scrollTop = objDiv.scrollHeight;
            }
          );
        });

        this.state.socketConnection.on("allowCall", () => {
          this.show();
        });

        this.state.socketConnection.on("dtyping", () => {
          // setTimeout(() => {
          this.setState({ dtyping: true });
          // }, 2000);
        });

        this.state.socketConnection.on("dNtyping", () => {
          // setTimeout(() => {
          this.setState({ dtyping: false });
          // },2000);
        });
      }
    );
  };

  _translateText = (textObj) => {
    // check the selected language
    // check the incoming text and langugae
    // if selected and incoming msg has same langague by pass
    // else translate and print the translated text
    return textObj.message;
    // return new Promise((resolve, reject) => {

    //   try {
    //     const language = sessionStorage.getItem("language")
    //       ? sessionStorage.getItem("language")
    //       : "en";
    //     let transQuestion = null;
    //     debugger;
    //     if (textObj.langauge !== language) {
    //       const translating = (transQuestion) => {
    //         this.setState({ question: transQuestion });
    //       };
    //       googleTranslate.translate(
    //         textObj.message,
    //         language,
    //         function (err, translation) {
    //           transQuestion = translation.translatedText;
    //           //translating(transQuestion);
    //           textObj["message"] = transQuestion;
    //           resolve(textObj);
    //         }
    //       );
    //     }
    //   } catch (error) {
    //     console.error(error);
    //     reject(false);
    //   }
    // });
  };

  fetchMessage = () => {
    var token = localStorage.getItem("med_token");
    var details = {
      receiver: this.state.assignedDoctor.id,
    };
    postDataAuth("getMessage", details, token)
      .then((response) => {
        this.setState(
          {
            messages: response.messages,
          },
          () => {
            console.log(this.state.messages);
          }
        );
      })
      .catch(() => {});
  };

  joinRoom = () => {
    var room = this.state.assignedDoctor.id + "&" + this.state.authenticated.id;
    this.state.socketConnection.emit("join", { room: room });
  };
  joinActiveRoom = () => {
    var room = this.state.assignedDoctor.id + "Wavemedics";
    this.state.socketConnection.emit("joinActiveRoom", { room: room }, () => {
      this.sendSelf();
    });
  };
  sendSelf = () => {
    this.state.socketConnection.emit("paired", {
      patient: this.state.authenticated,
      room: this.state.assignedDoctor.id + "Wavemedics",
    });
  };
  sendMessage = (e, message) => {
    if (!message) {
      e.preventDefault();
      var details = {
        room: this.state.assignedDoctor.id + "&" + this.state.authenticated.id,
        message: e.target.message.value,
        senderId: this.state.authenticated.id,
        receiverId: this.state.assignedDoctor.id,
        type: "patient",
        langauge: "yo",
      };
      e.target.message.value = "";
    } else {
      var details = {
        room: this.state.assignedDoctor.id + "&" + this.state.authenticated.id,
        message: message,
        senderId: this.state.authenticated.id,
        receiverId: this.state.assignedDoctor.id,
      };
    }
    this.state.socketConnection.emit("sendMessage", details, () => {});
  };
  // .............
  sendMessage2 = (mes) => {
    var details = {
      room: this.state.assignedDoctor.id + "&" + this.state.authenticated.id,
      message: mes,
      senderId: this.state.authenticated.id,
      receiverId: this.state.assignedDoctor.id,
      type: "patient",
      langauge: "yo",
    };
    this.state.socketConnection.emit("sendMessage", details, () => {});
  };
  // ............
  startcall = () => {
    var meet = new window.JitsiMeet("https://meet.covid19telemedics.com");

    var meetingName =
      this.state.assignedDoctor.firstname +
      " and " +
      this.state.authenticated.firstname;

    meet.on("ready", function () {
      var interfaceConfig = {
        TOOLBAR_BUTTONS: [
          "microphone",
          "camera",
          "desktop",
          "fullscreen",
          "hangup",
          "settings",
        ],
        SETTINGS_SECTIONS: ["language", "devices"],
        DEFAULT_REMOTE_DISPLAY_NAME: "John Doe",
      };
      var options = {};
      options.interfaceConfig = interfaceConfig;
      try {
        console.log(meetingName);
        var conference = meet.join(meetingName, "#patient-call", options);
        conference.on("joined", function () {});
      } catch (error) {
        console.log(error);
      }
    });
  };

  logout = () => {
    var token = localStorage.getItem("med_token");
    Alert.success("Logging you out.....");
    this.sendMessage(null, "loggingout");
    postDataAuth("logout", { id: this.state.assignedDoctor.id }, token)
      .then((response) => {
        if (response.status === true) {
          this.setState({ loggedIn: false }, () => {
            setTimeout(() => {
              localStorage.removeItem("med_token");
            }, 1000);
          });
        } else {
          Alert.error("Unable to log you out");
        }
      })
      .catch(() => {
        Alert.error("Error logging you out");
      });
  };

  typing = () => {
    var details = {
      room: this.state.assignedDoctor.id + "&" + this.state.authenticated.id,
    };
    this.state.socketConnection.emit("patient_typing", details);
  };
  notTyping = () => {
    var details = {
      room: this.state.assignedDoctor.id + "&" + this.state.authenticated.id,
    };
    setTimeout(() => {
      this.state.socketConnection.emit("patient_not_typing", details);
    }, 2000);
  };

  _goBack = (e) => {
    this.props.backButton();
  };
  render() {
    if (this.state.loggedIn === false) {
      return <Redirect to="/" />;
    }
    var msg = this.state.messages.map((item, key) => {
      return (
        <Row>
          <Col xs={12}>
            <div
              className={
                item.senderId === this.state.authenticated.id
                  ? "bubble sender  bg-white"
                  : "bubble receiver  bg-white"
              }
              key={key}
            >
              {item.senderId === this.state.authenticated.id ? (
                <div className="chatowner float-right">
                  <img
                    alt=""
                    className="profile-pic rounded"
                    style={{ width: "50px", height: "50px" }}
                    src={
                      this.state.profile.picture.indexOf(
                        "telemedicine-wave.s3"
                      ) > -1
                        ? this.state.profile.picture
                        : "https://telemedicine-wave.s3.us-east-2.amazonaws.com/" +
                          this.state.profile.picture
                    }
                  />
                  {/* {this.state.authenticated.firstname} */}
                </div>
              ) : (
                <div className="chatowner">
                  <img
                    alt=""
                    className="profile-pic rounded"
                    style={{ width: "50px", height: "50px" }}
                    src={
                      this.state.docProfile.picture.indexOf(
                        "telemedicine-wave.s3"
                      ) > -1
                        ? this.state.docProfile.picture
                        : "https://telemedicine-wave.s3.us-east-2.amazonaws.com/" +
                          this.state.docProfile.picture
                    }
                  />
                  {/* {this.state.assignedDoctor.firstname} */}
                </div>
              )}
              <div
                className={
                  item.senderId === this.state.authenticated.id
                    ? "message sender text-dark p-2 float-left doc-panel"
                    : "message receiver text-dark p-2  doc-panel"
                }
              >
                {item.message}{" "}
              </div>
            </div>
          </Col>
        </Row>
      );
    });

    return (
      <Container className="container-fluid cont" fluid>
        <Header2 />
        {/* <Navbar bg="white" className="shadow-sm p-0">
          <Navbar.Brand href="#home" className="navbar-brand m-0 p-0">
          <img src={waveLogo} alt="1" className="doc-images shadow p-3" style={{width:"42.8%"}}/>
          </Navbar.Brand>
        
        </Navbar> */}
        {this.state.call === true ? (
          <Call
            authenticated={this.state.authenticated}
            assignedDoctor={this.state.assignedDoctor}
            socketConnection={this.state.socketConnection}
            from="Patient"
            backToChat={this.showChat}
          />
        ) : (
          <div>
            {/* profile modal */}
            <Modal onHide={this.closeprofile}>
              <Modal.Header>
                <Modal.Title>Profile</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="profileBox">
                  <span>
                    <b>First Name: </b>
                    {this.state.authenticated.firstname}{" "}
                  </span>{" "}
                  <br />
                  <span>
                    <b>Last Name: </b>
                    {this.state.authenticated.lastname}{" "}
                  </span>{" "}
                  <br />
                  <span>
                    <b>Genotype: </b>
                    {this.state.profile.genotype}{" "}
                  </span>{" "}
                  <br />
                  <span>
                    <b>Blood Group: </b>
                    {this.state.profile.blood_group}{" "}
                  </span>{" "}
                  <br />
                  <span>
                    <b>Date of Birth: </b>
                    {this.state.profile.date_of_birth}{" "}
                  </span>{" "}
                  <br />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={this.closeprofile} appearance="primary">
                  Ok
                </Button>
                <Button onClick={this.closeprofile} appearance="subtle">
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
            {/* end of profile modal */}
            {/* Menu drawer */}
            <Drawer
              show={this.state.showMenu}
              onHide={this.closeMenu}
              size="xs"
              placement="left"
            >
              <Drawer.Header>
                <Drawer.Title>Menu</Drawer.Title>
              </Drawer.Header>
              <Drawer.Body>
                <hr />
                <div onClick={this.showprofile} className="menu-items">
                  Patient Profile
                </div>
                <br />
                <div onClick={this.showDprofile} className="menu-items">
                  Doctor Information
                </div>
                <br />
                <div className="menu-items" onClick={this.logout}>
                  <FontAwesomeIcon icon={faPowerOff} /> Logout
                </div>
                <br />
              </Drawer.Body>
              <Drawer.Footer>
                {/* <Button onClick={this.closeMenu} appearance="primary">Confirm</Button>
               <Button onClick={this.closeMenu} appearance="subtle">Cancel</Button> */}
              </Drawer.Footer>
            </Drawer>
            {/* menu drawer ends here */}
            {/* Accept call modal */}
            <Modal size="xs" show={this.state.show} onHide={this.close}>
              <Modal.Header>
                {this.state.assignedDoctor && (
                  <Modal.Title>
                    {captializeFirstLetter(
                      this.state.assignedDoctor.firstname
                    ) +
                      " " +
                      captializeFirstLetter(
                        this.state.assignedDoctor.lastname
                      )}{" "}
                    has requested you join a video conference{" "}
                  </Modal.Title>
                )}
              </Modal.Header>
              <Modal.Body>
                <p style={{ fontWeight: "bold" }}>
                  Allow video call from doctor?
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={this.close} appearance="primary">
                  Ok
                </Button>
                <Button appearance="subtle" onClick={this.closeFinally}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
            {/* modal ends here */}

            {/* Patient Profile in responsive */}
            <Drawer
              show={this.state.showProfile}
              onHide={this.closeprofile}
              size="xs"
              className="responsive-drawer"
              placement="left"
            >
              <Drawer.Header>
                <Drawer.Title>{translate("patientProfile")}</Drawer.Title>
              </Drawer.Header>
              <Drawer.Body>
                {/* <div id="patientProfileHeader" style={{backgroundColor: '#EBEBEB', height: 60, marginBottom: 30, paddingTop: 15, textAlign: 'center'}}>
                      <span className="h4 font-weight-bold" style={{color: 'black'}}>{translate('patientProfile')}</span>
                    </div> */}

                {/* <hr /> */}
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginBottom: 40,
                  }}
                >
                  Start Converstion With
                  <img
                    className="profile-pic"
                    src={profileBase + this.state.profile.picture}
                    alt="patient"
                    style={{ width: 112, height: 112 }}
                  />
                  <br />
                  {/* <Button className="btn darkblue-btn mt-2">Refer Patient</Button> */}
                  <br />
                  <div className="userheader2">
                    {" "}
                    {this.state.authenticated !== null ? (
                      <p>
                        {captializeFirstLetter(
                          this.state.authenticated.firstname
                        ) +
                          " " +
                          captializeFirstLetter(
                            this.state.authenticated.lastname
                          )}
                      </p>
                    ) : null}
                  </div>
                  {/* {this.state.authenticated !== null ? (
                    <p
                      style={{ marginTop: 15, marginBottom: 15, fontSize: 20 }}
                    >
                      {captializeFirstLetter(
                        this.state.authenticated.firstname
                      ) +
                        " " +
                        captializeFirstLetter(
                          this.state.authenticated.lastname
                        )}
                    </p>
                  ) : null} */}
                </div>
                <div className="userDetail ml-5 fz14 mt-4">
                  <div className="mt-2">
                    {translate("dob")} :{" "}
                    <b>
                      {this.state.profile.date_of_birth
                        ? this.state.profile.date_of_birth
                        : "N/A"}
                    </b>
                  </div>
                  <div className="mt-2">
                    {translate("bloodGroup")}:{" "}
                    <b>
                      {this.state.profile.blood_group
                        ? this.state.profile.blood_group
                        : "N/A"}
                    </b>
                  </div>

                  <div className="mt-2">
                    {translate("genotype")} :{" "}
                    <b>
                      {this.state.profile.genotype
                        ? this.state.profile.genotype
                        : "N/A"}
                    </b>
                  </div>
                  <div className="mt-2">
                    {translate("anitbodies")}:{" "}
                    <b>
                      {this.state.profile.antibodies
                        ? this.state.profile.antibodies
                        : "N/A"}
                    </b>
                  </div>
                  <div className="mt-2">
                    {translate("currentIllnes")}:{" "}
                    <b>
                      {this.state.profile.illness
                        ? this.state.profile.illness
                        : "N/A"}
                    </b>
                  </div>
                  <div className="mt-2">
                    {translate("anyRespiratory")} :
                    <b>
                      {this.state.profile.respiratory
                        ? captializeFirstLetter(this.state.profile.respiratory)
                        : "N/A"}
                    </b>
                  </div>
                  <div className="mt-2">
                    {translate("diabetic")}:
                    <b>
                      {this.state.profile.diabetes
                        ? captializeFirstLetter(this.state.profile.diabetes)
                        : "N/A"}
                    </b>
                  </div>
                  <div className="mt-2">
                    {translate("familyMedical")}:
                    <b>
                      {this.state.profile.family_medical_history
                        ? this.state.profile.family_medical_history
                        : "N/A"}
                    </b>
                  </div>
                </div>
                {/* <div
                  style={{
                    paddingLeft: 10,
                    paddingRight: 10,
                    overflowY: "auto",
                  }}
                >
                  <div
                    style={{ marginBottom: 15 }}
                    className="font-weight-light"
                  >
                    <p style={{ fontSize: "19px" }}>
                      {translate("dob")} :
                      <br />
                      <strong className="pl-4">
                        {this.state.profile.date_of_birth
                          ? this.state.profile.date_of_birth
                          : "N/A"}
                      </strong>
                    </p>
                  </div>
                  <div
                    style={{ marginBottom: 15 }}
                    className="font-weight-light"
                  >
                    <p style={{ fontSize: "19px" }}>
                      {translate("bloodGroup")}:
                      <br />
                      <strong className="pl-4">
                        {this.state.profile.blood_group
                          ? this.state.profile.blood_group
                          : "N/A"}
                      </strong>
                    </p>
                  </div>
                  <div
                    style={{ marginBottom: 15 }}
                    className="font-weight-light"
                  >
                    <p style={{ fontSize: "19px" }}>
                      {translate("genotype")} :
                      <br />
                      <strong className="pl-4">
                        {this.state.profile.genotype
                          ? this.state.profile.genotype
                          : "N/A"}
                      </strong>
                    </p>
                  </div>
                  <div
                    style={{ marginBottom: 15 }}
                    className="font-weight-light"
                  >
                    <p style={{ fontSize: "19px" }}>
                      {translate("anitbodies")}:
                      <br />
                      <strong className="pl-4">
                        {this.state.profile.antibodies
                          ? this.state.profile.antibodies
                          : "N/A"}
                      </strong>
                    </p>
                  </div>
                  <div
                    style={{ marginBottom: 15 }}
                    className="font-weight-light"
                  >
                    <p style={{ fontSize: "19px" }}>
                      {translate("currentIllnes")}:
                      <br />
                      <strong className="pl-4">
                        {this.state.profile.illness
                          ? this.state.profile.illness
                          : "N/A"}
                      </strong>
                    </p>
                  </div>
                  <div
                    style={{ marginBottom: 15 }}
                    className="font-weight-light"
                  >
                    <p style={{ fontSize: "19px" }}>
                      {translate("anyRespiratory")} :
                      <br />
                      <strong className="pl-4">
                        {this.state.profile.respiratory
                          ? captializeFirstLetter(
                              this.state.profile.respiratory
                            )
                          : "N/A"}
                      </strong>
                    </p>
                  </div>

                  <div
                    style={{ marginBottom: 15 }}
                    className="font-weight-light"
                  >
                    <p style={{ fontSize: "19px" }}>
                      {translate("diabetic")}:
                      <br />
                      <strong className="pl-4">
                        {this.state.profile.diabetes
                          ? captializeFirstLetter(this.state.profile.diabetes)
                          : "N/A"}
                      </strong>
                    </p>
                  </div>
                  <div
                    style={{ marginBottom: 15 }}
                    className="font-weight-light"
                  >
                    <p style={{ fontSize: "19px" }}>
                      {translate("familyMedical")}:
                      <br />
                      <strong className="pl-4">
                        {this.state.profile.family_medical_history
                          ? this.state.profile.family_medical_history
                          : "N/A"}
                      </strong>
                    </p>
                  </div>
                </div> */}
              </Drawer.Body>
              <Drawer.Footer></Drawer.Footer>
            </Drawer>
            <Drawer
              show={this.state.showProfile}
              onHide={this.closeprofile}
              size="xs"
              className="responsive-drawer"
              placement="left"
            >
              <Drawer.Header>
                <Drawer.Title>{translate("patientProfile")}</Drawer.Title>
              </Drawer.Header>
              <Drawer.Body>
                {/* <div id="patientProfileHeader" style={{backgroundColor: '#EBEBEB', height: 60, marginBottom: 30, paddingTop: 15, textAlign: 'center'}}>
                      <span className="h4 font-weight-bold" style={{color: 'black'}}>{translate('patientProfile')}</span>
                    </div> */}

                {/* <hr /> */}
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginBottom: 40,
                  }}
                >
                  <img
                    className="profile-pic"
                    src={this.state.profile.picture}
                    alt="patient"
                    style={{ width: 112, height: 112 }}
                  />
                  <br />
                  {/* <Button className="btn darkblue-btn mt-2">Refer Patient</Button> */}
                  <br />
                  <div className="userheader2">
                    {" "}
                    {this.state.authenticated !== null ? (
                      <p>
                        {captializeFirstLetter(
                          this.state.authenticated.firstname
                        ) +
                          " " +
                          captializeFirstLetter(
                            this.state.authenticated.lastname
                          )}
                      </p>
                    ) : null}
                  </div>
                  {/* {this.state.authenticated !== null ? (
                    <p
                      style={{ marginTop: 15, marginBottom: 15, fontSize: 20 }}
                    >
                      {captializeFirstLetter(
                        this.state.authenticated.firstname
                      ) +
                        " " +
                        captializeFirstLetter(
                          this.state.authenticated.lastname
                        )}
                    </p>
                  ) : null} */}
                </div>
                <div className="userDetail ml-5 fz14 mt-4">
                  <div className="mt-2">
                    {translate("dob")} :{" "}
                    <b>
                      {this.state.profile.date_of_birth
                        ? this.state.profile.date_of_birth
                        : "N/A"}
                    </b>
                  </div>
                  <div className="mt-2">
                    {translate("bloodGroup")}:{" "}
                    <b>
                      {this.state.profile.blood_group
                        ? this.state.profile.blood_group
                        : "N/A"}
                    </b>
                  </div>

                  <div className="mt-2">
                    {translate("genotype")} :{" "}
                    <b>
                      {this.state.profile.genotype
                        ? this.state.profile.genotype
                        : "N/A"}
                    </b>
                  </div>
                  <div className="mt-2">
                    {translate("anitbodies")}:{" "}
                    <b>
                      {this.state.profile.antibodies
                        ? this.state.profile.antibodies
                        : "N/A"}
                    </b>
                  </div>
                  <div className="mt-2">
                    {translate("currentIllnes")}:{" "}
                    <b>
                      {this.state.profile.illness
                        ? this.state.profile.illness
                        : "N/A"}
                    </b>
                  </div>
                  <div className="mt-2">
                    {translate("anyRespiratory")} :
                    <b>
                      {this.state.profile.respiratory
                        ? captializeFirstLetter(this.state.profile.respiratory)
                        : "N/A"}
                    </b>
                  </div>
                  <div className="mt-2">
                    {translate("diabetic")}:
                    <b>
                      {this.state.profile.diabetes
                        ? captializeFirstLetter(this.state.profile.diabetes)
                        : "N/A"}
                    </b>
                  </div>
                  <div className="mt-2">
                    {translate("familyMedical")}:
                    <b>
                      {this.state.profile.family_medical_history
                        ? this.state.profile.family_medical_history
                        : "N/A"}
                    </b>
                  </div>
                </div>
                {/* <div
                  style={{
                    paddingLeft: 10,
                    paddingRight: 10,
                    overflowY: "auto",
                  }}
                >
                  <div
                    style={{ marginBottom: 15 }}
                    className="font-weight-light"
                  >
                    <p style={{ fontSize: "19px" }}>
                      {translate("dob")} :
                      <br />
                      <strong className="pl-4">
                        {this.state.profile.date_of_birth
                          ? this.state.profile.date_of_birth
                          : "N/A"}
                      </strong>
                    </p>
                  </div>
                  <div
                    style={{ marginBottom: 15 }}
                    className="font-weight-light"
                  >
                    <p style={{ fontSize: "19px" }}>
                      {translate("bloodGroup")}:
                      <br />
                      <strong className="pl-4">
                        {this.state.profile.blood_group
                          ? this.state.profile.blood_group
                          : "N/A"}
                      </strong>
                    </p>
                  </div>
                  <div
                    style={{ marginBottom: 15 }}
                    className="font-weight-light"
                  >
                    <p style={{ fontSize: "19px" }}>
                      {translate("genotype")} :
                      <br />
                      <strong className="pl-4">
                        {this.state.profile.genotype
                          ? this.state.profile.genotype
                          : "N/A"}
                      </strong>
                    </p>
                  </div>
                  <div
                    style={{ marginBottom: 15 }}
                    className="font-weight-light"
                  >
                    <p style={{ fontSize: "19px" }}>
                      {translate("anitbodies")}:
                      <br />
                      <strong className="pl-4">
                        {this.state.profile.antibodies
                          ? this.state.profile.antibodies
                          : "N/A"}
                      </strong>
                    </p>
                  </div>
                  <div
                    style={{ marginBottom: 15 }}
                    className="font-weight-light"
                  >
                    <p style={{ fontSize: "19px" }}>
                      {translate("currentIllnes")}:
                      <br />
                      <strong className="pl-4">
                        {this.state.profile.illness
                          ? this.state.profile.illness
                          : "N/A"}
                      </strong>
                    </p>
                  </div>
                  <div
                    style={{ marginBottom: 15 }}
                    className="font-weight-light"
                  >
                    <p style={{ fontSize: "19px" }}>
                      {translate("anyRespiratory")} :
                      <br />
                      <strong className="pl-4">
                        {this.state.profile.respiratory
                          ? captializeFirstLetter(
                              this.state.profile.respiratory
                            )
                          : "N/A"}
                      </strong>
                    </p>
                  </div>

                  <div
                    style={{ marginBottom: 15 }}
                    className="font-weight-light"
                  >
                    <p style={{ fontSize: "19px" }}>
                      {translate("diabetic")}:
                      <br />
                      <strong className="pl-4">
                        {this.state.profile.diabetes
                          ? captializeFirstLetter(this.state.profile.diabetes)
                          : "N/A"}
                      </strong>
                    </p>
                  </div>
                  <div
                    style={{ marginBottom: 15 }}
                    className="font-weight-light"
                  >
                    <p style={{ fontSize: "19px" }}>
                      {translate("familyMedical")}:
                      <br />
                      <strong className="pl-4">
                        {this.state.profile.family_medical_history
                          ? this.state.profile.family_medical_history
                          : "N/A"}
                      </strong>
                    </p>
                  </div>
                </div> */}
              </Drawer.Body>
              <Drawer.Footer></Drawer.Footer>
            </Drawer>
            <Drawer
              show={this.state.showDoctorProfile}
              onHide={this.closeDprofile}
              size="xs"
              className="responsive-drawer"
              placement="left"
            >
              <Drawer.Header>
                <Drawer.Title>Doctor Information</Drawer.Title>
              </Drawer.Header>
              <Drawer.Body>
                {this.state.assignedDoctor !== null ? (
                  <div>
                    <h3 className="text-center">
                      {" " +
                        captializeFirstLetter(
                          this.state.assignedDoctor.firstname
                        ) +
                        " " +
                        captializeFirstLetter(
                          this.state.assignedDoctor.lastname
                        )}
                    </h3>
                    <br />
                    {/* {this.state.assignedDoctor.country} */}
                    <div>
                      <div style={{ marginBottom: 5 }}>
                        {this.state.assignedDoctor !== null ? (
                          <div
                            className="p-3 mt-2 shadow-sm rounded"
                            style={{
                              flexDirection: "row",
                              backgroundColor: "#f2f3f8",
                              padding: 10,
                              fontSize: 19,
                              borderRadius: 3,
                            }}
                          >
                            <span style={{ marginRight: 5 }}>Country: </span>
                            <span>
                              {this.state.assignedDoctor.country
                                ? this.state.assignedDoctor.country
                                : "N/A"}
                            </span>
                          </div>
                        ) : null}
                      </div>
                      <div style={{ marginBottom: 5 }}>
                        {this.state.assignedDoctor !== null ? (
                          <div
                            className="p-3 mt-2 shadow-sm rounded"
                            style={{
                              flexDirection: "row",
                              backgroundColor: "#f2f3f8",
                              padding: 10,
                              fontSize: 19,
                              borderRadius: 3,
                            }}
                          >
                            <span style={{ marginRight: 5 }}>
                              Specialisation in:{" "}
                            </span>
                            <span>
                              {this.state.assignedDoctor.speciality
                                ? this.state.assignedDoctor.speciality
                                : "N/A"}
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}
              </Drawer.Body>
              <Drawer.Footer></Drawer.Footer>
            </Drawer>
            <div className="whole-box">
              <div
                className="vertical-nav bg-white pt-4 float-left"
                id="sidebar"
              >
                <ul className="nav flex-column bg-white mb-0">
                  <li className="nav-item p-3 cursor" onClick={this._goBack}>
                    <FontAwesomeIcon
                      size="2x"
                      icon={faArrowCircleLeft}
                      className="icons"
                      style={{ color: "#020197" }}
                    />
                  </li>
                  <li className="nav-item p-3 cursor line-break position-relative">
                    <img src={camera} className="no-cam" alt="inbox" />
                  </li>
                  <li className="nav-item p-3">
                    <img src={imageInbox} alt="inbox" />
                  </li>
                </ul>
              </div>
              <div className="page-content">
                <Row noGutters={true}>
                  <Col md={3}>
                    <div className="saq-font">
                      <FontAwesomeIcon
                        size="2x"
                        icon={faBars}
                        onClick={this.showMenu}
                      />
                    </div>
                    <div className="doc-details saq-pat-chat1">
                      <div
                        id="patientProfileHeader"
                        className="bc2s"
                        style={{
                          // backgroundColor: "#EBEBEB",
                          color: "white",
                          height: 60,
                          marginBottom: 30,
                          paddingTop: 15,
                          textAlign: "center",
                        }}
                      >
                        <span
                          className="h4 font-weight-bold"
                          style={{ color: "white" }}
                        >
                          {translate("patientProfile")}
                        </span>
                      </div>

                      {/* <hr /> */}
                      <div
                        style={{
                          width: "100%",
                          textAlign: "center",
                          marginBottom: 40,
                        }}
                      >
                        <img
                          className="profile-pic"
                          src={this.state.profile.picture}
                          alt="patient"
                          style={{ width: 112, height: 112 }}
                        />

                        <br />
                        {/* <Button className="btn darkblue-btn mt-2">Refer Patient</Button> */}
                        <br />
                        <div className="userheader2">
                          {" "}
                          {this.state.authenticated !== null ? (
                            <p>
                              {captializeFirstLetter(
                                this.state.authenticated.firstname
                              ) +
                                " " +
                                captializeFirstLetter(
                                  this.state.authenticated.lastname
                                )}
                            </p>
                          ) : null}
                        </div>
                        {/* {this.state.authenticated !== null ? (
                          <p
                            style={{
                              marginTop: 15,
                              marginBottom: 15,
                              fontSize: 20,
                            }}
                          >
                            {captializeFirstLetter(
                              this.state.authenticated.firstname
                            ) +
                              " " +
                              captializeFirstLetter(
                                this.state.authenticated.lastname
                              )}
                          </p>
                        ) : null} */}
                      </div>

                      {/* <div
                        style={{
                          paddingLeft: 10,
                          paddingRight: 10,
                          overflowY: "auto",
                        }}
                      >
                        <div
                          style={{ marginBottom: 15 }}
                          className="font-weight-light"
                        >
                          <p style={{ fontSize: "19px" }}>
                            {translate("dob")} :
                            <br />
                            <strong className="pl-4">
                              {this.state.profile.date_of_birth
                                ? this.state.profile.date_of_birth
                                : "N/A"}
                            </strong>
                          </p>
                        </div>
                        <div
                          style={{ marginBottom: 15 }}
                          className="font-weight-light"
                        >
                          <p style={{ fontSize: "19px" }}>
                            {translate("bloodGroup")}:
                            <br />
                            <strong className="pl-4">
                              {this.state.profile.blood_group
                                ? this.state.profile.blood_group
                                : "N/A"}
                            </strong>
                          </p>
                        </div>
                        <div
                          style={{ marginBottom: 15 }}
                          className="font-weight-light"
                        >
                          <p style={{ fontSize: "19px" }}>
                            {translate("genotype")} :
                            <br />
                            <strong className="pl-4">
                              {this.state.profile.genotype
                                ? this.state.profile.genotype
                                : "N/A"}
                            </strong>
                          </p>
                        </div>
                        <div
                          style={{ marginBottom: 15 }}
                          className="font-weight-light"
                        >
                          <p style={{ fontSize: "19px" }}>
                            {translate("anitbodies")}:
                            <br />
                            <strong className="pl-4">
                              {this.state.profile.antibodies
                                ? this.state.profile.antibodies
                                : "N/A"}
                            </strong>
                          </p>
                        </div>
                        <div
                          style={{ marginBottom: 15 }}
                          className="font-weight-light"
                        >
                          <p style={{ fontSize: "19px" }}>
                            {translate("currentIllnes")}:
                            <br />
                            <strong className="pl-4">
                              {this.state.profile.illness
                                ? this.state.profile.illness
                                : "N/A"}
                            </strong>
                          </p>
                        </div>
                        <div
                          style={{ marginBottom: 15 }}
                          className="font-weight-light"
                        >
                          <p style={{ fontSize: "19px" }}>
                            {translate("anyRespiratory")} :
                            <br />
                            <strong className="pl-4">
                              {this.state.profile.respiratory
                                ? captializeFirstLetter(
                                    this.state.profile.respiratory
                                  )
                                : "N/A"}
                            </strong>
                          </p>
                        </div>

                        <div
                          style={{ marginBottom: 15 }}
                          className="font-weight-light"
                        >
                          <p style={{ fontSize: "19px" }}>
                            {translate("diabetic")}:
                            <br />
                            <strong className="pl-4">
                              {this.state.profile.diabetes
                                ? captializeFirstLetter(
                                    this.state.profile.diabetes
                                  )
                                : "N/A"}
                            </strong>
                          </p>
                        </div>
                        <div
                          style={{ marginBottom: 15 }}
                          className="font-weight-light"
                        >
                          <p style={{ fontSize: "19px" }}>
                            {translate("familyMedical")}:
                            <br />
                            <strong className="pl-4">
                              {this.state.profile.family_medical_history
                                ? this.state.profile.family_medical_history
                                : "N/A"}
                            </strong>
                          </p>
                        </div>
                      </div> */}
                      <div className="userDetail ml-5 fz14 mt-4">
                        <div className="mt-2">
                          {translate("dob")} :{" "}
                          <b>
                            {this.state.profile.date_of_birth
                              ? this.state.profile.date_of_birth
                              : "N/A"}
                          </b>
                        </div>
                        <div className="mt-2">
                          {translate("bloodGroup")}:{" "}
                          <b>
                            {this.state.profile.blood_group
                              ? this.state.profile.blood_group
                              : "N/A"}
                          </b>
                        </div>

                        <div className="mt-2">
                          {translate("genotype")} :{" "}
                          <b>
                            {this.state.profile.genotype
                              ? this.state.profile.genotype
                              : "N/A"}
                          </b>
                        </div>
                        <div className="mt-2">
                          {translate("anitbodies")}:{" "}
                          <b>
                            {this.state.profile.antibodies
                              ? this.state.profile.antibodies
                              : "N/A"}
                          </b>
                        </div>
                        <div className="mt-2">
                          {translate("currentIllnes")}:{" "}
                          <b>
                            {this.state.profile.illness
                              ? this.state.profile.illness
                              : "N/A"}
                          </b>
                        </div>
                        <div className="mt-2">
                          {translate("anyRespiratory")} :
                          <b>
                            {this.state.profile.respiratory
                              ? captializeFirstLetter(
                                  this.state.profile.respiratory
                                )
                              : "N/A"}
                          </b>
                        </div>
                        <div className="mt-2">
                          {translate("diabetic")}:
                          <b>
                            {this.state.profile.diabetes
                              ? captializeFirstLetter(
                                  this.state.profile.diabetes
                                )
                              : "N/A"}
                          </b>
                        </div>
                        <div className="mt-2">
                          {translate("familyMedical")}:
                          <b>
                            {this.state.profile.family_medical_history
                              ? this.state.profile.family_medical_history
                              : "N/A"}
                          </b>
                        </div>
                      </div>
                    </div>
                    <br />
                  </Col>
                  <Col md={6} className="saq-header">
                    <div className="chat-box">
                      <div className="chat-header">
                        <Row>
                          <Col xs={12}>
                            {this.state.dtyping === true ? (
                              <div style={{ display: "inline" }}>
                                <p
                                  style={{
                                    color: "#0000a0",
                                    fontSize: "12px",
                                    marginTop: "15px",
                                    marginLeft: "5px",
                                    fontStyle: "italic",
                                  }}
                                >
                                  {this.state.assignedDoctor.firstname
                                    ? this.state.assignedDoctor.firstname
                                    : "Doctor"}{" "}
                                  is typing....
                                </p>
                              </div>
                            ) : null}
                            {/* <span
                              className="h4 font-weight-bold text-center d-block pt-3"
                              style={{ color: "black" }}
                            >
                              Patient Inbox 1
                            </span> */}
                            <div className="chatheader">
                              <div
                                className="row"
                                style={{ paddingTop: "10px" }}
                              >
                                <div className="col-2 text-center">
                                  {/* <img
                                    src={
                                      profileBase +
                                      this.state.docProfile.picture
                                    }
                                    alt="Doctor Pic"
                                  /> */}
                                </div>
                                {this.state.assignedDoctor !== null ? (
                                  <div className="col-7 fz16">
                                    <div>
                                      {" " +
                                        captializeFirstLetter(
                                          this.state.assignedDoctor.firstname
                                        ) +
                                        " " +
                                        captializeFirstLetter(
                                          this.state.assignedDoctor.lastname
                                        )}
                                    </div>

                                    <div>Online</div>
                                  </div>
                                ) : null}
                                <div
                                  className="col-3"
                                  style={{ paddingTop: "10px" }}
                                >
                                  <span>
                                    <img Src={Phone} alt="" />
                                  </span>
                                  <span style={{ marginLeft: "25px" }}>
                                    <img Src={Video} alt="" />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col xs={3}>
                            <FontAwesomeIcon
                              icon={faEllipsisV}
                              className="menu"
                              onClick={this.showMenu}
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className="chat" id="patient-chat">
                        {this.state.messages.length < 1 ? <NoChat /> : msg}{" "}
                      </div>
                      <div className="suggestionsBox">
                        {this.state.suggestions.map((x, y) => (
                          <span
                            className="suggestionsItems"
                            key={y}
                            value={x}
                            onClick={() => {
                              this.sendMessage2(x);
                            }}
                          >
                            {translate(x)}
                          </span>
                        ))}
                      </div>
                      <div style={{ marginTop: "15px" }}>
                        {this.state.suggestions2.map((x, y) => (
                          <span
                            key={y}
                            value={x}
                            className="suggestionsItems"
                            onClick={() => {
                              this.sendMessage2(x);
                            }}
                          >
                            {translate(x)}
                          </span>
                        ))}
                      </div>
                      <form
                        onSubmit={this.sendMessage}
                        acceptCharset="utf-8"
                        style={{ backgroundColor: "#fff", padding: 17 }}
                      >
                        <div
                          className="chat-textbox p-0 responsive-chatbox"
                          style={{ flexDirection: "row", borderRadius: 3 }}
                        >
                          <div className="text-div p-2">
                            <input
                              type="text"
                              style={{
                                resize: "none",
                                minHeight: "45px",
                                // marginTop: "1.5vh",
                                width: "90%",
                                // marginLeft: "5px",
                                borderRadius: "2px",
                                border: "none",
                                padding: "7px",
                              }}
                              onKeyPress={this.typing}
                              onKeyUp={this.notTyping}
                              placeholder="Type a message this"
                              id="message"
                              name="message"
                            />

                            <button
                              className="bg-white"
                              style={{ width: "10%" }}
                            >
                              <img
                                src={sendMessage}
                                // style={{ width: "100%" }}
                                alt="send"
                              />
                            </button>
                          </div>
                          {/* <button type="submit" style={{ display: "none" }}></button> */}
                        </div>
                      </form>
                    </div>
                  </Col>
                  <Col md={3} className="saq-show">
                    <div className="side-bar-chat">
                      <div className="doc-details">
                        <div
                          id="patientProfileHeader"
                          className="bc2s"
                          style={{
                            // backgroundColor: "#EBEBEB",
                            height: 60,
                            marginBottom: 30,
                            paddingTop: 15,
                            textAlign: "center",
                          }}
                        >
                          <span
                            className="h4 font-weight-bold"
                            style={{ color: "white" }}
                          >
                            Doctor’s Details
                            {/* {translate("connectedDoctor")} */}
                          </span>
                        </div>

                        {this.state.docProfile.picture &&
                          this.state.docProfile.picture.indexOf("https") ==
                            -1 && (
                            <img
                              className="profile-pic d-block"
                              src={profileBase + this.state.docProfile.picture}
                              alt="Doctor"
                              style={{ width: 120, height: 120 }}
                            />
                          )}
                        {this.state.docProfile.picture &&
                          this.state.docProfile.picture.indexOf("https") >
                            -1 && (
                            <img
                              className="profile-pic d-block"
                              src={profileBase + this.state.docProfile.picture}
                              alt="Doctor"
                              style={{ width: 120, height: 120 }}
                            />
                          )}
                        <br />
                        {this.state.assignedDoctor !== null ? (
                          <div>
                            {/* <h3 className="text-center">
                              {" " +
                                captializeFirstLetter(
                                  this.state.assignedDoctor.firstname
                                ) +
                                " " +
                                captializeFirstLetter(
                                  this.state.assignedDoctor.lastname
                                )}
                            </h3> */}
                            <div className="userheader2">
                              {" " +
                                captializeFirstLetter(
                                  this.state.assignedDoctor.firstname
                                ) +
                                " " +
                                captializeFirstLetter(
                                  this.state.assignedDoctor.lastname
                                )}
                            </div>
                            <br />
                            {/* {this.state.assignedDoctor.country} */}
                            <div className="userDetail ml-5 fz14 mt-4">
                              <div className="mt-2">
                                Country:{" "}
                                <b>
                                  {this.state.assignedDoctor.country
                                    ? this.state.assignedDoctor.country
                                    : "N/A"}
                                </b>
                              </div>
                              <div className="mt-2">
                                Specialisation in:{" "}
                                <b>
                                  {this.state.assignedDoctor.speciality
                                    ? this.state.assignedDoctor.speciality
                                    : "N/A"}
                                </b>
                              </div>
                            </div>
                            {/* <div>
                              <div style={{ marginBottom: 5 }}>
                                {this.state.assignedDoctor !== null ? (
                                  <div
                                    className="p-3 mt-2 shadow-sm rounded"
                                    style={{
                                      flexDirection: "row",
                                      backgroundColor: "#f2f3f8",
                                      padding: 10,
                                      fontSize: 19,
                                      borderRadius: 3,
                                    }}
                                  >
                                    <span style={{ marginRight: 5 }}>
                                      Country:{" "}
                                    </span>
                                    <span>
                                      {this.state.assignedDoctor.country
                                        ? this.state.assignedDoctor.country
                                        : "N/A"}
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                              <div style={{ marginBottom: 5 }}>
                                {this.state.assignedDoctor !== null ? (
                                  <div
                                    className="p-3 mt-2 shadow-sm rounded"
                                    style={{
                                      flexDirection: "row",
                                      backgroundColor: "#f2f3f8",
                                      padding: 10,
                                      fontSize: 19,
                                      borderRadius: 3,
                                    }}
                                  >
                                    <span style={{ marginRight: 5 }}>
                                      Specialisation in:{" "}
                                    </span>
                                    <span>
                                      {this.state.assignedDoctor.speciality
                                        ? this.state.assignedDoctor.speciality
                                        : "N/A"}
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            </div> */}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        )}
      </Container>
    );
  }
}
