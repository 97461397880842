import React from "react";
import LeftBar from "./leftBar";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faArrowCircleLeft,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Alert } from "rsuite";
import { postDataAuth, getAuth, postData } from "../../../services/request";

export default class AddBeneficiary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHmoNumber: false,
      isAgreedCheck: false,
      name: "",
      email: "",
      balance: "",
      wallet: [],
      wallet_id: "",
    };
  }
  componentDidMount = () => {
    var token = localStorage.getItem("med_token");
    getAuth("authenticated", token).then((response) => {
      // console.log("authenticated==", response);
      postDataAuth(
        "getWalletInfo",
        {
          email: response.data.user.email,
        },
        token
      ).then((response) => {
        // console.log("get walet==", response);
        this.setState({
          name: response[0].name,
          email: response[0].email,
          wallet_id: response[0].wallet_id,
        });
      });
      //
      postDataAuth("getWalletTransactions", {
        email: response.data.user.email,
      }).then((response) => {
        // console.log("walettrans==", response);
        const sortArr = response.sort(function (a, b) {
          var dateA = new Date(a.created_at.toString()),
            dateB = new Date(b.created_at.toString());
          return dateA - dateB;
        });

        this.setState({
          wallet: sortArr,
        });
      });
      //
      postDataAuth(
        "getWalletBalance",
        {
          email: response.data.user.email,
        },
        token
      ).then((response) => {
        // console.log(response);
        this.setState({
          balance: response.balance,
        });
      });
    });
  };

  //
  showPassword = (id) => {
    var password = document.getElementById(id);
    if (password.type === "password") {
      password.type = "text";
    } else {
      password.type = "password";
    }
  };
  //
  signup = (e) => {
    e.preventDefault();
    var details = {
      firstname: e.target.firstname.value,
      lastname: e.target.lastname.value,
      email: e.target.email.value,
      country: e.target.country.value,
      user: "Patient",
      regNumber: null,
      password: e.target.password.value,
      confirm_password: e.target.confirm_password.value,
      relation:
        e.target.relation && e.target.relation.value
          ? e.target.relation.value
          : "",
      secondaryEmail: this.state.email,
    };
    console.log(details);
    if (details.firstname.trim() === "" || details.firstname.trim() === null) {
      Alert.warning("Input first name");
    } else if (
      details.lastname.trim() === "" ||
      details.lastname.trim() === null
    ) {
      Alert.warning("Input last name");
    } else if (details.email.trim() === "" || details.email.trim() === null) {
      Alert.warning("Input email");
    } else if (details.country === "Select Country") {
      Alert.warning("Select a country");
    } else if (
      details.password.trim() === null ||
      details.password.trim() === "" ||
      details.password.length <= 7
    ) {
      Alert.warning(
        "Input your password & length should be atleast of 8 characters"
      );
    } else if (
      details.confirm_password.trim() === null ||
      details.confirm_password.trim() === "" ||
      details.confirm_password.length <= 7
    ) {
      Alert.warning(
        "Confirm your password & length should be atleast of 8 characters"
      );
    } else if (details.password.trim() !== details.confirm_password.trim()) {
      Alert.error("Passwords do not match");
    } else {
      this.setState({ loader: true });

      postData("register", details)
        .then((response) => {
          this.setState({ loader: false });
          if (response.status === true) {
            Alert.success(response.message);
          } else {
            Alert.error(response.message);
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
          if (error.request.status === 422) {
            Alert.error(error.response.data.errors.email);
          } else if (error.request.status === 500) {
            Alert.error(
              "Connection to internet lost, please check connection and retry"
            );
          } else {
            Alert.error("Error creating account");
          }
        });
    }
    // this.registerWallet(details);
  };
  render() {
    return (
      <div className="container-fluid">
        <div className="row ">
          <div className="col-2 top-bo">
            <LeftBar history={this.props.history} />
          </div>
          <div className="col-10 top-bo bc-f5">
            <div className="row mt-3">
              <div className="col-4 user-header">
                Name:<span className="px20 ml-2">{this.state.name}</span>
              </div>
              <div className="col-4 user-header">
                Email:<span className="px20 ml-2">{this.state.email}</span>
              </div>
              <div className="col-4 user-header">
                Balance:<span className="px20 ml-2">{this.state.balance}</span>
              </div>
            </div>
            <div className="row justify-content-center mt-4">
              <div className="col-8 welcome-container2">
                <form onSubmit={this.signup}>
                  <div className="row pt-3">
                    <div className="col-6">
                      <label className="rem1">First Name</label>
                      <input
                        type="text"
                        name="firstname"
                        id="firstname"
                        className="form-control bc-f5"
                      />
                    </div>
                    <div className="col-6">
                      <label className="rem1">Last Name</label>
                      <input
                        type="text"
                        className="form-control bc-f5"
                        name="lastname"
                        id="lastname"
                      />
                    </div>
                    <div className="col-12">
                      <label className="rem1">Email Address</label>
                      <input
                        type="text"
                        className="form-control bc-f5"
                        name="email"
                        id="email"
                      />
                    </div>
                    <div className="col-6">
                      <label className="rem1">NIN</label>
                      <input
                        type="number"
                        className="form-control bc-f5"
                        name="nin"
                        id="nin"
                      />
                    </div>
                    <div className="col-6">
                      <label className="rem1">Relation</label>
                      <input
                        type="text"
                        className="form-control bc-f5"
                        name="relation"
                        id="relation"
                      />
                    </div>
                    <div className="col-6">
                      <label className="rem1">Date of Birth</label>
                      <input
                        type="date"
                        className="form-control bc-f5"
                        name="dob"
                        id="dob"
                      />
                    </div>
                    <div className="col-6">
                      <label className="rem1">Country</label>
                      <select
                        className="form-control bc-f5"
                        onChange={this.check}
                        name="country"
                        id="country"
                      >
                        <option className="form-control bc-f5"></option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="USA">USA</option>
                      </select>
                    </div>
                    <div className="col-6">
                      <label className="rem1">Password</label>
                      <input
                        type="password"
                        className="form-control bc-f5"
                        name="password"
                        id="password"
                      />
                      <FontAwesomeIcon
                        onClick={() => {
                          this.showPassword("password");
                        }}
                        icon={faEye}
                        style={{
                          marginTop: "-24px",
                          cursor: "pointer",
                          position: "absolute",
                          zIndex: 9,
                          right: "20px",
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <label className="rem1">Confirm Password</label>
                      <input
                        type="password"
                        className="form-control bc-f5"
                        name="confirm_password"
                        id="confirm_password"
                      />
                      <FontAwesomeIcon
                        onClick={() => {
                          this.showPassword("confirm_password");
                        }}
                        icon={faEye}
                        style={{
                          marginTop: "-24px",
                          // marginLeft: "5px",
                          cursor: "pointer",
                          position: "absolute",
                          zIndex: 9,
                          right: "20px",
                        }}
                      />
                    </div>
                    <div className="col-12 mt-2 mb-3">
                      <Button className="signup-btn cur" type="submit">
                        Add
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
