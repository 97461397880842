import React, { Component } from "react";
import Dialog from "./Dialog";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCommentAlt,
  faComments,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { postData } from "../../services/request";

class Comment extends Component {
  state = {
    loder: false,
    isOpen: false,
    message: "",
  };
  componentDidMount = () => {
    console.log("this ====", this.props.authenticated);
  };
  sendMessage = async () => {
    const timer = (ms) => new Promise((res) => setTimeout(res, ms));
    this.setState({
      loder: true,
    });
    const data = {
      name:
        this.props.authenticated.firstname +
        "-" +
        this.props.authenticated.lastname,
      email: this.props.authenticated.email,
      userType: this.props.authenticated.usertype,
      message: this.state.message,
      status: null,
    };
    console.log(data);
    await timer(3000);
    this.setState({
      message: "",
      loder: false,
      isOpen: false,
    });
    postData("save-feedback", data);
  };
  render() {
    return (
      <div className="">
        <FontAwesomeIcon
          onClick={(e) => this.setState({ isOpen: true })}
          icon={faComments}
          style={{
            color: "#E74C3C",
            cursor: "pointer",
            position: "fixed",
            zIndex: 9,
            right: "20px",
            bottom: "20px",
            fontSize: "40px",
          }}
        />
        {/* <button onClick={(e) => this.setState({ isOpen: true })}>
          Open Dialog
        </button> */}

        <Dialog
          isOpen={this.state.isOpen}
          onClose={(e) => this.setState({ isOpen: false })}
        >
          Give Your FeedBack
          <textarea
            type="form-control"
            row="3"
            onChange={(e) => this.setState({ message: e.target.value })}
            value={this.state.message}
          ></textarea>
          {this.state.loder === false && (
            <button
              type="button"
              class="btn btn-primary"
              onClick={this.sendMessage}
            >
              Send
            </button>
          )}
          {this.state.loder === true && (
            <button
              className="btn btn-success"
              type="button"
              disabled
              // style={{ width: "20%" }}
            >
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Sending...
            </button>
          )}
        </Dialog>
      </div>
    );
  }
}

export default Comment;
