import React from 'react';
import { useFlutterwave } from 'flutterwave-react-v3';
import { postData } from "../services/request";

export default function App(props) {
  const { type } = props;
  const { configuration } = props;
  const {extraClass} = configuration;
  const config = {
    public_key: 'FLWPUBK_TEST-337927ddc0c6a09b6e65d1986f9f097c-X',
    tx_ref: Date.now(),
    amount: configuration.amount,
    currency: 'NGN',
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: configuration.user.email,
      phonenumber: configuration.user.phone,
      name: configuration.user.firstname + " " + configuration.user.lastname,
    },
    customizations: {
      title: 'Telemedicine',
      description: configuration && configuration.description ? configuration.description : 'Payment for doctor fee',
      logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
    },
  };
  
  const updateUserWallet = (response) => {
    const payload = {
      amount : response.amount,
      email : response.customer.email
    }
    postData("userDeposit",payload)
    .then((result)=>{
      console.log(result);
      debugger;
    })
    .catch(err => {
      console.error(err);
    })
  }
  const handleFlutterPayment = useFlutterwave(config);
 
  return (
    <div className="flutter-wave">
      <button
        className={`btn btn-block ${extraClass}`}
        onClick={() => {
          handleFlutterPayment({
            callback: (response) => {
              console.log(response);
              updateUserWallet(response);
            },
            onClose: () => {},
          });
        }}
      >
        {configuration && configuration.text ? configuration.text : "Pay 200 Fee Now"}
      </button>
    </div>
  );
}