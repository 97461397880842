import React from "react";
import { fetchData, postDataAuth } from "../../services/request";
import NavBar from "./navbar";

export default class Transaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transaction: [],
    };
  }
  componentDidMount = async () => {
    const data = await fetchData("all-transaction");
    console.log(data);
    this.setState({
      transaction: data.feednacks,
    });
  };

  render() {
    return (
      <>
        <div className="container-fluid bo-top">
          <div className="row">
            <div className="col-2 bo-right">
              <NavBar history={this.props.history} />
            </div>
            <div className="col-10 bc-f5">
              <div className="row page-head">Flutterwave Transaction</div>
              <div className="row page-size">
                <div className="col-12">
                  <table class="table">
                    <thead className="boder-col">
                      <tr>
                        <th scope="col" className="">
                          Id
                        </th>
                        <th scope="col" className="">
                          Name
                        </th>

                        <th scope="col" className="">
                          Amount
                        </th>
                        <th scope="col" className="">
                          Status
                        </th>
                        <th scope="col" className="">
                          Bank-Code
                        </th>
                        <th scope="col" className="">
                          Account-Number
                        </th>
                        <th scope="col" className="">
                          Fee
                        </th>
                        <th scope="col" className="">
                          Date
                        </th>
                      </tr>
                    </thead>
                    {this.state.transaction.map((data, idx) => (
                      <tbody key={idx}>
                        <tr>
                          <td className="">{data.id}</td>
                          <td className="">{data.fullname}</td>
                          <td className="">{data.amount}</td>
                          <td className="">{data.status}</td>
                          <td className="">{data.bank_code}</td>
                          <td className="">{data.account_number}</td>
                          <td className="">{data.fee}</td>
                          <td className="">{data.createdAt}</td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
