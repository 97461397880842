import React from "react";

const ButtonBar = () => {
  return (
    <div className="row mt-4">
      <div className="col-8">
        <div style={{ fontSize: 24, fontWeight: 700 }}>Doctor List</div>
      </div>
      <div className="col-4">
        <div className="row">
          <div className="col-4">
            <button type="button" class="cur btn-delete">
              Delete All
            </button>
          </div>
          <div className="col-4">
            <button type="button" class="cur btn-deactivate">
              Deactivate All
            </button>
          </div>
          <div className="col-4">
            <button type="button" class="cur btn-activate">
              Activate All
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ButtonBar;
