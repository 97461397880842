import React from "react"
import { Row,Col,Tabs,Tab,Card } from 'react-bootstrap';
import Loader from "react-loader-spinner";
import wave from "../assets/images/wave.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import translate from "../i18n/translation";
export default class Call extends React.Component{
    constructor(props){
        super(props)
       this.state = {
            loader:false,
            videoCall:[],
            key: 'home'
        }
    }

    startcall = () =>{
    //     var meet = new window.JitsiMeet('https://covid19telemedics.com');
    //     this.setState({loader:true})
    //    var meetingName = this.props.authenticated.firstname+" and "+this.props.assignedPatient.firstname
    //    var details = {
    //     room:this.props.authenticated.id+"&"+this.props.authenticated.assigned,
    // }
    //    this.props.socketConnection.emit("startCall",details)
     
    //         meet.on('ready', function() {
    //             var interfaceConfig = {
    //                 // TOOLBAR_BUTTONS: ['microphone', 'camera', 'desktop', 'fullscreen', 'hangup', 'settings'],
    //                 SETTINGS_SECTIONS: ['language', 'devices'],
    //                 DEFAULT_REMOTE_DISPLAY_NAME: 'John Doe',
    //             };
    //             var options = {};
    //             options.interfaceConfig = interfaceConfig;
    //     try{
    //         var conference = meet.join(meetingName, '#call', options);
    //         conference.on('joined', function() {
    //         });
    //     }catch(error){
    //             console.log(error)
    //     }
        
    //         });   
    const domain = 'covid19telemedics.com';
const options = {
    roomName: this.props.authenticated.firstname+" and "+this.props.assignedPatient.firstname,
    width: "100%",
    height: "100%",
    userInfo: {
        email: this.props.authenticated.email,
        displayName: this.props.authenticated.firstname,
    },
    parentNode: document.querySelector('#call'),
    interfaceConfigOverwrite:{
        APP_NAME: 'WaveTelemedics',
    JITSI_WATERMARK_LINK: wave,
    TOOLBAR_BUTTONS: [
        'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
        'fodeviceselection', 'profile', 'chat', 'recording',
        'livestreaming', 'etherpad', 'sharedvideo', 'raisehand',
        'videoquality', 'filmstrip', 'invite', 'feedback', 'stats', 'shortcuts',
        'tileview', 'download', 'help', 'security'
    ],
    BRAND_WATERMARK_LINK: wave
    }

};
var details = {
        room:this.props.authenticated.id+"&"+this.props.authenticated.assigned,
    }
       this.props.socketConnection.emit("startCall",details)

const api = new window.JitsiMeetExternalAPI(domain, options);
this.setState({videoCall:api})
}
startPcall = () =>{
//     this.setState({loader:true})
//     var meet = new window.JitsiMeet('https://covid19telemedics.com');
// console.log("Chckpoint")
//    var meetingName = this.props.assignedDoctor.firstname+" and "+this.props.authenticated.firstname

//         meet.on('ready', function() {
//             var interfaceConfig = {
//                 TOOLBAR_BUTTONS: ['microphone', 'camera', 'desktop', 'fullscreen', 'hangup', 'settings'],
//                 SETTINGS_SECTIONS: ['language', 'devices'],
//                 DEFAULT_REMOTE_DISPLAY_NAME: 'John Doe',
//             };
//             var options = {};
//             options.interfaceConfig = interfaceConfig;
//     try{
//         console.log(meetingName)
//         var conference = meet.join(meetingName, '#call', options);
//         conference.on('joined', function() {
//             this.setState({loader:false})
//         });
//     }catch(error){
//             console.log(error)
//     }
           
//         });   
const domain = 'covid19telemedics.com';
const options = {
    roomName: this.props.assignedDoctor.firstname+" and "+this.props.authenticated.firstname,
    width: "100%",
    height: "100%",
    userInfo: {
        email: this.props.authenticated.email,
        displayName: this.props.authenticated.firstname,
    },
    parentNode: document.querySelector('#call'),
    interfaceConfigOverwrite:{
        APP_NAME: 'WaveTelemedics',
    JITSI_WATERMARK_LINK: wave,
    TOOLBAR_BUTTONS: [
        'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
        'fodeviceselection', 'profile', 'chat', 'recording',
        'livestreaming', 'etherpad', 'sharedvideo', 'raisehand',
        'videoquality', 'filmstrip', 'invite', 'feedback', 'stats', 'shortcuts',
        'tileview', 'download', 'help', 'security'
    ],
    BRAND_WATERMARK_LINK: wave
    }

};
const api = new window.JitsiMeetExternalAPI(domain, options);
this.setState({videoCall:api})

}
    componentDidMount = () =>{
        if(this.props.from === "Doc"){
            this.startcall()
        }else{
            this.startPcall()
        }
    }
    endCall = () =>{
        this.state.videoCall.dispose()
        this.props.backToChat()
    }
    render(){
        var pres = this.props.previousPrsecriptions && this.props.previousPrsecriptions.length > 0 && this.props.previousPrsecriptions.map((item,idx) => {
            return (
              <>
              <Card key={idx} style={{ width: '70%' }}  className="mb-3">
                <Card.Body>
                  <Card.Text>
                    {item.prescription}
                  </Card.Text>
                </Card.Body>
              </Card>
              </>
            ) 
          })
         
        return(
                    <Row>
                        {this.props.patientProfile && (
                            <>
                            <Col xs={3} className="p-0">
                            <div className="side-bar-chat">
                                <div className="doc-details">
                                        <div id="patientProfileHeader" style={{backgroundColor: '#EBEBEB', height: 60, marginBottom: 30, paddingTop: 15, textAlign: 'center'}}>
                                            <span className="h4 font-weight-bold" style={{color: 'black'}}>{translate('vitalStatistics')}</span>
                                        </div>
                                        
                                        {/* <hr /> */}
                        
                                        <div className="pl-3">
                                        <Tabs
                                            id="controlled-tab-example"
                                            activeKey={this.state.key}
                                            onSelect={(k) => this.setState({key:k})}
                                            transition={false} 
                                        >
    
                                        <Tab eventKey="home" title="Vital Statistics">
                                        <div style={{paddingLeft: 5, paddingRight: 5, overflowY: 'auto',}}>
                                            <div style={{marginBottom: 5}}>
                                            {this.props.assignedPatient !== null ? (
                                                <div className="p-3 mt-2 shadow-sm rounded" style={{flexDirection: 'row', backgroundColor: "#f2f3f8", padding: 10,
                                                fontSize: 15, borderRadius: 3}}>
                                                <span style={{marginRight: 5}}>{translate('bpm')}:</span>
                                                <span>
                                                    {this.props.patientProfile && this.props.patientProfile.blood_pressure_measurement ? this.props.patientProfile.blood_pressure_measurement : "N/A"}
                                                </span>
                                                </div>
                                            ) : null}
                                            </div>
                                            <div style={{marginBottom: 5}}>
                                            {this.props.assignedPatient !== null ? (
                                                <div className="p-3 mt-2 shadow-sm rounded" style={{flexDirection: 'row', backgroundColor: "#f2f3f8", padding: 10,
                                                fontSize: 15, borderRadius: 3}}>
                                                <span style={{marginRight: 5}}>{translate('bsl')}:</span>
                                                <span>
                                                    {this.props.patientProfile && this.props.patientProfile.blood_sugar_level ? this.props.patientProfile.blood_sugar_level : "N/A"}
                                                </span>
                                                </div>
                                            ) : null}
                                            </div>
                                            <div style={{marginBottom: 5}}>
                                            {this.props.assignedPatient !== null ? (
                                                <div className="p-3 mt-2 shadow-sm rounded" style={{flexDirection: 'row', backgroundColor: "#f2f3f8", padding: 10,
                                                fontSize: 15, borderRadius: 3}}>
                                                <span style={{marginRight: 5}}>{translate('ct')}:</span>
                                                <span>
                                                {this.props.patientProfile && this.props.patientProfile.current_body_temperature ? this.props.patientProfile.current_body_temperature : "N/A"}
                                                </span>
                                                </div>
                                            ) : null}
                                            </div>
                                            <div style={{marginBottom: 5}}>
                                            {this.props.assignedPatient !== null ? (
                                                <div className="p-3 mt-2 shadow-sm rounded" style={{flexDirection: 'row', backgroundColor: "#f2f3f8", padding: 10,
                                                fontSize: 15, borderRadius: 3}}>
                                                <span style={{marginRight: 5}}>{translate('cc')}:</span>
                                                <span>
                                                    {this.props.patientProfile && this.props.patientProfile.cough ? this.props.patientProfile.cough : "N/A"}
                                                </span>
                                                </div>
                                            ) : null}
                                            </div>
                                            <div style={{marginBottom: 5}}>
                                            {this.props.assignedPatient !== null ? (
                                                <div className="p-3 mt-2 shadow-sm rounded" style={{flexDirection: 'row', backgroundColor: "#f2f3f8", padding: 10,
                                                fontSize: 15, borderRadius: 3}}>
                                                <span style={{marginRight: 5}}>{translate('los')}:</span>
                                                <span>
                                                    {this.props.patientProfile && this.props.patientProfile.loss_of_smell ? this.props.patientProfile.loss_of_smell : "N/A"}
                                                </span>
                                                </div>
                                            ) : null}
                                            </div>
                                            <div style={{marginBottom: 5}}>
                                            {this.props.assignedPatient !== null ? (
                                                <div className="p-3 mt-2 shadow-sm rounded" style={{flexDirection: 'row', backgroundColor: "#f2f3f8", padding: 10,
                                                fontSize: 15, borderRadius: 3}}>
                                                <span style={{marginRight: 5}}>{translate('aop')}:</span>
                                                <span>
                                                    {this.props.patientProfile && this.props.patientProfile.area_of_pain_in_body ? this.props.patientProfile.area_of_pain_in_body : "N/A"}
                                                </span>
                                                </div>
                                            ) : null}
                                            </div>
                                            <div style={{marginBottom: 5}}>
                                            {this.props.assignedPatient !== null ? (
                                                <div className="p-3 mt-2 shadow-sm rounded" style={{flexDirection: 'row', backgroundColor: "#f2f3f8", padding: 10,
                                                fontSize: 15, borderRadius: 3}}>
                                                <span style={{marginRight: 5}}>{translate('dizziness')}:</span>
                                                <span>
                                                    {this.props.patientProfile && this.props.patientProfile.dizziness ? this.props.patientProfile.dizziness : "N/A"}
                                                </span>
                                                </div>
                                            ) : null}
                                            </div>
                                            <div style={{marginBottom: 5}}>
                                            {this.props.assignedPatient !== null ? (
                                                <div className="p-3 mt-2 shadow-sm rounded" style={{flexDirection: 'row', backgroundColor: "#f2f3f8", padding: 10,
                                                fontSize: 15, borderRadius: 3}}>
                                                <span style={{marginRight: 5}}>{translate('lob')}:</span>
                                                <span>
                                                {this.props.patientProfile && this.props.patientProfile.loss_of_balance ? this.props.patientProfile.loss_of_balance : "N/A"}
                                                </span>
                                                </div>
                                            ) : null}
                                            </div>
                                            <div style={{marginBottom: 5}}>
                                            {this.props.assignedPatient !== null ? (
                                                <div className="p-3 mt-2 shadow-sm rounded" style={{flexDirection: 'row', backgroundColor: "#f2f3f8", padding: 10,
                                                fontSize: 15, borderRadius: 3}}>
                                                <span style={{marginRight: 5}}>{translate('travel')}:</span>
                                                <span>
                                                    {this.props.patientProfile && this.props.patientProfile && this.props.patientProfile.travel_in_last_days ? this.props.patientProfile.travel_in_last_days : "N/A"}
                                                </span>
                                                </div>
                                            ) : null}
                                            </div>
                                            <div style={{marginBottom: 5}}>
                                            {this.props.assignedPatient !== null ? (
                                                <div className="p-3 mt-2 shadow-sm rounded" style={{flexDirection: 'row', backgroundColor: "#f2f3f8", padding: 10,
                                                fontSize: 15, borderRadius: 3}}>
                                                <span style={{marginRight: 5}}>{translate('meet')}:</span>
                                                <span>
                                                    {this.props.patientProfile && this.props.patientProfile.visitor_interacts ? this.props.patientProfile.visitor_interacts : "N/A"}
                                                </span>
                                                </div>
                                            ) : null}
                                            </div>
                                        </div>
                                        </Tab>
                                        <Tab eventKey="profile" title="Make Prescription">
                                        <>
                                        <form className="p-2" onSubmit={this.prescribe}>
                                        <textarea
                                        className="textArea border shadow-sm"
                                        name="prescription"
                                        placeholder="Enter Prescription details here"
                                        style={{ backgroundColor: "#f2f3f8"}}
                                        ></textarea>
                                        <br />
                                        <textarea
                                        className="textArea border shadow-sm"
                                        name="medicalComments"
                                        placeholder="Comments Regarding Patience Medical Status"
                                        style={{ backgroundColor: "#f2f3f8"}}
                                        ></textarea>
                                        <br />
                                        {this.state.prescriptionLoader === true ? (
                                        <Loader
                                            type="TailSpin"
                                            width={60}
                                            height={60}
                                            color="white"
                                        />
                                        ) : (
                                        <button
                                            className="btn darkblue-btn pr-4 pl-4 mt-3"
                                            style={{
                                            color: "white",
                                            }}
                                        >
                                            Send
                                        </button>
                                        )}
                                        </form>
    
                                        <div className="mt-4 p-2">
                                        <h5 className="mb-2">Previous Prescribed Medicine</h5>  
                                        {this.props.previousPrsecriptions && this.props.previousPrsecriptions.length < 1 ? <></> : pres}{" "}
                                        </div>
                                        </> 
                                        </Tab>
                                        <Tab eventKey="setting" title="Medical History">
                                        <div className="p-2" >
                                        {this.props.previousPrsecriptions && this.props.previousPrsecriptions.length > 0 && this.generateMedicalHistory &&  (
                                        this.generateMedicalHistory()
                                        )}
                                        </div>
                                        {this.props.previousPrsecriptions && this.props.previousPrsecriptions.length === 0 && (
                                        <div className="menu-items">
                                            <strong>{translate('nomedicalhistory')}</strong>
                                        </div>
                                        )}
                                        </Tab>
    
                                        </Tabs>
                                        </div>
                                        
                                        </div>
                                        <br />
                                        </div>
    
                            </Col> 
                            <Col xs={9} className="p-0">
                            <div id="call">
                                <div style={{width:"50px",height:"50px",backgroundColor:"red",borderRadius:"100%",textAlign:"center",color:"white",lineHeight:"17px",position:"absolute",marginLeft:"20%",marginTop:"91vh",cursor:"pointer"}}onClick={this.endCall}><br/>
                                <FontAwesomeIcon icon={faPhone} size="1x"/>
                                </div>
                            </div>
                            </Col>
                        </>   
                        )}
                        {!this.props.patientProfile && ( 
                            <Col xs={12} className="p-0">
                            <div id="call">
                                <div style={{width:"50px",height:"50px",backgroundColor:"red",borderRadius:"100%",textAlign:"center",color:"white",lineHeight:"17px",position:"absolute",marginLeft:"20%",marginTop:"91vh",cursor:"pointer"}}onClick={this.endCall}><br/>
                                <FontAwesomeIcon icon={faPhone} size="1x"/>
                                </div>
                            </div>
                            </Col>
                        )}
                    </Row>
        )
    }
}