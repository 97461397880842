import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCommentSlash } from "@fortawesome/free-solid-svg-icons"

export const NoChat = () =>{
return <div>
    <div className="no-messages">
    <br/><br/><br/><br/><br/><br/><br/><br/>
    <FontAwesomeIcon icon={faCommentSlash} color="#ebebed" size="5x"/>
    <h4>NO MESSAGES</h4>
    </div>
</div>
}