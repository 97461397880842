import React from "react"
import { Container,Row,Col } from "react-bootstrap";
import FlutterPay from "./flutterWave";
import logo from "../assets/images/image 1.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import {
    getAuth,
    fetchData,
    postDataAuth,
    fetchDataAuth,
  } from "../services/request";
export default class PaymentPlan extends React.Component{
constructor(props){
    super(props)
    this.state = {
          
    }
}
componentDidMount = () => {
    var token = localStorage.getItem("med_token");
    getAuth("authenticated", token)
      .then((response) => {
        this.setState(
          {
            authenticated: response.data.user,
          },
          () => {
            if (this.state.authenticated.usertype === "Patient") {
              this.getWalletInfo();
            }
          })
        })
  }  
  getWalletInfo(){
    var token = localStorage.getItem("med_token");
    if(this.state.authenticated && this.state.authenticated.email){
      postDataAuth("getWalletInfo", {
        "email":this.state.authenticated.email
      }, token).then((response) => { // console.log(response.medicalExperts)
  
        this.setState({
          wallet_id: response && response.length > 0 && response[0].wallet_id ? response[0].wallet_id : "",
          currency : this.state.authenticated.country === "Nigeria" ? "NGN" : "USD"
        })
      }).catch((error) => {})

      postDataAuth("getWalletBalance", {
        "email":this.state.authenticated.email
      }, token).then((response) => {
          this.setState({
              walletBalance: response && response.balance ? response.balance : ""
          })
      }).catch((error) => {})

      postDataAuth("getWalletTransactions", {
        "email":this.state.authenticated.email
      }, token).then((response) => { // console.log(response.medicalExperts)
        
          const transactions = response && response.length > 0 ? response : [];
          if(transactions.length > 0){
            this.setState({
              transactions: transactions
            })
          }
          
      }).catch((error) => {})
    }
  }
 
    render(){
       
        return(
            <div className="container-fluid mul pay-page">
                
                <div className="row mt-2 ml-5">
                    <img src={logo} width="300px"></img>
                </div>
                <div className="row justify-content-center mt-4">
                     <div className="col-12 text-center">
                         <span className="px36 f7 col-a0" >Flexible </span>
                         <span className="px36 f7 col-20" >Plans</span>
                     </div>
                     <div className="col-12 mt-1 text-center px24 f4 col-35">
                         Choose a plan that works best for you <br/> from our subscription packages
                     </div>
                </div>

                <div className="row mt-4">
                    {/* gap */}
                    <span className="sp-9"></span>
                    {/* 1st */}
                    <span className="pak mt-2">
                        <div className="row justify-content-center">
                            <div className="col-10 bc-ff bd12" style={{height:'55vh'}}>
                            <div className="row ml-3 mt-3 f4 px20 col-35">
                             Monthly
                        </div>
                        <div className="row ml-3 mt-3">
                             <span className="px28 f7 col-35" >$200 </span>
                             <span className="px12 f7 col-35" style={{marginTop:'15px', marginLeft:'5px'}}>/month</span>
                        </div>
                        <div className="row mt-2 ml-3">
                             <span className="px12 f4 col-35">Elit cras dignissim sed <br/>bibendum facilisi </span>
                        </div>
                        <div className="row ml-3 mt-5">
                             <span>
                                 <FontAwesomeIcon icon={faCheckCircle} color="#5555C0" size="25px"/>      
                            </span>
                             <span className="px16 f4 col-35" style={{marginLeft:'10px'}}>Volutpat ullamcorper</span>
                        </div>
                        <div className="row ml-3 mt-2">
                             <span>
                                 <FontAwesomeIcon icon={faCheckCircle} color="#5555C0" size="25" />      
                            </span>
                             <span className="px16 f4 col-35" style={{ marginLeft:'10px'}}>Vestibulum faucibus</span>
                        </div>
                        <div className="row justify-content-center" style={{position:'absolute', bottom:'5px',width:'100%'}}>
                             <div className="col-8 mb-3 choose-plan-wrapper" style={{border:"1px solid #0000a0", borderRadius:'5px',cursor:'pointer'}}>
                                 {/* <div className="text-center pt-1 pb-1" style={{}}>Choose Plan</div> */}
                                 {this.state.authenticated && 
                                <FlutterPay type="topup" configuration={{
                                    text : "Choose Plan",
                                    description : "Top your wallet",
                                    extraClass : "text-center pt-1 pb-1 choose-plan",
                                    user : this.state.authenticated,
                                    amount: 200
                                }}/>
                                }
                             </div>
                             
                        </div>
                            </div>
                        </div>
                      
                    </span>
                    
                     {/* Gap */}
                     <span className="sp-9 mobile"></span>
                     <span style={{width:'1%'}} className="desktop"></span>
                     <span className="sp-9 mobile"></span>
                    {/* 2nd */}
                    <span className="pak mt-2" >
                        <div className="row justify-content-center">
                            <div className="col-10 bc-ff bd12" style={{height:'55vh'}}>
                            <div className="row ml-3 mt-3 f4 px20 col-35">
                            Quarterly
                        </div>
                        <div className="row ml-3 mt-3">
                            <span className="px28 f7 col-35" >$200 </span>
                             <span className="px12 f7 col-35" style={{marginTop:'15px', marginLeft:'5px'}}>/month</span>
                        </div>
                        <div className="row mt-2 ml-3">
                              <span className="px12 f4 col-35">Tempor, elit, velit tristique proin<br/> hendrerit dui non. Sodales.</span>
                        </div>
                        <div className="row ml-3 mt-5">
                             <span>
                                 <FontAwesomeIcon icon={faCheckCircle} color="#5555C0" size="25px"/>      
                            </span>
                             <span className="px16 f4 col-35" style={{marginLeft:'10px'}}>Sed ullamcorper</span>
                        </div>
                        <div className="row ml-3 mt-2">
                             <span>
                                 <FontAwesomeIcon icon={faCheckCircle} color="#5555C0" size="25" />      
                            </span>
                             <span className="px16 f4 col-35" style={{ marginLeft:'10px'}}>Eros tincidunt a</span>
                        </div>
                        <div className="row ml-3 mt-2">
                             <span>
                                 <FontAwesomeIcon icon={faCheckCircle} color="#5555C0" size="25px"/>      
                            </span>
                             <span className="px16 f4 col-35" style={{marginLeft:'10px'}}>Aliquam a pharetra</span>
                        </div>
                        <div className="row ml-3 mt-2">
                             <span>
                                 <FontAwesomeIcon icon={faCheckCircle} color="#5555C0" size="25px"/>      
                            </span>
                             <span className="px16 f4 col-35" style={{marginLeft:'10px'}}>Pharetra adipiscing</span>
                        </div>
                        <div className="row justify-content-center" style={{position:'absolute', bottom:'5px',width:'100%'}}>
                        <div className="col-8 mb-3 choose-plan-wrapper" style={{border:"1px solid #0000a0", borderRadius:'5px',cursor:'pointer'}}>
                                 {/* <div className="text-center pt-1 pb-1" style={{}}>Choose Plan</div> */}
                                 {this.state.authenticated && 
                                <FlutterPay type="topup" configuration={{
                                    text : "Choose Plan",
                                    description : "Top your wallet",
                                    extraClass : "text-center pt-1 pb-1 choose-plan",
                                    user : this.state.authenticated,
                                    amount: 200
                                }}/>
                                }
                             </div>
                        </div>
                            </div>
                        </div>
                      
                    </span>
                    {/* gap */}
                    <span className="sp-9 mobile"></span>
                    <span style={{width:'1%'}} className="desktop"></span>
                    <span className="sp-9 mobile"></span>
                    {/* 3rd */}
                    <span className="pak mt-2" >
                        <div className="row justify-content-center">
                            <div className="col-10 bc-ff bd12" style={{height:'55vh'}}>
                            <div className="row ml-3 mt-3 f4 px20 col-35">
                            Yearly
                        </div>
                        <div className="row ml-3 mt-3">
                            <span className="px28 f7 col-35" >$200 </span>
                             <span className="px12 f7 col-35" style={{marginTop:'15px', marginLeft:'5px'}}>/month</span>
                        </div>
                        <div className="row mt-2 ml-3">
                              <span className="px12 f4 col-35">Suspendisse pretium leo morbi <br/>augue sit. Ac a tellus odio.</span>
                        </div>
                        <div className="row ml-3 mt-5">
                             <span>
                                 <FontAwesomeIcon icon={faCheckCircle} color="#5555C0" size="25px"/>      
                            </span>
                             <span className="px16 f4 col-35" style={{marginLeft:'10px'}}>Sollicitudin semper</span>
                        </div>
                        <div className="row ml-3 mt-2">
                             <span>
                                 <FontAwesomeIcon icon={faCheckCircle} color="#5555C0" size="25" />      
                            </span>
                             <span className="px16 f4 col-35" style={{ marginLeft:'10px'}}>Lectus sit</span>
                        </div>
                        <div className="row ml-3 mt-2">
                             <span>
                                 <FontAwesomeIcon icon={faCheckCircle} color="#5555C0" size="25px"/>      
                            </span>
                             <span className="px16 f4 col-35" style={{marginLeft:'10px'}}>Fringilla</span>
                        </div>
                        <div className="row ml-3 mt-2">
                             <span>
                                 <FontAwesomeIcon icon={faCheckCircle} color="#5555C0" size="25px"/>      
                            </span>
                             <span className="px16 f4 col-35" style={{marginLeft:'10px'}}>Lobortis nunc.</span>
                        </div>
                        <div className="row justify-content-center" style={{position:'absolute', bottom:'5px',width:'100%'}}>
                        <div className="col-8 mb-3 choose-plan-wrapper" style={{border:"1px solid #0000a0", borderRadius:'5px',cursor:'pointer'}}>
                                 {/* <div className="text-center pt-1 pb-1" style={{}}>Choose Plan</div> */}
                                 {this.state.authenticated && 
                                <FlutterPay type="topup" configuration={{
                                    text : "Choose Plan",
                                    description : "Top your wallet",
                                    extraClass : "text-center pt-1 pb-1 choose-plan",
                                    user : this.state.authenticated,
                                    amount: 200
                                }}/>
                                }
                             </div>
                        </div>
                            </div>
                        </div>
                      
                    </span>
                    {/* gap */}
                    <span className="sp-9 mobile"></span>
                    <span style={{width:'1%'}} className="desktop"></span>
                    <span className="sp-9 mobile"></span>
                    
                    {/* 4th */}
                    <span className="pak mt-2" >
                        <div className="row justify-content-center">
                            <div className="col-10 bc-ff bd12" style={{height:'55vh'}}>
                            <div className="row ml-3 mt-3 f4 px20 col-35">
                            Instant Payment
                        </div>
                        <div className="row ml-3 mt-3">
                            <span className="px28 f7 col-35" >$200 </span>
                             <span className="px12 f7 col-35" style={{marginTop:'15px', marginLeft:'5px'}}>/month</span>
                        </div>
                        <div className="row mt-2 ml-3">
                              <span className="px12 f4 col-35">Cras faucibus odio facilisis <br/>ipsum. Lacus viverra pharetra.</span>
                        </div>
                        <div className="row ml-3 mt-5">
                             <span>
                                 <FontAwesomeIcon icon={faCheckCircle} color="#5555C0" size="25px"/>      
                            </span>
                             <span className="px16 f4 col-35" style={{marginLeft:'10px'}}>Enim porta faucibus.</span>
                        </div>
                        <div className="row ml-3 mt-2">
                             <span>
                                 <FontAwesomeIcon icon={faCheckCircle} color="#5555C0" size="25" />      
                            </span>
                             <span className="px16 f4 col-35" style={{ marginLeft:'10px'}}>Sed vitae imperdiet.</span>
                        </div>
                        <div className="row justify-content-center" style={{position:'absolute', bottom:'5px',width:'100%'}}>
                        <div className="col-8 mb-3 choose-plan-wrapper" style={{border:"1px solid #0000a0", borderRadius:'5px',cursor:'pointer'}}>
                                 {/* <div className="text-center pt-1 pb-1" style={{}}>Choose Plan</div> */}
                                 {this.state.authenticated && 
                                <FlutterPay type="topup" configuration={{
                                    text : "Choose Plan",
                                    description : "Top your wallet",
                                    extraClass : "text-center pt-1 pb-1 choose-plan",
                                    user : this.state.authenticated,
                                    amount: 200
                                }}/>
                                }
                             </div>
                        </div>
                            </div>
                        </div>
                      
                    </span>
                    
                    {/* end */}
                </div>
            </div>
        )
    }
}