import React from "react";
import {
  getAuth,
  fetchData,
  postDataAuth,
  fetchDataAuth,
} from "../services/request";
// import waveLogo from "../assets/images/fulllogo.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { captializeFirstLetter } from "./common";
// import {
//   faUser,
//   faVideo,
//   faPowerOff,
//   faEllipsisV,
//   faUnlink,
//   faIdBadge,
//   faEnvelopeOpenText,
//   faMapMarkedAlt,
// } from "@fortawesome/free-solid-svg-icons";
// import { NoChat } from "./pieces";
// import io from "socket.io-client";
// import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import { Link, Redirect, withRouter } from "react-router-dom";
// import {
//   Alert,
//   Tooltip,
//   Whisper,
//   Modal,
//   Button,
//   Drawer,
//   Toggle,
//   Loader,
// } from "rsuite";
import WalletImg from "../assets/Wallet.png";
import Coin from "../assets/Coin.png";
import Cash from "../assets/Cash.png";
// import { baseUrl } from "./../services/request";
// import PayStack from "./PayStack";

export default class Wallet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPatientVitalStats: true,
      wallet_id: null,
      walletBalance: 0,
      redirect: false,
    };
  }

  closePatientVital = () => {
    this.setState({ showPatientVitalStats: false });
  };
  handlePatientVitalStats = () => {
    this.setState({
      showPatientVitalStats: !this.state.showPatientVitalStats,
    });
  };

  handleWalletTransactioDetails = () => {
    this.setState({
      redirect: true,
    });
  };

  componentDidMount = () => {
    var token = localStorage.getItem("med_token");
    if (
      this.props.loggedinUser.secondaryEmail == "" ||
      this.props.loggedinUser.secondaryEmail == null
    ) {
      console.log("ok");
      postDataAuth(
        "getWalletInfo",
        {
          email: this.props.loggedinUser.email,
        },
        token
      )
        .then((response) => {
          // console.log(response.medicalExperts)
          this.setState({
            wallet_id:
              response && response.length > 0 && response[0].wallet_id
                ? response[0].wallet_id
                : "",
          });
        })
        .catch((error) => {});

      postDataAuth(
        "getWalletBalance",
        {
          email: this.props.loggedinUser.email,
        },
        token
      )
        .then((response) => {
          this.setState({
            walletBalance: response && response.balance ? response.balance : "",
          });
        })
        .catch((error) => {});

      postDataAuth(
        "getWalletTransactions",
        {
          email: this.props.loggedinUser.email,
        },
        token
      )
        .then((response) => {
          // console.log(response.medicalExperts)

          const transactions = response && response.length > 0 ? response : [];
          if (transactions.length > 0) {
            this.setState({
              transactionId: transactions[0].transaction_id,
              type: transactions[0].transaction_type,
              description: transactions[0].description,
              amount: transactions[0].amount,
              balance: transactions[0].balance,
              created_at: transactions[0].created_at,
            });
          }
        })
        .catch((error) => {});
    }
    // if (this.props.loggedinUser && this.props.loggedinUser.email) {
    else {
      postDataAuth(
        "getWalletInfo",
        {
          email: this.props.loggedinUser.secondaryEmail,
        },
        token
      )
        .then((response) => {
          // console.log(response.medicalExperts)
          this.setState({
            wallet_id:
              response && response.length > 0 && response[0].wallet_id
                ? response[0].wallet_id
                : "",
          });
        })
        .catch((error) => {});

      postDataAuth(
        "getWalletBalance",
        {
          email: this.props.loggedinUser.secondaryEmail,
        },
        token
      )
        .then((response) => {
          this.setState({
            walletBalance: response && response.balance ? response.balance : "",
          });
        })
        .catch((error) => {});

      postDataAuth(
        "getWalletTransactions",
        {
          email: this.props.loggedinUser.secondaryEmail,
        },
        token
      )
        .then((response) => {
          // console.log(response.medicalExperts)

          const transactions = response && response.length > 0 ? response : [];
          if (transactions.length > 0) {
            this.setState({
              transactionId: transactions[0].transaction_id,
              type: transactions[0].transaction_type,
              description: transactions[0].description,
              amount: transactions[0].amount,
              balance: transactions[0].balance,
              created_at: transactions[0].created_at,
            });
          }
        })
        .catch((error) => {});
    }
  };

  componentWillMount = () => {};

  render() {
    if (this.state.loggedIn === false) {
      return <Redirect to="/login" />;
    }

    if (this.state.redirect === true) {
      return <Redirect to="/viewTransactions" />;
    }
    return (
      <>
        {/* <Drawer
                show={this.state.showPatientVitalStats}
                onHide={this.closePatientVital}
                size="xs"
                placement="left"
              >
                <Drawer.Header>
                  <Drawer.Title></Drawer.Title>
                </Drawer.Header>
                <Drawer.Body style={{"wordWrap":"break-word"}}>
                  
                </Drawer.Body>
                <Drawer.Footer>
                  <Button onClick={this.closeMenu} appearance="primary">Confirm</Button>
              <Button onClick={this.closeMenu} appearance="subtle">Cancel</Button>
                </Drawer.Footer>
              </Drawer> */}
        {this.state.showPatientVitalStats && (
          // <div class="Wallet-Wrapper mt-3 pt-2">
          //   <div className="text-dark p-2 pl-3 pr-3 rounded dark-blue-border mt-4 h5  text-truncate bg-white">
          //     <strong className="h5 font-weight-bold"> Wallet ID </strong> :{" "}
          //     {this.state.wallet_id}
          //   </div>
          //   <div className="text-dark p-2 pl-3 pr-3 rounded dark-blue-border mt-4 h5 bg-white">
          //     <strong className="h5 font-weight-bold">
          //       {" "}
          //       Current Currency{" "}
          //     </strong>{" "}
          //     : NGN
          //   </div>
          //   <div className="text-dark p-2 pl-3 pr-3 rounded dark-blue-border mt-4 h5 bg-white">
          //     <strong className="h5 font-weight-bold">
          //       {" "}
          //       Available Balance{" "}
          //     </strong>{" "}
          //     : {this.state.walletBalance}
          //   </div>
          //   <br />
          //   <Card className="dark-blue-border" style={{ width: "" }}>
          //     <Card.Header className="bg-white text-dark card-header dark-blue-border font-weight-bold h5 border-0">
          //       Latest Transaction
          //     </Card.Header>
          //     <ListGroup variant="flush">
          //       <ListGroup.Item className="h6 text-dark">
          //         <strong>ID</strong>: {this.state.transactionId}
          //       </ListGroup.Item>
          //       <ListGroup.Item className="h6 text-dark">
          //         <strong>Type</strong> : {this.state.type}
          //       </ListGroup.Item>
          //       <ListGroup.Item className="h6 text-dark">
          //         <strong>Description</strong> : {this.state.description}
          //       </ListGroup.Item>
          //       <ListGroup.Item className="h6 text-dark">
          //         <strong>Transaction</strong> : {this.state.amount} (NGN)
          //       </ListGroup.Item>
          //       <ListGroup.Item className="h6 text-dark">
          //         <strong>New Balance</strong> : {this.state.balance} (NGN)
          //       </ListGroup.Item>
          //       <ListGroup.Item className="h6 text-dark">
          //         <strong>Date</strong> : {this.state.created_at}
          //       </ListGroup.Item>
          //     </ListGroup>
          //   </Card>

          //   {/* <span>Set Status</span><br/><br/>
          //    */}
          //   <button
          //     onClick={this.handleWalletTransactioDetails}
          //     className="rounded BGBlueColor text-white btn-block mt-3 p-3"
          //   >
          //     View Complete Transaction History
          //   </button>
          //   {/* <PayStack /> */}
          //   <br />
          // </div>
          <>
            <div className="row mt-4 ml-3">
              <div className="col-12 ">
                <div className="mt-2">
                  <img src={WalletImg} alt=""></img>
                  <span className="ml-2 fz16 ">
                    <b>Wallet ID :</b> {this.state.wallet_id}
                  </span>
                </div>
                <div className="mt-2">
                  <img src={Coin} alt=""></img>
                  <span className="ml-2 fz16 ">
                    <b>Current Currency :</b> NGN
                  </span>
                </div>
                <div className="mt-2">
                  <img src={Cash} alt=""></img>
                  <span className="ml-2 fz16">
                    <b>Avalaible Balance :</b> {this.state.walletBalance}
                  </span>
                </div>
              </div>
            </div>
            <div className="row ml-5 mt-5">
              <div className="col-10">
                <table class="table">
                  <thead className="transcation bc2s clff">
                    <tr>
                      <th scope="col">Latest Transaction</th>
                    </tr>
                  </thead>
                  <tbody className="transcation-row">
                    <tr>
                      <td>ID : {this.state.transactionId}</td>
                    </tr>
                    <tr>
                      <td>Type : {this.state.type}</td>
                    </tr>
                    <tr>
                      <td>Description : {this.state.description}</td>
                    </tr>
                    <tr>
                      <td>Amount : {this.state.amount} (NGN)</td>
                    </tr>
                    <tr>
                      <td>Date : {this.state.created_at}</td>
                    </tr>
                  </tbody>
                  <thead className="transcation bc-green2 clff text-center">
                    <tr>
                      <th
                        scope="col"
                        className="cur"
                        onClick={this.handleWalletTransactioDetails}
                      >
                        View full history
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
