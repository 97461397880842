import React from "react";
import MadicalLogin from "./madicalExpertLogin";

export default class ExpertHome extends React.Component {
  render() {
    if (
      window.location.href.indexOf("https") == -1 &&
      window.location.href.indexOf("wavetelemedicine") > -1
    ) {
      window.location.href = "https://wavetelemedicine.com/";
    }
    return <MadicalLogin />;
  }
}
