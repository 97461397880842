import React from "react";
import { Row, Col } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Alert } from "rsuite";
import { postData } from "../../services/request";
import { Redirect } from "react-router-dom";
import waveLogo from "../../assets/images/fulllogo.png";
import translate from "../../i18n/translation";
export default class AdminLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      authenticated: false,
    };
  }

  login = (e) => {
    e.preventDefault();
    var details = {
      email: e.target.email.value,
      password: e.target.password.value,
    };
    if (details.email.trim() === null || details.email.trim() === "") {
      Alert.warning("Input your username address");
    } else if (
      details.password.trim() === null ||
      details.password.trim() === ""
    ) {
      Alert.warning("Input your password");
    } else {
      this.setState({ loader: true });
      postData("admin-login", details)
        .then((response) => {
          this.setState({ loader: false });
          if (response.status === true) {
            localStorage.setItem("med_token", response.token);
            sessionStorage.setItem("admin", "admin");
            Alert.success("Logging you in......");
            setTimeout(() => {
              this.setState({ authenticated: true });
            }, 5000);
          } else {
            Alert.error("Error logging you in");
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
          if (error.request.status == 401) {
            Alert.error("Authentication Failed");
          } else {
            Alert.error("Error connecting to server");
          }
        });
    }
  };
  showPassword = () => {
    var password = document.getElementById("password");
    if (password.type === "password") {
      password.type = "text";
    } else {
      password.type = "password";
    }
  };
  render() {
    if (this.state.authenticated === true) {
      return <Redirect to="/adminhome" />;
    }
    return (
      <div className="container-fluid backimg1">
        <Row className="justify-content-center">
          <div className="col-10 col-sm-4 welcome-container">
            <Row className="justify-content-center">
              <div className="col-10 col-sm-8 pt-4">
                <img src={waveLogo} alt="1" className="doc-images" />
              </div>
              <div className="col-10 col-sm-8 px23 mt-4 fw-b">Admin Login</div>
              <br />
              <div className="col-10 col-sm-8">
                <form style={{ width: "100%" }} onSubmit={this.login}>
                  <Row>
                    <Col>
                      <br />
                      <label className="rem1">Userame</label>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        className="form-control bc-f5"
                      />
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col className="position-relativt">
                      <label className="rem1">Password</label>
                      <input
                        type="password"
                        className="form-control bc-f5"
                        name="password"
                        id="password"
                      />
                      <FontAwesomeIcon
                        onClick={this.showPassword}
                        icon={faEye}
                        style={{
                          marginTop: "-24px",
                          cursor: "pointer",
                          position: "absolute",
                          zIndex: 9,
                          right: "20px",
                        }}
                      />
                    </Col>
                  </Row>
                  <br />

                  <Row className="no-gutters row">
                    <Col className="mt-3">
                      <input
                        type="checkbox"
                        name="checkbox"
                        value="check"
                        id="agree"
                      />
                      &nbsp;&nbsp;Keep me logged in
                    </Col>
                  </Row>
                  {this.state.loader === true ? (
                    <Loader
                      type="TailSpin"
                      width={60}
                      height={60}
                      color="blue"
                    />
                  ) : (
                    <>
                      <button className="signup-btn mt-3 mb-4">
                        {translate("login")}
                      </button>
                    </>
                  )}
                </form>
              </div>
            </Row>
          </div>
        </Row>
      </div>
    );
  }
}
