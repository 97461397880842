import { LOCALES } from "../locales";

export default {
  [LOCALES.IGBO]: {
    hello: "Nnọọ",
    welcome: "Nnọọ na Wave Telemedicine App",
    platformInfo1: `Ikpo okwu nke na-enye onye ọrịa ohere ijikọ ndị dọkịta kachasị mma na ngalaba Ahụike na nkasi obi nke ụlọ gị. Can nwere ike ịkwụ ụgwọ na njem ma ọ bụ melite mkpuchi Ahụike na ndị HMO ndị anyị na ha na-emekọ ihe.
    Ihe ọ bụla metụtara ahụike gị bụ na anyị ekpuchila gị.
    `,
    platformInfo2: `
    Site na mkparịta ụka vidiyo anyị nwere ike ịga na ndenye ọgwụ zuru oke na ụlọ ọgwụ na mpaghara gị.
    `,
    platformInfo3: `Ikpo okwu nke na-enye onye ọrịa ohere ijikọ ndị dọkịta kachasị mma na ngalaba Ahụike na nkasi obi nke ụlọ gị. Can nwere ike ịkwụ ụgwọ na njem ma ọ bụ melite mkpuchi Ahụike na ndị HMO ndị anyị na ha na-emekọ ihe.
    Ihe ọ bụla metụtara ahụike gị bụ na anyị ekpuchila gị.
   `,
    patient: `Ndidi`,
    "medical Expert": "Ọkachamara Ọgwụ",
    "sign In": "Banye",
    register: "Deba aha",
    rural: "ime obodo",
    login: "Banye",
    "sign Up": "Debanye aha",
    urban: "Obodo",
    Profile: "Profaịlụ",
    "date of birth": "Ụbọchị ọmụmụ",
    genotype: "Genotype",
    "blood Group": "Otu Ọbara",
    picture: "Foto",
    submit: "Nyefee",
    "rural users": "Ime ụlọ ọrụ ime obodo",
    user: "Onye ọrụ",
    registration: "Ndebanye aha",
    "already have an account?": "Have nweela akaụntụ?",
    select: "Họrọ",
    country: "Obodo",
    nigeria: "Naịjirịa",
    "united Kingdom": "United Kingdom",
    usa: "USA",
    "welcome to wave": `
    Nabata na WaveTelemedicine. Ikpo okwu nke na-enye gị ohere izute dọkịta na nkasi obi nke ụlọ gị. Biko dejupụta mpempe akwụkwọ a n'okpuru. Ihe ọmụma ahụ na-enye dọkịta ohere ịghọta ọnọdụ gị nke ọma`,
    areUMedication: "Are na-a medicationụ ọgwụ?",
    anyIllness: "Ọrịa ọ bụla dị ugbu a?",
    doUSuffer: "Na-arịa ọrịa shuga?",
    anyRespiratiory: "Ọrịa ọ bụla metụtara Respiratiory?",
    no: "Mba",
    yes: "Eeh",
    nodoctor: `Enweghị dọkịta dị site na obodo gị n'oge a. Ọ ga-amasị gị ijikọ dọkịta na mba ọzọ?`,
    startconversation: "Pụrụ ịmalite mkparịta ụka na",
    fetchingdoctors: "Etchọta ndị dọkịta dị na mba gị",
    connectedDoctor: "Njikọ dọkịta",
    yourconnected: "Ejikọtara gị na Dr.",
    typeMessage: "Pịnye ozi",
    patientName: "Aha Onye Ọrịa",
    dob: "Ụbọchị ọmụmụ",
    bloodGroup: "Otu Ọbara",
    genotype: "Genotype",
    anitbodies: "Ihe mgbochi",
    currentIllnes: "Ọrịa Ugbu A",
    anyRespiratory: "Akụkụ ume ọ bụla",
    diabetic: "Ọrịa shuga",
    familyMedical: "Akụkọ gbasara ahụike ezinụlọ",
    bpm: "Mmetụta ọbara mgbali",
    bsl: "Ọbara shuga dị n’ọbara",
    ct: "Ugbu okpomọkụ",
    cc: "Na-agbanwe agbanwe ughkwara",
    los: "Loss na-esi isi",
    aop: "Mgbu ma ọ bụ Mgbu?",
    dizziness: "Dizzziz",
    lob: "Loss nke itule",
    travel: "You si obodo ọzọ pụọ n’ụbọchị asaa gara aga",
    meet: "Mmekọrịta ọ bụla na onye ọ bụla nke si na mba ofesi lọta na ụbọchị asaa gara aga?",
    medical: "Akụkọ Ahụike",
    nomedicalhistory: "Enweghị Ahụike Ọgwụ",
    connectedPatient: "Njikọ Ndidi",
    viewpatientprofile: "Lelee profaịlụ ndị ọrịa",
    makePrescription: "Mee ndenye ọgwụ",
    "Hello Doc": "Ndewo Doc",
    "I have the Flu": "Enwere m flu",
    "I have Sore throat": "Enwere m akpịrị akpịrị",
    "I have Stomach Cramps": "Enwere m afọ mgbu",
    "I feel Dizzy": "Ọ dị m ka ajụ na-ebu m",
    "I feel unwell": "Ahụ adịghị m mma",
    "I have a Headache": "Enwere m isi ọwụwa",
    "I am feeling Feverish": "Ahụ ọkụ na-adị m",
  },
};
