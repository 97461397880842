import React, { useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import IndexComponent from "./componenets/index";
import "./assets/style/style.css";
import SignUp from "./componenets/signUp";
import Login from "./componenets/login";
import DashBoard from "./componenets/dash";
import PatientIndex from "./componenets/patientindex";
import MedicalIndex from "./componenets/medicalindex";
import Ups from "./componenets/urbanpatientSignUp";
import UrbanLogin from "./componenets/urbanLogin";
import RG from "./componenets/ruralreg";
import RuralLogin from "./componenets/ruralLogin";
import RuralReg from "./componenets/ruralSignup";
import WalletTransaction from "./componenets/walletTransaction";
import AiQuestionnaire from "./componenets/patient-ai/questionnaire";

import { I18nProvider, LOCALES } from "./i18n";
import PaymentPlan from "./componenets/PaymentPlan";
import ExpertHome from "./componenets/madicalExpert/homePge";
import PatientHome from "./componenets/patientPortal/userType";
import UrbanUser from "./componenets/patientPortal/urbanPortal";
import RuralUser from "./componenets/patientPortal/ruralPortal";
import RuralSignUp from "./componenets/patientPortal/ruralSignUp";
import UrbanSignUp from "./componenets/patientPortal/urbanSignUp";
import UrbanUserLogin from "./componenets/patientPortal/urbanLogin";
import RuralUserLogin from "./componenets/patientPortal/ruralLogin";
import MadicalExpertLogin from "./componenets/madicalExpert/madicalExpertLogin";
import MadicalExpertSignUp from "./componenets/madicalExpert/madicalExpertSignUp";
import PatientQuestion from "./componenets/patientPortal/question";
import AdminLogin from "./componenets/admin/adminlogin";
import AdminHome from "./componenets/admin/adminhome";
import Doctor from "./componenets/admin/doctor";
import Setting from "./componenets/admin/setting";
import Comment from "./componenets/DialogBox/Comment";
import FeedBack from "./componenets/admin/feedback";
import Transaction from "./componenets/admin/transaction";
import RecoverPassword from "./componenets/password/ForgetPassword";
import ResetPassword from "./componenets/password/ResetPassword";
import NGOHome from "./componenets/ngo/NGOHome";
import UserLogin from "./componenets/patientPortal/panel/userLogin";
import UserPanel from "./componenets/patientPortal/panel/userPanel";
import AddBeneficiary from "./componenets/patientPortal/panel/addBeneficiary";
import AllBeneficiary from "./componenets/patientPortal/panel/allBeneficiary";
import NGO from "./componenets/patientPortal/ngoPortal";
import NGOSignUp from "./componenets/patientPortal/ngoSignUp";
import AdminDashBoard from "./componenets/admin/adminDashboard";
import Patients from "./componenets/admin/patients";

function App() {
  const [locate, setLocate] = useState(LOCALES.ENGLISH);
  const handleLocales = (event) => {
    const selectedLang = event.target.value || "en-us";
    if (selectedLang) {
      sessionStorage.setItem("language", selectedLang.split("-")[0]);
    }

    setLocate(event.target.value);
  };
  return (
    <I18nProvider locale={locate}>
      <select
        style={{
          float: "right",
          marginRight: "60px",
          position: "absolute",
          top: "10px",
          right: "0",
          zIndex: 9,
        }}
        onChange={handleLocales}
      >
        <option value={LOCALES.ENGLISH}>English</option>
        <option value={LOCALES.GERMAN}>GERMAN</option>
        <option value={LOCALES.FRENCH}>FRENCH</option>
        <option value={LOCALES.IGBO}>IGBO</option>
        <option value={LOCALES.YORUBA}>YORUBA</option>
        <option value={LOCALES.HAUSA}>HAUSA</option>
      </select>
      <button
        onClick={() => {
          sessionStorage.removeItem("aiform");
          sessionStorage.removeItem("admin");
          window.location.href = "/";
        }}
        className="btn  float-right pt-1 pb-0 pl-1 pr-1 m-1 btn-hover"
      >
        logout
      </button>
      <Router>
        <Route path="/" exact component={IndexComponent} />
        {/* <Route path="/signup" exact component={IndexComponent}/> */}
        <Route path="/doctorsignup" component={SignUp} />
        <Route path="/login" component={Login} />
        <Route path="/dashboard" component={DashBoard} />
        <Route path="/urbansignup" component={Ups} />
        <Route path="/patientIndex" component={PatientIndex} />
        <Route path="/medicalIndex" component={MedicalIndex} />
        <Route path="/urbanlogin" component={UrbanLogin} />
        <Route path="/ruralreg" component={RG} />
        <Route path="/rurallogin" component={RuralLogin} />
        <Route path="/ruralsingup" component={RuralReg} />
        <Route path="/viewTransactions" component={WalletTransaction} />
        <Route path="/patient-detail-history" component={AiQuestionnaire} />
        <Route path="/payment" component={PaymentPlan} />

        <Route path="/expert" component={ExpertHome} />
        <Route path="/usertype" component={PatientHome} />
        <Route path="/urban" component={UrbanUser} />
        <Route path="/rural" component={RuralUser} />
        <Route path="/ngo" component={NGO} />
        <Route path="/rural-signup" component={RuralSignUp} />
        <Route path="/urban-signup" component={UrbanSignUp} />
        <Route path="/ngo-signup" component={NGOSignUp} />
        <Route path="/urban-login" component={UrbanUserLogin} />
        <Route path="/rural-login" component={RuralUserLogin} />
        <Route path="/dr-login" component={MadicalExpertLogin} />
        <Route path="/dr-signup" component={MadicalExpertSignUp} />
        <Route path="/question" component={PatientQuestion} />

        {/* admin */}
        <Route path="/admin" component={AdminLogin} />
        <Route path="/adminhome" component={AdminHome} />
        <Route path="/patients" component={Patients} />
        <Route path="/doctors" component={Doctor} />
        <Route path="/setting" component={Setting} />
        <Route path="/feedback" component={FeedBack} />
        <Route path="/transaction" component={Transaction} />
        <Route path="/adminDashboard" component={AdminDashBoard} />

        {/* test */}
        <Route path="/co" component={Comment} />

        {/* forgot password */}
        <Route path="/forgotPassword" component={RecoverPassword} />
        <Route path="/reset-password" component={ResetPassword} />

        {/* NGO */}
        <Route path="/ngo-home" component={NGOHome} />
        {/* User */}
        <Route path="/userlogin" component={UserLogin} />
        <Route path="/userpanel" component={UserPanel} />
        <Route path="/addbeneficiary" component={AddBeneficiary} />
        <Route path="/allbeneficiary" component={AllBeneficiary} />
      </Router>
    </I18nProvider>
  );
}

export default App;
