import { LOCALES } from "../locales";

export default {
  [LOCALES.ENGLISH]: {
    hello: "Hello",
    welcome: "Welcome To Wave Telemedicine App",
    platformInfo1: `The platform that allows the patient to connect to the best Doctors in the Medical field at the comfort of your home. You can pay on the go or set up Medical insurance with the HMOs that partner with us.
    Whatever your health concerns are we have got you covered.`,
    platformInfo2: `Through our video conferencing you can be fully attended to and prescription sent to a pharmacy in your area.`,
    platformInfo3: `The situation of the Covid-19 has affected the way we interact which has affected the Medical sector.`,
    patient: `Patient`,
    "medical Expert": "Medical Expert",
    "medical Expert portal": "Medical Expert Portal",
    "sign In": "SIGN IN",
    "select user type": "Select your user type below",
    register: "Register",
    rural: "Rural",
    "rural user": "Rural User",
    "rural user portal": "Rural user portal",
    "Rural patient signup": "Rural Patient Signup",
    login: "Login",
    "sign Up": "Sign Up",
    urban: "Urban",
    "urban user": "Urban User",
    "urban user portal": "Urban user portal",
    "Urban patient signup": "Urban Patient Signup",
    "Health care provider signup": "Health Care Provider Signup",
    Profile: "Profile",
    "date of birth": "Date of birth",
    genotype: "Genotype",
    "blood Group": "Blood Group",
    picture: "Picture",
    submit: "Submit",
    "rural users": "RURAL USERS",
    user: "User",
    registration: "Registration",
    "already have an account?": "Already have an account?",
    select: "Select",
    country: "Country",
    nigeria: "Nigeria",
    "united Kingdom": "United Kingdom",
    usa: "USA",
    "welcome to wave":
      "Welcome to WaveTelemedicine. The platform that allows you to meet with a doctor in the comfort of your home. Please complete this form below. The information allows the doctor to properly understand your situation",
    areUMedication: "Are you on medication?",
    anyIllness: "Any current illness?",
    doUSuffer: "Do you suffer from Diabetes?",
    anyRespiratiory: "Any Respiratiory related Illness?",
    no: "No",
    yes: "Yes",
    nodoctor:
      "There is no available doctor from your country at the moment. Would you like to be connected to a doctor from another country?",
    startconversation: "You can start a conversation with",
    fetchingdoctors: "Fetching doctors available in your country",
    connectedDoctor: "Connected Doctor",
    yourconnected: "You are connected to Dr.",
    typeMessage: "Type a message",
    patientName: "Patient Name",
    dob: "Date of Birth",
    bloodGroup: "Blood Group",
    genotype: "Genotype",
    anitbodies: "Antibodies",
    currentIllnes: "Current Illness",
    anyRespiratory: "Any Respiratory",
    diabetic: "Diabetic",
    familyMedical: "Family Medical History",
    bpm: "Blood pressure measurement",
    bsl: "Blood sugar level",
    ct: "Current temperature",
    cc: "Consistent Cough",
    los: "Loss of Smell",
    aop: "Aches or Pains?",
    dizziness: "Dizziness",
    lob: "Loss of balance",
    travel: "Have you travelled out of the country in the last 7 days",
    meet: "Any interaction with anyone that has just return from overseas in the last 7days ?",
    medical: "Medical History",
    nomedicalhistory: "No Medical History Found",
    connectedPatient: "Connected Patient",
    viewpatientprofile: "View Patient's Profile",
    makePrescription: "Make Prescription",
    referpatient: "Refer patient",
    // Chat Variables
    patientProfile: "Patient Profile",
    vitalStatistics: "Vital Statistics",
    ngo: "NGO",
    ngoPanel: "NGO Panel",
    "Hello Doc": "Hello Doc",
    "I have the Flu": "I have the Flu",
    "I have Sore throat": "I have Sore throat",
    "I have Stomach Cramps": "I have Stomach Cramps",
    "I feel Dizzy": "I feel Dizzy",
    "I feel unwell": "I feel unwell",
    "I have a Headache": "I have a Headache",
    "I am feeling Feverish": "I am feeling Feverish",
  },
};
