import React from "react";
import { postDataAuth } from "../../services/request";
import { Container, Row, Col } from "react-bootstrap";
import { Radio, RadioGroup, Loader } from "rsuite";
import Pulse from "react-reveal/Pulse";
import { Redirect } from "react-router-dom";
import Header3 from "../../componenets/headers/Header3";

export default class DashBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: null,
      formActivePanel1: 1,
      formActivePanel1Changed: false,
      Step2: false,
    };
  }

  swapFormActive = (a) => (param) => (e) => {
    this.setState({
      ["formActivePanel" + a]: param,
      ["formActivePanel" + a + "Changed"]: true,
    });
  };

  handleNextPrevClick = (a) => (param) => (e) => {
    this.setState({
      ["formActivePanel" + a]: param,
      ["formActivePanel" + a + "Changed"]: true,
    });
  };

  handleSubmission = (e) => {
    e.preventDefault();
    var details = {
      indexs:
        this.props.location.state && this.props.location.state.patient
          ? parseInt(this.props.location.state.patient.id)
          : 0, // patient id needs to be passed
      Gender: parseInt(this.state.gender),
      Sleep: parseInt(e.target.Sleep.value),
      Work: parseInt(e.target.Work.value),
      NoSugar: parseInt(this.state.noSugar),
      Vegetarian: parseInt(this.state.vegitratian),
      LowSugar: parseInt(this.state.lowSugar),
      LowCarbs: parseInt(this.state.lowcabs),
      keto: parseInt(this.state.keto),
      Lowsodium: parseInt(this.state.lowSodium),
      BPS: parseInt(e.target.bps.value),
      BPD: parseInt(e.target.BPD.value),
      BSF: parseInt(e.target.BSF.value),
      BSR: parseInt(e.target.BSR.value),
      HBA1C: parseInt(e.target.HBA1C.value),
      Steps: parseInt(e.target.Steps.value),
      Cardio: parseInt(e.target.Cardio.value),
      Gym: parseInt(e.target.Gym.value),
      MD: parseInt(this.state.MD),
      FD: parseInt(this.state.FH),
      BD: parseInt(this.state.BD),
      SD: parseInt(this.state.SD),
      MMD: parseInt(this.state.MMD),
      MFD: parseInt(this.state.MFD),
      FMD: parseInt(this.state.FMD),
      FFD: parseInt(this.state.FFD),
      MH: parseInt(this.state.MH),
      FH: parseInt(this.state.FH),
      SH: parseInt(this.state.SH),
      BH: parseInt(this.state.BH),
      MMH: parseInt(this.state.MMH),
      MFH: parseInt(this.state.MFH),
      FMH: parseInt(this.state.FMH),
      FFH: parseInt(this.state.FFH),
      BMI: parseInt(e.target.weight.value) / parseInt(e.target.height.value),
      Tea: parseInt(this.state.tea),
      Coffee: parseInt(this.state.coffee),
      Cannabis: parseInt(this.state.cannabis),
      Tobbaco: parseInt(this.state.tobacco),
      Daibetes: parseInt(this.state.diabetes),
      Migraines: parseInt(this.state.migrane),
      Hypertension: parseInt(this.state.hypertension),
      Cancer: parseInt(this.state.cancer),
      Kidney: parseInt(this.state.kidney),
      Alzheimer: parseInt(this.state.alzhiemers),
      Jaundice: parseInt(this.state.jaundice),
      Thyroid: parseInt(this.state.thyroid),
      Asthma: parseInt(this.state.asthma),
      Alcohol: parseInt(this.state.alcohol),
      Fastfood: parseInt(this.state.Fastfood),
    };

    if (details.Index == 0) {
      this.props.history.push("./patientIndex");
    } else {
      var token = localStorage.getItem("med_token");
      postDataAuth("savePatientInAI", details, token)
        .then((response) => {
          alert("Form submitted!");
          if (response) {
            this.props.history.push({
              pathname: "/dashboard",
              search: "",
              state: { vital: true },
            });
            sessionStorage.setItem("aiform", true);
            this.setState({ available: true });
          } else {
          }
        })
        .catch(() => {});
    }
  };

  calculateAutofocus = (a) => {
    if (this.state["formActivePanel" + a + "Changed"]) {
      return true;
    }
  };
  componentDidMount = () => {};

  render() {
    if (this.state.loggedIn === false) {
      return <Redirect to="/patientIndex" />;
    }
    return (
      <>
        <Container fluid className="container-fluid">
          <div className="">
            <Pulse>
              <div className="">
                <Header3 title="Patient Information" />
                <Row>
                  <div className="col-12 text-center fz16">
                    Please fill the form below. Do not leave any field blank or
                    form cannot be submitted
                  </div>
                </Row>
                <Row>
                  <Col md={1}></Col>
                  <Col md={10}>
                    <div className="m-3">
                      {/* First Step form */}
                      <form className="pt-4" onSubmit={this.handleSubmission}>
                        <div
                          className={!this.state.Step2 ? "d-block" : "d-none"}
                        >
                          <Row>
                            <Col xs={12} md={6}>
                              <span className="h5">What is your gender?</span>
                              <RadioGroup
                                name="radioList"
                                inline
                                onChange={(value, event) => {
                                  this.setState({ gender: value });
                                }}
                              >
                                <Radio className="h5" value="0">
                                  Male
                                </Radio>
                                <Radio className="h5" value="1">
                                  Female
                                </Radio>
                              </RadioGroup>
                            </Col>
                            <Col xs={12} md={6}>
                              <lable>
                                What is your average blood pressure systolic?
                              </lable>
                              <input
                                type="number"
                                placeholder="Blood pressure systolic"
                                className="text-boxe"
                                name="bps"
                                id="bps"
                              />
                            </Col>
                          </Row>
                          <br />

                          <Row>
                            <Col xs={12} md={6}>
                              <lable>How many hours a day do you sleep?</lable>
                              <input
                                type="number"
                                placeholder="Sleep Hours"
                                className="text-boxe"
                                name="Sleep"
                                id="Sleep"
                              />
                            </Col>

                            <Col xs={12} md={6}>
                              <label>
                                What is your average blood pressure diastolic?
                              </label>
                              <input
                                type="number"
                                placeholder="Blood pressure diastolic"
                                className="text-boxe"
                                name="BPD"
                                id="BPD"
                              />
                            </Col>
                          </Row>
                          <br />
                          <Row>
                            <Col xs={12} md={6}>
                              <lable>How many hours a day do you work?</lable>
                              <input
                                type="number"
                                placeholder="Work Hours"
                                className="text-boxe"
                                name="Work"
                                id="Work"
                              />
                            </Col>
                            <Col xs={12} md={6}>
                              <lable>
                                What is your average blood sugar fasting?
                              </lable>
                              <input
                                type="number"
                                placeholder="Blood sugar fasting"
                                className="text-boxe"
                                name="BSF"
                                id="BSF"
                              />
                            </Col>
                          </Row>
                          <br />
                          <Row>
                            <Col xs={12} md={6}>
                              <lable>
                                Are you on any of the following diets?
                              </lable>
                              <br />
                              <br />
                              <div className="pl-2">
                                <RadioGroup
                                  name="radioList"
                                  inline
                                  onChange={(value, event) => {
                                    this.setState({ noSugar: value });
                                  }}
                                >
                                  <span className="h6 w-30 d-inline-block">
                                    No Sugar
                                  </span>
                                  <Radio className="h6" value="1">
                                    Yes
                                  </Radio>
                                  <Radio className="h6" value="0">
                                    No
                                  </Radio>
                                </RadioGroup>
                                <br />
                                <RadioGroup
                                  name="radioList"
                                  inline
                                  onChange={(value, event) => {
                                    this.setState({ vegitratian: value });
                                  }}
                                >
                                  <span className="h6 w-30 d-inline-block">
                                    Vegitraian
                                  </span>
                                  <Radio className="h6" value="1">
                                    Yes
                                  </Radio>
                                  <Radio className="h6" value="0">
                                    No
                                  </Radio>
                                </RadioGroup>
                                <br />
                                <RadioGroup
                                  name="radioList"
                                  inline
                                  onChange={(value, event) => {
                                    this.setState({ lowSugar: value });
                                  }}
                                >
                                  <span className="h6 w-30 d-inline-block">
                                    Low Sugar
                                  </span>
                                  <Radio className="h6" value="1">
                                    Yes
                                  </Radio>
                                  <Radio className="h6" value="0">
                                    No
                                  </Radio>
                                </RadioGroup>
                                <br />
                                <RadioGroup
                                  name="radioList"
                                  inline
                                  onChange={(value, event) => {
                                    this.setState({ lowcabs: value });
                                  }}
                                >
                                  <span className="h6 w-30 d-inline-block">
                                    Low Carbs
                                  </span>
                                  <Radio className="h6" value="1">
                                    Yes
                                  </Radio>
                                  <Radio className="h6" value="0">
                                    No
                                  </Radio>
                                </RadioGroup>
                                <br />
                                <RadioGroup
                                  name="radioList"
                                  inline
                                  onChange={(value, event) => {
                                    this.setState({ lowSodium: value });
                                  }}
                                >
                                  <span className="h6 w-30 d-inline-block">
                                    Low Sodium
                                  </span>
                                  <Radio className="h6" value="1">
                                    Yes
                                  </Radio>
                                  <Radio className="h6" value="0">
                                    No
                                  </Radio>
                                </RadioGroup>
                                <br />
                                <RadioGroup
                                  name="radioList"
                                  inline
                                  onChange={(value, event) => {
                                    this.setState({ keto: value });
                                  }}
                                >
                                  <span className="h6 w-30 d-inline-block">
                                    Keto
                                  </span>
                                  <Radio className="h6" value="1">
                                    Yes
                                  </Radio>
                                  <Radio className="h6" value="0">
                                    No
                                  </Radio>
                                </RadioGroup>
                                <br />
                                <RadioGroup
                                  name="radioList"
                                  inline
                                  onChange={(value, event) => {
                                    this.setState({ Fastfood: value });
                                  }}
                                >
                                  <span className="h6 w-30 d-inline-block">
                                    Consume Fast Food
                                  </span>
                                  <Radio className="h6" value="1">
                                    Yes
                                  </Radio>
                                  <Radio className="h6" value="0">
                                    No
                                  </Radio>
                                </RadioGroup>
                              </div>
                            </Col>

                            <Col xs={12} md={6}>
                              <lable>
                                What is your average blood sugar random?
                              </lable>
                              <input
                                type="number"
                                placeholder="Blood sugar random"
                                className="text-boxe"
                                name="BSR"
                                id="BSR"
                              />
                              <br />
                              <br />
                              <lable>
                                What are your result from HBA1C test?
                              </lable>
                              <input
                                type="number"
                                placeholder="Blood sugar random"
                                className="text-boxe"
                                name="HBA1C"
                                id="HBA1C"
                              />
                              <br />
                              <label>
                                Do you consume any of the following?
                              </label>
                              <br />
                              <div className="pl-2">
                                <RadioGroup
                                  name="radioList"
                                  inline
                                  onChange={(value, event) => {
                                    this.setState({ tea: value });
                                  }}
                                >
                                  <span className="h6 w-30 d-inline-block">
                                    Tea
                                  </span>
                                  <Radio className="h6" value="1">
                                    Yes
                                  </Radio>
                                  <Radio className="h6" value="0">
                                    No
                                  </Radio>
                                </RadioGroup>
                                <br />
                                <RadioGroup
                                  name="radioList"
                                  inline
                                  onChange={(value, event) => {
                                    this.setState({ coffee: value });
                                  }}
                                >
                                  <span className="h6 w-30 d-inline-block">
                                    Coffee
                                  </span>
                                  <Radio className="h6" value="1">
                                    Yes
                                  </Radio>
                                  <Radio className="h6" value="0">
                                    No
                                  </Radio>
                                </RadioGroup>
                                <br />
                                <RadioGroup
                                  name="radioList"
                                  inline
                                  onChange={(value, event) => {
                                    this.setState({ cannabis: value });
                                  }}
                                >
                                  <span className="h6 w-30 d-inline-block">
                                    Cannabis
                                  </span>
                                  <Radio className="h6" value="1">
                                    Yes
                                  </Radio>
                                  <Radio className="h6" value="0">
                                    No
                                  </Radio>
                                </RadioGroup>
                                <br />
                                <RadioGroup
                                  name="radioList"
                                  inline
                                  onChange={(value, event) => {
                                    this.setState({ tobacco: value });
                                  }}
                                >
                                  <span className="h6 w-30 d-inline-block">
                                    Tobacco
                                  </span>
                                  <Radio className="h6" value="1">
                                    Yes
                                  </Radio>
                                  <Radio className="h6" value="0">
                                    No
                                  </Radio>
                                </RadioGroup>
                                <br />
                                <RadioGroup
                                  name="radioList"
                                  inline
                                  onChange={(value, event) => {
                                    this.setState({ alcohol: value });
                                  }}
                                >
                                  <span className="h6 w-30 d-inline-block">
                                    Alcohol
                                  </span>
                                  <Radio className="h6" value="1">
                                    Yes
                                  </Radio>
                                  <Radio className="h6" value="0">
                                    No
                                  </Radio>
                                </RadioGroup>
                              </div>
                            </Col>
                          </Row>
                        </div>

                        {/* STEP 2 */}
                        {this.state.Step2 && (
                          <>
                            <Row>
                              <Col xs={12} md={6}>
                                <label className="h6">
                                  Do you have any of following diseases ?
                                </label>
                                <br />
                                <div className="pl-2">
                                  <RadioGroup
                                    name="radioList"
                                    inline
                                    onChange={(value, event) => {
                                      this.setState({ diabetes: value });
                                    }}
                                  >
                                    <span className="h6 w-30 d-inline-block">
                                      Diabetes
                                    </span>
                                    <Radio className="h6" value="1">
                                      Yes
                                    </Radio>
                                    <Radio className="h6" value="0">
                                      No
                                    </Radio>
                                  </RadioGroup>
                                  <br />
                                  <RadioGroup
                                    name="radioList"
                                    inline
                                    onChange={(value, event) => {
                                      this.setState({ migrane: value });
                                    }}
                                  >
                                    <span className="h6 w-30 d-inline-block">
                                      Migrane
                                    </span>
                                    <Radio className="h6" value="1">
                                      Yes
                                    </Radio>
                                    <Radio className="h6" value="0">
                                      No
                                    </Radio>
                                  </RadioGroup>
                                  <br />
                                  <RadioGroup
                                    name="radioList"
                                    inline
                                    onChange={(value, event) => {
                                      this.setState({ hypertension: value });
                                    }}
                                  >
                                    <span className="h6 w-30 d-inline-block">
                                      Hypertension
                                    </span>
                                    <Radio className="h6" value="1">
                                      Yes
                                    </Radio>
                                    <Radio className="h6" value="0">
                                      No
                                    </Radio>
                                  </RadioGroup>
                                  <br />
                                  <RadioGroup
                                    name="radioList"
                                    inline
                                    onChange={(value, event) => {
                                      this.setState({ cancer: value });
                                    }}
                                  >
                                    <span className="h6 w-30 d-inline-block">
                                      Cancer
                                    </span>
                                    <Radio className="h6" value="1">
                                      Yes
                                    </Radio>
                                    <Radio className="h6" value="0">
                                      No
                                    </Radio>
                                  </RadioGroup>
                                  <br />
                                  <RadioGroup
                                    name="radioList"
                                    inline
                                    onChange={(value, event) => {
                                      this.setState({ kidney: value });
                                    }}
                                  >
                                    <span className="h6 w-30 d-inline-block">
                                      Kidney
                                    </span>
                                    <Radio className="h6" value="1">
                                      Yes
                                    </Radio>
                                    <Radio className="h6" value="0">
                                      No
                                    </Radio>
                                  </RadioGroup>
                                  <br />
                                  <RadioGroup
                                    name="radioList"
                                    inline
                                    onChange={(value, event) => {
                                      this.setState({ alzhiemers: value });
                                    }}
                                  >
                                    <span className="h6 w-30 d-inline-block">
                                      Alzhiemers
                                    </span>
                                    <Radio className="h6" value="1">
                                      Yes
                                    </Radio>
                                    <Radio className="h6" value="0">
                                      No
                                    </Radio>
                                  </RadioGroup>
                                  <br />
                                  <RadioGroup
                                    name="radioList"
                                    inline
                                    onChange={(value, event) => {
                                      this.setState({ jaundice: value });
                                    }}
                                  >
                                    <span className="h6 w-30 d-inline-block">
                                      Jaundice
                                    </span>
                                    <Radio className="h6" value="1">
                                      Yes
                                    </Radio>
                                    <Radio className="h6" value="0">
                                      No
                                    </Radio>
                                  </RadioGroup>
                                  <br />
                                  <RadioGroup
                                    name="radioList"
                                    inline
                                    onChange={(value, event) => {
                                      this.setState({ thyroid: value });
                                    }}
                                  >
                                    <span className="h6 w-30 d-inline-block">
                                      Thyroid
                                    </span>
                                    <Radio className="h6" value="1">
                                      Yes
                                    </Radio>
                                    <Radio className="h6" value="0">
                                      No
                                    </Radio>
                                  </RadioGroup>
                                  <br />
                                  <RadioGroup
                                    name="radioList"
                                    inline
                                    onChange={(value, event) => {
                                      this.setState({ asthma: value });
                                    }}
                                  >
                                    <span className="h6 w-30 d-inline-block">
                                      Asthma
                                    </span>
                                    <Radio className="h6" value="1">
                                      Yes
                                    </Radio>
                                    <Radio className="h6" value="0">
                                      No
                                    </Radio>
                                  </RadioGroup>
                                  <br />
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <label>
                                  What are you average daily steps? (take from
                                  apple watch)
                                </label>
                                <input
                                  type="number"
                                  placeholder="Average daily steps"
                                  className="text-boxe"
                                  name="Steps"
                                  id="Steps"
                                />
                                <br />
                                <br />
                                <label>
                                  How many hours weight exercise do you do
                                  daily?
                                </label>
                                <input
                                  type="number"
                                  placeholder="Daily Exercise"
                                  className="text-boxe"
                                  name="Gym"
                                  id="Gym"
                                />
                                <br />
                                <br />
                                <label>How many hours cardio do you do?</label>
                                <input
                                  type="number"
                                  placeholder="Cardio"
                                  className="text-boxe"
                                  name="Cardio"
                                  id="Cardio"
                                />
                                <br />
                                <br />
                                <label>What is your Weight (Kgs)?</label>
                                <input
                                  type="number"
                                  placeholder="Weight"
                                  className="text-boxe"
                                  name="weight"
                                  id="weight"
                                />
                                <br />
                                <br />
                                <label>
                                  What is your Height (centimeters)?
                                </label>
                                <input
                                  type="number"
                                  placeholder="Height"
                                  className="text-boxe"
                                  name="height"
                                  id="height"
                                />
                              </Col>
                            </Row>
                            <br />
                            <br />
                            <Row>
                              <Col xs={12} md={6}>
                                <label className="h6">
                                  Do you any of following members of your family
                                  have diabetes ?
                                </label>
                                <br />
                                <div className="pl-2">
                                  <RadioGroup
                                    name="radioList"
                                    inline
                                    onChange={(value, event) => {
                                      this.setState({ FD: value });
                                    }}
                                  >
                                    <span className="h6 w-30 d-inline-block">
                                      Father
                                    </span>
                                    <Radio className="h6" value="1">
                                      Yes
                                    </Radio>
                                    <Radio className="h6" value="0">
                                      No
                                    </Radio>
                                  </RadioGroup>
                                  <br />
                                  <RadioGroup
                                    name="radioList"
                                    inline
                                    onChange={(value, event) => {
                                      this.setState({ MD: value });
                                    }}
                                  >
                                    <span className="h6 w-30 d-inline-block">
                                      Mother
                                    </span>
                                    <Radio className="h6" value="1">
                                      Yes
                                    </Radio>
                                    <Radio className="h6" value="0">
                                      No
                                    </Radio>
                                  </RadioGroup>
                                  <br />
                                  <RadioGroup
                                    name="radioList"
                                    inline
                                    onChange={(value, event) => {
                                      this.setState({ BD: value });
                                    }}
                                  >
                                    <span className="h6 w-30 d-inline-block">
                                      Brother
                                    </span>
                                    <Radio className="h6" value="1">
                                      Yes
                                    </Radio>
                                    <Radio className="h6" value="0">
                                      No
                                    </Radio>
                                  </RadioGroup>
                                  <br />
                                  <RadioGroup
                                    name="radioList"
                                    inline
                                    onChange={(value, event) => {
                                      this.setState({ SD: value });
                                    }}
                                  >
                                    <span className="h6 w-30 d-inline-block">
                                      Sister
                                    </span>
                                    <Radio className="h6" value="1">
                                      Yes
                                    </Radio>
                                    <Radio className="h6" value="0">
                                      No
                                    </Radio>
                                  </RadioGroup>
                                  <br />
                                  <RadioGroup
                                    name="radioList"
                                    inline
                                    onChange={(value, event) => {
                                      this.setState({ MMD: value });
                                    }}
                                  >
                                    <span className="h6 w-30 d-inline-block">
                                      Mothers Mother
                                    </span>
                                    <Radio className="h6" value="1">
                                      Yes
                                    </Radio>
                                    <Radio className="h6" value="0">
                                      No
                                    </Radio>
                                  </RadioGroup>
                                  <br />
                                  <RadioGroup
                                    name="radioList"
                                    inline
                                    onChange={(value, event) => {
                                      this.setState({ MFD: value });
                                    }}
                                  >
                                    <span className="h6 w-30 d-inline-block">
                                      Mothers Father
                                    </span>
                                    <Radio className="h6" value="1">
                                      Yes
                                    </Radio>
                                    <Radio className="h6" value="0">
                                      No
                                    </Radio>
                                  </RadioGroup>
                                  <br />
                                  <RadioGroup
                                    name="radioList"
                                    inline
                                    onChange={(value, event) => {
                                      this.setState({ FMD: value });
                                    }}
                                  >
                                    <span className="h6 w-30 d-inline-block">
                                      Fathers Mother
                                    </span>
                                    <Radio className="h6" value="1">
                                      Yes
                                    </Radio>
                                    <Radio className="h6" value="0">
                                      No
                                    </Radio>
                                  </RadioGroup>
                                  <br />
                                  <RadioGroup
                                    name="radioList"
                                    inline
                                    onChange={(value, event) => {
                                      this.setState({ FFD: value });
                                    }}
                                  >
                                    <span className="h6 w-30 d-inline-block">
                                      Fathers Father
                                    </span>
                                    <Radio className="h6" value="1">
                                      Yes
                                    </Radio>
                                    <Radio className="h6" value="0">
                                      No
                                    </Radio>
                                  </RadioGroup>
                                  <br />
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <label className="h6">
                                  Do you any of following members of your family
                                  have Hypertension ?
                                </label>
                                <br />
                                <div className="pl-2">
                                  <RadioGroup
                                    name="radioList"
                                    inline
                                    onChange={(value, event) => {
                                      this.setState({ FH: value });
                                    }}
                                  >
                                    <span className="h6 w-30 d-inline-block">
                                      Father
                                    </span>
                                    <Radio className="h6" value="1">
                                      Yes
                                    </Radio>
                                    <Radio className="h6" value="0">
                                      No
                                    </Radio>
                                  </RadioGroup>
                                  <br />
                                  <RadioGroup
                                    name="radioList"
                                    inline
                                    onChange={(value, event) => {
                                      this.setState({ MH: value });
                                    }}
                                  >
                                    <span className="h6 w-30 d-inline-block">
                                      Mother
                                    </span>
                                    <Radio className="h6" value="1">
                                      Yes
                                    </Radio>
                                    <Radio className="h6" value="0">
                                      No
                                    </Radio>
                                  </RadioGroup>
                                  <br />
                                  <RadioGroup
                                    name="radioList"
                                    inline
                                    onChange={(value, event) => {
                                      this.setState({ BH: value });
                                    }}
                                  >
                                    <span className="h6 w-30 d-inline-block">
                                      Brother
                                    </span>
                                    <Radio className="h6" value="1">
                                      Yes
                                    </Radio>
                                    <Radio className="h6" value="0">
                                      No
                                    </Radio>
                                  </RadioGroup>
                                  <br />
                                  <RadioGroup
                                    name="radioList"
                                    inline
                                    onChange={(value, event) => {
                                      this.setState({ SH: value });
                                    }}
                                  >
                                    <span className="h6 w-30 d-inline-block">
                                      Sister
                                    </span>
                                    <Radio className="h6" value="1">
                                      Yes
                                    </Radio>
                                    <Radio className="h6" value="0">
                                      No
                                    </Radio>
                                  </RadioGroup>
                                  <br />
                                  <RadioGroup
                                    name="radioList"
                                    inline
                                    onChange={(value, event) => {
                                      this.setState({ MMH: value });
                                    }}
                                  >
                                    <span className="h6 w-30 d-inline-block">
                                      Mothers Mother
                                    </span>
                                    <Radio className="h6" value="1">
                                      Yes
                                    </Radio>
                                    <Radio className="h6" value="0">
                                      No
                                    </Radio>
                                  </RadioGroup>
                                  <br />
                                  <RadioGroup
                                    name="radioList"
                                    inline
                                    onChange={(value, event) => {
                                      this.setState({ MFH: value });
                                    }}
                                  >
                                    <span className="h6 w-30 d-inline-block">
                                      Mothers Father
                                    </span>
                                    <Radio className="h6" value="1">
                                      Yes
                                    </Radio>
                                    <Radio className="h6" value="0">
                                      No
                                    </Radio>
                                  </RadioGroup>
                                  <br />
                                  <RadioGroup
                                    name="radioList"
                                    inline
                                    onChange={(value, event) => {
                                      this.setState({ FMH: value });
                                    }}
                                  >
                                    <span className="h6 w-30 d-inline-block">
                                      Fathers Mother
                                    </span>
                                    <Radio className="h6" value="1">
                                      Yes
                                    </Radio>
                                    <Radio className="h6" value="0">
                                      No
                                    </Radio>
                                  </RadioGroup>
                                  <br />
                                  <RadioGroup
                                    name="radioList"
                                    inline
                                    onChange={(value, event) => {
                                      this.setState({ FFH: value });
                                    }}
                                  >
                                    <span className="h6 w-30 d-inline-block">
                                      Fathers Father
                                    </span>
                                    <Radio className="h6" value="1">
                                      Yes
                                    </Radio>
                                    <Radio className="h6" value="0">
                                      No
                                    </Radio>
                                  </RadioGroup>
                                  <br />
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                        {!this.state.Step2 && (
                          <Row>
                            <Col xs={12}>
                              <button
                                type="button"
                                onClick={() => {
                                  this.setState({ Step2: true });
                                }}
                                style={{
                                  float: "right",
                                }}
                                className="blue-btn pt-2 pb-2 pl-5 pr-5 rounded bg-white"
                              >
                                Continue
                              </button>
                            </Col>
                          </Row>
                        )}

                        <br />
                        <br />
                        {this.state.Step2 && (
                          <>
                            {this.state.loader === true ? (
                              <Loader
                                type="TailSpin"
                                width={60}
                                height={60}
                                color="white"
                              />
                            ) : (
                              <>
                                <button
                                  onClick={() => {
                                    this.setState({ Step2: false });
                                  }}
                                  style={{
                                    float: "left",
                                  }}
                                  type="button"
                                  className="blue-btn pt-2 pb-2 pl-5 pr-5 rounded bg-white"
                                >
                                  Back
                                </button>
                                <button
                                  style={{
                                    float: "right",
                                  }}
                                  className="blue-btn pt-2 pb-2 pl-5 pr-5 rounded bg-white"
                                >
                                  Submit {">"}
                                </button>
                              </>
                            )}
                          </>
                        )}
                        <div className="err" id="error-div"></div>
                        <div className="succ" id="success-div"></div>
                        <br />
                        <br />
                        <br />
                      </form>
                    </div>
                  </Col>
                </Row>
              </div>
            </Pulse>
          </div>
        </Container>
      </>
    );
  }
}
