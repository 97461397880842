import React from "react";
import { Container, Col, Row, Button, Badge, Navbar } from "react-bootstrap";
import { fetchDataAuth, postDataAuth } from "../services/request";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft, faBars } from "@fortawesome/free-solid-svg-icons";
import Header2 from "../componenets/headers/Header2";
import Loader from "react-loader-spinner";
import { Alert, Modal } from "rsuite";
import translate from "../i18n/translation";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import Wallet from "./wallet";
import FlutterPay from "./flutterWave";
import PatientHistory from "./patientHistory";
import io from "socket.io-client";
import waveLogo from "../assets/images/fulllogo.png";
import { Redirect } from "react-router-dom";
import { Tooltip, Whisper } from "rsuite";
export default class Assignment extends React.Component {
  constructor(props) {
    super(props);
    this.childWallet = React.createRef();
    this.state = {
      question: false,
      authenticated: null,
      doctors: null,
      assignedDoctor: null,
      socketConnection: null,
      ticketToken: localStorage.getItem("ticket_token"),
      queuedNumber: null,
      docProfile: null,
      doctorImage: null,
      show: false,
      showAiStatsForm: false,
      walletBalance: 0,
    };
  }
  componentDidMount = () => {
    this.setState({ authenticated: this.props.authenticated }, () => {
      this.assign();
      this.showAIStatistics();
      this.checkResolution();
    });
  };
  componentWillReceiveProps = (nextProps) => {
    this.setState(
      {
        authenticated: nextProps.authenticated,
      },
      () => {
        this.assign();
        console.log(this.state.authenticated);
      }
    );
  };

  componentWillMount = () => {
    var token = localStorage.getItem("med_token");
    if (this.props.authenticated && this.props.authenticated.email) {
      this.setUpSocketToListen();
      this.getUserWalletInformation();
    }
  };

  checkResolution = () => {
    console.log(window.innerWidth, "window.innerWidthwindow.innerWidth");
    if (window.innerWidth < 500) {
      this.handleWalletOverview();
    }
  };
  setUpSocketToListen = () => {
    const socket = io("https://telemedicine-server.herokuapp.com/");
    //  const socket = io('http://127.0.0.1:2000/');
    socket.on("connect", function () {
      console.log("connected to " + socket.id);
    });
    this.setState({ socketConnection: socket }, () => {
      this.state.socketConnection.on("queued-updated", (queuedArray) => {
        const index = queuedArray.indexOf(parseInt(this.state.ticketToken));
        console.log("----------", index, queuedArray);
        this.setState({ queuedNumber: index + 1 });
        //this.ticketToken
      });
    });
  };
  getUserWalletInformation = () => {
    var token = localStorage.getItem("med_token");
    postDataAuth(
      "getWalletBalance",
      {
        email: this.props.authenticated.email,
      },
      token
    )
      .then((response) => {
        // console.log(response.medicalExperts)
        this.setState({
          walletBalance: response && response.balance ? response.balance : "",
        });
      })
      .catch((error) => {});
  };
  assign = () => {
    if (this.state.authenticated.assigned === null) {
      var token = localStorage.getItem("med_token");
      fetchDataAuth("allDoctors", {}, token)
        .then((response) => {
          // console.log(response.medicalExperts)
          this.setState(
            {
              doctors: response.medicalExperts,
            },
            () => {
              var unassigned = this.state.doctors.filter((item) => {
                return item.active === 0;
              });
              console.log(unassigned);
              unassigned = unassigned.filter((item) => {
                return item.country === this.props.authenticated.country;
              });
              var samecountry_unassigned = unassigned.filter((item) => {
                return item.assigned === null;
              });
              console.log(samecountry_unassigned);
              if (samecountry_unassigned.length == 0) {
                this.setState({ question: true });
              } else {
                var doctor_assigned =
                  samecountry_unassigned[
                    Math.floor(Math.random() * samecountry_unassigned.length)
                  ];
                var token = localStorage.getItem("med_token");
                this.setState(
                  {
                    assignedDoctor: doctor_assigned,
                  },
                  () => {
                    postDataAuth(
                      "assignDoctors",
                      {
                        id: doctor_assigned.id,
                      },
                      token
                    )
                      .then((response) => {
                        if (response.status === true) {
                          if (response.status === true) {
                            postDataAuth(
                              "assignPatients",
                              {
                                id: doctor_assigned.id,
                              },
                              token
                            )
                              .then(() => {})
                              .catch(() => {
                                Alert.error("Unable to complete assignment");
                              });
                          }
                        }
                      })
                      .catch(() => {
                        Alert.error("Unable to complete assignment");
                      });
                  }
                );
              }
            }
          );
        })
        .catch((error) => {});
    } else {
      var token = localStorage.getItem("med_token");
      postDataAuth(
        "assignedDoc",
        {
          id: this.state.authenticated.assigned,
        },
        token
      )
        .then((response) => {
          if (response.status === true) {
            this.setState({ assignedDoctor: response.doc });
          }
        })
        .catch((error) => {});
    }
  };
  assignForeign = () => {
    var active = this.state.doctors.filter((item) => {
      return item.active === 0;
    });
    var notCountry = active.filter((item) => {
      return item.country !== this.state.authenticated.country;
    });

    var notAssigned = notCountry.filter((item) => {
      return item.assigned === null;
    });
    if (notAssigned.length < 1) {
      Alert.error(
        "There is no available doctor at the moment, please check back later"
      );
    } else {
      var doctor_assigned =
        notAssigned[Math.floor(Math.random() * notAssigned.length)];
      var token = localStorage.getItem("med_token");
      this.setState(
        {
          assignedDoctor: doctor_assigned,
        },
        () => {
          postDataAuth(
            "assignDoctors",
            {
              id: doctor_assigned.id,
            },
            token
          )
            .then((response) => {
              if (response.status === true) {
                if (response.status === true) {
                  postDataAuth(
                    "assignPatients",
                    {
                      id: doctor_assigned.id,
                    },
                    token
                  )
                    .then(() => {
                      if (response.status === true) {
                        Alert.success("Assignment complete");
                      }
                    })
                    .catch(() => {
                      Alert.error("Unable to complete assignment");
                    });
                }
              } else {
                Alert.error(response.message);
              }
            })
            .catch(() => {
              Alert.error("Unable to complete assignment");
            });
        }
      );
    }
  };

  end = () => {
    Alert.warning("Unfortunately, you cannot use our service at the moment");
  };
  captializeFirstLetter = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  handleWalletOverview = () => {
    this.childWallet.current.handlePatientVitalStats();
  };

  getDoctorProfileImage = (id) => {
    if (!this.state.doctorImage) {
      var token = localStorage.getItem("med_token");
      fetchDataAuth("getDoctorProfile", id, token)
        .then((response) => {
          if (response.status === true) {
            this.setState({
              docProfile: response.profile,
              doctorImage: response.profile.picture,
            });
          }
        })
        .catch(() => {
          Alert.error("Error fetching your patient profile");
        });
    }
  };

  payDoctorFee = () => {
    console.log(this.state.authenticated, "patient");
    console.log(this.state.assignedDoctor, "doctor");
    if (this.state.walletBalance == 0) {
      alert("Please Recharge your Balance");
    } else {
      const payload = {
        email: this.state.assignedDoctor.email,
        amount: 200,
        payeremail: this.state.authenticated.email,
      };
      var token = localStorage.getItem("med_token");
      postDataAuth("userSend", payload, token)
        .then((response) => {
          if (response.message) {
            Alert.success("Doctor Fees Paid Successfully");
            this.getUserWalletInformation();
          }
        })
        .catch(() => {
          Alert.error("Error Payment Transfer");
        });
    }
  };

  showAIStatistics() {
    setTimeout(() => {
      if (!sessionStorage.getItem("aiform")) {
        // this.setState({
        //   showAiStatsForm: true,show:true
        // })
      }
    }, 5000);
  }

  handleAiForm() {
    this.props.history.push({
      pathname: "/patient-detail-history",
      state: { patient: this.props.authenticated },
    });
  }
  render() {
    const tooltip = <Tooltip>View Details</Tooltip>;
    var question = (
      <div className="h5 p-3" style={{ textAlign: "center" }}>
        <br />
        <br />
        <p>{translate("nodoctor")}</p>
        <br />
        <br />
        <button
          className="buttons"
          style={{
            textAlign: "center",
            backgroundColor: "#2c6cb0",
            color: "white",
          }}
          onClick={this.assignForeign}
        >
          {translate("yes")}
        </button>
        <br />
        <br />
        <button
          className="buttons"
          style={{
            textAlign: "center",
            backgroundColor: "#2c6cb0",
            color: "white",
          }}
          onClick={this.end}
        >
          {translate("no")}
        </button>
      </div>
    );
    return (
      <Container className="container-fluid" fluid>
        <Header2 />
        <div className="vital-statis">Online Consultation </div>

        {this.state.showAiStatsForm && (
          <Modal
            backdrop="static"
            show={this.state.show}
            onHide={() => {
              this.setState({ show: false, showAiStatsForm: false });
            }}
            size="sm"
          >
            <Modal.Header>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <h2 className="h2 text-center">
                  While your waiting for doctor would you mind taking 5mins to
                  fill up form.{" "}
                </h2>
                <Button
                  className="btn btn-block p-3 mb-3 BGBlueColor text-white"
                  onClick={this.handleAiForm.bind(this)}
                >
                  Fill the Statical Form
                </Button>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        )}
        <div
          className="whole-box"
          style={{ backgroundColor: "#f2f3f8", height: "auto" }}
        >
          <div
            className="vertical-nav bg-white pt-4 float-left mr-1"
            id="sidebar"
          >
            <ul className="nav flex-column bg-white mb-0">
              <li
                className="nav-item p-3 cursor"
                onClick={this.handleWalletOverview}
              >
                <FontAwesomeIcon
                  size="2x"
                  icon={faBars}
                  className="icons text-dark"
                />
              </li>
            </ul>
          </div>
          <div className="page-content">
            <Row>
              <Col md={3}>
                <Wallet
                  ref={this.childWallet}
                  loggedinUser={this.props.authenticated}
                />
              </Col>
              <Col md={9}>
                <div
                  className="assignment border-secondary border m-5"
                  style={{ backgroundColor: "rgb(242, 243, 248)" }}
                >
                  {this.state.assignedDoctor === null ? null : (
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "3%",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      {this.state.assignedDoctor &&
                        this.state.assignedDoctor.id && (
                          <>
                            <Row>
                              <Col md="10">
                                <h3
                                  className="h1 text-center mt-2 darkblue-color cursor"
                                  onClick={this.props.loadChat}
                                >
                                  <u>Start Converstion With</u>
                                </h3>
                              </Col>
                              <Col md="2">
                                <Button className="float-left p-2 mt-2 BGBlueColor text-white rounded">
                                  Ticket : {this.state.ticketToken}{" "}
                                  <Badge variant="light" className="ml-2">
                                    {this.state.queuedNumber == 1
                                      ? "Your Next"
                                      : this.state.queuedNumber}
                                  </Badge>
                                </Button>
                                {/* <Whisper
          placement="left"
          trigger="hover"
          speaker={tooltip}
          >
          <span className="btn darkblue-btn float-right mt-2" style={{fontSize: "1.1vw"}} onClick={this.handleWalletOverview}><FontAwesomeIcon icon={faWallet} /> Current Balance :  <strong>{this.state.walletBalance} (NGN)</strong> </span>
          </Whisper> */}
                              </Col>
                            </Row>
                            <div
                              className="card border-0 cursor p-3"
                              style={{ backgroundColor: "rgb(242, 243, 248)" }}
                            >
                              <div className="row no-gutters">
                                <div className="col-sm-10">
                                  {this.getDoctorProfileImage(
                                    this.state.assignedDoctor.id
                                  )}
                                
                                  <img
                                    className="card-img rounded-circle customRoundImg"
                                    src={
                                      "https://telemedicine-wave.s3.us-east-2.amazonaws.com/" +
                                      this.state.doctorImage
                                    }
                                    alt={this.state.assignedDoctor.firstname}
                                  />
                                </div>
                                <div className="col-sm-10">
                                  <div className="card-body text-left">
                                    <p className="card-text h2 text-center BGBlueColor-text">
                                      {this.captializeFirstLetter(
                                        this.state.assignedDoctor.firstname
                                      )}{" "}
                                      {this.captializeFirstLetter(
                                        this.state.assignedDoctor.lastname
                                      )}{" "}
                                      from {this.state.assignedDoctor.country}
                                    </p>
                                    <p className="card-text h5 text-center BGBlueColor-text">
                                      {this.state.assignedDoctor.speciality
                                        ? this.captializeFirstLetter(
                                            this.state.assignedDoctor.speciality
                                          )
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <Row>
                              <Col md={8}>
                                <div className="walletInfo">
                                  <Button
                                    className="btn btn-block p-3 mb-3 BGBlueColor text-white"
                                    onClick={this.payDoctorFee}
                                  >
                                    Pay Fee Now
                                  </Button>
                                  <FlutterPay
                                    className="BGBlueColor"
                                    type="topup"
                                    configuration={{
                                      text: "Topup Your Wallet",
                                      description: "Top your wallet",
                                      user: this.props.authenticated,
                                      amount: 200,
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md={4}>
                                <Button className="btn darkblue-btn btn-block btn btn-primary p-5 BGBlueColor">
                                  <span
                                    className="h6"
                                    style={{
                                      position: "absolute",
                                      top: "5px",
                                      left: "26px",
                                    }}
                                  >
                                    Total Fee:
                                  </span>
                                  <span className="h1">₦ 200</span>
                                </Button>
                              </Col>
                            </Row>
                          </>
                        )}

                      {/* <p className="mb-3">You can start a conversation with</p>
        {this.state.assignedDoctor !== null ? <button className="buttons"
        style={
          {
            width: "auto",
            backgroundColor: "#2c6cb0",
            color: "white"
          }
        } onClick={this.props.loadChat}>Dr. {
          this.captializeFirstLetter(this.state.assignedDoctor.firstname) + " " + this.captializeFirstLetter(this.state.assignedDoctor.lastname) + " " + "from" + " " + this.state.assignedDoctor.country
        }</button>: null}
        <br/> */}
                      <br />
                      {/* <FlutterPay /> */}

                      {/* <button tton className="btn btn-outline-success">Pay 200 (NGN) Fee</button> */}
                    </div>
                  )}
                  {this.state.assignedDoctor === null ? (
                    this.state.question === true ? (
                      question
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <Loader
                          type="TailSpin"
                          width="80"
                          height="80"
                          color="#2c6cb0"
                        />
                        <br />
                        <p>Fetching doctors available in your country</p>
                      </div>
                    )
                  ) : null}
                  {/* {this.state.question === true ? question : <div style={{textAlign:"center"}}>
      <br/><br/><br/><br/><br/>
      <Loader type="TailSpin" width="80" height="80" color="#2c6cb0"/>
      <br/><p>Fetching doctors available in your country</p>
    </div>} */}{" "}
                </div>
                <div className="patientHistoryWrap">
                  <PatientHistory loadChat={this.props.loadChat} />
                </div>
              </Col>
              <Col md={4}></Col>
            </Row>
          </div>
        </div>
      </Container>
    );
  }
}
