import React from "react";
import { fetchData, postDataAuth } from "../../services/request";
import NavBar from "./navbar";
import swal from "sweetalert";

export default class FeedBack extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feedBack: [],
    };
  }
  componentDidMount = async () => {
    const data = await fetchData("all-feedback");
    console.log(data);
    this.setState({
      feedBack: data.feednacks,
    });
  };

  ActiveUser = (id) => {
    swal({
      title: "Are you sure?",
      text: "You Done this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        postDataAuth("workdone", {
          id: id,
        });

        swal("Poof! Good Job!", {
          icon: "success",
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        swal("Complete it next time!");
      }
    });
  };
  render() {
    return (
      <>
        <div className="container-fluid bo-top">
          <div className="row">
            <div className="col-2 bo-right">
              <NavBar history={this.props.history} />
            </div>
            <div className="col-10 bc-f5">
              <div className="row page-head">Feed Back</div>
              <div className="row page-size">
                <div className="col-12">
                  <table class="table">
                    <thead className="boder-col">
                      <tr>
                        <th scope="col" className="">
                          Id
                        </th>
                        <th scope="col" className="">
                          Name
                        </th>

                        <th scope="col" className="">
                          Email
                        </th>
                        <th scope="col" className="">
                          Type
                        </th>
                        <th scope="col" className="">
                          Message
                        </th>
                        <th scope="col" className="">
                          Status
                        </th>
                      </tr>
                    </thead>
                    {this.state.feedBack.map((data, idx) => (
                      <tbody key={idx}>
                        <tr>
                          <td className="">{data.id}</td>
                          <td className="">{data.name}</td>
                          <td className="">{data.email}</td>
                          <td className="">{data.userType}</td>
                          <td className="">{data.message}</td>

                          {data.status === 1 && <td className="">Solved</td>}
                          {data.status === 0 && (
                            <>
                              <td className="">Pending</td>

                              <td>
                                <div
                                  className="act-bt"
                                  onClick={() => {
                                    this.ActiveUser(data.id);
                                  }}
                                >
                                  Solved
                                </div>
                              </td>
                            </>
                          )}
                        </tr>
                      </tbody>
                    ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
