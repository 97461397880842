import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { Alert } from "rsuite";
import { postData } from "../services/request";
import { Link, Redirect } from "react-router-dom";
import waveLogo from "../assets/images/fulllogo.png"
import translate from "../i18n/translation";
export default class RuralLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      authenticated: false,
    };
  }

  login = (e) => {
    e.preventDefault();
    var details = {
      phone: e.target.phone.value,
      password: e.target.password.value,
    };
    if (details.phone.trim() === null || details.phone.trim() === "") {
      Alert.warning("Input your registered phone number");
    } else if (
      details.password.trim() === null ||
      details.password.trim() === ""
    ) {
      Alert.warning("Input your password");
    } else {
      this.setState({ loader: true });
      postData("patient-login", details)
        .then((response) => {
          this.setState({ loader: false });
          console.log(response);
          if (response.status === true) {
            localStorage.setItem("med_token", response.token);
            localStorage.setItem("ticket_token", response.ticket);
            Alert.success("Logging you in......");
            setTimeout(() => {
              this.setState({ authenticated: true });
            }, 5000);
          } else {
            Alert.error("Error logging you in");
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
          if (error.request.status == 401) {
            Alert.error("Authentication Failed");
          } else {
            Alert.error("Error connecting to server");
          }
        });
    }
  };
  showPassword = () => {
    var password = document.getElementById("password");
    if (password.type === "password") {
      password.type = "text";
    } else {
      password.type = "password";
    }
  };
  render() {
    if (this.state.authenticated === true) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <Container className="container-fluid" fluid>
        <div className="backdrop-signup2">
          <div>
            {/* Normal Version */}
            <Row className="d-none d-sm-block">
              <Col md="3" className="bg-white pt-3" style={{"height": "100vh"}}>
            
                <Row>
                  <Col md={2} className="pt-3">
                      <Link to="/patientIndex"><FontAwesomeIcon size="2x" icon={faArrowCircleLeft} className="icons" style={{"color":"#020197"}}/></Link>
                  </Col>
                  <Col md={10} className="pt-2">
                      <img src={waveLogo} alt="1" className="doc-images float-right"/>
                  </Col>
                </Row>
                <br />
                <br />
                <br />
                <br />
                <p
                  className="text-center font-weight-bold"
                  style={{
                    fontSize: "36px",
                    marginLeft: "10%",
                  }}
                >
                  {translate('rural users')}
                </p>
                <br />
                <form onSubmit={this.login}>
                <div style={{ width: "90%", margin: "auto" }}>
                  <div>
                    <label className="h5">Phone</label>
                    <input
                      type="text"
                      placeholder="Enter Phone"
                      className="text-boxe p-3"
                      name="phone"
                    />
                    <br />
                    <br />
                    <label className="h5">Password</label>
                    <input
                      type="password"
                      placeholder="Password"
                      className="text-boxe p-3"
                      name="password"
                    />
                    <br />
                    <br />
                {this.state.loader === true ? <Loader type="TailSpin" width={60} height={60} color="blue"/> : <button className="blue-btn pt-2 pb-2 pl-5 pr-5 rounded d-block bg-white" style={{color:"white",fontSize:"25px",width:"200px","margin":"0 auto"}}>{translate('login')}</button>}
                <br/>
                <Link className="d-block text-center text-dark" to="/ruralsingup">
                <span
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                >
                Don't have an account ? Signup
                </span>{" "}
                </Link>
                  </div>
                  <br />
                  {/* <Link to="/signup"><span style={{color:"white",fontWeight:"bold",fontSize:"16px"}}>Register</span> </Link>   */}
                </div>
                </form>
              </Col>
              <Col md={9}></Col>
            </Row>
            
            {/* Mobile Version */}
            <Row className="d-block d-sm-none">
              <Col  className="align-self-center p-4">
                <div className="bg-white">
                <Row>
                  <Col xs={12} className="pt-3">
                      <Link to="/patientIndex"><FontAwesomeIcon size="2x" icon={faArrowCircleLeft} className="icons" style={{"color":"#020197"}}/></Link>
                  </Col>
                  {/* <Col md={10} className="pt-2">
                      <img src={waveLogo} alt="1" className="doc-images float-right"/>
                  </Col> */}
                </Row>
                <br />
                <br />

                <p
                  className="text-center font-weight-bold"
                  style={{
                    fontSize: "36px",
                    marginLeft: "10%",
                  }}
                >
                  {translate('rural users')}
                </p>
                <br />
                <form onSubmit={this.login}>
                <div style={{ width: "90%", margin: "auto" }}>
                  <div>
                    <label className="h5">Phone</label>
                    <input
                      type="text"
                      placeholder="Enter Phone"
                      className="text-boxe p-3"
                      name="phone"
                    />
                    <br />
                    <br />
                    <label className="h5">Password</label>
                    <input
                      type="password"
                      placeholder="Password"
                      className="text-boxe p-3"
                      name="password"
                    />
                    <br />
                    <br />
                {this.state.loader === true ? <Loader type="TailSpin" width={60} height={60} color="blue"/> : <button className="blue-btn pt-2 pb-2 pl-5 pr-5 rounded d-block bg-white" style={{color:"white",fontSize:"25px",width:"200px","margin":"0 auto"}}>{translate('login')}</button>}
                <br/>
                <Link className="d-block text-center text-dark" to="/ruralsingup">
                <span
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                >
                Don't have an account ? Signup
                </span>{" "}
                </Link>
                  </div>
                  <br />
                  {/* <Link to="/signup"><span style={{color:"white",fontWeight:"bold",fontSize:"16px"}}>Register</span> </Link>   */}
                </div>
                </form>
                </div>
             
                
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    );
  }
}
