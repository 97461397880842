import React from "react"
import { Container,Row,Col } from "react-bootstrap"
import { Uploader, Alert } from 'rsuite';
import { postDataAuth } from "../services/request";
import Loader from "react-loader-spinner";

export default class DocProfile extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            pic:""
        }
    }
   fileChange = (value) =>{
    this.setState({pic:value},()=>{
        if(value.length > 1){
            Alert.error("You can't upload more than a single picture")
            this.state.pic.pop()
            this.setState({pic:value})
        }
    })
   }
   submit = (e) =>{
       e.preventDefault()
       var token = localStorage.getItem("med_token")
       if(this.state.pic === null || this.state.pic === ""){
           Alert.warning("Plase select a file to upload")
       }else{
        var formData = new FormData();
        var file = this.state.pic[0].blobFile
        formData.append("doc_profile_pic",file);
        formData.append("accountNumber", e.target.accountNumber.value);
        formData.append("bankAccount", e.target.bankAccount.value);
        formData.append("beneficiaryName", this.props.authenticated.firstname + " " + this.props.authenticated.lastname);
        this.setState({loader:true})
           postDataAuth("doc-profile",formData,token).then((response)=>{
            this.setState({loader:false})
            if(response.status === true){

                Alert.success(response.message)
                this.props.chat()
            }else{
                Alert.error(response.message)
            }
           }).catch(()=>{
            this.setState({loader:false})
            Alert.error("Error updating profile")
           })
       }
   }
    render(){
        return(
         <Container className="container-fluid">

<Row>
    <Col md={2}>
    
    </Col>
    <Col md={8}>
        <form onSubmit={this.submit}  className="signup-form-q">
        <h4>Welcome <span style={{'textTransform':'capitalize'}}>{this.props.authenticated.firstname} {this.props.authenticated.lastname}</span>,Please update your Profile Picture.</h4>
            <hr/>
            <label>Please provide your bank details</label>
            <Row>
                <Col xs={11}>
                <input
                    type="text"
                    placeholder="Account Number"
                    className="text-boxes"
                    name="accountNumber"
                    id="accountNumber"
                />
                </Col>
                <Col xs={1}></Col>
            </Row>
            <br/>
            <Row>
                <Col xs={11}>
                <input
                    type="text"
                    placeholder="Bank Account"
                    className="text-boxes"
                    name="bankAccount"
                    id="bankAccount"
                />
                </Col>
                <Col xs={1}></Col>
            </Row>
            <br/>
            <label>Click the upload button to upload a file to the browser</label>
        <Uploader
          autoUpload={false}
          onChange={this.fileChange}
          ref={ref => {
            this.uploader = ref;
          }}
        />
        <br/>
        {this.state.loader === true ? <Loader type="TailSpin" width={60} height={60} color="#2c6cb0"/> : <button className="buttons" style={{backgroundColor:"rgb(46, 59, 95)",color:"white"}}>
            Submit
        </button>}
        </form>
    </Col>
    <Col md={2}>
    </Col>
</Row>
         </Container>
        )
    }
}