import React from "react";
import { Col, Row } from "react-bootstrap";
import waveLogo from "../../assets/images/fulllogo.png";
import "rsuite/dist/styles/rsuite-default.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Alert } from "rsuite";
import { postData } from "../../services/request";
import {
  faChevronLeft,
  faEye,
  faArrowCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import translate from "../../i18n/translation";
import { Button } from "reactstrap";

export default class NGOSignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "own",
      redirect: false,
      isHmo: false,
      showHmoNumber: false,
      isAgreedCheck: false,
      hmolist: [
        "Hygeia HMO",
        "Total Health Trust HMO",
        "Integrated Healthcare Limited",
        "Avon HMO",
        "AIICO Multi-Shield Nig. Ltd",
        "Princeton Health Limited",
        "Defence Health Maintenance Ltd",
        "Mediplan Healthcare Limited",
        "Clearline International Limited",
        "Reliance HMO",
        "Swift HMO",
        "Novo Health Africa",
        "Pro-Health HMO",
        "Venus Medicare Ltd",
        "Metrohealth HMO",
        "Redcare HMO",
      ],
    };
  }
  signup = (e) => {
    e.preventDefault();
    var details = {
      firstname: e.target.firstname.value,
      lastname: e.target.lastname.value,
      email: e.target.email.value,
      country: e.target.country.value,
      user: "Patient",
      regNumber: null,
      password: e.target.password.value,
      confirm_password: e.target.confirm_password.value,
      cgh: e.target.userType.value,
      insuranceName:
        e.target.insuranceName && e.target.insuranceName.value
          ? e.target.insuranceName.value
          : "",
      insuranceNumber:
        e.target.insuranceNumber && e.target.insuranceNumber.value
          ? e.target.insuranceNumber.value
          : "",
      sponsorName:
        e.target.sponsorName && e.target.sponsorName.value
          ? e.target.sponsorName.value
          : "",
      relation:
        e.target.relation && e.target.relation.value
          ? e.target.relation.value
          : "",
    };
    if (details.firstname.trim() === "" || details.firstname.trim() === null) {
      Alert.warning("Input your first name");
    } else if (
      details.lastname.trim() === "" ||
      details.lastname.trim() === null
    ) {
      Alert.warning("Input your last name");
    } else if (details.email.trim() === "" || details.email.trim() === null) {
      Alert.warning("Input your email");
    } else if (details.country === "Select Country") {
      Alert.warning("Select a country");
    } else if (
      details.password.trim() === null ||
      details.password.trim() === "" ||
      details.password.length <= 7
    ) {
      Alert.warning(
        "Input your password & length should be atleast of 8 characters"
      );
    } else if (
      details.confirm_password.trim() === null ||
      details.confirm_password.trim() === "" ||
      details.confirm_password.length <= 7
    ) {
      Alert.warning(
        "Confirm your password & length should be atleast of 8 characters"
      );
    } else if (details.password.trim() !== details.confirm_password.trim()) {
      Alert.error("Passwords do not match");
    } else {
      this.setState({ loader: true });
      postData("register", details)
        .then((response) => {
          this.setState({ loader: false });
          if (response.status === true) {
            Alert.success(response.message);
            this.props.history.push("/urban-login");
          } else {
            Alert.error(response.message);
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
          if (error.request.status === 422) {
            Alert.error(error.response.data.errors.email);
          } else if (error.request.status === 500) {
            Alert.error(
              "Connection to internet lost, please check connection and retry"
            );
          } else {
            Alert.error("Error creating account");
          }
        });
    }
    this.registerWallet(details);
  };

  registerWallet(details) {
    postData("wallet-registration", {
      name: details.firstname + " " + details.lastname,
      email: details.email,
      password: details.password,
      user_type: "telemedics",
      currency: "NGN",
    })
      .then((response) => {
        if (response.status === true) {
          // Alert.success();
          console.log("success in creation of wallet", response);
        } else {
          console.log("error in creation of wallet");
          // Alert.error(response.message);
        }
      })
      .catch((error) => {
        console.log("error in creation of wallet");
      });
  }

  showPassword = (id) => {
    var password = document.getElementById(id);
    if (password.type === "password") {
      password.type = "text";
    } else {
      password.type = "password";
    }
  };
  _handleCgh = (e) => {
    if (e.target.value === "HMO") {
      this.setState({ isHmo: true });
    } else {
      this.setState({ isHmo: false });
    }
  };
  hmoLists = () => {
    const list = this.state.hmolist.map((item) => (
      <option value={item}>{item}</option>
    ));
    return list;
  };
  _handleSelectHMO = (e) => {
    if (e.target.value) {
      this.setState({
        showHmoNumber: true,
      });
    } else {
      this.setState({
        showHmoNumber: false,
      });
    }
  };

  render() {
    if (
      window.location.href.indexOf("https") == -1 &&
      window.location.href.indexOf("wavetelemedicine") > -1
    ) {
      window.location.href = "https://wavetelemedicine.com/";
    }
    return (
      <div className="container-fluid backimg4">
        <Row className="justify-content-center">
          <div className="col-10 col-sm-6 welcome-container2 flowy">
            <Row>
              <div className="col-4 mt-3">
                <div
                  className="cur"
                  onClick={() => {
                    this.props.history.push("/usertype");
                  }}
                >
                  <span>
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      color="#195773"
                      size="1x"
                    />
                  </span>
                  <span className="cl-19 px16 ml-2">Back</span>
                </div>
              </div>
            </Row>
            <Row className="justify-content-center">
              <div className="col-10 pt-4">
                <img src={waveLogo} alt="1" style={{ width: "45%" }} />
              </div>
              <div className="col-10 px28 fw-b mt-2">Organisation Signup</div>
            </Row>
            <form onSubmit={this.signup}>
              <Row className="justify-content-center">
                <div className="col-10 pt-2">
                  <Row>
                    <Col sm={12} lg={6}>
                      <label className="rem1">Organisation Name</label>
                      <input
                        type="text"
                        name="firstname"
                        id="firstname"
                        className="form-control bc-f5"
                      />
                    </Col>
                    <Col sm={12} lg={6}>
                      <label className="rem1">Organisation Address</label>
                      <input
                        type="text"
                        className="form-control bc-f5"
                        name="lastname"
                        id="lastname"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <label className="rem1">Organisation Email</label>
                      <input
                        type="text"
                        className="form-control bc-f5"
                        name="email"
                        id="email"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12} lg={6}>
                      <label className="rem1">Organisation Number</label>
                      <input
                        type="number"
                        className="form-control bc-f5"
                        name="nin"
                        id="nin"
                      />
                    </Col>
                    <Col sm={12} lg={6}>
                      <label className="rem1">Organisation Type</label>
                      <select
                        className="form-control bc-f5"
                        onChange={this._handleCgh}
                        name="userType"
                        id="userType"
                      >
                        <option className="form-control"></option>
                        <option value="NGO">NGO</option>
                        <option value="Charities">Charities</option>
                        <option value="Students">Students</option>
                        <option value="IT">IT</option>
                        <option value="Banking">Banking</option>
                      </select>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} lg={6}>
                      <label className="rem1">Date of Build</label>
                      <input
                        type="date"
                        className="form-control bc-f5"
                        name="dob"
                        id="dob"
                      />
                    </Col>
                    <Col sm={12} lg={6}>
                      <label className="rem1">Country</label>
                      <select
                        className="form-control bc-f5"
                        onChange={this.check}
                        name="country"
                        id="country"
                      >
                        <option className="form-control bc-f5"></option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="USA">USA</option>
                      </select>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12} lg={6} className="position-relativt">
                      <label className="rem1">Password</label>
                      <input
                        type="password"
                        className="form-control bc-f5"
                        name="password"
                        id="password"
                      />
                      <FontAwesomeIcon
                        onClick={() => {
                          this.showPassword("password");
                        }}
                        icon={faEye}
                        style={{
                          marginTop: "-24px",
                          cursor: "pointer",
                          position: "absolute",
                          zIndex: 9,
                          right: "20px",
                        }}
                      />
                    </Col>
                    <Col sm={12} lg={6}>
                      <label className="rem1">Confirm Password</label>
                      <input
                        type="password"
                        className="form-control bc-f5"
                        name="confirm_password"
                        id="confirm_password"
                      />
                      <FontAwesomeIcon
                        onClick={() => {
                          this.showPassword("confirm_password");
                        }}
                        icon={faEye}
                        style={{
                          marginTop: "-24px",
                          // marginLeft: "5px",
                          cursor: "pointer",
                          position: "absolute",
                          zIndex: 9,
                          right: "20px",
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row className="no-gutters row">
                    <Col xs={12}>
                      <input
                        type="checkbox"
                        name="checkbox"
                        value="check"
                        id="agree"
                        onChange={(e) => {
                          this.setState({
                            isAgreedCheck: !this.state.isAgreedCheck,
                          });
                        }}
                      />
                      &nbsp;&nbsp;I accept <b>WaveTelemedicine</b> terms and
                      Conditions
                    </Col>
                  </Row>
                  <Row className="justify-content-center mt-4">
                    <div className="col-12">
                      <Button className="signup-btn cur" type="submit">
                        Sign Up
                      </Button>
                    </div>
                  </Row>
                </div>
              </Row>
            </form>
          </div>
        </Row>
      </div>
    );
  }
}
