import en from './en-US';
import de from './de-DE';
import fr from './fr-CA';
import ig from './ig-NI';
import yo from './yo-NI';
import ha from './ha-NI';

export default {
  ...en,
  ...de,
  ...fr,
  ...ig,
  ...yo,
  ...ha,
}