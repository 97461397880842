import React from "react"
import { Container,Row,Col } from "react-bootstrap"
import Loader from "react-loader-spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'rsuite';
import { faEye,faArrowCircleLeft, faThList } from "@fortawesome/free-solid-svg-icons";
import { Link, Redirect } from 'react-router-dom';
import Pulse from 'react-reveal/Pulse';
import { postData } from "../services/request";
import waveLogo from "../assets/images/fulllogo.png"
import translate from "../i18n/translation";

export default class Ups extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            redirect:false,
            isHmo : false,
            showHmoNumber: false,
            isAgreedCheck: false,
            hmolist : [
                'Hygeia HMO',
                'Total Health Trust HMO',
                'Integrated Healthcare Limited',
                'Avon HMO',
                'AIICO Multi-Shield Nig. Ltd',
                'Princeton Health Limited',
                'Defence Health Maintenance Ltd',
                'Mediplan Healthcare Limited',
                'Clearline International Limited',
                'Reliance HMO',
                'Swift HMO',
                'Novo Health Africa',
                'Pro-Health HMO',
                'Venus Medicare Ltd',
                'Metrohealth HMO',
                'Redcare HMO',
            ]
        }
    }
    signup = (e) =>{
        e.preventDefault();
        var details = {
            firstname:e.target.firstname.value,
            lastname:e.target.lastname.value,
            email:e.target.email.value,
            country:e.target.country.value,
            user:"Patient",
            regNumber:null,
            password:e.target.password.value,
            confirm_password:e.target.confirm_password.value,
            cgh: e.target.userType.value,
            insuranceName: e.target.insuranceName && e.target.insuranceName.value ? e.target.insuranceName.value : "",
            insuranceNumber: e.target.insuranceNumber && e.target.insuranceNumber.value ? e.target.insuranceNumber.value : ""
        }
        if(details.firstname.trim() === "" || details.firstname.trim() === null){
            Alert.warning("Input your first name")
        }else if(details.lastname.trim() === "" || details.lastname.trim() === null){
            Alert.warning("Input your last name")
        }else if(details.email.trim() === "" || details.email.trim() === null){
            Alert.warning("Input your email")
        }else if(details.country === "Select Country"){
            Alert.warning("Select a country")
        }else if(details.password.trim() === null || details.password.trim() === "" || details.password.length  <= 7){
            Alert.warning("Input your password & length should be atleast of 8 characters")
        }else if(details.confirm_password.trim() === null || details.confirm_password.trim() === "" || details.confirm_password.length  <= 7){
            Alert.warning("Confirm your password & length should be atleast of 8 characters")
        }else if(details.password.trim() !== details.confirm_password.trim()){
            Alert.error("Passwords do not match")
        }else{
            this.setState({loader:true});
            postData("register",details).then((response)=>{
                this.setState({loader:false});
                if(response.status === true){
                    Alert.success(response.message);
                    this.props.history.push("/UrbanLogin")
                }else{
                    Alert.error(response.message);
                }
            }).catch((error)=>{
                this.setState({loader:false});
                if(error.request.status === 422){
                    Alert.error(error.response.data.errors.email);
                }else if(error.request.status === 500){
                    Alert.error("Connection to internet lost, please check connection and retry");
                }
                else{
                    Alert.error("Error creating account");
                }

            })
        }
        this.registerWallet(details);
    }

    registerWallet(details) {
        postData("wallet-registration", {name: details.firstname + " " + details.lastname, email: details.email,  password: details.password, user_type: "telemedics",currency : "NGN"})
        .then((response) => {
        if (response.status === true) {
            // Alert.success();
            console.log('success in creation of wallet',response);
        } else {
            console.log('error in creation of wallet');
            // Alert.error(response.message);
        }
        })
        .catch((error) => {
          // this.setState({ loader: false });
          // if (error.request.status === 422) {
            // Alert.error(error.response.data.errors.email);
          // } else {
            // Alert.error("Error creating account");
          // }
          console.log('error in creation of wallet');
        });
      }

    showPassword = (id) =>{
        var password = document.getElementById(id);
        if(password.type === "password"){
            password.type = "text"
        }else{
            password.type = "password"
        }
    }
    _handleCgh = (e) => {
        if(e.target.value === "HMO") {
            this.setState({isHmo : true})
        }else{
            this.setState({isHmo : false})
        }
    }
    hmoLists = () => {
    const list = this.state.hmolist.map(item => <option value={item}>{item}</option>)
    return list;
    }
    _handleSelectHMO = (e) => {
        if(e.target.value){
            this.setState({
                showHmoNumber : true
            })
        }else{
            this.setState({
                showHmoNumber : false
            })
        }
    }
    render(){
        return(
            <Container fluid className="container-fluid">
            <div className="backdrop-signup2">
                <Pulse>
                <div className="" >
                    {/* Normal Version */}
                    <Row className="d-none d-sm-block">
                    <Col md="5" className="bg-white pt-3" style={{"height": "100vh"}}>
                    <Row>
                        <Col md={2} className="pt-3">
                            <Link to="/patientIndex"><FontAwesomeIcon size="2x" icon={faArrowCircleLeft} className="icons" style={{"color":"#020197"}}/></Link>
                        </Col>
                        <Col md={10} className="pt-2">
                            <img src={waveLogo} alt="1" className="doc-images float-right"/>
                        </Col>
                    </Row>
                    <br/>
                    <div className="mt-4" style={{width:"90%",margin:"auto"}}>
              <form className="pt-4" onSubmit={this.signup}>
                <h3 className="text-center">Patients Registration</h3>
                <Link className="d-block text-center text-dark" to="/UrbanLogin">
                <span
                style={{
                    fontWeight: "bold",
                    fontSize: "16px"
                }}
                >
                {translate('already have an account?')} {translate('login')}
                </span>{" "}
            </Link>
                  <br/>
            <Row>
                <Col>
                <label className="h5">First Name</label>
                <input type="text" placeholder="First Name" name="firstname" id="firstname" className="text-boxe"/>
                </Col>
                <Col>
                <label className="h5">Last Name</label>
                <input type="text" placeholder="Last Name" className="text-boxe" name="lastname" id="lastname"/>
                </Col>
            </Row>
      <br/>
            <Row>
            <Col>
            <label className="h5">Date of Birth</label>
            <input
            type="date"
            placeholder="Enter your date of birth"
            className="text-boxe"
            name="dob"
            id="dob"
            />
            </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                <label className="h5">Email Address</label>
                <input type="text" placeholder="Email Name" className="text-boxe" name="email" id="email"/>
                </Col>
            </Row>
    <br/>
    <Row>
    <Col>
    <label className="h5">Users</label>
    <select className="text-boxe" onChange={this._handleCgh} name="userType" id="userType">
    <option className="text-boxe">Select Users</option>
    <option value="Corportate">Corportate</option>
    <option value="Government">Government</option>
    <option value="HMO">HMO</option>
    </select><br/><br/>
    </Col>
    <Col>
    <label className="h5">Country</label>
    <select className="text-boxe" onChange={this.check} name="country" id="country">
    <option className="text-boxe">Select Country</option>
    <option>Nigeria</option>
    <option>United Kingdom</option>
    <option>USA</option>
    </select><br/><br/>
    </Col>
    </Row>

    {this.state.isHmo && (
        <Row>
        <Col>
        <label className="h5">HMO's</label>
        <select className="text-boxe" onChange={this._handleSelectHMO} name="insuranceName" id="insuranceName">
        <option className="text-boxe">Select HMO</option>
        {
            this.hmoLists()
        }        
        </select><br/><br/>
        </Col>
        </Row>
    )}

    {this.state.showHmoNumber && (
            <Row>
            <Col>
            <label className="h5">Insurance Number</label>
            <input type="text" placeholder="Insurance number" className="text-boxe" name="insuranceNumber" id="insuranceNumber"/>
            <br/><br/></Col>
            </Row>
        )}
    
    <Row className="no-gutters">
        <Col className="position-relative mr-4">
        <label className="h5">Password</label>
        <input type="password" placeholder="Password" className="text-boxe" name="password" id="password"/>
        <FontAwesomeIcon
                onClick={()=>{this.showPassword('password')}}
                icon={faEye}
                style={{
                marginTop: "10px",
                marginLeft: "5px",
                cursor: "pointer",
                position: "absolute",
                zIndex: 9,
                right: "9px"
                }}
                />
        </Col>
        <Col>
        <label className="h5">Confirm Password</label>
        <input type="password" placeholder="Confirm Password" className="text-boxe" name="confirm_password" id="confirm_password"/>
        <FontAwesomeIcon
            onClick={()=>{this.showPassword('confirm_password')}}
            icon={faEye}
            style={{
            marginTop: "10px",
            marginLeft: "5px",
            cursor: "pointer",
            position: "absolute",
            zIndex: 9,
            right: "9px"
            }}
            />
    </Col>
    {/* <Col xs={1}>
    <FontAwesomeIcon onClick={()=>{this.showPassword('password')}} icon={faEye} style={{marginTop:"10px",color:"white",marginLeft:"5px",cursor:"pointer"}}/>
    </Col> */}
    </Row>
    <br/>
    <Row className="no-gutters row">
                                
                                <Col xs={12}>
                                    <input type="checkbox" name="checkbox" value="check" id="agree" onChange={(e)=>{this.setState({isAgreedCheck: !this.state.isAgreedCheck}) }}/> 
                                    &nbsp;&nbsp;I have read and agree to the Terms and Conditions and Privacy Policy
                                  </Col>
                                </Row>
    <br/>
    <Row className="no-gutters">
    
    {/* <Col xs={1}>
    <FontAwesomeIcon onClick={()=>{this.showPassword('confirm_password')}} icon={faEye} style={{marginTop:"10px",color:"white",marginLeft:"5px",cursor:"pointer"}}/>
    </Col> */}
    </Row>
    <br/>
    {this.state.isAgreedCheck && (
                    <>
                    {this.state.loader === true ?<Loader type="TailSpin" width={60} height={60} color="blue"/> : <button
                            style={{
                            float: "right",
                            }}
                            className="blue-btn pt-2 pb-2 pl-5 pr-5 rounded bg-white"
                            >
                            SIGN UP {">"}
                            </button>}
                    </>
                )}  
                {!this.state.isAgreedCheck && (
                    <>
                     <button
                            type="button"
                            style={{
                            float: "right",
                            }}
                            className="btn btn-dark pt-2 pb-2 pl-5 pr-5 rounded"
                            >
                            SIGNUP {">"}
                            </button>
                    </>
                )}  
    <br/><br/><br/>
            <Link to="/UrbanLogin"><span style={{color:"white",fontWeight:"bold",fontSize:"16px",float:"left"}}>Already have an account? Login</span> </Link>
        </form>
                </div>
                    </Col>
                    <Col md="9">
                    </Col>
                </Row>
                    {/* Mobile Version */}
                    <Row className="d-block d-sm-none">
                        <Col className="align-self-center p-4">
                    <div className="area-class text-left p-3 bg-white">
                        <Row>
                            <Col md={2} className="pt-3">
                                <Link to="/patientIndex"><FontAwesomeIcon size="2x" icon={faArrowCircleLeft} className="icons" style={{"color":"#020197"}}/></Link>
                            </Col>
                        {/* <Col md={10} className="pt-2">
                            <img src={waveLogo} alt="1" className="doc-images float-right"/>
                        </Col> */}
                        </Row>
                    <br/>
                        <div className="" style={{width:"90%",margin:"auto"}}>
                            <form className="" onSubmit={this.signup}>
                            <div style={{"maxHeight": "67vh","overflowY":"auto"}}>
                            <h3 className="text-center">Patients Registration</h3>
                            <Link className="d-block text-center text-dark" to="/UrbanLogin">
                            <span
                            style={{
                                fontWeight: "bold",
                                fontSize: "16px"
                            }}
                            >
                            {translate('already have an account?')} {translate('login')}
                            </span>{" "}
                        </Link>
                            <br/>
                        <Row>
                            <Col>
                            <label className="h5">First Name</label>
                            <input type="text" placeholder="First Name" name="firstname" id="firstname" className="text-boxe"/>
                            </Col>
                            <Col>
                            <label className="h5">Last Name</label>
                            <input type="text" placeholder="Last Name" className="text-boxe" name="lastname" id="lastname"/>
                            </Col>
                        </Row>
                <br/>
                        <Row>
                        <Col>
                        <label className="h5">Date of Birth</label>
                        <input
                        type="date"
                        placeholder="Enter your date of birth"
                        className="text-boxe"
                        name="dob"
                        id="dob"
                        />
                        </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                            <label className="h5">Email Address</label>
                            <input type="text" placeholder="Email Name" className="text-boxe" name="email" id="email"/>
                            </Col>
                        </Row>
                <br/>
                <Row>
                <Col>
                <label className="h5">Users</label>
                <select className="text-boxe" onChange={this._handleCgh} name="userType" id="userType">
                <option className="text-boxe">Select Users</option>
                <option value="Corportate">Corportate</option>
                <option value="Government">Government</option>
                <option value="HMO">HMO</option>
                </select><br/><br/>
                </Col>
                <Col>
                <label className="h5">Country</label>
                <select className="text-boxe" onChange={this.check} name="country" id="country">
                <option className="text-boxe">Select Country</option>
                <option>Nigeria</option>
                <option>United Kingdom</option>
                <option>USA</option>
                </select><br/><br/>
                </Col>
                </Row>

                {this.state.isHmo && (
                    <Row>
                    <Col>
                    <label className="h5">HMO's</label>
                    <select className="text-boxe" onChange={this._handleSelectHMO} name="insuranceName" id="insuranceName">
                    <option className="text-boxe">Select HMO</option>
                    {
                        this.hmoLists()
                    }        
                    </select><br/><br/>
                    </Col>
                    </Row>
                )}

                {this.state.showHmoNumber && (
                        <Row>
                        <Col>
                        <label className="h5">Insurance Number</label>
                        <input type="text" placeholder="Insurance number" className="text-boxe" name="insuranceNumber" id="insuranceNumber"/>
                        <br/><br/></Col>
                        </Row>
                    )}
                
                <Row className="no-gutters">
                    <Col xs={12} className="position-relative mr-4">
                    <label className="h5">Password</label>
                    <input type="password" placeholder="Password" className="text-boxe" name="password" id="password"/>
                    <FontAwesomeIcon
                            onClick={()=>{this.showPassword('password')}}
                            icon={faEye}
                            style={{
                            marginTop: "10px",
                            marginLeft: "5px",
                            cursor: "pointer",
                            position: "absolute",
                            zIndex: 9,
                            right: "9px"
                            }}
                            />
                    </Col>
                    <Col xs={12} >
                    <label className="h5">Confirm Password</label>
                    <input type="password" placeholder="Confirm Password" className="text-boxe" name="confirm_password" id="confirm_password"/>
                    <FontAwesomeIcon
                        onClick={()=>{this.showPassword('confirm_password')}}
                        icon={faEye}
                        style={{
                        marginTop: "10px",
                        marginLeft: "5px",
                        cursor: "pointer",
                        position: "absolute",
                        zIndex: 9,
                        right: "9px"
                        }}
                        />
                </Col>
                {/* <Col xs={1}>
                <FontAwesomeIcon onClick={()=>{this.showPassword('password')}} icon={faEye} style={{marginTop:"10px",color:"white",marginLeft:"5px",cursor:"pointer"}}/>
                </Col> */}
                </Row>
                <br/>
                
                <Row className="no-gutters">
                
                {/* <Col xs={1}>
                <FontAwesomeIcon onClick={()=>{this.showPassword('confirm_password')}} icon={faEye} style={{marginTop:"10px",color:"white",marginLeft:"5px",cursor:"pointer"}}/>
                </Col> */}
                </Row>
                            </div>    
                            
                {this.state.loader === true ?<Loader type="TailSpin" width={60} height={60} color="blue"/> : <button
                            style={{
                            float: "right",
                            }}
                            className="blue-btn pt-2 pb-2 pl-5 pr-5 rounded bg-white"
                            >
                            SIGN UP {">"}
                            </button>}
                <br/><br/><br/>
                        <Link to="/UrbanLogin"><span style={{color:"#222",fontWeight:"bold",fontSize:"16px",float:"left"}}>Already have an account? Login</span> </Link>
                    </form>
                        </div>
                        
                    </div>
                        </Col>
                    </Row>
                </div>
                </Pulse>
                </div>
                </Container>
        )
    }
}
