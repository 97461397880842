import React from "react";
import "../../App.css";
import Logo from "../../assets/Logo.png";

export default class Header2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="header">
        <img
          src={Logo}
          alt="wavetelemedicine"
          style={{ paddingTop: "10px", paddingLeft: "25px" }}
        ></img>
      </div>
    );
  }
}
