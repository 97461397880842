import React from "react";
import Chat from "./chat";
import DoctorChat from "./doctorChat";
import "../assets/style/style.less";
import { getAuth, postDataAuth } from "./../services/request";
import SignupQuestion from "./signupQuestions";
import Assignment from "./assignment";
import { Alert } from "rsuite";
import Profile from "./profile";
import DocProfile from "./DocProfileForm";
import { Redirect } from "react-router-dom";
import io from "socket.io-client";
import Comment from "./DialogBox/Comment";

export default class DashBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      receiver: null,
      authenticated: [],
      socketConnection: null,
      activeComponent: null,
      hideVitalStatics:
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.vital
          ? this.props.location.state.vital
          : false,
    };
  }
  handleBackButton = () => {
    this.showAssignment();
  };

  componentDidMount = () => {
    var token = localStorage.getItem("med_token");
    getAuth("authenticated", token)
      .then((response) => {
        this.setState(
          {
            authenticated: response.data.user,
          },
          () => {
            if (this.state.authenticated.usertype === "Patient") {
              postDataAuth("checkDetails", {}, token)
                .then((response) => {
                  if (response.message === "Authentication failed") {
                    Alert.error("You have been logged out due inactive");
                    this.props.history("/");
                    return false;
                  }
                  if (response.status === true) {
                    postDataAuth("details2", {}, token)
                      .then((response) => {
                        if (
                          response.status === false &&
                          this.state.hideVitalStatics
                        ) {
                          this.setState({
                            activeComponent: (
                              <>
                                <SignupQuestion
                                  authenticated={this.state.authenticated}
                                  next={this.showAssignment}
                                  hideVitalStatics={this.state.hideVitalStatics}
                                />
                                <Comment
                                  authenticated={this.state.authenticated}
                                />
                              </>
                            ),
                          });
                        } else {
                          this.setState({
                            activeComponent: (
                              <>
                                <SignupQuestion
                                  authenticated={this.state.authenticated}
                                  next={this.showAssignment}
                                  hideVitalStatics={this.state.hideVitalStatics}
                                />
                                <Comment
                                  authenticated={this.state.authenticated}
                                />
                              </>
                            ),
                          });
                        }
                      })
                      .catch(() => {
                        Alert.error("Error checking your details.");
                      });
                  } else if (
                    response.status === false &&
                    this.state.authenticated.usertype === "Medical Expert"
                  ) {
                    this.setState({
                      activeComponent: (
                        <>
                          <DoctorChat />{" "}
                          <Comment authenticated={this.state.authenticated} />
                        </>
                      ),
                    });
                  } else if (response.status === false) {
                    //  this.setState({activeComponent:<SignupQuestion authenticated={this.state.authenticated} next={this.showAssignment}/>})
                    this.setState({
                      activeComponent: (
                        <>
                          <Profile questions={this.showQuestions} />
                          <Comment authenticated={this.state.authenticated} />
                        </>
                      ),
                    });
                  }
                })
                .catch(() => {
                  Alert.error("Unable to verify details");
                });
            } else {
              postDataAuth("check-doc-profile", {}, token)
                .then((response) => {
                  if (response.status === true) {
                    this.showChat();
                  } else {
                    this.setState({
                      activeComponent: (
                        <>
                          <DocProfile
                            authenticated={this.state.authenticated}
                            chat={this.showDocChat}
                          />
                          <Comment authenticated={this.state.authenticated} />
                        </>
                      ),
                    });
                  }
                })
                .catch(() => {});
            }
          }
        );
      })
      .then(() => {});
  };
  showDocChat = () => {
    this.setState({
      activeComponent: (
        <>
          <DoctorChat /> <Comment authenticated={this.state.authenticated} />
        </>
      ),
    });
  };
  showAssignment = () => {
    this.setState({
      activeComponent: (
        <>
          <Assignment
            loadChat={this.showChat}
            authenticated={this.state.authenticated}
            history={this.props.history}
          />
          <Comment authenticated={this.state.authenticated} />
        </>
      ),
    });
  };
  showQuestions = () => {
    this.setState({
      activeComponent: (
        <>
          <SignupQuestion
            authenticated={this.state.authenticated}
            next={this.showAssignment}
          />
          <Comment authenticated={this.state.authenticated} />
        </>
      ),
    });
  };
  showChat = () => {
    if (this.state.authenticated.usertype === "Patient") {
      this.setState({
        activeComponent: <Chat backButton={this.handleBackButton} />,
      });
    } else if (this.state.authenticated.usertype === "Medical Expert") {
      this.setState({
        activeComponent: (
          <>
            <DoctorChat /> <Comment authenticated={this.state.authenticated} />
          </>
        ),
      });
    }
  };
  render() {
    if (this.state.loggedIn === false) {
      return <Redirect to="/login" />;
    }
    return <div className="dash">{this.state.activeComponent}</div>;
  }
}
