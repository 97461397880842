import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCommentAlt,
  faDollarSign,
  faCog,
  faThLarge,
  faUsers,
  faUserMd,
} from "@fortawesome/free-solid-svg-icons";
import waveLogo from "../../assets/LogoNew.png";
import logout from "../../assets/admin/logout.png";

export default class Navbar extends React.Component {
  constructor(props) {
    super(props);
    const activeNav = sessionStorage.getItem("active-nav");
    this.state = {
      activeLink: activeNav ? activeNav : "adminhome",
    };
  }
  componentDidMount = () => {};
  _HandleClick = (value) => {
    sessionStorage.setItem("active-nav", value);
    this.setState({
      activeLink: value,
    });
  };
  render() {
    return (
      <>
        <div className="container-fluid pt-3">
          <img src={waveLogo} alt="1" className="doc-images ml-2 pb-3" />
          <div
            className={`row hov ${
              this.state.activeLink === "adminDashboard" ? "act-link" : ""
            }`}
            onClick={() => {
              this._HandleClick("adminDashboard");
              this.props.history.push("/adminDashboard");
            }}
          >
            <span>
              <FontAwesomeIcon icon={faThLarge} className="nav-style" />
            </span>
            <span className="nav-text">Dashboard</span>
          </div>

          <div
            className={`row hov ${
              this.state.activeLink === "patients" ? "act-link" : ""
            }`}
            onClick={() => {
              this._HandleClick("patients");
              this.props.history.push("/patients");
            }}
          >
            <span>
              <FontAwesomeIcon icon={faUsers} className="nav-style" />
            </span>
            <span className="nav-text">Patients</span>
          </div>
          <div
            className={`row hov ${
              this.state.activeLink === "doctors" ? "act-link" : ""
            }`}
            onClick={() => {
              this._HandleClick("doctors");
              this.props.history.push("/doctors");
            }}
          >
            <span>
              <FontAwesomeIcon icon={faUserMd} className="nav-style" />
            </span>
            <span className="nav-text">Doctors</span>
          </div>

          <div
            className={`row hov ${
              this.state.activeLink === "feedback" ? "act-link" : ""
            }`}
            onClick={() => {
              this._HandleClick("feedback");
              this.props.history.push("/feedback");
            }}
          >
            <span>
              <FontAwesomeIcon icon={faCommentAlt} className="nav-style" />
            </span>
            <span className="nav-text">Feed Back</span>
          </div>
          <div
            className={`row hov ${
              this.state.activeLink === "transaction" ? "act-link" : ""
            }`}
            onClick={() => {
              this._HandleClick("transaction");
              this.props.history.push("/transaction");
            }}
          >
            <span>
              <FontAwesomeIcon icon={faDollarSign} className="nav-style" />
            </span>
            <span className="nav-text">Transaction</span>
          </div>
          <div
            className={`row hov ${
              this.state.activeLink === "setting" ? "act-link" : ""
            }`}
            onClick={() => {
              this._HandleClick("setting");
              this.props.history.push("/setting");
            }}
          >
            <span>
              <FontAwesomeIcon icon={faCog} className="nav-style" />
            </span>
            <span className="nav-text">Settings</span>
          </div>

          <div className="mt-5 ml-3 ">
            <span className="logout-icon cur">
              <img src={logout} alt="Logout"></img>
            </span>
            <span className="logout cur">Logout</span>
          </div>
        </div>
      </>
    );
  }
}
