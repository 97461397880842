import React from "react";
import Loader from "react-loader-spinner";
import Header2 from "../../componenets/headers/Header2";
import V3 from "../../assets/Vector3.png";
import { Alert } from "rsuite";
import { postData, postNIN } from "../../services/request";
import { Link, Redirect } from "react-router-dom";
import translate from "../../i18n/translation";
export default class RuralUserLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      authenticated: false,
      nin: "",
      fname: "",
      lname: "",
      email: "",
      usertype: "Patient",
      country: "Nigeria",
      password: "patient@123",
    };
  }

  login = async () => {
    var NIN = {
      nin: this.state.nin,
    };
    if (this.state.nin === "") {
      Alert.warning("Enter your registered nin number");
    } else if (this.state.fname === "") {
      Alert.warning("Enter your Name");
    } else if (this.state.lname === "") {
      Alert.warning("Enter your Last Name");
    } else {
      this.setState({
        loader: true,
        email: this.state.fname + this.state.lname + "@gmail.com",
      });
      const ninResponse = await postNIN("fetch-nin-nimc", NIN);
      console.log(ninResponse);
      if (ninResponse.message === "Successful Hit.") {
        if (
          ninResponse.data.firstname.toLowerCase() ===
            this.state.fname.toLowerCase() &&
          ninResponse.data.surname.toLowerCase() ===
            this.state.lname.toLowerCase()
        ) {
          var details = {
            firstname: this.state.fname,
            lastname: this.state.lname,
            email: this.state.email,
            user: this.state.usertype,
            country: this.state.country,
            password: this.state.password,
          };
          const response = await postData("register-rular", details);
          console.log("php response====", response);
          localStorage.setItem("med_token", response.token);
          localStorage.setItem("ticket_token", response.ticket);
          Alert.success("Logging you in......");
          setTimeout(() => {
            this.setState({ authenticated: true });
          }, 5000);
        } else alert("name not match");
      } else {
        Alert.error("Error logging you in");
      }
    }
  };
  render() {
    if (this.state.authenticated === true) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <div className="container-fluid">
        <div className="row ">
          <div className="col-sm-6 head2">
            <Header2 />
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-10">
                  <div className="row">
                    <Link to="/" style={{ textDecoration: "none" }}>
                      <div className="back-btn">
                        <img
                          src={V3}
                          alt=""
                          style={{ paddingBottom: "4px" }}
                        ></img>
                        <span className="pl-3 fz20 fw4 cl1A">Back</span>
                      </div>
                    </Link>
                  </div>
                  <div className="row">
                    <div className=" mt-4">
                      <div className="fz32 fw5 cl1A">Welcome </div>
                      <div className="fz20 fw5">
                        <span>Login as an</span>
                        <span className="pl-2 cl00 fw5">Rural Patient</span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12  mt-4">
                      <div className="row fw4 fz20">
                        <div className="col-sm-4">NIN</div>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="input-field"
                            placeholder="Enter your NIN"
                            onChange={(e) => {
                              this.setState({ nin: e.target.value });
                            }}
                            value={this.state.nin}
                          ></input>
                        </div>
                      </div>
                      <div className="row mt-3 fw4 fz20">
                        <div className="col-sm-4">First Name</div>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="input-field"
                            placeholder="Enter your First Name "
                            onChange={(e) => {
                              this.setState({ fname: e.target.value });
                            }}
                            value={this.state.fname}
                          ></input>
                        </div>
                      </div>
                      <div className="row mt-3 fw4 fz20">
                        <div className="col-sm-4">Last Name</div>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="input-field"
                            placeholder="Enter your Last Name "
                            onChange={(e) => {
                              this.setState({ lname: e.target.value });
                            }}
                            value={this.state.lname}
                          ></input>
                        </div>
                      </div>
                      <div className="row mt-3 forgetPaswd ">
                        <div className="col-12 text-end">
                          Forgot your Password?
                        </div>
                      </div>
                      <div className="row mt-3 uderline"></div>
                      <div className="row justify-content-between mt-3">
                        <div className="col-6">
                          <span style={{ paddingTop: "10px" }}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                            ></input>
                          </span>
                          <span style={{ paddingTop: "10px" }}>
                            {" "}
                            Remember me
                          </span>
                        </div>
                        <div className="col-6 text-right">
                          <button
                            type="button"
                            className="bt-patient"
                            onClick={this.login}
                            disabled={this.state.loader}
                          >
                            {this.state.loader
                              ? "Loging..."
                              : translate("login")}
                          </button>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-12 text-center">
                          <span className="fw3 fz14 m-auto">
                            Don’t have an account?{" "}
                          </span>
                          <Link to="/rural-signup">
                            <span className="fz14 fw5 cl00">Sign Up</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-6 img4 desktop"></div>
        </div>
      </div>
    );
  }
}
