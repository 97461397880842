import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faArrowCircleLeft,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Alert } from "rsuite";
import { postData } from "../../services/request";
import { Link, Redirect } from "react-router-dom";
import waveLogo from "../../assets/images/fulllogo.png";
import { postDr } from "../../services/request";

Alert.config({
  duration: 4000,
  top: 10,
});

export default class RecoverPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      authenticated: false,
      email: "",
      btn: false,
    };
  }
  sendEmail = () => {
    if (this.state.email === "") {
      alert("Please enter your email");
    } else {
      this.setState({
        btn: true,
      });
      const otp = Date.now();
      const mail = {
        email: this.state.email,
        otp: otp,
      };
      postDr("forgetPassword", mail);
    }
  };
  render() {
    if (this.state.authenticated === true) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <div className="container-fluid backimg1">
        <Row className="justify-content-center">
          <div className="col-10 col-sm-4 welcome-container">
            <Row>
              <div className="col-4 mt-3">
                <div
                  className="cur"
                  onClick={() => {
                    this.props.history.push("/");
                  }}
                >
                  <span>
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      color="#195773"
                      size="1x"
                    />
                  </span>
                  <span className="cl-19 px16 ml-2">Back</span>
                </div>
              </div>
            </Row>
            <Row className="justify-content-center">
              <div className="col-10 col-sm-8 pt-4">
                <img src={waveLogo} alt="1" className="doc-images" />
              </div>
              <div className="col-10 col-sm-8 px23 mt-4 fw-b">
                Account Recovery
              </div>
              <br />
              <div className="col-10 col-sm-8">
                <Row>
                  <Col>
                    <br />
                    <label className="rem1">Enter your email address</label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      className="form-control bc-f5"
                      onChange={(e) =>
                        this.setState({
                          email: e.target.value,
                        })
                      }
                      value={this.state.email}
                    />
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  {this.state.btn ? (
                    <div className="col-6 mt-3 mb-5">Check your email</div>
                  ) : (
                    <div
                      className="col-6 green-btn mt-3 mb-5"
                      onClick={this.sendEmail}
                    >
                      Send
                    </div>
                  )}
                </Row>
              </div>
            </Row>
          </div>
        </Row>
      </div>
    );
  }
}
