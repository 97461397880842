import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "rsuite";
import { faEye, faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, Redirect } from "react-router-dom";
import Pulse from "react-reveal/Pulse";
import { postData } from "../services/request";
import translate from "../i18n/translation";

export default class RG extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }
  signup = (e) => {
    e.preventDefault();
    var details = {
      // firstname:e.target.firstname.value,
      // lastname:e.target.lastname.value,
      // email:e.target.email.value,
      // country:e.target.country.value,
      // user:"Patient",
      regNumber: e.target.nin.value,
      password: e.target.password.value,
      confirm_password: e.target.confirm_password.value,
    };
    if (details.regNumber.trim() === "" || details.regNumber.trim() === null) {
      Alert.warning("Provide a valid NIN number");
    } else if (
      details.password.trim() === null ||
      details.password.trim() === "" || 
      details.password.length <= 7  
      ) {
        Alert.warning("Input your password & length should be atleast of 8 characters");
      } else if (
        details.confirm_password.trim() === null ||
        details.confirm_password.trim() === "" ||
        details.confirm_password.length <= 7  
        ) {
          Alert.warning("Confirm your password & length should be atleast of 8 characters");
        } else if (details.password.trim() !== details.confirm_password.trim()) {
          Alert.error("Passwords do not match");
        } else {
          this.setState({ loader: true });
          postData("api/personal-retrieve-nin", { nin: details.regNumber }, true)
          .then((response) => {
            this.setState({ loader: false });
            if (response.info && response.info.length > 0) {
              this.registerUser(response.info[0], details);
              Alert.success(
                "A verified NIN found, Congratulation you have been registered"
                );
              } else {
                Alert.error("No NIN found, redirecting to signup");
                setTimeout(() => {window.location.href= "https://idverify.ng"}, 1000)
                
              }
            })
            .catch((error) => {
              this.setState({ loader: false });
              if (error.request.status === 422) {
                Alert.error(error.response.data.errors.email);
              } else {
                Alert.error("Error creating account");
              }
            });
          }
        };
        registerUser = (data, formData) => {
          var details = {
            firstname: data.firstname,
            lastname: data.lastname,
            email: data.email,
            user: "Patient",
            reg_number: data.NIN,
            password: formData.password,
            confirm_password: formData.confirm_password,
          };
          postData("register", details)
          .then((response) => {
            this.setState({ loader: false });
            if (response.status === true) {
              Alert.success(response.message);
            } else {
              Alert.error(response.message);
            }
          })
          .catch((error) => {
            this.setState({ loader: false });
            if (error.request.status === 422) {
              Alert.error(error.response.data.errors.email);
            } else {
              Alert.error("Error creating account");
            }
          });

          this.registerWallet(details);
        };

        registerWallet(details) {
          postData("wallet-registration", {name: details.firstname + " " + details.lastname, email: details.email,  password: details.password, user_type: "telemedics"})
          .then((response) => {
            if (response.status === true) {
              // Alert.success();
              console.log('success in creation of wallet',response);
            } else {
              console.log('error in creation of wallet');
              // Alert.error(response.message);
            }
          })
          .catch((error) => {
            // this.setState({ loader: false });
            // if (error.request.status === 422) {
              // Alert.error(error.response.data.errors.email);
            // } else {
              // Alert.error("Error creating account");
            // }
            console.log('error in creation of wallet');
          });
        }
        
        render() {
          return (
            <Container fluid className="container-fluid">
            <div className="backdrop-signup">
            <Pulse>
            <div className="backdrop-signup2">
            <Row>
            <Col xs={12}>
            <Link to="/patientIndex">
            <FontAwesomeIcon
            size="2x"
            icon={faArrowCircleLeft}
            className="icons"
            />
            </Link>
            </Col>
            </Row>
            <Row>
            <Col md="3">
            <br />
            <br />
            <br />
            <div style={{ width: "90%", margin: "auto" }}>
            <div>
            <form onSubmit={this.signup} method="post">
            <h3 style={{ color: "white" }}>
            {translate('rural')} {translate('user')} {translate('registration')} {}
            </h3>
            <input
            type="number"
            placeholder="NIN"
            name="nin"
            className="text-boxe"
            />
            <br />
            <br />
            <input
            type="password"
            placeholder="Password"
            name="password"
            className="text-boxe"
            />
            <br />
            <br />
            <input
            type="password"
            className="text-boxe"
            name="confirm_password"
            placeholder="Confirm Password"
            />
            <br />
            <br />
            <button
            style={{
              float: "left",
              backgroundColor: "rgb(46, 59, 95)",
              color: "white",
            }}
            className="buttons"
            >
            {translate('register')}
            </button>
            </form>
            </div>
            <br />
            <br />
            <br />
            <Link to="/rurallogin">
            <span
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: "16px",
              float: "left",
            }}
            >
            {translate('already have an account?')} {translate('login')}
            </span>{" "}
            </Link>
            </div>
            </Col>
            <Col md="9"></Col>
            </Row>
            </div>
            </Pulse>
            </div>
            </Container>
            );
          }
        }
        