import { LOCALES } from "../locales";

export default {
  [LOCALES.GERMAN]: {
    hello: "Hallo",
    welcome: "Willkommen bei der Wave Telemedicine App",
    platformInfo1: `Die Plattform, die es dem Patienten ermöglicht, sich bequem von zu Hause aus mit den besten Ärzten im medizinischen Bereich zu verbinden. Sie können unterwegs bezahlen oder eine Krankenversicherung bei den HMOs abschließen, die mit uns zusammenarbeiten.
    Was auch immer Ihre gesundheitlichen Bedenken sind, wir haben Sie abgesichert.`,
    platformInfo2: `Durch unsere Videokonferenzen können Sie umfassend betreut und Rezepte an eine Apotheke in Ihrer Nähe gesendet werden.`,
    platformInfo3: `Die Situation des Covid-19 hat die Art und Weise unserer Interaktion beeinflusst, was sich auf den medizinischen Sektor ausgewirkt hat.`,
    patient: `Geduldig`,
    "medical Expert": "Medizinischer Experte",
    "sign In": "Einloggen",
    register: `Registrieren`,
    rural: "Ländlich",
    login: "Anmeldung",
    "sign Up": "Anmelden",
    urban: "Städtisch",
    profile: "Profil",
    "date of birth": "Geburtsdatum",
    genotype: "Genotyp",
    "blood Group": "Blutgruppe",
    picture: "Bild",
    submit: "Einreichen",
    "rural users": "LANDLICHE NUTZER",
    user: "Benutzer",
    registration: "Anmeldung",
    "already have an account?": "Sie haben bereits ein Konto?",
    select: "Wählen",
    country: "Land",
    nigeria: "Nigeria",
    "united Kingdom": "Großbritannien",
    usa: "USA",
    "welcome to wave":
      "Willkommen bei WaveTelemedicine. Die Plattform, auf der Sie sich bequem von zu Hause aus mit einem Arzt treffen können. Bitte füllen Sie dieses Formular aus. Die Informationen ermöglichen es dem Arzt, Ihre Situation richtig zu verstehen",
    areUMedication: "Nehmen Sie Medikamente ein?",
    anyIllness: "Irgendeine aktuelle Krankheit?",
    doUSuffer: "Leiden Sie an Diabetes?",
    anyRespiratiory: "Atemwegserkrankungen?",
    no: "Nein",
    yes: "Ja",
    nodoctor:
      "Derzeit ist kein Arzt aus Ihrem Land verfügbar. Möchten Sie mit einem Arzt aus einem anderen Land verbunden sein?",
    startconversation: "Sie können ein Gespräch mit beginnen",
    fetchingdoctors: "In Ihrem Land verfügbare Ärzte holen",
    connectedDoctor: "Verbonden dokter",
    yourconnected: "U bent verbonden met Dr.",
    typeMessage: "Type een bericht",
    patientName: "Patient naam",
    dob: "Geboortedatum",
    bloodGroup: "Bloedgroep",
    genotype: "Genotype",
    anitbodies: "Antilichamen",
    currentIllnes: "Huidige ziekte",
    anyRespiratory: "Elke ademhaling",
    diabetic: "Diabetes",
    familyMedical: "Familie medische geschiedenis",
    bpm: "Bloeddrukmeting",
    bsl: "Bloedsuikerspiegel",
    ct: "Huidige temperatuur",
    cc: "Aanhoudende hoest",
    los: "Verlies van geur",
    aop: "Pijn of pijn?",
    dizziness: "Duizeligheid",
    lob: "Verlies van evenwicht",
    travel: "Bent u de afgelopen 7 dagen het land uit gereisd?",
    meet: "Heb je de afgelopen 7 dagen contact gehad met iemand die net uit het buitenland is teruggekeerd?",
    medical: "Medische geschiedenis",
    nomedicalhistory: "Geen medische geschiedenis gevonden",
    connectedPatient: "Verbonden patiënt",
    viewpatientprofile: "Bekijk het profiel van de patiënt",
    makePrescription: "Maak recept",
    "Hello Doc": "Hallo Doc",
    "I have the Flu": "Ich habe Grippe",
    "I have Sore throat": "Ich habe Halsschmerzen",
    "I have Stomach Cramps": "Ich habe Magenkrämpfe",
    "I feel Dizzy": "Mir ist schwindlig",
    "I feel unwell": "ich fühle mich unwohl",
    "I have a Headache": "Ich habe Kopfschmerzen",
    "I am feeling Feverish": "Ich fühle mich fiebrig",
  },
};
