import React from "react";
import "../../App.css";
// import Ellipse from "../assets/Ellipse.png";
import Logo from "../../assets/Logo.png";

export default class Header3 extends React.Component {
  constructor(props) {
    console.log("props==", props);
    super(props);
    this.state = {
      pageTitle: props.title,
    };
  }

  componentDidMount = () => {};
  render() {
    return (
      <div className="container-fluid header">
        <div className="row">
          <div className="col-6">
            <img
              // src={Ellipse}
              src={Logo}
              alt="wavetelemedicine"
              style={{ paddingTop: "10px", paddingLeft: "25px" }}
            ></img>
            {/* <span className="clff desktop" style={{ marginLeft: "15px" }}>
              Adesuwa Ewatomi
            </span> */}
          </div>
          <div className="col-6">
            <div className="clff fw4 fz20" style={{ paddingTop: "23px" }}>
              {this.state.pageTitle}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
