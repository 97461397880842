import React from "react";
import NavBar from "./navbar";
import Detail from "./detail";
import HeadBar from "./headBar";
import PersonDetail from "./personDetail";

class AdminDashBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="container-fluid bo-top">
        <div className="row">
          <div className="col-2 bo-right">
            <NavBar history={this.props.history} />
          </div>
          <div className="col-10 bc-f1">
            <HeadBar />
            <Detail />
            <PersonDetail />
          </div>
        </div>
      </div>
    );
  }
}
export default AdminDashBoard;
