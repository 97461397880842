import Axios from "axios";

export var basenimc = "https://nimc-app.herokuapp.com/api/";
export var baseUrl = "https://telemedicine-server.herokuapp.com/";
// export var idVerifyBaseURL = "https://idverify.ng/api" LIVE APPLICATION
export var idVerifyBaseURL = "http://idverify-test.wavepalm-effico.com/";
// export var bankUrl = "https://api.flutterwave.com/v3/banks/";
const MailURL = "https://telemed.wavepalm.com";

export var postData = (route, data, isIdverify) => {
  return new Promise((resolve, reject) => {
    let newBase = isIdverify ? idVerifyBaseURL : baseUrl;
    Axios.post(newBase + route, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export var postDataAuth = (route, data, token) => {
  return new Promise((resolve, reject) => {
    Axios.post(baseUrl + route, data, {
      headers: {
        Authorization: "Bearer " + token, //the token is a variable which holds the token
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export var fetchData = (route, item, email, password) => {
  return new Promise((resolve, reject) => {
    Axios.get(baseUrl + route, {
      params: {
        item: item,
        email: email,
        password: password,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export var fetchDataAuth = (route, item, token) => {
  return new Promise((resolve, reject) => {
    Axios.get(baseUrl + route, {
      params: {
        item: item,
      },
      headers: {
        Authorization: "Bearer " + token, //the token is a variable which holds the token
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export var getAuth = (route, token) => {
  return new Promise((resolve, reject) => {
    Axios.get(baseUrl + route, {
      headers: {
        Authorization: "Bearer " + token, //the token is a variable which holds the token
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// NIN
export var postNIN = (route, data) => {
  return new Promise((resolve, reject) => {
    Axios.post(basenimc + route, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// get bank list
export var getBank = (route, data) => {
  return new Promise((resolve, reject) => {
    Axios.post(baseUrl + route, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// get dr
export var postDr = (route, data) => {
  return new Promise((resolve, reject) => {
    Axios.post(baseUrl + route, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// signUpMail
export var signUpMail = (route, data) => {
  return new Promise((resolve, reject) => {
    Axios.post(baseUrl + route, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
