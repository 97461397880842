import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserFriends,
  faPhone,
  faNotesMedical,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";

const Detail = () => {
  return (
    <div className="row justify-content-center mt-4 ">
      <div className="col-11 bc-33 pt-2 pb-2 bd12">
        <div className="row mt-3">
          <div className="col-3 bor-right">
            <div className="row ">
              <div className="col-6  col-ff pl-4">
                <div className="px16 f6">Total Patients</div>
                <div className="px24 f7">33000</div>
              </div>
              <div className="col-6">
                <div className="icon-box2">
                  <FontAwesomeIcon
                    icon={faUserFriends}
                    className="icon-style2"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3 col-b1">
              <div className="col-6 pl-4">
                <div className="px14">Mobile med</div>
                <div className="px16 f6">20000</div>
              </div>
              <div className="col-6">
                <div className="px14">Telemedicine</div>
                <div className="px16 f6">13000</div>
              </div>
            </div>
          </div>
          <div className="col-3 bor-right">
            <div className="row">
              <div className="col-6 col-ff pl-4">
                <div className="px16 f6">Total Doctors</div>
                <div className="px24 f7">33000</div>
              </div>
              <div className="col-6">
                <div className="icon-box2">
                  <FontAwesomeIcon
                    icon={faNotesMedical}
                    className="icon-style2"
                    style={{
                      fontSize: "30px",
                      marginLeft: "13px",
                      marginTop: "8px",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3 col-b1">
              <div className="col-6 pl-4">
                <div className="px14">Mobile med</div>
                <div className="px16 f6">20000</div>
              </div>
              <div className="col-6">
                <div className="px14">Telemedicine</div>
                <div className="px16 f6">13000</div>
              </div>
            </div>
          </div>
          <div className="col-3 bor-right">
            <div className="row">
              <div className="col-6 col-ff pl-4">
                <div className="px16 f6">Total Calls</div>
                <div className="px24 f7">145000</div>
              </div>
              <div className="col-6">
                <div className="icon-box2">
                  <FontAwesomeIcon icon={faPhone} className="icon-style2" />
                </div>
              </div>
            </div>
            <div className="row mt-3 col-b1">
              <div className="col-6 pl-4">
                <div className="px14">Mobile med</div>
                <div className="px16 f6">10000</div>
              </div>
              <div className="col-6">
                <div className="px14">Telemedicine</div>
                <div className="px16 f6">45000</div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="row">
              <div className="col-6 col-ff pl-4">
                <div className="px16 f6">Total Payments</div>
                <div className="px24 f7">$1450000</div>
              </div>
              <div className="col-6">
                <div className="icon-box2">
                  <FontAwesomeIcon icon={faMoneyBill} className="icon-style2" />
                </div>
              </div>
            </div>
            <div className="row mt-3 col-b1">
              <div className="col-6 pl-4">
                <div className="px14">Mobile med</div>
                <div className="px16 f6">10000</div>
              </div>
              <div className="col-6">
                <div className="px14">Telemedicine</div>
                <div className="px16 f6">45000</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail;
