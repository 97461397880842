import React from "react";
import { Input } from "reactstrap";
import Bell from "../../assets/admin/bell.png";

const HeadBar = () => {
  return (
    <div className="row justify-content-between">
      <div className="col-6">
        <div className="col-6 pt-3">
          <Input
            id="search-input"
            placeholder="Search"
            style={{
              borderBottomLeftRadius: 4,
              borderTopLeftRadius: 4,
            }}
          />
        </div>
      </div>
      <div className="col-3">
        <div className="row ">
          <div className="col-3">
            <div className="icon-box">
              <img src={Bell} alt="Bell" className="bell-style"></img>
            </div>
          </div>
          <div className="col-9">
            <img
              src="https://mdbcdn.b-cdn.net/img/new/avatars/1.webp"
              alt="Avatar"
              className="avatar"
            ></img>
            <span style={{ paddingTop: "2px" }}>Administrator</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeadBar;
